<div class="row">
  <div *ngFor="let banner of banners" class="col-12 mt-3"
       [attr.data-testid]="DATA_TEST_ID.dashboard.banner + '-' + currentComponentCount">
    <a
      *ngIf="banner.url; else imgNoLink"
      [href]="banner.url"
      [target]="banner.target"
      [attr.data-testid]="DATA_TEST_ID.dashboard.bannerLink + '-' + currentComponentCount"
    >
      <img [src]="banner.image" alt="" class="img-fluid"/>
    </a>
    <ng-template #imgNoLink>
      <img
        [src]="banner.image"
        alt=""
        class="img-fluid"
      />
    </ng-template>
  </div>
</div>
