import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'val-kontent-form',
  templateUrl: './kontent-form.component.html',
  styleUrls: ['./kontent-form.component.scss'],
})
export class KontentFormComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
