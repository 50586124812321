<div class="dropdown-menu roll-out lists-wrapper" [id]="link.type">
  <div class="pg-title">{{ link.title }}</div>

  <div class="listing" *ngIf="(lists && noResults) || !lists">
    <div class="no-results">
      <div class="block-title" [innerHTML]="!lists ? 'Loading ...' : 'No records found.'"></div>
    </div>
  </div>

  <div *ngIf="lists && !noResults">
    <form *ngIf="showSearch" [formGroup]="searchForm" class="search">
      <input
        class="form-control"
        formControlName="searchQuery"
        type="search"
        (keyup)="getList(link.type, 'search')"
        placeholder="Search"
        aria-label="Search"
      />
      <i class="material-icons-outlined">search</i>
    </form>

    <div class="listing">
      <div class="d-flex page-th">
        <div class="col td-6">List name</div>
        <div class="col td-3">Products</div>
        <div class="col td-3">Actions</div>
      </div>
      <div class="page">
        <form class="tbl-row">
          <div class="details d-flex" *ngFor="let item of list; let i = index">
            <div class="col td-6">
              <span class="_labels">List name</span>
              <a
                class="links"
                [routerLink]="'/product-lists/product-list/' + item.id"
                [attr.data-testid]="DATA_TEST_ID.navbar.productList.link + '-' + i | idFormatter"
                >{{ item.name }}</a
              >
            </div>
            <div class="col td-3"><span class="_labels">Products</span>{{ item.productCount }}</div>
            <div class="col td-3 icon flex-nowrap text-nowrap d-flex justify-content-end gap-2">
              <span class="_labels">Actions</span>
              <a
                class="edit dt-icon md"
                [ngClass]="link.type === 'user' ? 'edit' : 'view'"
                [title]="link.type === 'user' ? 'Edit' : 'View'"
                [routerLink]="'product-lists/product-list/' + item.id"
                [attr.data-testid]="DATA_TEST_ID.navbar.productList.edit + '-' + i | idFormatter"
              ></a>
              <button
                class="btn cart"
                title="Cart"
                (click)="addListToCart(item.id)"
                [attr.data-testid]="DATA_TEST_ID.navbar.productList.cart + '-' + i | idFormatter"
              ></button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
