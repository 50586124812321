import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { DashboardWidget, WidgetId } from './widget.model';
import { DashboardService } from '../dashboard.service';
import { UserModel } from '../../../core/services/user/user.model';
import { FormOption } from '../../../types/form-option.model';
import { CustomerCompanyService } from '../../../core/services/customer-company/customer-company.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';

@Component({
  selector: 'val-widget',
  templateUrl: './widget.component.html',
})
export class WidgetComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() widget: DashboardWidget;
  @Input() user: UserModel | null | undefined;
  @Input() data: any;
  @Output() onRemoveWidget = new EventEmitter<string>();
  public WidgetId = WidgetId;
  public overviewDetails: FormOption[] = [];

  constructor(public dashboardService: DashboardService, private customerCompanyService: CustomerCompanyService) {
    super();
  }

  ngOnInit(): void {
    this.formatOverview();
  }

  private formatOverview(): void {
    if (this.widget.id === WidgetId.OVERVIEW && this.user) {
      if (this.user.email) {
        this.overviewDetails.push({
          label: 'Email address',
          value: this.user.email,
        });
      }

      const phones = [this.user.contactPhoneNumber, this.user.contactMobileNumber].filter((x) => !!x);
      if (phones.length) {
        this.overviewDetails.push({
          label: 'Contact number',
          value: phones.join(' / '),
        });
      }

      let shipToAccount = this.user.username;
      if (this.customerCompanyService.selectedCustomerAccount.value) {
        shipToAccount += ` (${this.customerCompanyService.selectedCustomerAccount.value})`;
      }
      this.overviewDetails.push({
        label: 'Ship-to account',
        value: shipToAccount,
      });
    }
  }

  onDelete(): void {
    this.onRemoveWidget.emit(this.widget.id);
  }
}
