import { Pipe, PipeTransform } from '@angular/core';
import { OrderHistory } from '../../../modules/order/order-history/order-history.model';
import { OrderDetail } from '../../../modules/order/order-detail.model';

@Pipe({ name: 'orderNumber' })
export class OrderNumberPipe implements PipeTransform {
  transform(value: OrderHistory | OrderDetail): string {
    const orderNo = 'orderNumber' in value ? value.orderNumber : value.orderno;
    const orderSuffix = 'orderSuffix' in value ? value.orderSuffix : value.ordersuf;
    return `${orderNo}-${orderSuffix.toString().padStart(2, '0')}`;
  }
}
