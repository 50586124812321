import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingSpinnerService } from 'src/app/shared/components/loading-spinner/loading-spinner.service';
import { Breadcrumb } from '../../../breadcrumbs/breadcrumb.model';
import { BreadcrumbsService } from '../../../breadcrumbs/breadcrumbs.service';
import { KontentService } from '../../kontent.service';
import { ArticleItem } from '../../models/content-types/article_item';
import { Title } from '@angular/platform-browser';
import { Banner } from '../../models/content-types/banner';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'val-kontent-article-list',
  templateUrl: './kontent-article-list.component.html',
  styleUrls: ['../kontent-generic/kontent-generic.component.scss', './kontent-article-list.component.scss'],
})
export class KontentArticleListComponent extends BaseSubscriptionComponent {
  banner: any;
  categories: any;
  allArticles: ArticleItem[];
  articles: ArticleItem[];
  selectedCategory: any | null;
  categorySlug: string;
  currentPage: number = 1;
  pageSize: number = 12;
  totalPages: number = 0;
  noResults: boolean = false;
  @ViewChild('articlesTop') articlesTop!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbsService,
    public kontent: KontentService,
    private loadingService: LoadingSpinnerService,
    private titleService: Title,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.destroyed)).subscribe((params) => {
      this.currentPage = 1;
      if (params['category']) {
        this.categorySlug = params['category'];
      }
      this.getData();
    });
  }

  getData() {
    this.loadingService.start();
    this.kontent
      .getArticleListingBanner()
      .then((banner: Banner) => {
        this.banner = banner;
        return this.kontent.getArticleCategories();
      })
      .then((categories: any) => {
        this.categories = categories;
        if (this.categorySlug) {
          const selectedCat = this.categories.filter((c: any) => {
            return c.urlSlug === this.categorySlug;
          });
          this.selectedCategory = selectedCat ? selectedCat[0] : null;
        }

        if (this.selectedCategory) {
          this.titleService.setTitle(this.selectedCategory.label);
          this.breadcrumbService.setBreadcrumbs([
            new Breadcrumb('News', '/articles'),
            new Breadcrumb(this.selectedCategory.label, ''),
          ]);
        } else {
          this.titleService.setTitle('News');
          this.breadcrumbService.setBreadcrumbs([new Breadcrumb('News', '')]);
        }

        return this.kontent.getArticleList(this.selectedCategory ? this.selectedCategory.id : null);
      })
      .then((response) => {
        this.allArticles = response.data.items;
        this.totalPages = Math.ceil(this.allArticles.length / this.pageSize);
        this.setArticlesForCurrentPage();
      })
      .catch((err: any) => {
        console.log('Error: ', err);
        this.router.navigate(['/404']);
      })
      .finally(() => {
        this.loadingService.stop();
      });
  }

  articleUrl(article: any) {
    const dir = this.kontent.categoryObject[article.elements.category.value[0]]?.urlSlug || 'details';
    return `/articles/${dir}/${article.elements.urlSlug.value}`;
  }

  setArticlesForCurrentPage() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    this.articles = this.allArticles.slice(startIndex, startIndex + this.pageSize);
    this.noResults = !this.articles.length;
  }

  onChangePage(newPage: number): void {
    this.currentPage = newPage;
    const element = this.articlesTop.nativeElement as HTMLElement;
    element.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
    this.setArticlesForCurrentPage();
  }
}
