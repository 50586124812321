import { CardSection } from 'src/app/shared/components/import-export/import-export.model';

export const importItemLimit: number = 1000;

export const importQuickListContent: CardSection[] = [
  {
    title: 'Import By: ',
    radioboxes: [
      {
        name: 'productCode',
        value: 'Product Code',
        checked: true,
      },
      {
        name: 'upc',
        value: 'UPC (Universal product code)',
        checked: false,
      },
      {
        name: 'manufacturer',
        value: 'Manufacturer #',
        checked: false,
      },
    ],
  },
  {
    title: 'Ignore First Line: ',
    radioboxes: [
      {
        name: 'yes',
        value: 'Yes',
        checked: false,
      },
      {
        name: 'no',
        value: 'No',
        checked: true,
      },
    ],
  },
  {
    title: '',
    subTitle1: 'File Formats = .xlsx, .csv (Max Number of Products = ' + importItemLimit.toLocaleString('en-US') + ')',
    subTitle2: 'Example shown below: ',
    image: 'assets/img/import-quicklist-example.jpg',
    content: ['Column A: Product code / UPC / Mfg #', 'Column B: Personal code'],
  },
];

export const importProductListContent: CardSection[] = [
  {
    title: 'Import By: ',
    radioboxes: [
      {
        name: 'productCode',
        value: 'Product Code',
        checked: true,
      },
      {
        name: 'personalCode',
        value: 'Personal Code',
        checked: false,
      },
      {
        name: 'upc',
        value: 'UPC (Universal product code)',
        checked: false,
      },
      {
        name: 'manufacturer',
        value: 'Manufacturer #',
        checked: false,
      },
    ],
  },
  {
    title: 'Ignore First Line: ',
    radioboxes: [
      {
        name: 'yes',
        value: 'Yes',
        checked: false,
      },
      {
        name: 'no',
        value: 'No',
        checked: true,
      },
    ],
  },
  {
    title: '',
    subTitle1: 'File Formats = .xlsx, .csv (Max Number of Products = ' + importItemLimit.toLocaleString('en-US') + ')',
    subTitle2: 'Example shown below: ',
    image: 'assets/img/import-order-example.jpg',
    content: ['Column A: Product Code / Personal Code / UPC / Mfg #', 'Column B: Product Quantity'],
  },
];

export const exportQuickListPDFContent: CardSection[] = [
  {
    title: 'Display Columns: ',
    checkboxes: [
      { label: 'Image', value: 'image', checked: true },
      { label: 'Product Code', value: 'itemId', checked: true },
      { label: 'Personal Code', value: 'personalCodes', checked: false },
      { label: 'Product Title', value: 'title', checked: true },
      { label: 'UPC', value: 'mainUpc', checked: true },
      { label: 'Barcode', value: 'mainUpc', checked: true },
      { label: 'Price', value: 'price', checked: true },
      { label: 'Quantity', value: 'qty', checked: false },
      { label: 'Total', value: 'total', checked: false },
    ],
  },
];

export const exportCartPDFContent: CardSection[] = [
  {
    title: 'Display Mode and Branch: ',
    radioboxes: [
      {
        name: 'modeAndBranch',
        value: 'Yes',
        checked: false,
      },
      {
        name: 'modeAndBranch',
        value: 'No',
        checked: true,
      },
    ],
  },
  {
    title: 'Display Columns: ',
    checkboxes: [
      { label: 'Image', value: 'image', checked: true },
      { label: 'Product Code', value: 'itemId', checked: true },
      { label: 'Personal Code', value: 'personalCodes', checked: false },
      { label: 'Product Title', value: 'title', checked: true },
      { label: 'UPC', value: 'mainUpc', checked: true },
      { label: 'Barcode', value: 'barCode', checked: true },
      { label: 'Price', value: 'price', checked: true },
      { label: 'Quantity', value: 'qty', checked: false },
      { label: 'Total', value: 'total', checked: false },
    ],
  },
];

export const exportOrderDetailsPDFContent: CardSection[] = [
  {
    title: 'Display Order Details: ',
    radioboxes: [
      {
        name: 'orderDetails',
        value: 'Yes',
        checked: false,
      },
      {
        name: 'orderDetails',
        value: 'No',
        checked: true,
      },
    ],
  },
  {
    title: 'Display Shipping Details: ',
    radioboxes: [
      {
        name: 'shippingDetails',
        value: 'Yes',
        checked: false,
      },
      {
        name: 'shippingDetails',
        value: 'No',
        checked: true,
      },
    ],
  },
  {
    title: 'Display Columns: ',
    checkboxes: [
      { label: 'Image', value: 'image', checked: false },
      { label: 'Product Code', value: 'productNumber', checked: true },
      { label: 'Personal Code', value: 'personalCodes', checked: false },
      { label: 'Product Title', value: 'title', checked: true },
      { label: 'UPC', value: 'mainUpc', checked: false },
      { label: 'Price', value: 'sellPrice', checked: false },
      { label: 'Quantity', value: 'quantityOrdered', checked: false },
      { label: 'Total', value: 'netAmount', checked: false },
    ],
  },
];

export const exportQuickListExcelContent: CardSection[] = [
  {
    title: 'Display Columns: ',
    checkboxes: [
      { label: 'Product Code', value: 'itemId', checked: true },
      { label: 'Personal Code', value: 'personalCodes', checked: false },
      { label: 'Product Title', value: 'title', checked: true },
      { label: 'UPC', value: 'mainUpc', checked: true },
      { label: 'Price', value: 'price', checked: true },
      { label: 'Quantity', value: 'qty', checked: true },
      { label: 'Total', value: 'total', checked: true },
    ],
  },
];

export const exportExcelContent: CardSection[] = [
  {
    title: 'Display Columns: ',
    checkboxes: [
      { label: 'Product Code', value: 'itemId', checked: true },
      { label: 'Personal Code', value: 'personalCodes', checked: false },
      { label: 'Product Title', value: 'title', checked: true },
      { label: 'UPC', value: 'mainUpc', checked: true },
      { label: 'Price', value: 'price', checked: true },
      { label: 'Quantity', value: 'qty', checked: true },
      { label: 'Total', value: 'total', checked: true },
    ],
  },
];

export const exportOrderDetailsExcelContent: CardSection[] = [
  {
    title: 'Display Columns: ',
    checkboxes: [
      { label: 'Product Code', value: 'itemId', checked: true },
      { label: 'Personal Code', value: 'personalCodes', checked: false },
      { label: 'Product Title', value: 'title', checked: true },
      { label: 'UPC', value: 'mainUpc', checked: true },
      { label: 'Price', value: 'price', checked: true },
      { label: 'Quantity', value: 'qty', checked: true },
      { label: 'Total', value: 'total', checked: true },
    ],
  },
];
