import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgxCSVParserError, NgxCsvParser } from 'ngx-csv-parser';
import readXlsxFile from 'read-excel-file';
import {
  exportCartPDFContent,
  exportExcelContent,
  exportOrderDetailsPDFContent,
  exportQuickListExcelContent,
  exportQuickListPDFContent,
  importProductListContent,
  importQuickListContent,
  exportOrderDetailsExcelContent,
  importItemLimit,
} from 'src/assets/data/import-export';
import {
  ImportExportAction,
  CardSection,
  ActionFormat,
  Radio,
  ActionType,
  ImportedProduct,
  ImportResponse,
  Checkbox,
  SelectedCheckboxAndRadio,
  ExportMappingType,
  productImportTypeMapping,
} from './import-export.model';
import { ToastService } from '../toast/toast.service';
import { ImportExportService } from 'src/app/core/services/import-export/import-export.service';
import { PageType } from '../../consts/page-type';
import { UserModel } from 'src/app/core/services/user/user.model';
import * as XLSX from 'xlsx';
import { ProductResult, ProductSearchResults, SearchOptions } from 'src/app/core/services/search/search.model';
import { LoadingSpinnerService } from '../loading-spinner/loading-spinner.service';
import { SearchService } from 'src/app/core/services/search/search.service';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../../types/app-state.interface';
import { WINDOW } from 'src/app/app.module';

@Component({
  selector: 'val-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.scss'],
})
export class ImportExportComponent implements OnInit, OnChanges {
  @Output() cancel = new EventEmitter<void>();
  @Input() actionType: ImportExportAction;
  @Input() listName: string = '';
  // TODO: Output data results to item-list-actions to display successful and failed imports
  @Output() importedData = new EventEmitter<ImportResponse>();
  @Output() closeImport = new EventEmitter<boolean>();
  @Output() importSuccess = new EventEmitter<void>();
  public importError: string = '';
  public ActionType = ActionType;
  @Input() pageType: string | undefined;
  @Input() user: UserModel | undefined;
  @Input() results: ProductResult[];
  listId: string | null;

  public PageType = PageType;
  public section: CardSection[] = [];
  public products: ImportedProduct[] = [];
  quickListId?: number;

  constructor(
    private ngxCsvParser: NgxCsvParser,
    private toast: ToastService,
    private importExportService: ImportExportService,
    public router: Router,
    private loading: LoadingSpinnerService,
    private store: Store<AppStateInterface>,
    private search: SearchService,
    @Inject(WINDOW) private window: Window
  ) {
    this.store.select('user').subscribe((data) => {
      this.quickListId = data?.user?.currentShoppingAccount?.quickListHeader?.id || undefined;
    });
  }

  ngOnInit(): void {
    this.importError = '';
  }

  ngOnChanges(): void {
    if (this.actionType) {
      this.formatContent();
    }
  }

  private formatContent() {
    const formatMapping = {
      [ActionFormat.IMPORT_QUICKLIST]: importQuickListContent,
      [ActionFormat.IMPORT_PRODUCT_LIST]: importProductListContent,
      [ActionFormat.IMPORT_ORDER]: importProductListContent,
      [ActionFormat.IMPORT_ORDER_PAGE]: importProductListContent,
      [ActionFormat.EXPORT_QUICKLIST_PDF]: exportQuickListPDFContent,
      [ActionFormat.EXPORT_CART_PDF]: exportCartPDFContent,
      [ActionFormat.EXPORT_PRODUCT_LIST_PDF]: exportQuickListPDFContent,
      [ActionFormat.EXPORT_PUBLIC_LIST_PDF]: exportQuickListPDFContent,
      [ActionFormat.EXPORT_SHARED_LIST_PDF]: exportQuickListPDFContent,
      [ActionFormat.EXPORT_ORDER_DETAILS_PDF]: exportOrderDetailsPDFContent,
      [ActionFormat.EXPORT_ORDER_DETAILS_EXCEL]: exportOrderDetailsExcelContent,
      [ActionFormat.EXPORT_QUICKLIST_EXCEL]: exportQuickListExcelContent,
      [ActionFormat.EXPORT_EXCEL]: exportExcelContent,
    };
    return (this.section = formatMapping[this.actionType.format] || this.section);
  }

  onFileClick($event: any): void {
    // clear existing value to allow multiple uploads of the same file
    $event.target.value = null;
  }

  fileChangeListener($event: any, content: CardSection[]): any[] | undefined {
    const files = $event.srcElement.files;
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    let propertyName: string = '';

    // Get the yes or no value from the "Ignore First Line" section
    const header = content![1].radioboxes![0].checked;

    // Get the property name from the "Import By" section
    content![0].radioboxes?.forEach((radio) => {
      propertyName = radio.checked ? radio.name : propertyName;
    });

    if (files[0].type === 'text/csv') {
      this.ngxCsvParser
        .parse(files[0], { header: header, delimiter: ',', encoding: 'utf8' })
        .pipe()
        .subscribe({
          next: (result): any[] => {
            const jsonData = this.importExportService.formatImportJson(
              result as any[],
              propertyName,
              this.actionType.format
            );
            this.importProducts(jsonData);
            return (this.products = jsonData);
          },
          error: (error: NgxCSVParserError): void => {
            this.toast.showError('Error while parsing CSV file');
            // console.log('Error', error);
          },
        });
    } else if (files[0].type === excelFileType) {
      readXlsxFile(files[0]).then((rows) => {
        if (header) {
          rows.shift();
        }
        const jsonData = this.importExportService.formatImportJson(rows, propertyName, this.actionType.format);
        this.importProducts(jsonData);
        return (this.products = jsonData);
      });

      return this.products;
    } else {
      this.toast.showError('Invalid File: Please import a valid .csv or .xlsx file.');
    }
    // Reset the input
    $event.srcElement.value = null;
    return;
  }

  private requestOptions: SearchOptions = {
    facetFilters: [],
    facets: [],
    hitsPerPage: 1000,
    page: 0,
  };
  resultsTest: ProductSearchResults = new ProductSearchResults();

  private importProducts(products: ImportedProduct[]) {
    if (products.length > importItemLimit) {
      this.importError =
        'Uploaded file exceeds the limit of ' +
        importItemLimit.toLocaleString('en-US') +
        ' products. Reduce the number of products accordingly.';
      return;
    } else {
      this.importError = '';
    }

    this.loading.start();
    let importedProducts: ImportResponse = {
      products: [],
      invalidProducts: [],
    };

    const importTypes = [
      ActionFormat.IMPORT_QUICKLIST,
      ActionFormat.IMPORT_PRODUCT_LIST,
      ActionFormat.IMPORT_ORDER,
      ActionFormat.IMPORT_ORDER_PAGE,
    ];

    if (this.actionType.format === ActionFormat.IMPORT_ORDER_PAGE) {
      this.handleImportOrder(products, importedProducts);
      return;
    }

    importTypes.forEach((type) => {
      if (this.actionType.format === type) {
        this.importExportService.importProducts(products, type, this.actionType.listId!).subscribe({
          next: (result: any) => {
            importedProducts = result;
            this.importedData.emit(importedProducts);
            this.importSuccess.emit();
            this.toast.showSuccess(`${importedProducts.products?.length} item(s) imported successfully`);
            this.loading.stop();
          },
          error: () => {
            importedProducts.invalidProducts = products;
            this.importedData.emit(importedProducts);
            this.loading.stop();

            this.toast.showError('Error while importing file');
          },
        });
      }
    });
  }

  handleImportOrder(products: ImportedProduct[], importedProducts: ImportResponse) {
    this.window?.localStorage.removeItem('importedData');
    const productArray = products.map((product) => {
      const transformedType =
        productImportTypeMapping[product.type as keyof typeof productImportTypeMapping] || product.type;
      return `${transformedType}: ${product.value}`;
    });

    if (this.requestOptions.facetFilters) {
      this.requestOptions.facetFilters.length = 0;
      this.requestOptions.facetFilters.push(productArray);
    }
    this.search
      .getProductResults('', this.requestOptions)
      .then((res: any) => {
        for (const product of products) {
          const mappedElement = productImportTypeMapping[product.type as keyof typeof productImportTypeMapping];
          let matched = false;
          for (const hit of res.hits) {
            if (hit[mappedElement] === product.value) {
              hit['qty'] = product.quantity;
              matched = true;
              break;
            }
          }
          if (!matched) {
            importedProducts.invalidProducts?.push(product);
          }
        }

        importedProducts.products = res.hits;
        this.importedData.emit(importedProducts);
        this.importSuccess.emit();
        this.window?.localStorage.setItem('importedData', JSON.stringify(importedProducts));
        this.loading.stop();
      })
      .catch(() => {
        importedProducts.invalidProducts = products;
        this.importedData.emit(importedProducts);
        this.loading.stop();
        this.toast.showError('Error while importing file');
      })
      .finally(() => {
        this.loading.stop();
      });

    this.loading.stop();
  }

  public onRadioClick(radio: Radio, radios: Radio[]) {
    radios.forEach((r) => {
      if (r.name !== radio.name) {
        r.checked = false;
      }
    });
    radio.checked ? (radio.checked = false) : (radio.checked = true);
  }

  public exportAction(format: ActionFormat) {
    const checkboxes = this.section.flatMap((s) => s.checkboxes || []).filter((checkbox) => checkbox.checked);
    const radioboxes = this.section.flatMap((s) => s.radioboxes || []).filter((rb) => rb.checked);
    const transformedSection: SelectedCheckboxAndRadio[] = [
      {
        radioboxes: radioboxes,
        checkboxes: checkboxes,
      },
    ];
    this.importExportService.setSelectedCheckboxes(transformedSection);

    const exportMappings: ExportMappingType = {
      [ActionFormat.EXPORT_EXCEL]: () => this.exportDataToExcel(checkboxes),
      [ActionFormat.EXPORT_ORDER_DETAILS_EXCEL]: () => this.exportDataToExcel(checkboxes),
      [ActionFormat.EXPORT_QUICKLIST_EXCEL]: () => this.exportDataToExcel(checkboxes),
      [ActionFormat.EXPORT_QUICKLIST_PDF]: () =>
        this.openExportedTable('quicklist', this.actionType.listId?.toString()),
      [ActionFormat.EXPORT_PRODUCT_LIST_PDF]: () =>
        this.openExportedTable('product-lists', this.actionType.listId?.toString()),
      [ActionFormat.EXPORT_PUBLIC_LIST_PDF]: () =>
        this.openExportedTable('product-lists', this.actionType.listId?.toString()),
      [ActionFormat.EXPORT_SHARED_LIST_PDF]: () =>
        this.openExportedTable('product-lists', this.actionType.listId?.toString()),
      [ActionFormat.EXPORT_ORDER_DETAILS_PDF]: () => this.openExportedTable('order'),
      [ActionFormat.EXPORT_CART_PDF]: () => this.openExportedTable('cart'),
    };

    const action = exportMappings[format];
    if (action) {
      action();
    } else {
      console.error('Invalid export format');
    }
  }

  private exportDataToExcel(checkboxes: Checkbox[]) {
    const selectedCheckboxValues = checkboxes.map((checkbox) => checkbox.value);
    let exportDataObservable;
    if (this.actionType.listId) {
      const actionTypeIdAsString = String(this.actionType.listId);
      if (this.pageType === `${PageType.LISTS} ${PageType.QUICKLIST}`) {
        exportDataObservable = this.importExportService.getExportQuickListData(
          actionTypeIdAsString,
          selectedCheckboxValues
        );
      } else {
        exportDataObservable = this.importExportService.getExportProductsData(
          actionTypeIdAsString,
          selectedCheckboxValues
        );
      }
    } else {
      exportDataObservable = of(this.results);
    }
    if (exportDataObservable) {
      this.loading.start();
      exportDataObservable
        .pipe(
          finalize(() => this.loading.stop()) // Stop spinner after data is processed or if an error occurs
        )
        .subscribe({
          next: (response: any) => {
            const filteredHits = response.map((hit: { [x: string]: any }) => {
              const filteredHit: { [key: string]: any } = {};
              for (const box of checkboxes) {
                let value;
                if (box.label === 'Total') {
                  value = '$' + (hit['qty'] * hit['price']).toFixed(2);
                } else if (box.label === 'Price') {
                  try {
                    value = '$' + hit[box.value].toFixed(2);
                  } catch (e) {
                    value = 'Unable to get pricing';
                  }
                } else if (box.value === 'personalCodes') {
                  value =
                    this.quickListId && hit[box.value] && hit[box.value][this.quickListId]
                      ? hit[box.value][this.quickListId]
                      : '';
                } else {
                  value = hit[box.value];
                }
                filteredHit[box.label] = value;

                if (filteredHit['Quantity'] && hit['uom']) {
                  filteredHit['UOM'] = hit['uom'];
                }
              }
              return filteredHit;
            });

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(filteredHits);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveExcelFile(excelBuffer, this.getTitleFromPageType() + '.xlsx');
          },
          error: (error: any) => {
            this.toast.showError(error);
          },
        });
    }
  }

  private saveExcelFile(buffer: any, fileName: string) {
    const data: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(data);
    const link = this.window?.document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      if(this.window?.document){
        window.URL.revokeObjectURL(url);
        link.remove();
      }
    }, 100);
  }

  public isCheckboxChecked(): boolean {
    for (let section of this.section) {
      if (section.checkboxes) {
        for (let checkbox of section.checkboxes) {
          if (checkbox.checked) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public getTitleFromPageType(): string {
    const titles: { [key: string]: string } = {
      user: 'Product List',
      public: 'Public List',
      shared: 'Shared List',
      quicklist: 'Quick List',
    };

    return this.pageType ? titles[this.pageType] || this.pageType : 'ExportedData';
  }

  private openExportedTable(pathPrefix: string, idSuffix?: string) {
    this.importExportService.setTitle(this.getTitleFromPageType() + ': ' + this.listName);
    let url = `/${pathPrefix}/exportpdf`;
    if (idSuffix) {
      url += `/${idSuffix}`;
    }
    this.openExportPageInNewWindow(url);
  }

  private openExportPageInNewWindow(url: string) {
    const exportWindow = this.window?.open(url, '_blank');
    if (exportWindow) {
      exportWindow.focus();
    } else {
      this.toast.show('Failed to Export PDF');
    }
  }

  onCancel(): void {
    this.importError = '';
    this.cancel.emit();
  }
}
