import { Component, OnDestroy, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { PageTitle } from '../../../shared/components/page-title/page-title.model';
import { ProductListService } from '../../../core/services/product-list/product-list.service';
import { Observable, Subject, Subscription, distinctUntilChanged, firstValueFrom, map, takeUntil } from 'rxjs';
import { ProductList, ProductLists } from '../../../types/product-list.model';
import { ActivatedRoute, NavigationStart, ParamMap, Router } from '@angular/router';
import { ApiService } from '../../../core/services/api/api.service';
import { API_URL } from '../../../shared/consts/api-urls';
import { LoadingSpinnerService } from '../../../shared/components/loading-spinner/loading-spinner.service';
import { select, Store } from '@ngrx/store';
import { AppStateInterface } from '../../../types/app-state.interface';
import { UserModel } from '../../../core/services/user/user.model';
import { userSelector } from '../../../core/store/user/user.selectors';
import { Title } from '@angular/platform-browser';
import { SearchService } from '../../../core/services/search/search.service';
import {
  ProductResult,
  ProductSearchResults,
  SearchOptions,
  CategoryResult,
} from '../../../core/services/search/search.model';
import { ToastService } from '../../../shared/components/toast/toast.service';
import { PageType } from '../../../shared/consts/page-type';
import { PricingService } from '../../../core/services/pricing/pricing.service';
import { ItemListService } from '../../../core/services/item-list/item-list.service';
import { ProductQuickList } from 'src/app/types/quick-list.model';
import { FacetGroup } from '../../../core/services/search/search-filter/search-filter.model';
import { SearchFilterService } from '../../../core/services/search/search-filter/search-filter.service';
import { FormOption } from '../../../types/form-option.model';
import { SiteCartService } from '../../../core/services/cart/site-cart.service';
import { Cart } from 'src/app/types/cart.model';
import { ShowDetails } from 'src/app/shared/components/products/item-list-actions/item-list-actions.component';
import { WINDOW } from 'src/app/app.module';

@Component({
  selector: 'val-user-product-list',
  templateUrl: 'product-list.component.html',
  styleUrls: [
    '../../../../assets/styles/shared/item-list.scss',
    '../../../../assets/styles/shared/product-filters.scss',
    '../../../../assets/styles/shared/product-list.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ProductListComponent implements OnInit, OnDestroy {
  pageTitle: PageTitle;
  lists: ProductLists | null;
  results: ProductSearchResults;
  user?: UserModel;
  public list?: ProductQuickList;
  quicklist: boolean;
  private itemListQty: { [key: string]: number } = {};
  private listTitle: { [key: string]: string } = {};
  private listSub: Subscription;
  private stopQuicklistWatch$ = new Subject<void>();
  private requestOptions: SearchOptions = {
    facetFilters: [],
    facets: ['*'],
    hitsPerPage: 24,
    page: 0,
  };
  page = 1;
  query = '';
  pageType: string = PageType.LISTS;
  private userSub: Subscription;
  private user$: Observable<UserModel | null | undefined>;

  pageTypeEnum = PageType;
  products: ProductResult[];
  facets: any[];
  activeFilters: any[] = [];
  currentIndex: string = '';
  categoryFilters: FacetGroup | null = null;
  facetFilters: FacetGroup[] | null;
  itemFilters: any[] = [];
  maxFilters: number = 10;
  data: any = [];
  category: CategoryResult;
  b2b: boolean = false;
  listId: number;
  listName: string;
  public listProductCount?: number;
  cart: Cart;
  isCollapsedDetails: ShowDetails = {
    collapsed: true,
    classes: ['details'],
  };
  private previousQParam: string | null = null;

  constructor(
    private api: ApiService,
    private listService: ProductListService,
    private itemList: ItemListService,
    private loading: LoadingSpinnerService,
    private pricing: PricingService,
    private route: ActivatedRoute,
    private router: Router,
    private search: SearchService,
    private store: Store<AppStateInterface>,
    private toast: ToastService,
    private titleService: Title,
    private searchFilter: SearchFilterService,
    private siteCart: SiteCartService,
    @Inject(WINDOW) private window: Window
  ) {
    this.user$ = this.store.pipe(select(userSelector));
    this.quicklist = route.snapshot.data['quicklist'] || false;
  }

  ngOnInit(): void {
    this.searchFilter.clear();
    this.listTitle = {
      user: 'Product List',
      shared: 'Shared List',
      public: 'Public List',
      quicklist: 'Quick List',
    };
    this.pageTitle = new PageTitle(
      this.quicklist ? this.listTitle['quicklist'] : this.listTitle['user'],
      'assignment',
      ''
    );

    this.userSub = this.user$.subscribe((user) => {
      this.user = user || undefined;
    });

    this.listSub = this.listService.productLists$.subscribe((lists) => {
      this.lists = lists;
      // Make sure this is a list that the user is allowed to view
      if (this.list && !this.listService.userCanViewList(this.list.id)) {
        this.router.navigate(['/404']);
        return;
      }
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      const currentQParam = params.get('listId');
      if (currentQParam) {
        this.listId = Number(currentQParam);
        this.getList(this.listId);
      }

      if (this.previousQParam && this.previousQParam !== currentQParam) {
        this.searchFilter.clear();
      }
      this.previousQParam = currentQParam;
    });

    if (this.quicklist) {
      this.store
        .select('user')
        .pipe(
          map((data) => {
            const context = data.user?.userTypeId === 'POU' ? data.shoppingContext : data.user?.currentShoppingAccount;
            return {
              listId: Number(context?.quickListHeader?.id),
              productCount: Number(context?.quickListHeader?.productCount),
              permissionType: context?.permissions,
            };
          }),
          distinctUntilChanged(
            (prev, curr) => prev.listId !== curr.listId || prev.permissionType !== curr.permissionType
          ),
          takeUntil(this.stopQuicklistWatch$)
        )
        .subscribe(({ listId, permissionType }) => {
          this.listId = listId;
          this.getList(this.listId);
          this.listProductCount = this.list?.productCount;
        });
    }

    //this allows all toggle to be visible on first load, may need update
    this.searchFilter.filterType = 'category';

    this.cart = this.siteCart.activeCart;
  }

  private getList(listId: number | null): void {
    this.query = '';
    this.itemListQty = {};
    let navigationOccurred = false;
    if (listId) {
      this.loading.start();
      this.api
        .get(`${this.quicklist ? API_URL.QuickListHeader : API_URL.ProductListHeader}/${listId}`, null, false)
        .subscribe({
          next: (list: any) => {
            this.updateList(list);
          },
          error: () => {
            this.loading.stop();
            this.router.navigate(['/404']);
          },
        });
    } else {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          navigationOccurred = true;

          ((navigationOccurred) => {
            setTimeout(() => {
              if (!navigationOccurred) {
                this.router.navigate(['/404']);
              }
            }, 100);
          })(navigationOccurred);
        }
      });
    }
  }

  onImportSuccess(): void {
    this.getList(this.listId);
  }

  private updateList(list: ProductQuickList | ProductList): void {
    this.list = list as unknown as ProductQuickList;
    this.pageType = `${PageType.LISTS} ${this.listType()}`;
    this.pageTitle = new PageTitle(
      this.listTitle[this.listType()],
      'assignment',
      !this.quicklist ? this.list?.name : ''
    );
    this.titleService.setTitle(`${this.listTitle[this.listType()]}: ${this.list?.name}`);
    this.itemListQty = this.itemList.itemListQuantities(
      this.quicklist ? this.list?.quickListDetails : this.list?.productListDetails
    );
    this.requestOptions.facetFilters = this.itemList.itemIdFacetFilters(Object.keys(this.itemListQty));
    this.itemFilters = this.requestOptions.facetFilters;
    this.listName = this.list?.name;
    // Set List Item Count
    this.listService.setCurrentListItemCount(this.list?.productCount);
    if (!this.requestOptions.facetFilters[0].length) {
      this.results = new ProductSearchResults();
      this.loading.stop();
    } else {
      this.getResults();
    }
  }

  private getResults(): void {
    this.itemList.unCheckAll(this.results?.hits || []);
    this.loading.start();
    // Fetch products from Algolia where algolia.itemId = productList.productCode
    // Once algolia results are returned, display them to user with same quantities stored in db
    // We may not find a match for all items in list... only display items that are found
    if (!this.list) {
      return;
    }
    if (!this.searchFilter.onFilterCalled) {
      this.searchFilter.setQueryStringFacetFilters(this.route.queryParams, this.requestOptions, this.itemFilters);
      this.activeFilters = this.searchFilter.setActiveFilters(this.requestOptions.facetFilters, this.pageType);
      this.activeFilters = this.activeFilters.filter((item) => item.facet != 'itemId');
    }

    this.search
      .getProductResults(this.query, this.requestOptions)
      .then((res) => {
        this.results = <ProductSearchResults>res;
        this.setCategoryFilters();
        this.setFacetFilters();
      })
      .catch(() => {
        this.toast.showError('Error retrieving items.');
      })
      .finally(() => {
        this.loading.stop();
        this.completeSearch();
      });
  }

  private completeSearch() {
    if (!this.results) {
      return;
    }

    if (this.list && !this.results.hits.length) {
      // TODO - display no results message
    } else if (this.results.hits.length) {
      // TODO - Real pricing
      this.pricing.getPricing(this.results.hits).subscribe({
        next: (updatedProducts) => {
          this.results.hits = updatedProducts;
        },
        error: (err) => {
          console.error('Error updating prices:', err);
          this.toast.showError('Error fetching pricing');
        },
      });
      this.itemList.setItemListQty(this.results.hits, this.itemListQty);
    }
  }

  onFilterQuery(q: string) {
    this.query = q;
    this.getResults();
  }

  onFilterFacets(data: { facetKey: string; facetValue: string }) {
    this.requestOptions.facetFilters = [
      ...this.itemFilters,
      ...this.searchFilter.updateFacetFilters(data.facetKey, data.facetValue),
    ];
    if (this.searchFilter.clearCategoryFilter) {
      this.categoryFilters = null;
    }
    this.page = 1;
    this.requestOptions.page = 0;
    this.activeFilters = this.searchFilter.setActiveFilters(this.requestOptions.facetFilters, this.pageType);
    // remove item filters showing active
    this.activeFilters = this.activeFilters.filter((item) => item.facet != 'itemId');
    this.searchFilter.activeFilterQueryString(this.activeFilters);

    this.getResults();
    this.unCheckAll();
  }

  onDeletedItems(selected: ProductResult[]): void {
    if (this.list?.id) {
      let deleteIds: string[] = [];
      selected.forEach((product) => deleteIds.push(product.itemId));
      this.loading.start();
      this.listService.deleteProductListItems(this.list.id, deleteIds, this.listType()).subscribe({
        next: (list: any) => {
          this.updateList(list);
        },
        error: () => {
          this.toast.showError('Error deleting products from list');
        },
      });
    } else {
      this.toast.showError('No list selected');
    }
  }

  onAddProduct(selected: ProductResult[]) {
    this.addToList(selected);
  }

  async addToList(selectedProducts: ProductResult[]): Promise<void> {
    try {
      for (const product of selectedProducts) {
        const data = {
          productListHeaderId: this.list?.id,
          productCode: product.itemId,
          quantity: 1,
        };

        await firstValueFrom(this.api.post(`${API_URL.ProductListDetail}`, data));
      }
      const msg =
        selectedProducts.length === 1 ? `Item #${selectedProducts[0].itemId}` : `${selectedProducts.length} items`;
      this.toast.showSuccess(`${msg} added to product list.`);
      this.getList(Number(this.list?.id));
    } catch (error) {
      this.handleError('Error adding item(s) to list.');
    }
  }

  private handleError(error: string) {
    this.toast.showError(error);
    this.loading.stop();
  }

  onDeletedList(listId: number): void {
    // if user is viewing list that they just deleted, switch to first available list
    if (this.list?.id === listId) {
      const otherLists = this.listService.userListIds.filter((i) => i !== listId);
      this.router.navigate(['product-lists', 'product-list', otherLists?.length ? otherLists[0] : null]);
    }
  }

  onChangePage(page: number | string) {
    this.page = <number>page;
    this.requestOptions.page = this.page - 1;
    this.getResults();
  }

  listType(): string {
    if (this.user?.username === this.list?.createdBy) {
      return 'user';
    }
    if (this.list?.isCompanyList) {
      return 'public';
    }
    if (this.list?.shared) {
      return 'shared';
    }
    return 'quicklist';
  }

  toggleFilterModal() {
    const el: HTMLInputElement = this.window?.document?.getElementById('myAllLists') as HTMLInputElement;
    if (el.classList.contains('show')) {
      el.classList.remove('show');
      el.classList.remove('d-block');
    } else {
      el.classList.add('show');
      el.classList.add('d-block');
    }
  }

  setCategoryFilters() {
    if (this.pageType !== 'category') {
      this.categoryFilters = this.searchFilter.formatCategoryFilters(this.results.facets, this.categoryFilters);
    }
  }

  setFacetFilters() {
    this.facetFilters = this.searchFilter.formatFacetFilters(this.results.facets);
  }

  toggleFacets(id: any) {
    this.searchFilter.toggleFacets(id);
  }

  toggleFacetPanel() {
    this.searchFilter.toggleFacetPanel('facetFilterPanel');
  }

  clearFilters() {
    // keep category filter, when it was not selected by user
    const keepCategory = this.activeFilters.filter((f: any) => f.filter.match(this.searchFilter.catRegExp)).length < 1;

    let newFilter: string[] = [];
    if (keepCategory && this.requestOptions.facetFilters?.length) {
      newFilter = this.requestOptions.facetFilters.filter((f: any) => {
        if (f.length === 1 && f[0].match(this.searchFilter.catRegExp)) {
          return f[0];
        }
      });
    } else {
      this.categoryFilters = null;
    }
    this.requestOptions.facetFilters = newFilter;
    this.activeFilters = [];
    this.onChangePage(1);
    this.toggleFacetPanel();
  }

  searchWithin(): boolean {
    return (this.results && this.results.hits && this.results.hits.length > 0) || !!this.query;
  }

  onToggleFilter(facet: FormOption) {
    this.onFilterFacets({ facetKey: facet.label, facetValue: facet.value });
  }

  unCheckAll() {
    if (this.results) {
      this.itemList.unCheckAll(this.results.hits);
    }
  }

  onSort(index: string) {
    this.currentIndex = index;
    this.page = 1;
    this.requestOptions.page = 0;
    this.getResults();
  }

  ngOnDestroy() {
    this.listSub.unsubscribe();
    this.userSub.unsubscribe();
    this.stopQuicklistWatch$.next();
    this.stopQuicklistWatch$.complete();
  }
}
