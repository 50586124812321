// By default, all GET requests are cached
// To force a fresh (non-cached) version of the data, add a valBypassCache=true header to the request

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CacheService } from '../../services/cache/cache.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private readonly cacheService: CacheService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.method !== 'GET') {
      return next.handle(req);
    }
    // TODO - Implement a timing aspect to the caching
    // delete cache if clearCache header is set
    if (req.headers.get('valClearCache')) {
      if (this.cacheService.cacheMap.get(req.urlWithParams)) {
        this.cacheService.cacheMap.delete(req.urlWithParams);
      }
      return next.handle(req);
    }

    if (!req.headers.get('valBypassCache')) {
      const cachedResponse = this.cacheService.getFromCache(req);
      if (cachedResponse) {
        // If parallel requests to same URI -
        // return the request already in progress
        // else return the last cached data
        return cachedResponse instanceof Observable ? cachedResponse : of(cachedResponse.clone());
      }
    }

    // Initial request
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.cacheService.addToCache(req, event);
        }
      })
    );
  }
}
