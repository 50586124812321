<div class="sticky-md-top order-summary">
  <div class="val-th">Summary</div>
  <div class="val-details order-totals border-top-0">
    <div class="sub-total">
      <span>{{ pageType === pageTypeEnum.QUOTE ? "Quote items" : "Order items" }}</span
      ><span>{{ cart ? cart.cartItems.length : order?.lineItems?.length }}</span>
    </div>
    <div
      class="sub-total"
      *ngIf="
        (canViewPrices && pageType === pageTypeEnum.CART) ||
        pageType === pageTypeEnum.CHECKOUT ||
        pageType === pageTypeEnum.REVIEW_ORDER
      "
    >
      <span>Sub-total</span>
      <div *ngIf="isLoading.summary" class="spinner"></div>
      <span *ngIf="!isLoading.summary">{{ cart.subTotal | currency }}</span>
    </div>
    <div class="sub-total" *ngIf="pageType === pageTypeEnum.QUOTE">
      <span>Sub-total</span>
      <span>{{ cart.subTotal | currency }}</span>
    </div>
    <div class="sub-total" *ngIf="pageType === pageTypeEnum.ORDERDETAILS">
      <span>Sub-total</span>
      <span>{{ order!.totalLineAmount | currency }}</span>
    </div>
    <div class="sub-total" *ngIf="cart && cart.totalSpecialCharges && pageType === pageTypeEnum.REVIEW_ORDER">
      <span>Shipping & Handling</span>
      <div *ngIf="isLoading.summary" class="spinner"></div>
      <span *ngIf="!isLoading.summary">{{ cart.totalSpecialCharges | currency }}</span>
    </div>
    <div *ngIf="pageType === pageTypeEnum.REVIEW_ORDER" class="sub-total">
      <span>Total Taxes</span>
      <div *ngIf="isLoading.summary" class="spinner"></div>
      <span *ngIf="!isLoading.summary">{{ cart.goodsServiceTax | currency }}</span>
    </div>
    <ng-container *ngIf="order">
      <div class="sub-total" *ngIf="order.totalSpecialCharges">
        <span>Shipping & Handling</span>
        <span>{{ order.totalSpecialCharges | currency }}</span>
      </div>
      <div class="sub-total">
        <span>Total Taxes</span>
        <span>{{ order.salesTaxAmount | currency }}</span>
      </div>
    </ng-container>
    <hr *ngIf="pageType === pageTypeEnum.ORDERDETAILS || pageType === pageTypeEnum.REVIEW_ORDER" />
    <div class="sub-total" *ngIf="pageType === pageTypeEnum.ORDERDETAILS && order">
      <span>Order Total</span><span>{{ order.totalOrderValue | currency }}</span>
    </div>
    <div *ngIf="pageType === pageTypeEnum.REVIEW_ORDER" class="sub-total">
      <span>Order Total</span><span>{{ cart.totalOrderValue | currency }}</span>
    </div>
    <p
      *ngIf="pageType === pageTypeEnum.CART || pageType === pageTypeEnum.CHECKOUT"
      class="supp text-center text-md-start px-0"
    >
      Taxes & shipping will be calculated during Review Order.
    </p>
  </div>

  <ngb-accordion
    [activeIds]="activePanelIds"
    class="facet-filters gray-bg"
    *ngIf="pageType === pageTypeEnum.CART && user !== null && user!.userTypeId !== 'B2C'"
  >
    <ngb-panel title="Save To Product List" id="saveToProductList" class="first-panel" *ngIf="hasProductLists">
      <ng-template ngbPanelContent>
        <form [formGroup]="form" (ngSubmit)="createList()" class="my-3">
          <div class="row">
            <div class="col-sm-12">
              <label class="e-label">Product list name: <span>*</span></label>
              <div class="form-group">
                <input class="form-control list-name" type="text" maxlength="30" formControlName="listName" />
                <p class="supp">Maximum 30 characters</p>
              </div>
            </div>
          </div>
          <button
            id="contactSubmit"
            class="btn btn-primary btn-lg mx-auto"
            type="submit"
            [disabled]="!form.valid"
            [attr.data-testid]="DATA_TEST_ID.itemSummary.saveList"
          >
            Save
          </button>
        </form>
      </ng-template>
    </ngb-panel>
    <ngb-panel *ngIf="submitBtn === 'showSendForApproval'" title="Send for Approval" id="showSendForApproval">
      <ng-template ngbPanelContent>
        <val-order-approval-request></val-order-approval-request>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <div class="val-details text-center border-top-0" *ngIf="pageType === pageTypeEnum.CART">
    <div *ngIf="user; else guestCheckout">
      <div *ngIf="!isPunchOut">
        <button
          (click)="onRequestQuote()"
          class="btn btn-lg btn-outline-primary"
          [attr.data-testid]="DATA_TEST_ID.itemSummary.requestQuote"
        >
          Request A Quote
        </button>
        <p class="my-3" *ngIf="submitBtn !== 'showSendForApproval'">OR</p>
      </div>
      <button
        *ngIf="submitBtn === 'showCheckout'"
        (click)="onCheckOut()"
        class="btn btn-lg btn-primary"
        [disabled]="isLoading.summary || isOverLimit()"
        [attr.data-testid]="DATA_TEST_ID.itemSummary.checkout"
      >
        Checkout
      </button>
      <!--          TODO - Need more info-->
      <button
        *ngIf="submitBtn === 'showRestrictionsMsg'"
        (click)="onRestrictiveMessage()"
        class="btn btn-lg btn-primary"
        [attr.data-testid]="DATA_TEST_ID.itemSummary.restrictionMessage"
      >
        Restrictive Message
      </button>
      <button
        *ngIf="submitBtn === 'showCheckoutWithRestrictions'"
        (click)="onCheckoutWithRestrict()"
        class="btn btn-lg btn-primary"
        [attr.data-testid]="DATA_TEST_ID.itemSummary.restrictionCheckout"
      >
        Checkout With Restriction
      </button>
    </div>
    <ng-template #guestCheckout>
      <a
        routerLink="/account/login"
        [queryParams]="{ returnUrl: '/cart' }"
        class="btn btn-lg btn-outline-primary"
        [attr.data-testid]="DATA_TEST_ID.itemSummary.loginToCheckout"
        >Login To Checkout</a
      >
      <p class="my-3">OR</p>
      <button
        (click)="onCheckOut()"
        class="btn btn-lg btn-primary"
        [attr.data-testid]="DATA_TEST_ID.itemSummary.guestCheckout"
      >
        Guest Checkout
      </button>
      <div class="supp text-center">You can still proceed to checkout</div>
    </ng-template>
  </div>

  <div class="val-details text-center border-top-0" *ngIf="pageType === 'checkout'">
    <button
      (click)="submitForm()"
      type="submit"
      class="btn btn-lg btn-primary w-100"
      [attr.data-testid]="DATA_TEST_ID.itemSummary.reviewOrder"
    >
      Review Order
    </button>
  </div>

  <div *ngIf="pageType === pageTypeEnum.ORDERDETAILS" class="mt-5">
    <div class="order-summary">
      <div class="val-details text-center">
        <button
          (click)="addToCart()"
          class="btn btn-lg btn-primary my-3"
          [disabled]="!selectedItems().length"
          [attr.data-testid]="DATA_TEST_ID.itemSummary.addToCart"
        >
          Add To Cart
        </button>
        <button
          class="btn btn-lg btn-outline-primary my-3"
          (click)="listService.toggleProductListForm()"
          [disabled]="!selectedItems().length"
          [attr.data-testid]="DATA_TEST_ID.itemSummary.addToList"
        >
          > Add To List
        </button>
        <val-product-list-form [user]="user" [products]="selectedItems()" [stacked]="true"></val-product-list-form>
      </div>
    </div>
  </div>

  <div class="val-details" *ngIf="pageType === pageTypeEnum.REVIEW_ORDER">
    <p class="supp text-center text-center">
      By clicking “{{ cart.billingMethod?.toUpperCase() === "CC" ? "Proceed to Pay" : "Submit Order" }}” you agree to
      the <a target="_blank" routerLink="/content/terms-and-conditions">Terms &amp; Conditions</a>.
    </p>
    <div>
      <a
        *ngIf="cart.billingMethod?.toUpperCase() === 'CC'; else showSubmit"
        routerLink="/checkout/creditcardpayment"
        class="btn btn-lg btn-primary"
        [attr.data-testid]="DATA_TEST_ID.itemSummary.proceedToPay"
        >Proceed To Pay</a
      >
      <ng-template #showSubmit>
        <a
          (click)="sumbitOrder()"
          class="btn btn-lg btn-primary"
          [attr.data-testid]="DATA_TEST_ID.itemSummary.submitOrder"
          >Submit Order</a
        >
      </ng-template>
    </div>
  </div>

  <div *ngIf="pageType === pageTypeEnum.QUOTE" class="val-details text-center border-top-0">
    <button
      (click)="onSubmit()"
      class="btn btn-lg btn-primary"
      [attr.data-testid]="DATA_TEST_ID.itemSummary.submitQuote"
    >
      Submit Quote
    </button>
    <hr />
    <button
      (click)="deleteQuote()"
      class="btn btn-lg btn-outline-primary"
      [attr.data-testid]="DATA_TEST_ID.itemSummary.deleteQuote"
    >
      Cancel
    </button>
  </div>
</div>
