import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { ApiService } from '../services/api/api.service';
import { API_URL } from '../../shared/consts/api-urls';

@Injectable({
  providedIn: 'root',
})
export class PunchoutResolver {
  authRedirect = '/products/categories';

  constructor(private router: Router, private authService: AuthenticationService, private api: ApiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Make sure there is no lingering user data
    this.authService.invalidate();
    const sessionId = route.paramMap.get('sessionId') ?? '';
    return this.api.get(`${API_URL.Punchout}/login/${sessionId}`).subscribe({
      next: (res) => {
        this.authService.loginPunchout(res);
        void this.router.navigate([this.authRedirect]);
      },
      error: (err) => {
        const msg = "ERROR: Unable to complete request<br><br>SessionId: " + sessionId + "<br>Message: " + err;
        void this.router.navigate(['unauthorized'], { state: { msg }});
      },
    });
  }
}
