import { Injectable } from '@angular/core';
import { BaseCartComponent } from '../../../shared/components/base-cart/base-cart.component';
import { ApiService } from '../api/api.service';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../../types/app-state.interface';
import { SearchService } from '../search/search.service';
import { CartType } from '../../../shared/consts/cart-types';
import { ToastService } from '../../../shared/components/toast/toast.service';
import { LoadingSpinnerService } from '../../../shared/components/loading-spinner/loading-spinner.service';
import { Router } from '@angular/router';
import { PricingService } from '../pricing/pricing.service';

@Injectable({
  providedIn: 'root',
})
export class QuoteCartService extends BaseCartComponent {

  constructor(
    api: ApiService,
    loading: LoadingSpinnerService,
    router: Router,
    search: SearchService,
    store: Store<AppStateInterface>,
    toast: ToastService,
    pricing: PricingService
  ) {
    super(api, loading, router, search, store, toast, pricing);
  }

  initQuoteCart(): void {
    const cartId = this.getDefaultCartId(CartType.Quote);
    if (cartId) {
      this.initCart(cartId, false, true);
    }
  }
}
