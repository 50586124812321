<div class="alert alert-warning val-banner alert-dismissible fade show" role="alert" *ngIf="alertMsg && !hidden">
  <div [innerHTML]="alertMsg" class="msg"></div>
  <button
    type="button"
    class="btn-close"
    (click)="closeSiteBanner()"
    data-bs-dismiss="alert"
    aria-label="Close"
  ></button>
</div>
