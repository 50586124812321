import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageTitle } from '../../../shared/components/page-title/page-title.model';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { OrderApprovalService } from '../../../core/services/order/order-approval.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastService } from '../../../shared/components/toast/toast.service';
import { UserModel } from '../../../core/services/user/user.model';
import { LoadingSpinnerService } from '../../../shared/components/loading-spinner/loading-spinner.service';
import { Store } from '@ngrx/store';
import { API_URL } from '../../../shared/consts/api-urls';
import { ApiService } from '../../../core/services/api/api.service';
import { AppStateInterface } from 'src/app/types/app-state.interface';
import { PageType } from '../../../shared/consts/page-type';
import { UserService } from '../../../core/services/user/user.service';
import { WarehouseNameNumber } from '../../../types/warehouse.model';
import { environment } from '../../../../environments/environment';
import { WarehouseState } from 'src/app/core/store/warehouse/warehouse.reducer';
import { takeUntil, firstValueFrom } from 'rxjs';
import { LoadingStatus } from '../../../types/loading-status.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductListService } from 'src/app/core/services/product-list/product-list.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { Cart, OrderReviewCartResponse } from 'src/app/types/cart.model';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { setCart } from '../../../core/store/cart/cart.actions';
import { ShowDetails } from 'src/app/shared/components/products/item-list-actions/item-list-actions.component';
import { ApprovalCartService } from '../../../core/services/cart/approval-cart.service';

@Component({
  selector: 'val-order-awaiting-approval-edit',
  templateUrl: './order-awaiting-approval-edit.component.html',
  styleUrls: ['./order-awaiting-approval-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderAwaitingApprovalEditComponent extends BaseSubscriptionComponent implements OnInit {
  user: UserModel | null | undefined;
  pageTitle: PageTitle;
  cart: Cart = this.orderApprovalService.initCartObj;
  shippingMethods = ['delivery', 'pickup'];
  whseError: string;
  submitBtn = '';
  isLoading: LoadingStatus;
  pageTypeEnum = PageType;
  pageType = `${this.pageTypeEnum.CART},${this.pageTypeEnum.APPROVAL}`;
  isPunchOut = false;
  pickupWarehouses: string[];
  deliveryWarehouses: string[];
  warehouses: WarehouseNameNumber[];
  hasError: boolean = false;
  activePanelIds: string[] = [];
  checkoutInfo: any;
  form: FormGroup;
  disableEdit: boolean = true;
  canViewPrices: boolean;
  isCollapsedDetails: ShowDetails = {
    collapsed: true,
    classes: ['details'],
  };

  constructor(
    public orderApprovalService: OrderApprovalService,
    private approvalCart: ApprovalCartService,
    private router: Router,
    public toast: ToastService,
    private store: Store<AppStateInterface>,
    private api: ApiService,
    private loadingService: LoadingSpinnerService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    public listService: ProductListService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private permissionService: PermissionService
  ) {
    super();
    this.loadingService
      .getLoadingPrices()
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.isLoading = data;
      });

    this.orderApprovalService
      .getCurrentCart()
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.cart = data;
      });

    this.store
      .select('warehouse')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.setWarehouses(data);
      });

    this.store
      .select('user')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.user = data.user;
        this.canViewPrices = this.permissionService.canViewPrices();
        this.isPunchOut = this.userService.isPunchOut(this.user);
        this.checkoutInfo = this.orderApprovalService.checkoutPermissions();
        this.submitBtn = this.checkoutInfo.cart;
        if (this.submitBtn == 'showSendForApproval') {
          this.activePanelIds = ['showSendForApproval'];
        }
        this.getUserWarehouses();
      });
  }

  ngOnInit(): void {
    this.pageTitle = new PageTitle(`Order Awaiting Approval`, 'shopping_cart', '');
    this.loadingService.start();
    this.orderApprovalService.currentCart = this.orderApprovalService.initCartObj;
    // Get approval id from url
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = params.get('id');
      if (id) {
        this.orderApprovalService.getCart(id).subscribe({
          next: (data: any | Cart) => {
            this.loadingService.stop();
            if (data) {
              this.cart = data;
              this.pageTitle = new PageTitle(`Order Awaiting Approval: ${data.cartName}`, 'shopping_cart', '');
              this.disableEdit = !this.cart?.approvers?.includes(this.user?.email || '');
              this.orderApprovalService.initiateCart(this.cart);
              this.orderApprovalService.submitToSxe(['summary', 'price', 'total'], false).subscribe({
                error: (err) => {
                  this.hasError = true;
                  this.api.toastError('Error Loading Approval Order');
                  console.log(err);
                },
              });
              this.orderApprovalService.calculateSubTotal();
            }
          },
          error: (error) => {
            this.loadingService.stop();
            this.hasError = true;
            this.api.toastError('Error Loading Approval Order');
            console.log(error);
          },
        });
      }
    });
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      listName: new FormControl('', [Validators.required, Validators.maxLength(30), Validators.pattern(/\S/)]),
    });
  }

  setWarehouses(data: WarehouseState) {
    this.warehouses = data?.warehouses || [];
    if (!this.user) {
      this.deliveryWarehouses = [environment.warehouse];
      this.pickupWarehouses = this.warehouses.map((x) => x.warehouseNumber);
      this.approvalCart.setSelectedWarehouse(this.cart, this.deliveryWarehouses, this.pickupWarehouses);
    } else {
      this.getUserWarehouses();
    }
  }

  private getUserWarehouses(): void {
    if (!this.user) {
      return;
    }
    this.deliveryWarehouses =
      this.user.currentShoppingAccount?.deliveryWarehouses &&
      this.user.currentShoppingAccount?.deliveryWarehouses?.length > 0
        ? this.user.currentShoppingAccount.deliveryWarehouses
        : [environment.warehouse];
    this.pickupWarehouses =
      this.user.currentShoppingAccount?.warehouses && this.user.currentShoppingAccount?.warehouses?.length > 0
        ? this.user.currentShoppingAccount.warehouses
        : [environment.warehouse];
    this.approvalCart.setSelectedWarehouse(this.cart, this.deliveryWarehouses, this.pickupWarehouses);
  }

  createList(): void {
    const params = {
      companyNumber: parseInt(this.user?.currentShoppingAccount?.customerNumber as string),
      isCompanyList: false,
      shoppingContextId: this.user?.currentShoppingAccount?.id,
      name: this.form.controls['listName'].value.trim(),
      shared: false,
    };

    this.api.post(API_URL.ProductListHeader, params).subscribe({
      next: (res: any) => {
        this.addToList(res.id);
        this.listService.getLists(this.user);
        this.initForm();
        this.toast.showSuccess('Product list created successfully');
      },
      error: (error) => {
        this.handleError(error);
      },
    });
  }

  onApproveCart(cart: any) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.options = {
      title: 'Replace Cart',
      body: `Are you sure you want to replace your current cart with the selected items?`,
      bodyClass: 'text-center',
    };

    modalRef.result.then((result: boolean) => {
      if (result) {
        this.orderApprovalService
          .getOrderApprovalReview(cart.cartId)
          .pipe(takeUntil(this.destroyed))
          .subscribe({
            next: (res: any | OrderReviewCartResponse) => {
              this.orderApprovalService.getCart(res.cartId).subscribe({
                next: (cart: any | Cart) => {
                  this.store.dispatch(setCart({ cart }));
                  this.router.navigate(['/cart']);
                },
                error: () => {
                  this.api.toastError('Approval order failed. Unable to fetch new cart.');
                },
              });
            },
            error: () => {
              this.api.toastError('Approval order failed. Please contact technical support.');
            },
          });
      }
    });
  }

  async addToList(productId: number): Promise<void> {
    for (const product of this.cart.cartItems) {
      const data = {
        productListHeaderId: productId,
        productCode: product.itemId,
        quantity: product.qty,
      };
      await firstValueFrom(this.api.post(`${API_URL.ProductListDetail}`, data));
    }
  }

  private handleError(error: string) {
    this.toast.showError(error);
  }
}
