import { Component, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IContentItem, IContentItemElements } from '@kontent-ai/delivery-sdk';
import { LoadingSpinnerService } from 'src/app/shared/components/loading-spinner/loading-spinner.service';
import { KontentService } from 'src/app/core/components/kontent/kontent.service';
import { CustomValidator } from 'src/app/core/custom-validators/custom-validator';
import { FormService } from 'src/app/core/services/form/form.service';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { API_URL } from 'src/app/shared/consts/api-urls';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { WINDOW } from 'src/app/app.module';
import { mapOptions } from '../../../../../shared/consts/google-maps';

@Component({
  selector: 'val-kontent-contact',
  templateUrl: './kontent-contact.component.html',
  styleUrls: ['./kontent-contact.component.scss'],
})
export class KontentContactComponent extends BaseSubscriptionComponent implements AfterViewInit {
  constructor(
    public kontent: KontentService,
    private loading: LoadingSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private errorService: FormService,
    private breakpointObserver: BreakpointObserver,
    private formService: FormService,
    private api: ApiService,
    private toast: ToastService,
    @Inject(WINDOW) private window: Window
  ) {
    super();
    this.contactForm.reset();
    this.breakpointObserver
      .observe(['(max-width: 991px)'])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result: BreakpointState) => {
        this.isSmall = result.matches;
      });
    this.route.params.pipe(takeUntil(this.destroyed)).subscribe((params) => {
      if (params['slug']) {
        this.slug = this.actionUrlDefault + params['slug'];
      }
    });
  }

  currentComponentCount: number;

  @ViewChild('mapDiv') mapDiv!: ElementRef;
  map: google.maps.Map;
  bannerContent: IContentItem;
  location: IContentItem;
  businessHours: IContentItemElements[] = [];
  isSmall = false;
  slug = '';
  actionUrlDefault = 'https://www.vallen.ca/content/';
  inquiryArr = [
    'Existing Order Inquiry',
    'New Order Inquiry',
    'Be A Supplier With Vallen',
    'Accounts Payable/Receivable',
    'Marketing',
    'General Inquiry',
  ];
  showRecaptcha: boolean = true;
  hideMap: boolean = false;
  private recipient = '';

  ngAfterViewInit(): void {
    this.getData();
  }

  public contactForm = new FormGroup({
    fullName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]),
    phoneNumber: new FormControl(null, [
      CustomValidator.patternValidator(this.formService.phoneRegEx, {
        isPhone: true,
      }),
    ]),
    city: new FormControl('', [Validators.maxLength(100)]),
    inquiry: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    recaptcha: new FormControl(null, [Validators.required]),
  });

  public controls = this.contactForm.controls;
  public inputValidationError = 'input-validation-error';

  getData() {
    this.loading.start();
    this.kontent
      .getContactUs('contact_us_page')
      .then((data) => {
        this.bannerContent = data.data.linkedItems['contact_us_banner'];
        if (!this.slug) {
          this.location = data.data.linkedItems['location___edmonton__head_office_'];
          this.getBusinessHours();
          this.initMap();
        } else {
          this.kontent.getBranch(this.slug).then((data) => {
            if (!data.data.items.length) {
              this.router.navigate(['/404']);
              return;
            }
            this.location = data.data.items[0];
            this.recipient = this.location.elements['contactEmailAddress'].value
              ? this.location.elements['contactEmailAddress'].value.trim()
              : '';
            this.initMap();

            const linkedItems = data.data.linkedItems;
            this.location.elements['businessHours'].value.forEach((x: string) => {
              if (linkedItems[x]) {
                this.businessHours.push(linkedItems[x].elements);
              }
            });
          });
        }
      })
      .catch((err) => {
        console.log('Error: ', err);
        this.router.navigate(['/404']);
      })
      .finally(() => {
        this.loading.stop();
      });
  }

  getBusinessHours(): void {
    this.location.elements['businessHours'].value.forEach((x: string) => {
      this.kontent.getBusinessHours(x).then((data) => {
        this.businessHours.push(data.data.item.elements);
      });
    });
  }

  initMap(): void {
    new Loader(mapOptions)
      .load()
      .then(() => {
        this.map = new google.maps.Map(this.mapDiv.nativeElement as HTMLElement, {
          zoom: 15,
        });

        const geocoder = new google.maps.Geocoder();

        this.codeAddress(geocoder, this.map);
      })
      .catch((err: any) => {
        this.hideMap = true;
        console.log('Init Map Error: ', err);
      });
  }

  codeAddress(geocoder: google.maps.Geocoder, map: google.maps.Map) {
    const address =
      this.location.elements['address'].value +
      ' ' +
      this.location.elements['city'].value +
      ' ' +
      this.location.elements['province'].value +
      ' ' +
      this.location.elements['postalCode'].value;

    const geocoderStatus = google.maps.GeocoderStatus;

    geocoder
      .geocode({ address: address }, function (results: unknown, status: any) {
        if (status === geocoderStatus.OK) {
          const data = results as Record<string, google.maps.GeocoderResult>;
          map.setCenter(data[0].geometry.location);
          new google.maps.Marker({
            map: map,
            position: data[0].geometry.location,
          });
        }
      })
      .catch((err: any) => {
        this.hideMap = true;
        console.log('Geocoder Error: ', err);
      });
  }

  onSubmit() {
    this.showRecaptcha = false;
    this.contactForm.markAllAsTouched();
    if (this.contactForm.valid) {
      this.loading.start();
      const data = {
        ...this.contactForm.value,
        ...{
          origin: this.window?.location.href,
          recipient: this.recipient,
          spam: false,
          reCaptcha: '',
        },
      };

      this.errorService.errors = [];
      this.api.post(`${API_URL.Contact}`, data).subscribe({
        next: (res) => {
          res && this.toast.showSuccess('Form sent successfully');
          this.showRecaptcha = true;
          this.contactForm.reset();
          this.loading.stop();
        },
        error: (error) => {
          this.api.toastError(error);
          this.showRecaptcha = true;
          this.loading.stop();
        },
      });
    } else {
      this.toast.showError('Make sure all required fields have been entered.');
    }
  }
}
