import { Component, Input, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api/api.service';
import { LoadingSpinnerService } from 'src/app/shared/components/loading-spinner/loading-spinner.service';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { ProductList, ProductListDetails } from 'src/app/types/product-list.model';
import { UserModel } from 'src/app/core/services/user/user.model';
import { ProductResult } from 'src/app/core/services/search/search.model';
import { ProductListService } from 'src/app/core/services/product-list/product-list.service';
import { API_URL } from 'src/app/shared/consts/api-urls';
import { forkJoin, Observable, Subscription, takeUntil } from 'rxjs';
import { ItemListService } from '../../../../core/services/item-list/item-list.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { UserService } from 'src/app/core/services/user/user.service';
import { AppStateInterface } from 'src/app/types/app-state.interface';
import { Store } from '@ngrx/store';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'val-product-list-form',
  templateUrl: './product-list-form.component.html',
  styleUrls: ['./product-list-form.component.scss'],
})
export class ProductListFormComponent extends BaseSubscriptionComponent implements OnInit, OnDestroy, OnChanges {
  shoppingContextPunchoutId: number;

  constructor(
    public listService: ProductListService,
    private itemList: ItemListService,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private loading: LoadingSpinnerService,
    private toast: ToastService,
    private userService: UserService,
    private store: Store<AppStateInterface>
  ) {
    super();

    this.store
      .select('punchout')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        if (data.punchout && data.punchout.id !== undefined) {
          this.shoppingContextPunchoutId = data.punchout.id;
        }
      });
  }

  @Input() user?: UserModel | null;
  @Input() products: ProductResult | ProductResult[];
  @Input() stacked?: boolean;

  private modeSelectionId = 'mode-selection';
  private listSub: Subscription;
  form: FormGroup;
  productLists: ProductList[] = [];
  selectedProducts: ProductResult[];
  mode = 'create';
  invalidList = false;
  isPdp = false;
  isPunchOutUser = false;

  ngOnInit() {
    this.isPunchOutUser = this.userService.isPunchOut(this.user);
    this.listSub = this.listService.productLists$.pipe(takeUntil(this.destroyed)).subscribe((lists) => {
      this.productLists = lists ? lists.user : [];
      this.initForm();
    });

    // Identify PDP usage
    if (!Array.isArray(this.products)) {
      this.products.isSelected = true;
      this.products = <ProductResult[]>[this.products];
      this.isPdp = true;
    }
    this.selectedProducts = this.products.filter((x) => x.isSelected);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['products'] && changes['products'].currentValue !== undefined) {
      const currentProducts = Array.isArray(changes['products'].currentValue)
        ? changes['products'].currentValue
        : [changes['products'].currentValue];

      this.selectedProducts = currentProducts.filter((product: ProductResult) => product.isSelected);
      if (!this.selectedProducts.length && this.listService.formOpen()) {
        this.cancel();
      }
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      listId: this.productLists.length ? this.productLists[0].id : [],
      listName: this.formBuilder.control('', {
        validators: [Validators.minLength(3)],
      }),
    });
    this.mode = this.productLists.length ? 'update' : 'create';
  }

  setMode(mode: string) {
    this.mode = mode;
  }

  submit(): void {
    if (!this.selectedProducts.length) {
      this.toast.showError('No products are selected');
      return;
    }

    if (!this.validated()) {
      // validation failed - do nothing else
    } else if (this.mode === 'create') {
      this.createList();
    } else {
      this.addToList();
    }
  }

  private addToList(): void {
    if (!this.selectedProducts.length) {
      return;
    }

    const apiCall: Observable<object>[] = [];
    this.selectedProducts.forEach((product) => {
      const data = {
        productListHeaderId: parseInt(this.form.controls['listId'].value),
        productCode: product.itemId,
        quantity: product.qty,
        uom: product.uom,
      };
      apiCall.push(this.api.post(`${API_URL.ProductListDetail}`, data));
    });

    if (!apiCall.length) {
      return;
    }
    this.loading.start();
    forkJoin(apiCall).subscribe({
      next: (res: unknown) => {
        const msg =
          (res as ProductListDetails[]).length === 1 ? 'Item' : (res as ProductListDetails[]).length + ' items';
        this.toast.showSuccess(`${msg} added to product list.`);
        this.listService.getLists(this.user);
        this.unCheckAll();
        this.cancel();
        this.loading.stop();
      },
      error: () => {
        this.loading.stop();
        this.handleError('Error adding item(s) to list.');
      },
    });
  }

  private createList(): void {
    const params = {
      companyNumber: environment.companyNumber,
      isCompanyList: false,
      ...(this.isPunchOutUser
        ? { shoppingContextPunchOutId: this.shoppingContextPunchoutId }
        : { shoppingContextId: this.user?.currentShoppingAccount?.id }),
      name: this.form.controls['listName'].value,
      shared: false,
    };

    this.api.post(API_URL.ProductListHeader, params).subscribe({
      next: (res) => {
        this.form.controls['listId'].setValue((res as ProductList).id);
        this.addToList();
      },
      error: (error) => {
        this.handleError(error);
      },
    });
  }

  cancel(): void {
    this.listService.toggleProductListForm();
    this.initForm();
  }

  private handleError(error: string) {
    this.toast.showError(error);
    this.loading.stop();
  }

  private unCheckAll(): void {
    if (!this.isPdp) {
      this.itemList.unCheckAll(this.selectedProducts);
    }
  }

  private validated(): boolean {
    if (this.mode === 'create') {
      this.form.controls['listName'].setValue(this.form.controls['listName'].value.trim());
      const listName = this.form.controls['listName'].value;
      if (!listName) {
        this.toast.showError('Please enter a list name');
        this.invalidList = true;
        setTimeout(() => {
          this.invalidList = false;
        }, 3000);
        return false;
      } else {
        if (this.listNames().find((x) => x === listName.toLowerCase())) {
          this.toast.showError('A list by this name already exists');
          return false;
        }
      }
    }
    return true;
  }

  private listNames(): string[] {
    return this.productLists.map((x) => x.name.toLowerCase());
  }
}
