import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UserModel } from '../../services/user/user.model';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { MenuService } from '../../services/menu/menu.service';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppStateInterface } from '../../../types/app-state.interface';
import { userSelector } from '../../store/user/user.selectors';
import { ProductListService } from '../../services/product-list/product-list.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { WINDOW } from 'src/app/app.module';

@Component({
  selector: 'val-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent extends BaseSubscriptionComponent implements OnInit {
  public user$: Observable<UserModel | null | undefined>;
  user: UserModel | null;
  private subName: Subscription;

  constructor(
    public authService: AuthenticationService,
    private listService: ProductListService,
    public menuService: MenuService,
    public router: Router,
    private store: Store<AppStateInterface>,
    @Inject(WINDOW) private window: Window
  ) {
    super();
    this.user$ = this.store.pipe(select(userSelector));
  }

  ngOnInit(): void {
    this.user$.pipe(takeUntil(this.destroyed)).subscribe((user) => {
      if (user) {
        this.user = user;
      } else {
        this.user = null;
      }
      this.menuService.getMenus(user);
      this.listService.getLists(user);
    });
  }

  getClassForUser() {
    if (!this.window) return '';
    return this.user ? 'loggedin' : 'loggedout';
  }
}
