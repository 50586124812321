<div class="row">
  <div *ngFor="let banner of banners; let i = index" class="block" [id]="banner.system.id">
    <div *ngIf="banner.elements['image'].value.length" class="img">
      <a
        [attr.data-testid]="DATA_TEST_ID.kontent.tinyBanner.link + '-' + i | idFormatter"
        *ngIf="banner.elements['isImageClickable'].value[0].codename === 'true'; else imgNoLink"
        [href]="banner.elements['actionUrl'].value"
      >
        <img [src]="banner.elements['image'].value[0].url" alt="" class="img-fluid" />
      </a>
      <ng-template #imgNoLink>
        <img [src]="banner.elements['image'].value[0].url" alt="" class="img-fluid" />
      </ng-template>
    </div>
    <div class="details">
      <div *ngIf="banner.elements['title'].value" class="title">{{ banner.elements["title"].value }}</div>
      <div
        *ngIf="banner.elements['shortDescription'].value"
        [innerHTML]="banner.elements['shortDescription'].value"
        class="description"
      ></div>
      <div
        *ngIf="banner.elements['longDescription'].value"
        [innerHTML]="banner.elements['longDescription'].value"
        class="l-description"
      ></div>

      <div *ngIf="banner.elements['actionUrl'].value" class="link">
        <a
          [href]="banner.elements['actionUrl'].value"
          [target]="banner.elements['target'].value[0]"
          [attr.data-testid]="DATA_TEST_ID.kontent.tinyBanner.actionUrl + '-' + i | idFormatter"
        >
          {{ banner.elements["buttonLabel"].value }}
        </a>
      </div>
    </div>
  </div>
</div>
