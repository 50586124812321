<div class="container simple-page">
  <div class="row section">
    <h5 class="text-center pb-3">Browse by Categories</h5>
    <div class="col-6 col-sm-4 col-md-3 col-xl-2 block" *ngFor="let category of categoryList">
      <div class="img-container">
        <a [href]="category.url">
          <img [src]="category.image" [alt]="category.name" fallbackImage />
        </a>
      </div>
      <div class="details">
        <div class="link">
          <a [href]="category.url">
            {{ category.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <val-form-alerts [error]="responseError"></val-form-alerts>
</div>
