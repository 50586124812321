import { Component, Input, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ProductList } from 'src/app/types/product-list.model';
import { NavbarLink } from './navbar-link.model';
import { AppStateInterface } from 'src/app/types/app-state.interface';
import { UserModel } from 'src/app/core/services/user/user.model';
import { ProductListService } from 'src/app/core/services/product-list/product-list.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { userSelector } from 'src/app/core/store/user/user.selectors';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { WINDOW } from 'src/app/app.module';

@Component({
  selector: 'val-navbar-link',
  templateUrl: './navbar-link.component.html',
})
export class NavbarLinkComponent extends BaseSubscriptionComponent implements OnInit {
  constructor(
    private router: Router,
    private userService: UserService,
    private store: Store<AppStateInterface>,
    public listService: ProductListService,
    @Inject(WINDOW) private window: Window
  ) {
    super();
    this.user$ = this.store.pipe(select(userSelector));
  }

  @Input() link: NavbarLink;
  public user$: Observable<UserModel | null | undefined>;
  user: UserModel | null;
  b2b: boolean;
  lists: ProductList[] | null;

  ngOnInit(): void {
    this.user$.subscribe((user) => {
      this.user = user || null;
      this.b2b = this.userService.isB2B(this.user);
    });
  }

  isSmallScreen() {
    return this.window?.innerWidth < 992;
  }

  goToRoute(route: string) {
    this.router.navigate([route]);
  }
}
