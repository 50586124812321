<ul id="footer-links" class="navbar-nav d-flex justify-content-between">
  <li *ngFor="let item of links; let i = index" class="nav-item">
    <div class="title collapsed" data-bs-toggle="collapse" [attr.data-bs-target]="'#' + i">
      <a class="title-link" [attr.data-testid]="'footer-collapse-' + i | idFormatter"
        >{{ item?.title }} <i class="material-icons-outlined val-toggle">chevron_right</i></a
      >
    </div>
    <ul id="{{ item?.id }}" class="navbar-nav sub-links collapse">
      <li *ngFor="let link of item?.links" class="nav-item">
        <a
          *ngIf="link.route != null; else useHref"
          [routerLink]="link.route"
          [attr.data-testid]="'footer' + '-' + link.label | idFormatter"
          [target]="link.target"
          class="nav-link"
        >
          {{ link.title }}
        </a>
        <ng-template #useHref>
          <a
            class="nav-link"
            [target]="link.target"
            [href]="link.href"
            [attr.data-testid]="'footer' + '-' + link.label | idFormatter"
          >
            {{ link.title }}
          </a>
        </ng-template>
      </li>
    </ul>
  </li>
</ul>
