import { Injectable } from '@angular/core';
import { UserModel } from '../user/user.model';
import { NavLink } from '../../../shared/components/nav-link/nav-link.model';
import { NavbarLink } from '../../components/navbar/navbar-link/navbar-link.model';
import { UserService } from '../user/user.service';
import { PermissionService } from '../permission/permission.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { KontentService } from '../../components/kontent/kontent.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService extends BaseSubscriptionComponent {
  public quickLinks: NavLink[] = [];
  public solutionLinks: NavbarLink[] = [];
  public navbarLinks: NavbarLink[] = [];
  public accountLinks: NavbarLink;

  constructor(
    public userService: UserService,
    private permissionService: PermissionService,
    private kontentService: KontentService
  ) {
    super();
  }

  public getMenus(user: UserModel | null | undefined) {
    this.getQuickLinks(user);
    this.getSolutionLinks();
    this.getNavbarLinks(user);
    this.getAccountLinks(user);
  }

  public getQuickLinks(user: UserModel | null | undefined) {
    this.quickLinks = [
      {
        id: this.DATA_TEST_ID.header.quickLink.connectWithUs,
        title: 'Connect With Us',
        route: '/connect-with-us',
      },
    ];

    if (!this.userService.isB2B(user)) {
      this.quickLinks.push({
        id: this.DATA_TEST_ID.header.quickLink.newCustomer,
        title: 'New Customer',
        route: '/register',
      });
    }

    if (this.userService.isB2C(user)) {
      this.quickLinks.push({
        id: this.DATA_TEST_ID.header.quickLink.upgradeAccount,
        title: 'Upgrade Your Account',
        route: '/upgrade-your-account',
      });
    }

    this.quickLinks.push({
      id: this.DATA_TEST_ID.header.quickLink.vallenUsa,
      title: 'Vallen USA',
      href: 'https://vallen.com',
      target: '_blank',
    });
  }

  public getSolutionLinks() {
    this.solutionLinks = [];

    this.kontentService.getSolutionsMenu().then((res) => {
      this.solutionLinks = res;
    });
  }

  public getNavbarLinks(user: UserModel | null | undefined) {
    this.navbarLinks = [];
    if (this.userService.isBuyer(user)) {
      const prodListLinks = [
        {
          title: 'Quick List',
          route: '/quicklist',
        },
        {
          title: 'Shared Lists',
          type: 'shared',
        },
        {
          title: 'Public Lists',
          type: 'public',
        },
      ];
      if (this.userService.hasProductLists(user)) {
        prodListLinks.splice(1, 0, {
          title: 'Product Lists',
          type: 'user',
        });
      }

      this.navbarLinks.push({
        title: 'My Lists',
        links: prodListLinks,
      });

      if (this.permissionService.enableISREquote()) {
        const quotesLinks = [
          {
            title: 'Review Quotes',
            route: '/quotes',
          },
        ];

        if (this.permissionService.canCreateQuote()) {
          quotesLinks.push({
            title: 'Create Quote',
            route: '/quotes/create',
          });
        }

        this.navbarLinks.push({
          title: 'Quotes',
          links: quotesLinks,
        });
      }

      const orderLinks = [
        {
          title: 'Order History',
          route: '/order',
        },
      ];

      if (user && !this.userService.isPunchOut(user)) {
        // PunchOut users don't see order approvals
        orderLinks.push({
          title: 'Orders Awaiting Approval',
          route: '/ordersawaitingapproval',
        });
      }

      orderLinks.push(
        {
          title: 'Product History',
          route: '/order/producthistory',
        },
        {
          title: 'Import Order',
          route: '/importorder',
        }
      );

      this.navbarLinks.push({
        title: 'Orders',
        links: orderLinks,
      });
    } else {
      this.navbarLinks.push(
        {
          title: 'In The Know',
          route: '/articles',
        },
        {
          title: 'Careers',
          route: '/careers',
        },
        {
          title: 'About Us',
          route: '/content/about-vallen',
        }
      );
    }
    this.navbarLinks.push({
      title: 'Contact Us',
      route: '/contact',
    });
  }

  public getAccountLinks(user: UserModel | null | undefined) {
    let links: NavLink[] = [];
    if (user && !this.userService.isPunchOut(user)) {
      links = [
        {
          title: 'Account Settings',
          route: '/account/accountsettings',
        },
      ];

      if (!this.userService.isBuyer(user)) {
        links.push({
          title: 'Address List',
          route: '/addresses',
        });
      }

      if (this.userService.isB2C(user)) {
        links.push(
          {
            title: 'Upgrade Your Account',
            route: '/upgrade-your-account',
          },
          {
            title: 'Order History',
            route: '/order',
          }
        );
      }
      if (this.userService.isB2B(user)) {
        links.push({
          title: 'Change Account',
          route: '/account/change',
        });
      }
      if (this.userService.isAdmin(user)) {
        links.push({
          title: 'Admin',
          route: '/admin',
        });
      }
      if (this.userService.hasDashboard(user)) {
        links.unshift({
          title: 'Dashboard',
          route: '/dashboard',
        });
      }
    } else {
      links = [];
    }
    this.accountLinks = {
      title: 'My Account',
      icon: 'person',
      links: links,
    };
  }
}
