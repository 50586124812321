import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { OrderApprovalService } from '../../../core/services/order/order-approval.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingSpinnerService } from 'src/app/shared/components/loading-spinner/loading-spinner.service';
import { LoadingStatus } from '../../../types/loading-status.model';
import { SiteCartService } from '../../../core/services/cart/site-cart.service';

@Component({
  selector: 'val-order-approval-request',
  templateUrl: './order-approval-request.component.html',
  styles: ['.form-group .form-label.message:after { content: ""; }'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderApprovalComponent extends BaseSubscriptionComponent implements OnInit {
  approverInfo: any;
  loadingApprovers: boolean = true;
  approversDisplayNames: string[] = [];
  isLoading: LoadingStatus;

  constructor(
    public siteCart: SiteCartService,
    public orderApprovalService: OrderApprovalService,
    private toast: ToastService,
    private loading: LoadingSpinnerService,
    private router: Router
  ) {
    super();
    this.loading
      .getLoadingPrices()
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.isLoading = data;
      });
  }

  public orderApprovalForm = new FormGroup({
    cartName: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    message: new FormControl('', [Validators.maxLength(150)]),
  });

  ngOnInit(): void {
    this.loadingApprovers = true;
    this.orderApprovalService
      .getOrderApprovers()
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (res) => {
          //Should I verify shipto matches here as well?
          this.approverInfo = res as any;
          this.approverInfo.approvers.forEach((item: { email: string }) =>
            this.approversDisplayNames.push(item?.email)
          );
          this.loadingApprovers = false;
        },
        error: (error) => {
          this.toast.showError(error);
          this.loadingApprovers = false;
        },
      });
  }

  onSubmit() {
    if (this.orderApprovalForm.controls['cartName'].value) {
      this.orderApprovalForm.controls['cartName'].setValue(this.orderApprovalForm.controls['cartName'].value.trim());
    }
    if (this.orderApprovalForm.valid) {
      this.loading.start();
      this.siteCart.submitForApproval(this.orderApprovalForm.value).subscribe({
        next: (res) => {
          this.orderApprovalForm.reset();
          this.loading.stop();
          this.siteCart.resetSiteCart();
          this.router.navigate([`/ordersawaitingapproval`]);
          this.toast.showSuccess('Order Successfully Submitted for Approval');
        },
        error: (error) => {
          this.toast.showError(error);
          this.loading.stop();
        },
      });
    } else {
      this.toast.showError('Make sure all required fields have been entered.');
    }
  }
}
