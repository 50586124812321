import { createReHydrationReducer } from '../hydration.reducer';
import { on } from '@ngrx/store';
import * as WarehouseActions from '../warehouse/warehouse.actions';
import { logout } from '../authentication/auth.actions';
import { environment } from '../../../../environments/environment';
import { WarehouseNameNumber } from 'src/app/types/warehouse.model';

// Define the scoped state of this reducer
export interface WarehouseState {
  warehouse?: string | null | undefined;
  warehouses?: WarehouseNameNumber[] | null;
}

// Set initial state values
const initialState: WarehouseState = {
  warehouse: environment.warehouse,
  warehouses: [],
};

export const warehouseReducer = createReHydrationReducer(
  'warehouse',
  initialState,
  on(WarehouseActions.setWarehouse, (state, action) => ({
    ...state,
    ...action,
  })),
  on(logout, (state) => ({
    ...state,
    warehouse: null,
  }))
);
