import { Injectable, Inject } from '@angular/core';
import { ApiService } from '../api/api.service';
import { API_URL } from 'src/app/shared/consts/api-urls';
import {
  ActionFormat,
  ImportedProduct,
  SelectedCheckboxAndRadio,
} from 'src/app/shared/components/import-export/import-export.model';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { OrderDetail } from 'src/app/modules/order/order-detail.model';
import { ProductListService } from '../product-list/product-list.service';
import { tap } from 'rxjs/operators';
import { ToastService } from '../../../shared/components/toast/toast.service';
import { LoadingSpinnerService } from '../../../shared/components/loading-spinner/loading-spinner.service';
import { WINDOW } from 'src/app/app.module';
import { SearchService } from '../search/search.service';

@Injectable({
  providedIn: 'root',
})
export class ImportExportService {
  private selectedCheckboxesSubject = new BehaviorSubject<any[]>([]);
  private titleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private orderSubject = new BehaviorSubject<OrderDetail | null>(null);

  constructor(
    private api: ApiService,
    private listService: ProductListService,
    private searchService: SearchService,
    private toast: ToastService,
    private loading: LoadingSpinnerService,
    @Inject(WINDOW) private window: Window
  ) {}

  public importProducts(data: ImportedProduct[], type: string, listId?: number) {
    if (type === ActionFormat.IMPORT_QUICKLIST) {
      return this.api.put(`${API_URL.QuickListHeader}/${listId}/import`, { importItems: data });
    } else if (type === ActionFormat.IMPORT_PRODUCT_LIST) {
      return this.api.put(`${API_URL.ProductListHeader}/${listId}/import`, { importItems: data });
    } else {
      return this.api.put(API_URL.ImportOrder, data);
    }
  }

  public formatImportJson(data: any[], propName: string, importBy: string): ImportedProduct[] {
    // Remove duplicates
    try {
      const seen = new Set();
      const uniqueItems = data.filter((item) => {
        const duplicate = seen.has(item[0]);
        seen.add(item[0]);
        return !duplicate;
      });
      return uniqueItems.map((item) => {
        const newItem: ImportedProduct = {
          type: propName,
          value: item[0].toString().trim(),
        };
        if (item[1] && importBy === ActionFormat.IMPORT_QUICKLIST) {
          newItem.personalCode = item[1].toString().trim();
        } else if (item[1] && !isNaN(parseInt(item[1]))) {
          newItem.quantity = parseInt(item[1]);
        }
        return newItem;
      });
    } catch (e) {
      this.toast.showWarning('Unable to parse file. Please make sure file format is correct.');
      return [];
    }
  }

  public getExportProductsData(listId: string, selectedCheckboxValues: string[]) {
    return this.exportItemLists(`${API_URL.ProductListHeader}/${listId}/export`, selectedCheckboxValues);
  }

  public getExportQuickListData(listId: string, selectedCheckboxValues: string[]) {
    return this.exportItemLists(`${API_URL.QuickListHeader}/${listId}/export`, selectedCheckboxValues);
  }

  private exportItemLists(url: string, selectedCheckBoxValues: string[]) {
    const apiCall: Observable<object>[] = [];
    const attributes = this.handleAttributes(selectedCheckBoxValues);
    const limit = 500;
    const productCount = this.listService.getCurrentListItemCount();
    let page = 1;
    let pages = Math.ceil(Number(productCount / limit));
    let exportList: Observable<object>[] = [];

    do {
      const requestBody = {
        attributes: attributes,
        limit: limit,
        page: page,
        filters: this.searchService.applySearchFilters(this.searchService.indexName.products),
      };
      apiCall.push(this.api.put(url, requestBody));
      page++;
    } while (page <= pages);

    return new Observable<any>((subscriber) => {
      let completedRequests = 0;
      const totalRequests = apiCall.length;
      if (totalRequests > 1) {
        this.loading.startProgressBar();
      }
      forkJoin(
        apiCall.map((o) =>
          o.pipe(
            tap(() => {
              // measure progress
              completedRequests++;
              if (completedRequests === totalRequests) {
                this.toast.showSuccess('Export complete');
              } else if (totalRequests > 1) {
                this.loading.setProgressValue(Math.round((completedRequests / totalRequests) * 100));
              }
            })
          )
        )
      ).subscribe({
        next: (response: any) => {
          response.map((batch: any): any => {
            exportList = exportList.concat(batch.export);
          });

          subscriber.next(exportList);
          subscriber.complete();
        },
        error: (error: string) => {
          console.log('error: ', error);
          subscriber.error(error);
        },
      });
    });
  }

  private handleAttributes(selectedCheckboxValues: string[]): string[] {
    if (selectedCheckboxValues.includes('total')) {
      if (!selectedCheckboxValues.includes('price')) {
        selectedCheckboxValues.push('price');
      }
      if (!selectedCheckboxValues.includes('qty')) {
        selectedCheckboxValues.push('qty');
      }
    }
    return selectedCheckboxValues;
  }

  setTitle(value: string) {
    this.titleSubject.next(value);
    this.window?.localStorage.setItem('title', value);
  }

  getTitle(): Observable<string> {
    const savedTitle = this.window?.localStorage.getItem('title');
    if (savedTitle) {
      this.titleSubject.next(savedTitle);
    }
    return this.titleSubject.asObservable();
  }

  public setSelectedCheckboxes(selectedOptions: SelectedCheckboxAndRadio[]): void {
    this.selectedCheckboxesSubject.next(selectedOptions);
    this.window?.localStorage.setItem('selectedCheckboxes', JSON.stringify(selectedOptions));
  }

  public getSelectedCheckboxes(): SelectedCheckboxAndRadio[] {
    const savedCheckboxes = JSON.parse(this.window?.localStorage.getItem('selectedCheckboxes') || '[]');
    this.selectedCheckboxesSubject.next(savedCheckboxes);
    return this.selectedCheckboxesSubject.getValue();
  }

  public setOrder(order: OrderDetail) {
    this.window?.localStorage.setItem('order', JSON.stringify(order));
    this.orderSubject.next(order);
  }

  public getOrder(): Observable<OrderDetail | null> {
    const savedOrder = JSON.parse(this.window?.localStorage.getItem('order') || 'null');
    if (savedOrder) {
      this.orderSubject.next(savedOrder);
    }
    return this.orderSubject.asObservable();
  }
}
