import { Injectable } from '@angular/core';
import { ApiOptions } from '../api/api.model';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor() {}

  public qsFromObj(searchParams: ApiOptions | Record<string, number | boolean | string>): string {
    if (!searchParams) {
      return '';
    }
    return Object.keys(searchParams)
      .map((key) => {
        // @ts-ignore
        const val = searchParams[key];

        if (this.isPureObject(val)) {
          // params passed in as object converted to associative array
          let params = [];
          for (const [objKey, objVal] of Object.entries(val)) {
            if (objKey === objVal) {
              params.push(`${key}[]=` + encodeURIComponent(<string>objVal));
            } else {
              params.push(`${key}[${objKey}]=` + encodeURIComponent(<string>objVal));
            }
          }
          return params.join('&');
        }

        return encodeURIComponent(key) + '=' + encodeURIComponent(val);
      })
      .join('&');
  }

  public isPureObject(val: any): boolean {
    return val ? Object.getPrototypeOf(val) == Object.prototype : false;
  }

  public fieldsWithData(data: unknown): number {
    if (this.isPureObject(data)) {
      return Object.values(data as object).filter((x) => ((x as string) || '').trim().length).length;
    }
    return 0;
  }

  public arrayUnique(a: string[]) {
    return a ? a.filter((x, y, z) => z.indexOf(x) == y) : a;
  }

  public clone(val: any): string | any {
    return val ? JSON.parse(JSON.stringify(val)) : val;
  }

  public removeDuplicatesByKey<T>(array: T[], key: keyof T): T[] {
    const uniqueItemsMap = new Map();
    return array.filter((item) => {
      const uniqueKey = item[key];
      if (!uniqueItemsMap.has(uniqueKey)) {
        uniqueItemsMap.set(uniqueKey, item);
        return true;
      }
      return false;
    });
  }

  public isValidUrl(input: string): boolean {
    try {
      new URL(input);
      return true;
    } catch (err) {
      return false;
    }
  }
}
