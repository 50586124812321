import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingSpinnerService } from 'src/app/shared/components/loading-spinner/loading-spinner.service';
import { BreadcrumbsService } from '../../../breadcrumbs/breadcrumbs.service';
import { KontentService } from '../../kontent.service';
import { ViewportScroller } from '@angular/common';
import { PageTitle } from '../../../../../shared/components/page-title/page-title.model';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';

@Component({
  selector: 'val-kontent-suppliers',
  templateUrl: './kontent-suppliers.component.html',
  styleUrls: ['./kontent-suppliers.component.scss'],
})
export class KontentSuppliersComponent extends BaseSubscriptionComponent implements OnInit {
  suppliers: any = {};
  objectKeys = Object.keys;
  pageTitle: PageTitle;

  constructor(
    private breadcrumbService: BreadcrumbsService,
    public kontent: KontentService,
    private loadingService: LoadingSpinnerService,
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {
    super();
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.loadingService.start();

    this.kontent
      .getSuppliers('line_card')
      .then((data) => {
        this.pageTitle = new PageTitle(data.data.item.elements.title.value, '', '');
        for (let prop in data.data.linkedItems) {
          let letter = data.data.linkedItems[prop].elements['name']['value'].charAt(0).toUpperCase();
          if (!this.suppliers.hasOwnProperty(letter)) {
            this.suppliers[letter] = [];
          }
          this.suppliers[letter].push(data.data.linkedItems[prop].elements);
        }
      })
      .catch((err: any) => {
        console.log('Error: ', err);
        this.router.navigate(['/404']);
      })
      .finally(() => {
        this.loadingService.stop();
      });
  }

  onClick(elementId: any): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
