import { Component, OnInit } from '@angular/core';
import { PageTitle } from '../../../shared/components/page-title/page-title.model';
import { PageType } from '../../../shared/consts/page-type';
import {
  ActionFormat,
  ActionType,
  ImportExportAction,
  ImportResponse,
} from 'src/app/shared/components/import-export/import-export.model';
import { ProductResult, ProductSearchResults } from 'src/app/core/services/search/search.model';
import { LoadingStatus } from 'src/app/types/loading-status.model';
import { LoadingSpinnerService } from 'src/app/shared/components/loading-spinner/loading-spinner.service';
import { PricingService } from 'src/app/core/services/pricing/pricing.service';
import { SiteCartService } from '../../../core/services/cart/site-cart.service';
import { ToastService } from 'src/app/shared/components/toast/toast.service';

@Component({
  selector: 'val-import-order',
  templateUrl: './import-order.component.html',
  styleUrls: ['./import-order.component.scss'],
})
export class ImportOrderComponent implements OnInit {
  pageTitle = new PageTitle('Import Order', 'file_upload', '');
  isLoading: LoadingStatus;
  pageTypeEnum = PageType;
  public importActions: ImportExportAction = {
    type: ActionType.IMPORT,
    format: ActionFormat.IMPORT_ORDER_PAGE,
  };
  public PageType = PageType;
  results: ProductSearchResults = new ProductSearchResults();

  public importedProducts: ImportResponse = {
    products: [],
    invalidProducts: [],
  };

  constructor(
    private loading: LoadingSpinnerService,
    private pricing: PricingService,
    private siteCart: SiteCartService,
    public toast: ToastService
  ) {}

  ngOnInit(): void {
    this.loading.getLoadingPrices().subscribe((loading) => {
      this.isLoading = loading || this.isLoading;
    });
  }

  dataFromImport($event: ImportResponse) {
    if ($event && Array.isArray($event.products)) {
      this.importedProducts = $event;
      if (this.importedProducts.products) {
        this.pricing.getPricing(this.importedProducts.products).subscribe({
          next: (updatedProducts) => {
            this.importedProducts.products = updatedProducts;
          },
          error: (err) => {
            console.error('Error updating prices:', err);
            this.toast.showError('Error fetching pricing');
          },
        });
      }
    }
  }

  onDelete(row: ProductResult): void {
    const index = this.results.hits.indexOf(row);
    if (index >= 0) {
      this.results.hits.splice(index, 1);
    }
  }

  public async addToCart() {
    if (this.importedProducts && Array.isArray(this.importedProducts.products)) {
      try {
        this.siteCart.addItem(this.importedProducts.products, true, true);
      } catch (error) {
        this.toast.showError(`Cart error`);
      }
    }
  }

  handleRemoveItem(index: number): void {
    if (this.importedProducts && Array.isArray(this.importedProducts.products)) {
      if (index >= 0 && index < this.importedProducts.products.length) {
        this.importedProducts.products.splice(index, 1);
      }
    }
  }
}
