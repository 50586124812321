import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayOptions, initDisplayOptions, PageType } from 'src/app/shared/consts/page-type';
import { Cart, CartLine } from 'src/app/types/cart.model';
import { AddProductsFormComponent } from '../add-products-form/add-products-form.component';
import { SiteCartService } from 'src/app/core/services/cart/site-cart.service';
import { LoadingSpinnerService } from '../../loading-spinner/loading-spinner.service';
import { ModalComponent } from '../../modal/modal.component';
import { ItemListService } from 'src/app/core/services/item-list/item-list.service';
import { OrderApprovalService } from 'src/app/core/services/order/order-approval.service';
import { QuoteCartService } from 'src/app/core/services/cart/quote-cart.service';
import { BaseSubscriptionComponent } from '../../base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { ToastService } from '../../toast/toast.service';
import { Router } from '@angular/router';
import { ImportExportAction, ImportResponse } from '../../import-export/import-export.model';
import { UserModel } from 'src/app/core/services/user/user.model';
import { ProductListService } from 'src/app/core/services/product-list/product-list.service';
import { FormOption } from 'src/app/types/form-option.model';
import { SearchService } from 'src/app/core/services/search/search.service';
import { ProductResult, ProductSearchResults } from 'src/app/core/services/search/search.model';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { DownloadDocumentsService } from 'src/app/core/services/download-documents/download-documents.service';
import { PermissionService } from 'src/app/core/services/permission/permission.service';
import { UserService } from 'src/app/core/services/user/user.service';

export interface ShowDetails {
  collapsed: boolean;
  classes: string[];
}

@Component({
  selector: 'val-item-list-actions',
  templateUrl: './item-list-actions.component.html',
  styleUrls: ['./item-list-actions.component.scss'],
})
export class ItemListActionsComponent extends BaseSubscriptionComponent implements OnInit, OnChanges {
  // * Inputs and Outputs
  @Input() pageType: PageType | string = 'details';
  @Input() user: UserModel | undefined;
  @Input() cart: Cart;
  @Input() cartType: string | undefined;
  @Input() products: CartLine[];
  @Input() results: ProductSearchResults;
  @Input() listId: number;
  @Input() listName: string;
  @Input() activeFilters: any[];
  @Input() listType: string;
  @Input() collapsedDetails: ShowDetails;
  @Input() page: number = 1;
  @Output() onDeletedItems = new EventEmitter<CartLine[]>();
  @Output() changePageEvent = new EventEmitter<number>();
  @Output() onSortEvent = new EventEmitter<string>();
  @Output() onToggleFilterEvent = new EventEmitter<FormOption>();
  @Output() importSuccess = new EventEmitter<void>();
  @Output() toggleDetails = new EventEmitter<ShowDetails>();
  @Output() onAddProductToList = new EventEmitter<ProductResult[]>();
  @Output() onProductsAdded = new EventEmitter<number>();

  // * Private Properties
  private productsAdded: number = 0;

  // * Public Properties
  currentIndex: string = '';
  isCollapsed: string = '';
  isLoading: any;
  listProductCount: number;
  showDetailsIcon: string = 'icon add-box';
  showDetailsValue: string = 'Show';
  b2b: boolean = false;
  isPunchOutUser = false;
  hasProductLists: boolean = false;
  sortOptions: FormOption[] = [];
  documents: string[] = [];
  canUpdateQuicklist: boolean;

  importActions: ImportExportAction;
  exportExcelActions: ImportExportAction;
  exportPDFActions: ImportExportAction;

  importedProducts: ImportResponse = {
    products: [],
    invalidProducts: [],
  };

  display: DisplayOptions = {};
  isHistory = false; // special add to cart/list checkbox functionality needed for order history page
  isOrderDetail = false; // special add to cart/list checkbox functionality needed for order detail page
  isQuoteDetails = false;

  constructor(
    private modalService: NgbModal,
    private siteCart: SiteCartService,
    private quoteCart: QuoteCartService,
    private loadingService: LoadingSpinnerService,
    private itemList: ItemListService,
    public listService: ProductListService,
    private orderApproval: OrderApprovalService,
    private router: Router,
    private toast: ToastService,
    private search: SearchService,
    private utility: UtilityService,
    private downloadService: DownloadDocumentsService,
    private permissionService: PermissionService,
    private userService : UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initSortOptions();
    this.canUpdateQuicklist = this.permissionService.canUpdateQuicklist();

    if (this.user) {
      this.b2b = this.user.userTypeId === 'B2B';
      this.isPunchOutUser = this.userService.isPunchOut(this.user);
    }
    this.hasProductLists = this.userService.hasProductLists(this.user || null);
    this.listProductCount = this.listService.getCurrentListItemCount();
    this.loadingService.getLoadingPrices().subscribe((loading) => {
      this.isLoading = loading || this.isLoading;
    });

    if (this.collapsedDetails) {
      this.showDetailsIcon = !this.collapsedDetails.collapsed ? 'icon add-box' : 'icon remove-box';
      this.showDetailsValue = !this.collapsedDetails.collapsed ? 'Show' : 'Hide';
    }

    this.setDisplayAndImportExportValues();
    if (this.pageType) {
      this.isHistory = this.pageType.includes('product-history');
      this.isOrderDetail = this.pageType.includes('order-details');
      this.isQuoteDetails = this.pageType.includes('quote-details');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cart']) {
      this.cart = changes['cart'].currentValue;
    }
    this.setDisplayAndImportExportValues();
    this.getDocuments();
  }

  // * Private Methods
  private setDisplayAndImportExportValues() {
    this.display = initDisplayOptions(this.pageType).displayOptions;
    this.importActions = initDisplayOptions(this.pageType, this.listId).importActions as ImportExportAction;
    this.exportExcelActions = initDisplayOptions(this.pageType, this.listId).exportExcelActions as ImportExportAction;
    this.exportPDFActions = initDisplayOptions(this.pageType, this.listId).exportPDFActions as ImportExportAction;
  }

  private getPricing() {
    this.loadingService.start();
    const cartService = this.cart.cartType === 'Q' ? this.quoteCart : this.siteCart;
    cartService.sxeGetPricing(['summary', 'price', 'total']).subscribe({
      next: () => {
        this.loadingService.stop();
      },
      error: (err) => {
        this.loadingService.stop();
        if (!err.includes('NotFound')) {
          console.log(err);
        }
      },
    });
  }

  private initSortOptions(): void {
    this.sortOptions = [
      {
        label: 'Relevance',
        value: this.search.indexName.products,
      },
      {
        label: 'Product Title (ascending)',
        value: this.search.indexName.products_asc,
      },
      {
        label: 'Product Title (descending)',
        value: this.search.indexName.products_desc,
      },
    ];
    this.currentIndex = this.sortOptions[0].value;
  }

  private getDocuments(): void {
    let allDocuments: string[] = [];
    if (this.results?.hits) {
      this.results.hits.forEach((hit: ProductResult) => {
        if (hit.attachments && hit.attachments.length) {
          allDocuments = allDocuments.concat(hit.attachments);
        }
      });
    } else {
      this.products.forEach((product: CartLine) => {
        if (product.attachments && product.attachments.length) {
          allDocuments = allDocuments.concat(product.attachments);
        }
      });
    }
    this.documents = this.utility.arrayUnique(allDocuments);
  }

  // * Public Methods
  toggleCollapse() {
    this.collapsedDetails.collapsed = !this.collapsedDetails.collapsed;
    this.showDetailsIcon = this.collapsedDetails.collapsed ? 'icon remove-box' : 'icon add-box';
    this.showDetailsValue = this.collapsedDetails.collapsed ? 'Hide' : 'Show';

    const classes = ['details'];
    if (!this.collapsedDetails.collapsed) {
      classes.push('show-other-details');
    }

    this.toggleDetails.emit({ collapsed: this.collapsedDetails.collapsed, classes: classes });
  }

  togglePanel(panel: string) {
    this.isCollapsed = this.isCollapsed === panel ? '' : panel;
  }

  collapseOnCancel() {
    this.isCollapsed = '';
  }

  onCheckAll(e: any) {
    if (this.products) {
      this.itemList.checkAll(e, this.products, (this.isHistory || this.isOrderDetail));
    } else if (this.results && this.results.hits) {
      this.results.hits.map((h) => {
        if (h.price) {
          h.isSelected = e.target.checked;
        }
      });
    }
  }

  unCheckAll() {
    this.itemList.unCheckAll(this.products);
  }

  selectedItems() {
    if (this.products !== undefined) {
      return this.itemList.selectedItems(this.products);
    } else {
      return this.itemList.selectedItems(this.results.hits);
    }
  }

  onImportSuccess() {
    this.importSuccess.emit();
  }

  onChangePage(page: number) {
    this.changePageEvent.emit(page);
    this.page = <number>page;
  }

  // TODO: Cart needs to be defined
  onAddToCart(products: CartLine | CartLine[]) {
    this.cart.cartType === 'approval' ? this.orderApproval.addItem(products) : this.siteCart.addItem(products);
    this.unCheckAll();

    if (this.products) {
      this.products.map((x) => {
        x.qty = x.sellMult;
      });
    }
  }

  onAddProduct() {
    this.productsAdded = 0;
    const modalRef = this.modalService.open(AddProductsFormComponent, { size: 'lg' });
    const modalOptions = {
      title: 'Add Products',
      allowNonStock: this.pageType === PageType.QUOTE, // non-stock items only allowed when creating quote
    };
    modalRef.componentInstance.setOptions(modalOptions);
    modalRef.componentInstance.productSelected.subscribe((selectedProducts: CartLine[]) => {
      // If Product List page add to list
      if (this.pageType.includes(PageType.LISTS)) {
        this.onAddProductToList.emit(selectedProducts);
      }
      // Add to cart
      else {
        if (selectedProducts?.length) {
          this.productsAdded++;
        }
        if (this.cart.cartType === 'Q') {
          this.quoteCart.addItem(selectedProducts, true);
        } else {
          this.siteCart.addItem(selectedProducts, true);
        }
        this.cart.cartItems?.map((x) => {
          x.sellMult = x.isNonStock || !x.sellMult ? 1 : x.sellMult;
          x.qty = x.sellMult;
        });
      }
    });

    modalRef.result.then(() => {
      if (this.productsAdded) {
        this.onProductsAdded.emit(this.productsAdded);
        this.getPricing();
      }
    });
  }

  onDelete() {
    const selected = this.selectedItems();
    if (!selected.length) {
      return;
    }

    // TODO: From item-list-actions
    // const msg = selected.length === 1 ? 'this item' : `these ${selected.length} items`;

    // ideally remove
    const rejectOrderApproval = this.cart.cartType === 'approval' && selected.length == this.products.length;
    const msg = rejectOrderApproval
      ? `${this.orderApproval.currentCart.cartName}`
      : selected.length === 1
      ? 'this item'
      : `these ${selected.length} items`;
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.options = {
      title: rejectOrderApproval ? 'Reject Approval Order' : 'Shopping Cart',
      body: `Are you sure you want to delete ${msg}?`,
      bodyClass: 'text-center',
    };

    modalRef.result.then(
      (result: any) => {
        if (result === true) {
          // TODO - proper API
          // ideally remove
          if (this.pageType.includes(PageType.LISTS)) {
            this.onDeletedItems.emit(selected);
          } else if (rejectOrderApproval) {
            this.orderApproval.rejectOrderPendingApproval(this.orderApproval.currentCart.cartId);
            this.orderApproval
              .rejectOrderPendingApproval(this.orderApproval.currentCart.cartId)
              .pipe(takeUntil(this.destroyed))
              .subscribe({
                next: () => {
                  this.router.navigate(['/ordersawaitingapproval']);
                  this.toast.showSuccess('Order Approval successfully deleted.');
                },
                error: () => {
                  this.toast.showError('Deleting approval order failed. Please contact technical support.');
                },
              });
          } else {
            if (this.cart.cartType === 'approval') {
              this.orderApproval.removeItems(selected);
            } else if (this.cart.cartType === 'quote') {
              this.quoteCart.removeItems(selected);
            } else {
              this.siteCart.removeItems(selected);
            }
          }
        }
      },
      () => {
        // User dismissed the modal
      }
    );
  }

  onEmpty() {
    const modalRef = this.modalService.open(ModalComponent);
    const type = this.cart.cartType === 'Q' ? 'Quote' : 'Cart';
    modalRef.componentInstance.options = {
      title: 'Empty ' + type,
      body: 'Are you sure you want to empty this ' + type.toLowerCase() + '?',
      bodyClass: 'text-center',
    };

    modalRef.result.then(
      (result: any) => {
        if (result === true) {
          // Empty cart
          // this.emptyQuote();
          if (this.cart.cartType === 'Q') {
            this.quoteCart.emptyCartItems();
          } else {
            this.siteCart.emptyCartItems();
          }
        }
      },
      () => {
        // User dismissed the modal
      }
    );
  }

  onSort(index: any) {
    if (index.value !== this.currentIndex) {
      this.currentIndex = index.value;
      this.onSortEvent.emit(index.value);
      this.page = 1;
    }
  }

  onFilter(facetKey: string, facetValue: string) {
    this.onToggleFilterEvent.emit({
      label: facetKey,
      value: facetValue,
    });
  }

  async downloadAllResults(): Promise<void> {
    if (!this.results) return;
    const attachments = this.results.hits.flatMap((product) => product.attachments || []);
    await this.downloadService.downloadAllFiles(attachments);
  }

  async downloadAll(): Promise<void> {
    const attachments = this.products.flatMap((product) => product.attachments || []);
    if (!attachments.length) return;
    await this.downloadService.downloadAllFiles(attachments);
  }

  dataFromImport($event: ImportResponse) {
    this.importedProducts = $event;
  }
}
