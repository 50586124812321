import { Component, Input, OnInit } from '@angular/core';
import { PageTitle } from './page-title.model';

@Component({
  selector: 'val-page-title',
  template: `<div class="header" *ngIf="pageTitle">
    <div class="pg-title">
      <i *ngIf="pageTitle.icon" class="material-icons-outlined">{{ pageTitle.icon }}</i>
      {{ pageTitle.title }}
    </div>
    <p *ngIf="pageTitle.desc">
      {{ pageTitle.desc }}
    </p>
  </div>`,
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {
  constructor() {}

  @Input() pageTitle?: PageTitle;

  ngOnInit(): void {}
}
