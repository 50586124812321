import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SearchService } from '../../core/services/search/search.service';
import { Title } from '@angular/platform-browser';
import {
  CategoryPath,
  CategoryResult,
  ProductResult,
  SearchOptions,
  ProductSearchResults,
} from '../../core/services/search/search.model';
import { FacetGroup } from '../../core/services/search/search-filter/search-filter.model';
import { Breadcrumb } from '../../core/components/breadcrumbs/breadcrumb.model';
import { BreadcrumbsService } from '../../core/components/breadcrumbs/breadcrumbs.service';
import { LoadingSpinnerService } from '../../shared/components/loading-spinner/loading-spinner.service';
import { SearchFilterService } from '../../core/services/search/search-filter/search-filter.service';
import { ToastService } from '../../shared/components/toast/toast.service';
import { PricingService } from '../../core/services/pricing/pricing.service';
import { ItemListService } from '../../core/services/item-list/item-list.service';
import { PageType } from '../../shared/consts/page-type';
import { UserModel } from '../../core/services/user/user.model';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppStateInterface } from '../../types/app-state.interface';
import { userSelector } from '../../core/store/user/user.selectors';
import { FormOption } from '../../types/form-option.model';
import { UserService } from '../../core/services/user/user.service';
import { ShowDetails } from 'src/app/shared/components/products/item-list-actions/item-list-actions.component';
import { SiteCartService } from 'src/app/core/services/cart/site-cart.service';
import { Cart } from 'src/app/types/cart.model';

// TODO - No results

@Component({
  selector: 'val-search-results',
  templateUrl: './product-listing-page.component.html',
  styleUrls: [
    '../../../assets/styles/shared/products.scss',
    '../../../assets/styles/shared/product-list.scss',
    '../../../assets/styles/shared/product-filters.scss',
    './product-listing-page.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ProductListingPageComponent implements OnInit, OnDestroy {
  storedParams: any;
  constructor(
    private route: ActivatedRoute,
    private crumbService: BreadcrumbsService,
    private itemList: ItemListService,
    private loadingService: LoadingSpinnerService,
    private router: Router,
    private search: SearchService,
    private searchFilter: SearchFilterService,
    private store: Store<AppStateInterface>,
    private titleService: Title,
    private toast: ToastService,
    private pricing: PricingService,
    private userService: UserService,
    private siteCart: SiteCartService
  ) {
    this.user$ = this.store.pipe(select(userSelector));
  }

  pageTypeEnum = PageType;
  page: number = 1;
  pageType: string = '';
  query: string = '';
  requestOptions: SearchOptions = {
    facets: ['*'],
    page: 1,
  };
  results: ProductSearchResults;
  resultsForFilter: ProductSearchResults;
  products: ProductResult[];
  facets: any[];
  activeFilters: any[] = [];
  currentIndex: string = '';
  categoryFilters: FacetGroup | null = null;
  facetFilters: FacetGroup[] | null;
  maxFilters: number = 10;
  user?: UserModel;

  data: any = [];
  category: CategoryResult;
  pageTitle: string = '';
  b2b: boolean = false;
  isCollapsedDetails: ShowDetails = {
    collapsed: true,
    classes: ['details'],
  };
  private userSub: Subscription;
  private user$: Observable<UserModel | null | undefined>;
  cart: Cart;
  private previousQParam: string | null = null;

  ngOnInit(): void {
    this.searchFilter.clear();
    this.userSub = this.user$.subscribe((user) => {
      this.user = user || undefined;
      this.b2b = this.userService.isB2B(user);
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      const currentQParam = params.get('seoUrl');
      if (this.previousQParam && this.previousQParam !== currentQParam) {
        this.searchFilter.clear();
      }
      this.previousQParam = currentQParam;
    });

    this.initResults();
    this.cart = this.siteCart.activeCart;
  }

  initResults() {
    let initSearch: Promise<any>;

    // determine what type of search to perform based on page URL/parameters, reset to page 1
    this.route.paramMap.subscribe((params: ParamMap) => {

      this.page = this.route.snapshot.queryParamMap.get('page')
          ? parseInt(this.route.snapshot.queryParamMap.get('page')!)
          : 1;
      // algolia pages start at 0
      this.requestOptions.page = this.page - 1;

      if (params.get('q')) {
        this.pageType = PageType.SEARCH;
        initSearch = this.initSearchResults(<string>params.get('q'));
        this.resetFilter();
        this.searchFilter.clear();
      } else if (params.get('seoUrl')) {
        initSearch = this.initCategoryResults(<string>params.get('seoUrl'));
        this.pageType = PageType.CATEGORY;
      } else if (this.route.snapshot.url[0].path === PageType.PROMOTIONS) {
        this.pageType = PageType.PROMOTIONS;
        this.requestOptions.facetFilters = ['isClearance:true'];
        initSearch = this.initClearanceResults();
      } else {
        this.router.navigate(['/404']);
        return;
      }

      this.loadingService.start();
      this.applyFiltersAndUpdateActive();
      initSearch
        .then(() => {
          const productResultsPromise = this.search.getProductResults(
            this.query,
            this.requestOptions,
            undefined,
            this.pageType === PageType.PROMOTIONS
          );

          const filterResultsPromise = this.search.getProductResults(
            this.query,
            this.requestOptions,
            undefined,
            this.pageType === PageType.PROMOTIONS
          );

          return Promise.all([productResultsPromise, filterResultsPromise]);
        })
        .then(([productResults, filterResults]) => {
          this.results = <ProductSearchResults>productResults;
          this.setCategoryFilters();

          this.resultsForFilter = <ProductSearchResults>filterResults;
          this.setFacetFilters();
        })
        .catch(() => {
          this.toast.showError('Error retrieving initial results');
        })
        .finally(() => {
          this.completeSearch(this.pageType === PageType.CATEGORY);
        });
    });
  }

  resetFilter() {
    this.activeFilters = [];
    this.requestOptions.facetFilters = [];
  }

  onSort(index: string) {
    this.currentIndex = index;
    this.page = 1;
    this.requestOptions.page = 0;
    this.getResults();
  }

  getResults() {
    this.unCheckAll();
    this.loadingService.start();
    this.formatProductSearchFilters();

    this.applyFiltersAndUpdateActive();
    this.search
      .getProductResults(this.query, this.requestOptions, this.currentIndex, this.pageType === PageType.PROMOTIONS)
      .then((res) => {
        this.results = <ProductSearchResults>res;
        this.setCategoryFilters();
        this.setFacetFilters();
      })
      .catch(() => {
        this.toast.show(`Error retrieving results`, {
          classname: 'alert-danger',
        });
      })
      .finally(() => {
        this.completeSearch();
        this.unCheckAll();
      });
  }

  initSearchResults(query: string) {
    this.query = decodeURIComponent(query);
    this.pageTitle = `<span>Search results for</span> ${this.query}`;
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  initClearanceResults() {
    this.query = '';
    this.formatProductSearchFilters();
    this.pageTitle = `Specials`;
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  initCategoryResults(seoUrl: string) {
    return this.search.getCategory(<string>seoUrl).then((res) => {
      if (!res.hits.length) {
        this.router.navigate(['/404']);
        return;
      }
      this.category = <CategoryResult>res.hits[0];

      // set title
      this.pageTitle = this.category.name;
      this.titleService.setTitle(this.pageTitle);

      // set breadcrumbs / filters
      const crumbs: Breadcrumb[] = [];
      const categories: string[] = [];
      let crumbUrl = '/products';
      this.category.path.forEach((path: CategoryPath) => {
        crumbUrl += `/${path.slug}`;
        crumbs.push(new Breadcrumb(path.name, crumbUrl));
        categories.push(path.name);
      });
      if (crumbs.length) {
        crumbs.pop();
        crumbs.push(new Breadcrumb(this.pageTitle, undefined));
      }
      this.crumbService.setBreadcrumbs(crumbs);
      this.onFilter({ facetKey: 'categories.lvl' + (categories.length - 1), facetValue: categories.join(' > ') });

      // TODO - Set canonical using category url
    });
  }

  setCategoryFilters() {
    if (this.pageType !== PageType.CATEGORY) {
      this.categoryFilters = this.searchFilter.formatCategoryFilters(this.results.facets, this.categoryFilters);
    }
  }

  setFacetFilters() {
    this.facetFilters = this.searchFilter.formatFacetFilters(this.resultsForFilter?.facets);
  }

  toggleFacets(id: any) {
    this.searchFilter.toggleFacets(id);
  }

  toggleFacetPanel() {
    this.searchFilter.toggleFacetPanel('facetFilterPanel');
  }

  clearFilters() {
    // keep category filter, when it was not selected by user
    const keepCategory = this.activeFilters.filter((f: any) => f.filter.match(this.searchFilter.catRegExp)).length < 1;

    let newFilter: string[] = [];
    if (keepCategory && this.requestOptions.facetFilters?.length) {
      newFilter = this.requestOptions.facetFilters.filter((f: any) => {
        if (f.length === 1 && f[0].match(this.searchFilter.catRegExp)) {
          return f[0];
        }
      });
    } else {
      this.categoryFilters = null;
    }
    this.requestOptions.facetFilters = newFilter;
    this.activeFilters = [];
    this.onChangePage(1);
    this.toggleFacetPanel();
  }

  completeSearch(bypassPricing?: boolean) {
    this.loadingService.stop();
    if (!this.results) {
      return;
    }

    if (this.query && !this.results.hits.length) {
      this.pageTitle = `<span>No results for</span> ${this.query}`;
    } else if (this.results.hits.length && !bypassPricing) {
      window.scrollTo(0, 0);
      this.pricing.getPricing(this.results.hits).subscribe({
        next: (updatedProducts) => {
          this.results.hits = updatedProducts;
        },
        error: (err) => {
          console.error('Error updating prices:', err);
          this.toast.showError('Error fetching pricing');
        },
      });
    }
  }

  onChangePage(page: number | string) {
    this.page = <number>page;
    this.requestOptions.page = this.page - 1;
    this.getResults();
  }

  onFilter(data: { facetKey: string; facetValue: string }) {
    this.requestOptions.facetFilters = this.searchFilter.updateFacetFilters(data.facetKey, data.facetValue);

    if (this.searchFilter.clearCategoryFilter) {
      this.categoryFilters = null;
    }
    this.page = 1;
    this.requestOptions.page = 0;
    this.activeFilters = this.searchFilter.setActiveFilters(this.requestOptions.facetFilters, this.pageType);
    this.searchFilter.activeFilterQueryString(this.activeFilters);
    this.getResults();
    this.unCheckAll();
  }

  onToggleFilter(facet: FormOption) {
    this.onFilter({ facetKey: facet.label, facetValue: facet.value });
  }

  unCheckAll() {
    if (this.results) {
      this.itemList.unCheckAll(this.results.hits);
    }
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  private formatProductSearchFilters(): void {
    if (this.pageType === PageType.PROMOTIONS) {
      if (!this.requestOptions.facetFilters) {
        this.requestOptions.facetFilters = [];
      }
      if (!this.requestOptions.facetFilters.includes('isClearance:true')) {
        this.requestOptions.facetFilters.push('isClearance:true');
      }
    }
  }

  private applyFiltersAndUpdateActive() {
    if (!this.searchFilter.onFilterCalled) {
      this.searchFilter.setQueryStringFacetFilters(this.route.queryParams, this.requestOptions, []);
      this.activeFilters = this.searchFilter.setActiveFilters(this.requestOptions.facetFilters, this.pageType);
    }
  }
}
