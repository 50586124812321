import { createReHydrationReducer } from '../hydration.reducer';
import { on } from '@ngrx/store';
import * as CustomerActions from './customer.actions';
import { logout } from '../authentication/auth.actions';
import { CustomerOption } from 'src/app/types/customer-option.model';

// Define the scoped state of this reducer
export interface CustomerState {
  customer: CustomerOption | null;
}

// Set initial state values
const initialState: CustomerState = {
  customer: null,
};

export const customerReducer = createReHydrationReducer(
  'customer',
  initialState,
  on(CustomerActions.setCustomer, (state, action) => ({
    ...state,
    ...action,
  })),
  on(logout, (state) => ({
    ...state,
    customer: null,
  }))
);
