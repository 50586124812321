<div class="container products-detail" *ngIf="product" [ngClass]="isBuyer ? 'b2b' : 'b2c'">
  <section class="details">
    <!-- Product gallery begins -->
    <div class="col-6 product-gallery">
      <div
        class="product-img"
        (click)="toggleImageModal('image', productImg)"
        [ngClass]="{ carousel: hasImageCarousel }"
        [attr.data-testid]="DATA_TEST_ID.product.detail.imageModalLink | idFormatter"
      >
        <img id="lg-img" [src]="productImg" [alt]="product.itemId" fallbackImage />
      </div>

      <div class="product-slider">
        <div class="content-carousel" *ngIf="hasImageCarousel">
          <owl-carousel-o class="owl-carousel-wrapper" [options]="customOptions">
            <ng-container *ngFor="let slide of product.imageGallery; let i = index">
              <ng-template carouselSlide>
                <div
                  class="img-wrapper"
                  (click)="productImg = slide"
                  [attr.data-testid]="DATA_TEST_ID.product.detail.slide + '-' + i | idFormatter"
                >
                  <img class="img-fluid mx-auto my-auto" [src]="slide" />
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
        <div *ngIf="videos.length" class="d-flex justify-content-center align-items-center videos">
          <div
            class="product-video"
            *ngFor="let video of videos; let i = index"
            (click)="toggleImageModal('video', video)"
            [attr.data-testid]="DATA_TEST_ID.product.detail.video + '-' + i | idFormatter"
          >
            <i class="material-icons-outlined md-36 icon">play_circle</i>
          </div>
        </div>
      </div>

      <div id="imageModal" class="modal">
        <span class="close">
          <i
            class="material-icons-outlined"
            style="cursor: pointer"
            (click)="toggleImageModal()"
            [attr.data-testid]="DATA_TEST_ID.product.detail.closeModal | idFormatter"
            >close</i
          >
        </span>
        <div class="center">
          <img class="modal-content" *ngIf="selectedMedia.type === 'image'; else showVideo" [src]="selectedMedia.src" />
          <ng-template #showVideo>
            <video
              class="img-fluid mx-auto"
              [src]="selectedMedia.src"
              (click)="toggleImageModal()"
              width="60%"
              height="60%"
              autoplay
              controls
              loop
              [attr.data-testid]="DATA_TEST_ID.product.detail.toggleImageModal | idFormatter"
            ></video>
          </ng-template>
        </div>
      </div>
    </div>
    <!-- Product gallery ends -->

    <!-- Product detail begins -->
    <div class="col-6 text position-relative">
      <val-item-tags
        [user]="user"
        [product]="product"
        [quickListHeaderId]="quickListHeaderId"
        class="pdp"
      ></val-item-tags>
      <div class="sub-title">{{ product.itemId }}</div>
      <h1 class="title">{{ product.title }}</h1>
      <div class="stock-status">
        <div class="price" *ngIf="canViewPrices">
          <val-product-price [product]="product" [isLoading]="isLoading.price"></val-product-price>
        </div>
        <val-product-status [product]="product" [b2b]="isBuyer"></val-product-status>
      </div>

      <div class="add-to-cart form-container">
        <div class="quantity d-flex">
          <form class="forms">
            <div class="qty">
              <span class="supp uom margin">Qty </span>
              <val-product-quantity [product]="product" [disabled]="!product.price"></val-product-quantity>
            </div>
          </form>
          <div class="col-9 right d-flex btn-wrapper">
            <button
              class="btn btn-lg btn-primary w-100 text-nowrap"
              type="button"
              (click)="addToCart()"
              [disabled]="!product.price || qtyError(product)"
              [attr.data-testid]="DATA_TEST_ID.product.detail.addToCart | idFormatter"
            >
              Add to Cart
            </button>

            <button
              *ngIf="hasProductLists"
              class="btn btn-lg btn-outline-primary w-100 text-nowrap ms-3"
              type="button"
              (click)="listService.toggleProductListForm()"
              [attr.data-testid]="DATA_TEST_ID.product.detail.addToList | idFormatter"
            >
              Add to List
            </button>
          </div>
        </div>
        <val-product-list-form *ngIf="hasProductLists" [user]="user" [products]="product"></val-product-list-form>
      </div>
      <div class="field-validation-error" *ngIf="qtyError(product)">
        <span class="alert-warning">Quantity must be in multiples of {{ product.sellMult }}</span>
      </div>

      <div class="other-details">
        <div *ngIf="quickListHeaderId && (canUpdateQuickList || hasPersonalCode(product))" class="code-terms mt-3">
          <div [ngClass]="canUpdateQuickList ? 'change-link' : 'mfg'">
            <span>Personal code:</span>
            <span>{{
              quickListHeaderId && product.personalCodes && product.personalCodes[quickListHeaderId]
                ? product.personalCodes[quickListHeaderId]
                : "Set personal code"
            }}</span>
          </div>
          <val-personal-code *ngIf="canUpdateQuickList" [product]="product!" [quickListHeaderId]="quickListHeaderId!"></val-personal-code>
        </div>
        <div class="mfg-block">
          <div class="mfg">
            <span>Manufacturer:</span><span>{{ product.manufacturer }}</span>
          </div>
          <div class="mfg">
            <span>Manufacturer #:</span><span>{{ product.manufacturerPartNo }}</span>
          </div>
        </div>
      </div>

      <val-product-documents [product]="product" [b2b]="isBuyer"></val-product-documents>
    </div>
    <!-- Product detail ends -->
  </section>

  <!--  TODO - Frequently viewed together / Similar products-->

  <section class="specifications" *ngIf="product.description">
    <h2>Description</h2>
    <div class="text" [innerHTML]="product.description"></div>
  </section>

  <val-specs-table *ngIf="product['attributes']" [specs]="product['attributes']" [title]="'Specifications'">
  </val-specs-table>

  <!--  TODO - Inventory-->
  <section class="specifications" *ngIf="inventory.length">
    <h2>Inventory</h2>
    <div class="row">
      <div *ngFor="let i of inventory" class="col-6 col-md-4 col-lg-3 mb-3">
        <div class="card">
          <div class="card-body font-medium py-2 px-3" [ngClass]="{ 'font-dark': i.available }">
            <div class="float-end">{{ i.quantity }}</div>
            {{ i.warehouse }} - {{ i.warehouse | warehouseName : warehouses }}
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="specifications" *ngIf="product.uomList?.length">
    <h2>Packages</h2>
    <div class="row">
      <div *ngFor="let uomItem of product.uomList" class="col-6 col-md-4 col-lg-3 mb-3">
        <div class="card">
          <div class="card-body font-medium py-2 px-3">{{ uomItem.units }} - {{ uomItem.description }}</div>
        </div>
      </div>
    </div>
  </section>

  <!--  TODO - Substitutes for this product-->
  <!--  TODO - Customers who bought this item also bought-->
</div>
