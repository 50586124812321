<footer>
  <div class="footer-main">
    <div class="container d-flex justify-content-between">
      <div class="col-xs-4 footer-links">
        <val-footer-links [links]="footerNavMenu"></val-footer-links>
      </div>
      <div class="col-xs-4 footer-address">
        <div class="title">Head Office</div>
        <p>
          {{ branchAddress.street }}<br />
          {{ branchAddress.cityStateZip }}
        </p>
        <p class="mb-0">
          <span>Phone number</span>:
          <a href="{{ 'tel:' + footerData?.footerBranch?.linkedItems[0]?.phoneNumber }}">{{
            footerData?.footerBranch?.linkedItems[0]?.phoneNumber | phone
          }}</a>
        </p>
        <p class="mb-0" *ngIf="footerData?.footerBranch?.linkedItems[0]?.faxNumber">
          <span>Fax number</span>:
          <a href="{{ 'tel:' + footerData?.footerBranch?.linkedItems[0]?.faxNumber }}">{{
            footerData?.footerBranch?.linkedItems[0]?.faxNumber | phone
          }}</a>
        </p>
      </div>
      <div class="col-xs-4 footer-signup">
        <val-newsletter-signup></val-newsletter-signup>
        <div class="socials">
          <a *ngFor="let item of socialLinks" target="_blank" href="{{ item.link }}">
            <img src="{{ item.asset }}" alt="{{ item.label }}" />
          </a>
        </div>
      </div>
    </div>

    <div class="copyright container mt-5">
      <span [innerHTML]="footerData?.copyrightText?.value"></span>
      <a (click)="scrollToTop()" class="back-to-top"><i class="material-icons-outlined md-36">expand_less</i></a>
    </div>
  </div>
</footer>
