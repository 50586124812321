import { createReHydrationReducer } from '../hydration.reducer';
import { on } from '@ngrx/store';
import { Cart } from '../../../types/cart.model';
import * as CartActions from '../cart/cart.actions';
import { logout } from '../authentication/auth.actions';

// Define the scoped state of this reducer
export interface CartState {
  cart?: Cart | null;
}

// Set initial state values
const initialState: CartState = {
  cart: null,
};

export const cartReducer = createReHydrationReducer(
  'cart',
  initialState,
  on(CartActions.setCart, (state, action) => ({
    ...state,
    cart: action.cart ? { ...action.cart } : action.cart,
  })),
  on(CartActions.updateCart, (state, action) => ({
    ...state,
    cart: {
      ...state.cart,
      ...action.cart,
    },
  })),
  on(CartActions.updateCartField, (state, action) => ({
    ...state,
    cart: state.cart
      ? {
          ...state.cart,
          [action.key]: action.value,
        }
      : null,
  })),
  on(logout, (state) => ({
    ...state,
    cart: null,
  }))
);
