import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PersonalCodeService {
  public isPersonalCodeVisible: boolean = false;
  public activeItemId: string | null = null;

  public isPersonalCodeVisible$ = new BehaviorSubject<boolean>(false);
  public activeItemId$ = new BehaviorSubject<string | null>(null);

  constructor() {}

  public showPersonalCode(itemId: string): void {
    if (this.isPersonalCodeVisible && this.activeItemId === itemId) {
      this.isPersonalCodeVisible = false;
      this.activeItemId = null;
    } else {
      this.isPersonalCodeVisible = true;
      this.activeItemId = itemId;
    }

    this.isPersonalCodeVisible$.next(this.isPersonalCodeVisible);
    this.activeItemId$.next(this.activeItemId);
  }

  public closePersonalCode(): void {
    this.isPersonalCodeVisible = false;
    this.activeItemId = null;

    this.isPersonalCodeVisible$.next(this.isPersonalCodeVisible);
    this.activeItemId$.next(this.activeItemId);
  }
}
