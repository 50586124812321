import { Injectable, Inject } from '@angular/core';
import { ProductResult } from '../search/search.model';
import { ProductListDetails } from '../../../types/product-list.model';
import { ItemListQty } from '../../../types/item-list-qty.model';
import { WINDOW } from 'src/app/app.module';

@Injectable({
  providedIn: 'root',
})
export class ItemListService {
  constructor(@Inject(WINDOW) private window: Window) {}

  checkAll(e: any, products: ProductResult[], isInAlgolia?: boolean): void {
    products.map((h) => {
      if (!isInAlgolia || (isInAlgolia && h.isInAlgolia)) {
        h.isSelected = e.target.checked;
      }
    });
  }

  unCheckAll(products: ProductResult[]): void {
    if (!products || !products.length) {
      return;
    }
    const checkAll: HTMLInputElement = this.window?.document.getElementById('chkAll') as HTMLInputElement;
    if (checkAll) {
      checkAll.checked = false;
    }
    products.map((x) => {
      x.isSelected = false;
    });
  }

  selectedItems(products: ProductResult[]): ProductResult[] {
    return products.filter((x) => x.isSelected);
  }

  itemListQuantities(items: ProductListDetails[] | undefined): ItemListQty {
    const itemQty: ItemListQty = {};
    if (items) {
      items.forEach((listItem) => {
        itemQty[listItem.productCode.toUpperCase()] = listItem.quantity || 1;
      });
    }
    return itemQty;
  }

  itemIdFacetFilters(itemIds: string[]): [string[]] {
    return [itemIds.map((x) => `itemId:${x}`)];
  }

  setItemListQty(hits: ProductResult[], itemListQty: ItemListQty): void {
    hits.forEach((hit) => {
      hit.qty = itemListQty[hit.itemId.toUpperCase()] || 1;
    });
  }
}
