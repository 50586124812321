<div class="modal-header">
  <h4 class="modal-title">Customize your Account Dashboard</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
</div>
<div class="modal-body">
  <div class="row" *ngFor="let widget of dashboardService.widgets">
    <ng-container *ngIf="widget.id !== widgetId.OVERVIEW">
      <div class="col-4">
        <label class="switch">
          <input type="checkbox" [checked]="widgetIds.includes(widget.id)" (click)="onToggle(widget)" />
          <span class="slider"></span>
          <p>{{ widget.title }}</p>
        </label>
      </div>
      <div class="col-8">{{ widget.description }}</div>
    </ng-container>
  </div>
</div>
<div class="modal-footer d-flex justify-content-center">
  <button
    type="button"
    class="btn btn-lg btn-primary"
    [attr.data-testid]="DATA_TEST_ID.dashboardModal.save"
    (click)="onSave()"
  >
    Save
  </button>
</div>
