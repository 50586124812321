import { Component, Input, OnChanges } from '@angular/core';
import { SpecsModel } from './specs.model';

@Component({
  selector: 'val-specs-table',
  templateUrl: './specs-table.component.html',
  styleUrls: ['./specs-table.component.scss'],
})
export class SpecsTableComponent implements OnChanges {
  @Input() specs: SpecsModel[];
  @Input() title: string;
  tableData: any[] = [];

  ngOnChanges() {
    this.splitData();
  }

  splitData(): void {
    this.tableData = [];
    if (this.specs) {
      let values: string[] = [];
      this.specs.forEach((spec, key) => {
        if (key % 2 === 0) {
          if (values.length) {
            this.tableData.push(values);
          }
          values = [];
        }
        values.push(spec.name, spec.value);
      });
      if (values.length) {
        this.tableData.push(values);
      }
    }
  }
}
