<div class="container-fluid" *ngIf="bannerContent" [ngClass]="!isSmall ? 'pb-2' : 'pb-4'">
  <div class="banner">
    <img [src]="bannerContent.elements['bannerImage'].value[0].url" alt=""/>
    <div [ngStyle]="{ fontSize: !isSmall ? '3rem' : '2rem' }">
      {{ bannerContent.elements["title"].value }}
    </div>
  </div>
</div>

<div class="py-5 container">
  <h1 class="text-center mb-4">Let's get connected!</h1>
  <p>Get information on our latest products and offers, event invites, parts discounts and more.</p>

  <div class="row mb-4">
    <div class="col-lg-6">
      <ul class="mb-0">
        <li>Receive new product information, demo's and events</li>
        <li>Be notified about contests and other sweepstakes</li>
      </ul>
    </div>
    <div class="col-lg-6">
      <ul class="mb-0">
        <li>Be among the first to know about our offers & promotions</li>
      </ul>
    </div>
  </div>

  <p class="mb-4">
    Canadian Anti-Spam Legislation (CASL) requires us to obtain express consent from you before we can email you about
    news and promotions for products and services. If you wish to receive these emails, please fill out the form below.
    You can unsubscribe anytime by clicking the “unsubscribe” link at the bottom of electronic communications you
    receive from us, or you may also unsubscribe by contacting us at
    <a href="//vallenmarketing@vallen.ca">vallenmarketing@vallen.ca</a>.
  </p>

  <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" novalidate
        [attr.data-testid]="DATA_TEST_ID.connectWithUs.form">
    <div class="row">
      <div class="col-sm-6">
        <val-form-input class="col-sm-6" [form]="contactForm" [id]="'fullName'"
                        [testId]="DATA_TEST_ID.connectWithUs.name"></val-form-input>
      </div>
      <div class="col-sm-6">
        <val-form-input class="col-sm-6" [form]="contactForm" [id]="'companyName'"
                        [testId]="DATA_TEST_ID.connectWithUs.company"></val-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <val-form-input class="col-sm-6" [form]="contactForm" [id]="'email'"
                        [testId]="DATA_TEST_ID.connectWithUs.email"></val-form-input>
      </div>
      <div class="col-sm-6">
        <val-form-input class="col-sm-6" [form]="contactForm" [id]="'phoneNumber'"
                        [testId]="DATA_TEST_ID.connectWithUs.phone"></val-form-input>
      </div>
    </div>
    <p>
      I would like to stay connected to receive communications containing news, updates and promotions from Vallen
      Canada.<span class="reqfld"></span>
    </p>

    <label class="custom-checkbox" [attr.data-testId]="DATA_TEST_ID.connectWithUs.optIn">
      <input type="checkbox" formControlName="receiveCommunications" class="custom-input"/>
      <div class="custom-check"></div>
      <p>Yes (You can unsubscribe at any time)</p>
    </label>

    <div class="row mt-4">
      <div class="col d-grid">
        <val-form-input class="mx-auto" [form]="contactForm" [id]="'recaptcha'" [type]="'recaptcha'"
                        [testId]="DATA_TEST_ID.connectWithUs.recaptcha"></val-form-input>
      </div>
    </div>

    <div class="text-center">
      <p>Mandatory Fields <span class="reqfld"></span></p>
      <button class="btn btn-primary btn-lg" [disabled]="!contactForm.valid"
              [attr.data-testid]="DATA_TEST_ID.connectWithUs.subscribe">Subscribe
      </button>
    </div>
  </form>
</div>
