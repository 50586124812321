<div [ngClass]="b2b ? 'b2b' : 'b2c'">
  <div class="container-fluid products-listing">
    <div class="container-fluid header">
      <div class="container">
        <h1 class="pg-title" [innerHTML]="pageTitle"></h1>
      </div>
    </div>
    <div></div>
    <div class="container list-wrapper">
      <section *ngIf="results?.hits?.length; else noResults" class="listing">
        <div class="col-3 left">
          <button
            type="button"
            (click)="toggleFacetPanel()"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#facetFilterPanel"
          >
            Filters <span>{{ activeFilters.length }}</span>
          </button>
          <div
            class="modal fade"
            id="facetFilterPanel"
            tabindex="-1"
            role="dialog"
            aria-labelledby="facetFilterPanelTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <val-product-facet-list
                  [categoryFilters]="categoryFilters"
                  [facetFilters]="facetFilters"
                  [maxFilters]="maxFilters"
                  [activeFilters]="activeFilters"
                  (onFilter)="onFilter($event)"
                  (onToggleFacets)="toggleFacets($event)"
                ></val-product-facet-list>

                <div class="modal-footer">
                  <button (click)="toggleFacetPanel()" type="button" class="btn btn-primary" data-dismiss="modal">
                    Done
                  </button>
                  <button (click)="clearFilters()" type="button" class="btn btn-primary">Clear Filters</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9 right">
          <val-item-list-actions
            [cart]="cart"
            [user]="user"
            [results]="results"
            [pageType]="pageTypeEnum.RESULTS"
            [page]="page"
            (changePageEvent)="onChangePage($event)"
            (onSortEvent)="onSort($event)"
            [activeFilters]="activeFilters"
            (onToggleFilterEvent)="onToggleFilter($event)"
          ></val-item-list-actions>
          <val-item-list
            [products]="results.hits"
            [pageType]="pageTypeEnum.RESULTS"
            [collapse]="isCollapsedDetails"
          ></val-item-list>
          <val-item-list-pagination
            [page]="page"
            [numPages]="results.nbPages"
            (changePageEvent)="onChangePage($event)"
          ></val-item-list-pagination>
        </div>
      </section>
      <ng-template #noResults>
        <div *ngIf="results" class="row justify-content-center my-4">
          <div class="col-11 col-lg-6">
            <val-no-results></val-no-results>
          </div>
        </div>
      </ng-template>
    </div>

    <span id="productImpressionsSearch"></span>
  </div>
</div>
