import { ProductList } from 'src/app/types/product-list.model';
import { WidgetId } from '../../modules/dashboard/widget/widget.model';
import { OrderApprovalCart } from '../../types/cart.model';

export const dashboardModel = {
  overview: {
    id: WidgetId.OVERVIEW,
    type: 'barchart',
    data: {
      labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple'],
      datasets: [
        {
          label: 'My First dataset',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [65, 59, 80, 81, 56, 55, 40],
        },
      ],
    },
  },
  activeOrders: {
    id: WidgetId.ORDERS_ACTIVE,
    type: 'table',
    columns: [
      { name: 'Order #', prop: 'orderNumber' },
      { name: 'PO #', prop: 'custpo' },
      { name: 'Order Date', prop: 'enterdt' },
      { name: 'Status', prop: 'status' },
    ],
    data: [],
  },
  orderApprovals: {
    id: WidgetId.ORDERS_APPROVAL,
    type: 'table',
    columns: [
      {
        name: 'Cart Name',
        flexGrow: 2,
        prop: 'cartName',
        link: (cartId: string) => {},
        linkParam: 'cartId',
      },
      {
        name: 'Created By',
        flexGrow: 2,
        prop: 'requestorEmail',
      },
      {
        name: 'Created On',
        flexGrow: 1,
        prop: 'approvalRequestDate',
      },
    ],
    data: [] as OrderApprovalCart[],
  },
  quotes: {
    id: WidgetId.QUOTES,
    type: 'table',
    columns: [
      { name: 'Quote #', prop: 'orderNumber' },
      { name: 'PO #', prop: 'custpo' },
      { name: 'Expiration', prop: 'canceldt' },
      { name: 'Status', prop: 'status' },
    ],
    data: [],
  },
  productLists: {
    id: WidgetId.LISTS_USER,
    type: 'table',
    columns: [
      {
        name: 'List Name',
        prop: 'name',
        flexGrow: 3,
        link: (id: number) => {},
        linkParam: 'id',
      },
      { name: 'Products', prop: 'productCount' },
    ],
    data: [] as ProductList[],
  },
  sharedLists: {
    id: WidgetId.LISTS_SHARED,
    type: 'table',
    columns: [
      {
        name: 'List Name',
        prop: 'name',
        flexGrow: 2,
        link: (id: number) => {},
        linkParam: 'id',
      },
      { name: 'Shared By', prop: 'createdBy', flexGrow: 2 },
      { name: 'Products', prop: 'productCount' },
    ],
    data: [] as ProductList[],
  },
  publicLists: {
    id: WidgetId.LISTS_PUBLIC,
    type: 'table',
    columns: [
      {
        name: 'List Name',
        prop: 'name',
        flexGrow: 3,
        link: (id: number) => {},
        linkParam: 'id',
      },
      { name: 'Products', prop: 'productCount' },
    ],
    data: [] as ProductList[],
  },
};
