import { Component, Input, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { KontentSharedAsset } from './kontent-shared-asset.model';
import { DATA_TEST_ID } from 'src/app/shared/consts/data-test-ids';
import { WINDOW } from 'src/app/app.module';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'val-kontent-shared-asset-carousel',
  templateUrl: './kontent-shared-asset-carousel.component.html',
  styleUrls: ['./kontent-shared-asset-carousel.component.scss'],
})
export class KontentSharedAssetCarouselComponent implements OnInit {
  @Input() content: any;
  @Input() items: any;
  slides: KontentSharedAsset[] = [];
  DATA_TEST_ID = DATA_TEST_ID;
  carouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      1080: {
        items: 5,
      },
    },
    nav: false,
    autoplay: true,
  };

  isBrowser: boolean = false;

  constructor(@Inject(WINDOW) private window: Window, @Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.formatSlides();
  }

  formatSlides(): void {
    this.content.elements.sharedAssets.value.forEach((codeName: string) => {
      if (this.items[codeName]) {
        this.slides.push({
          id: this.items[codeName].system.id,
          image: this.items[codeName].elements.image.value[0].url,
          title: this.items[codeName].elements.title.value,
          url: this.items[codeName].elements.actionUrl.value,
          target: this.items[codeName].elements.target.value.length
            ? this.items[codeName].elements.target.value[0].codename
            : '',
        });
      }
    });
  }

  clickSlide(slide: KontentSharedAsset): void {
    if (slide.url && this.window?.location?.href) {
      this.window.location.href = slide.url;
      return;
    }
  }
}
