import { Pipe, PipeTransform } from '@angular/core';
import { orderStatusTypes } from '../../../modules/order/order-history/order-history.model';

@Pipe({ name: 'orderStatus' })
export class OrderStatusPipe implements PipeTransform {
  transform(value: string): string {
    const statuses = orderStatusTypes;
    let orderStatus: string = 'n/a';
    statuses.forEach((status) => {
      if (value === status.label) {
        return (orderStatus = status.value);
      } else {
        return orderStatus;
      }
    });
    return orderStatus;
  }
}
