<span class="_labels">Price:</span>
<!--TODO - Implement SALE price logic-->
<!--<span class="sale">${{product.sale | number:'1.2-2'}}</span>-->

<ng-container *ngIf="product.price; else noPrice">
  <span class="price" [attr.data-testid]="DATA_TEST_ID.product.price.value + '-' + index | idFormatter"
    >${{ product.price | number : "1.2-2" }}</span
  >
  <span class="uom" [attr.data-testid]="DATA_TEST_ID.product.price.uom + '-' + index | idFormatter"
    >/ {{ product.uom }}</span
  >
</ng-container>
<ng-template #noPrice>
  <div *ngIf="isLoading" style="height: 24px">
    <div
      [attr.data-testid]="DATA_TEST_ID.product.price.loading + '-' + index | idFormatter"
      class="spinner"
      [ngClass]="{ 'float-start': !isList }"
    ></div>
  </div>
  <span
    *ngIf="!isLoading"
    class="spec-name"
    [attr.data-testid]="DATA_TEST_ID.product.price.unavailable + '-' + index | idFormatter"
    >Unavailable</span
  >
</ng-template>
