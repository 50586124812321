<div class="container">
  <val-kontent-dashboard-banner *ngIf="banner" [content]="banner"></val-kontent-dashboard-banner>
  <div *ngIf="alert !== ''" class="alert alert-danger my-5">{{ alert }}</div>
  <val-page-title [pageTitle]="pageTitle"></val-page-title>

  <div class="account-dashboard">
    <section class="widgets">
      <div class="row">
        <val-widget
          *ngFor="let widget of dashboardService.activeWidgets()"
          [widget]="widget"
          class="card"
          [ngClass]="widget.id"
          [user]="user"
          [data]="widget.data"
          (onRemoveWidget)="onRemoveWidget($event)"
        ></val-widget>

        <div class="card add-widget" *ngIf="dashboardService.activeWidgets().length < dashboardService.widgets.length">
          <div class="icon">
            <button (click)="openModal()" [attr.data-testid]="DATA_TEST_ID.dashboard.addWidget">
              <span class="material-icons-outlined">add_circle</span>Add widget
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
