<div class="branded-header">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="logo d-flex justify-content-center mt-3">
          <a routerLink="/">
            <img src="/assets/img/Vallen_NoTagline.jpeg" style="max-width: 150px" />
          </a>
        </div>
        <hr />
      </div>
    </div>
  </div>
</div>
