<div *ngIf="(product || lineItem) && !isQuoteDetails">
  <input
    type="number"
    class="form-control quantityMultiple"
    name="Quantity"
    [(ngModel)]="activeModel.qty"
    [min]="minAndStepQuantity"
    [step]="minAndStepQuantity"
    (change)="qtyChange()"
    [disabled]="disabled"
    [attr.data-testid]="DATA_TEST_ID.product.quantity.productInput + '-' + index | idFormatter"
  />
  <span class="supp uom" *ngIf="!isLoading">{{ uomLabel }}</span>
  <span class="supp multi" *ngIf="isProductContext && product.sellMult > 1">
    sold in multiples of {{ product.sellMult }}
  </span>
</div>

<div *ngIf="isQuoteDetails">
  <input
    type="number"
    class="form-control quantityMultiple"
    name="Quantity"
    [(ngModel)]="product.qty"
    [min]="0"
    [max]="initialQuantity"
    [step]="product.sellMult"
    (change)="qtyChange()"
    [disabled]="disabled"
    [attr.data-testid]="DATA_TEST_ID.product.quantity.productInput + '-' + index | idFormatter"
  />
  <span class="supp uom" *ngIf="!isLoading">{{ product.uom }}</span>
  <span class="supp multi" *ngIf="product.sellMult > 1">sold in multiples of {{ product.sellMult }}</span>
</div>

<div *ngIf="lineItem">
  <input
    type="number"
    class="form-control quantityMultiple"
    name="Quantity"
    [(ngModel)]="lineItem.quantityOrdered"
    [min]="1"
    (change)="qtyChange()"
    [disabled]="disabled"
    [attr.data-testid]="DATA_TEST_ID.product.quantity.lineItemInput + '-' + index | idFormatter"
  />
  <span class="supp uom" *ngIf="!isLoading">{{ lineItem.uom }}</span>
</div>
