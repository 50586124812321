import { UomList } from '../../../types/uom.model';

export interface SearchOptions {
  attributesToHighlight?: string[];
  attributesToRetrieve?: string[];
  facets?: any[];
  page: number;
  filters?: any;
  facetFilters?: any[];
  hitsPerPage?: number;
  distinct?: number;
}

export interface SearchQuery {
  indexName: string;
  query?: string;
  params?: any;
  facetFilters?: any[];
  filters?: any;
  distinct?: number;
}

export interface CategorySearchResults {
  appliedRules: any;
  exhaustiveFacetsCount: boolean;
  exhaustiveNbHits: boolean;
  exhaustiveTypo: boolean;
  facets: any;
  hits: CategoryResult[];
  hitsPerPage: number;
  indexUsed: string;
  nbHits: number;
  nbPages: number;
  page: number;
  params: string;
  parsedQuery: any;
  processingTimeMS: number;
  query: string;
}

export class ProductSearchResults {
  appliedRules: any;
  exhaustiveFacetsCount: boolean;
  exhaustiveNbHits: boolean;
  exhaustiveTypo: boolean;
  facets: any;
  hits: ProductResult[];
  hitsPerPage: number;
  indexUsed: string;
  nbHits: number;
  nbPages: number;
  page: number;
  params: string;
  parsedQuery: any;
  processingTimeMS: number;
  query: string;
}

export interface CategoryResult {
  objectID: string;
  batchId: number;
  categoryId: string;
  parentId: string;
  hasChildren: boolean;
  name: string;
  level: number;
  sequence?: number;
  url: string;
  path: CategoryPath[];
  image?: string;
  catalog?: string[];
  excludeCustomerCompany?: number[];
  isInAlgolia?: boolean;
}

export interface ProductResult {
  objectID: string;
  batchId: number;
  title: string;
  brand: string;
  itemId: string;
  manufacturer: string;
  manufacturerPartNo: string;
  upcCodes: string[] | null;
  description: string;
  rank: number | null;
  sequence: number;
  categories: any;
  facets: any;
  seoUrl: string;
  urlId: string;
  attributes: ProductAttribute[];
  attachments: string[] | null;
  callForPrice: boolean;
  canView: string[] | null;
  categoryPaths: [CategoryPath[]] | null;
  customerPartNo?: any; // not sure about this yet, might be array or string
  exclusionList: string[] | null;
  image: string;
  imageGallery: string[] | null;
  installationVideo?: string;
  isAvailable: boolean;
  isB2B: boolean;
  isB2C: boolean;
  isClearance: boolean;
  isFinalSale: boolean;
  isInStock: boolean | null;
  isNonStock?: boolean;
  leadTime: number | null;
  mainCategory: CategoryPath | null;
  mainUpc: string | null;
  manufacturerId: string;
  opCoId: number;
  personalCodes: any | null;
  personalCode?: string | number;
  productId: string;
  productVideo?: string | null;
  quickList?: number[];
  sellMult: number;
  statusType: string;
  totalInventory: number | null;
  tradeTerms?: any; // not sure about this yet, might be array or string
  unspsc: string;
  uom: string;
  uomList?: UomList[];
  url: string;
  catalog?: string[];
  countryOfOrigin?: string;
  productTitle?: string;
  productDescription?: string | null;
  isSelected: boolean;
  qty: number;
  qtyAvail: number;
  price: number;
  customerPartno: any | null;
  warehouse?: string | null;
  priceReady?: boolean;
  barcodeDataUrl?: string;
  uniqueId?: number;
  isInAlgolia?: boolean;
  isIndexed?: boolean;
  orderNumber?: number
}

export interface CategoryPath {
  id?: string;
  slug: string;
  name: string;
}

export interface ProductAttribute {
  name: string;
  value: string;
}

export interface CustomerResult {
  batch_id: number;
  customerNumber: number;
  customerName: string;
  lastUpdated: Date;
  addr: string;
  addr2: string;
  addr3: null;
  state: string;
  city: string;
  zipcd: string;
  whse: string;
  objectID: string;
}

export interface ProductItemId {
  itemId: string;
  qty?: number;
}
