<div
  *ngIf="!!product && !!product.price && !pricesLoading"
  class="branch d-flex justify-content-start flex-column text-start"
>
  <div class="d-inline-flex align-items-center mb-2 justify-content-start" *ngIf="product.isInStock != null">
    <div>
      <div
        class="availability d-flex align-items-center"
        [ngClass]="{ buyer: isBuyer, guest: !isBuyer, backorder: !product.isInStock, 'in-stock': product.isInStock }"
      >
        <div class="avail-icon"></div>
        <div *ngIf="product.qtyAvail && isBuyer" class="d-contents">{{ product.qtyAvail }}&nbsp;</div>
        <div *ngIf="isBuyer" class="d-contents">Available for {{ product.isInStock ? "Delivery" : "Backorder" }}</div>
        <div *ngIf="!isBuyer" class="d-contents">{{ product.isInStock ? "In Stock" : "Available for Backorder" }}</div>
      </div>
      <div *ngIf="product.leadTime && isBuyer" class="mt-1">
        <div>
          Lead Time: <span class="text-green d-contents">{{ product.leadTime }} Days</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isCart">
    <div *ngIf="product.priceReady && isBuyer">
      <div>
        <div class="d-inline-flex align-items-center mb-2 flex-row justify-content-start">
          <i
            class="material-icons-outlined me-1 align-self-start"
            [ngClass]="product.isInStock ? 'text-green' : 'text-danger'"
            >{{ product.isInStock ? "place" : "close" }}</i
          >
          <div>
            <div>
              <span class="text-green d-contents">{{ product.qtyAvail || null }} </span
              >{{ product.isInStock ? "Available" : "Unavailable" }} for pick up at
            </div>
            <div [ngClass]="product.isInStock ? 'text-green' : 'text-secondary'">
              {{ product.warehouse | warehouseName : warehouses }}
            </div>
          </div>
        </div>
      </div>

      <div class="inventory">
        <div
          *ngIf="hasOtherWarehouses()"
          ngbDropdown
          class="dropdown branch"
          (openChange)="getWarehouseAvailability($event)"
          [attr.data-testid]="DATA_TEST_ID.product.warehouseAvailability + '-' + index | idFormatter"
        >
          <a role="button" class="dropdown-toggle text-decoration-underline" id="dropdownBranch" ngbDropdownToggle
            >Check other branches<span class="caret d-inline"></span
          ></a>
          <div
            *ngIf="!availableWarehouses.length"
            class="dropdown-menu pt-0"
            aria-labelledby="dropdownBranch"
            ngbDropdownMenu
            name="listbox"
            id="listbox"
          >
            <div class="spinner w-50"></div>
          </div>
          <ul
            *ngIf="availableWarehouses.length"
            class="dropdown-menu p-0"
            aria-labelledby="dropdownBranch"
            ngbDropdownMenu
            name="listbox"
            id="listbox"
          >
            <li class="border-bottom p-1" *ngFor="let item of availableWarehouses; let i = index">
              <div class="d-inline-flex align-items-center w-100">
                <i
                  class="material-icons-outlined md-18 me-1"
                  [ngClass]="item.available ? 'text-green' : 'text-danger'"
                  >{{ item.available ? "place" : "close" }}</i
                >
                <div class="me-1" [ngClass]="item.available ? 'text-green' : 'text-secondary'">
                  {{ item.warehouse | warehouseName : warehouses }}
                </div>
                <div *ngIf="item.quantity" class="ml-auto text-green">
                  {{ item.quantity }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="!product.priceReady">Loading Pickup Availability...</div>
  </div>
</div>
