import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Inject } from '@angular/core';
import { ToastService } from '../../toast/toast.service';
import { Cart, CartLine } from 'src/app/types/cart.model';
import { UserModel } from '../../../../core/services/user/user.model';
import { PageType } from '../../../consts/page-type';
import { ProductResult } from '../../../../core/services/search/search.model';
import { firstValueFrom, takeUntil } from 'rxjs';
import { OrderDetail } from '../../../../modules/order/order-detail.model';
import { ProductListService } from '../../../../core/services/product-list/product-list.service';
import { PermissionService } from '../../../../core/services/permission/permission.service';
import { SiteCartService } from 'src/app/core/services/cart/site-cart.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingSpinnerService } from '../../loading-spinner/loading-spinner.service';
import { ApiService } from 'src/app/core/services/api/api.service';
import { API_URL } from 'src/app/shared/consts/api-urls';
import { UserService } from '../../../../core/services/user/user.service';
import { LoadingStatus } from 'src/app/types/loading-status.model';
import { BaseSubscriptionComponent } from '../../base-subscription/base-subscription.component';
import { WINDOW } from 'src/app/app.module';
import { AppStateInterface } from 'src/app/types/app-state.interface';
import { select, Store } from '@ngrx/store';
import { currentShoppingContextSelector } from '../../../../core/store/user/user.selectors';
import { UserShoppingContext } from '../../../../core/services/customer-company/user-shopping-context.model';
import { ShoppingContextPunchout } from '../../../../types/punchout.model';
import { UserTypeEnum } from '../../../../core/services/user/user-type.enum';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'val-item-summary',
  templateUrl: './item-summary.component.html',
  styleUrls: ['./item-summary.component.scss'],
})
export class ItemSummaryComponent extends BaseSubscriptionComponent implements OnInit, OnChanges {
  @Input() user: UserModel | null | undefined;
  @Input() pageType: string | PageType;
  @Input() products?: CartLine[];
  @Input() order?: OrderDetail;
  @Input() cart: Cart;
  @Input() quoteForm?: FormGroup;
  @Input() isFormValid: boolean;

  @Output() actionClicked = new EventEmitter<string>();

  pageTypeEnum = PageType;

  canViewPrices: boolean;
  isLoading: LoadingStatus;
  activePanelIds: string[] = [];
  form: FormGroup;
  submitBtn: string = '';
  isPunchOut: boolean = false;

  checkOrderLimits: boolean;
  monthlyOrderTotal: number = 0;
  checkoutInfo: any;
  disableBtn: boolean = false;
  shoppingContextPunchoutId?: number;
  hasProductLists: boolean = false;
  private shoppingContext$ = this.store.pipe(select(currentShoppingContextSelector));
  private shoppingContext: UserShoppingContext | ShoppingContextPunchout | null;

  constructor(
    private toast: ToastService,
    public listService: ProductListService,
    private permissionService: PermissionService,
    private loadingService: LoadingSpinnerService,
    private api: ApiService,
    private siteCart: SiteCartService,
    private userService: UserService,
    private store: Store<AppStateInterface>,
    @Inject(WINDOW) private window: Window
  ) {
    super();
    this.shoppingContext$.pipe(takeUntil(this.destroyed)).subscribe((shoppingContext: any) => {
      this.shoppingContext =
        shoppingContext?.defaultUser?.userTypeId === UserTypeEnum.POU
          ? (shoppingContext as ShoppingContextPunchout)
          : (shoppingContext as UserShoppingContext);
    });
    this.loadingService
      .getLoadingPrices()
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.isLoading = data;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If products are updated, update the cart subTotal for the summary component
    if (changes['products'] && this.cart) {
      this.cart.subTotal = this.cart.cartItems.reduce((total, item) => total + item.price, 0);
    }
  }

  ngOnInit(): void {
    if (this.window) {
      this.subscribeToLoadingPrices();
      this.canViewPrices = this.permissionService.canViewPrices();

      if (this.user) {
        this.hasProductLists = this.userService.hasProductLists(this.user);
        this.checkOrderLimits =
          this.user.currentShoppingAccount?.customerCompanyNumber.approvalWorkflow! &&
          this.userService.isB2B(this.user);

        if (this.checkOrderLimits && !this.monthlyOrderTotal) {
          this.api.get(`${API_URL.User}/${this.user?.userId}/orders/mtd`, null, false).subscribe((x: any) => {
            this.monthlyOrderTotal = x.total;
          });
        }

        this.isPunchOut = this.userService.isPunchOut(this.user);
        this.checkoutInfo = this.siteCart.checkoutPermissions();
        this.submitBtn = this.checkoutInfo.cart;
        if (this.submitBtn === 'showSendForApproval') {
          this.activePanelIds = ['showSendForApproval'];
        }
      }

      this.initForm();
    }
  }

  private subscribeToLoadingPrices(): void {
    this.loadingService
      .getLoadingPrices()
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.isLoading = data;
      });
  }

  private initForm() {
    this.form = new FormGroup({
      listName: new FormControl('', [Validators.required, Validators.maxLength(30), Validators.pattern(/\S/)]),
    });
  }

  createList(): void {
    const params = {
      companyNumber: environment.companyNumber,
      isCompanyList: false,
      ...(this.isPunchOut
        ? { shoppingContextPunchOutId: this.shoppingContext?.id }
        : { shoppingContextId: this.shoppingContext?.id }),
      name: this.form.controls['listName'].value.trim(),
      shared: false,
    };

    this.api.post(API_URL.ProductListHeader, params).subscribe({
      next: (res: any) => {
        this.addToList(res.id);
        this.listService.getLists(this.user);
        this.initForm();
        this.toast.showSuccess('Product list created successfully');
      },
      error: (error) => {
        this.handleError(error);
      },
    });
  }

  isOverLimit(): boolean {
    // make sure user doesn't exceed monthly/transaction limits, if specified
    if (!this.checkOrderLimits) {
      return false;
    }

    const monthlyLimit = this.cart?.maximumAmountByMonth || 0;
    const transactionLimit = this.cart?.maximumAmountByOrder || 0;
    const cartSubTotal = this.cart?.subTotal || 0;

    return (
      (monthlyLimit > 0 && cartSubTotal + this.monthlyOrderTotal > monthlyLimit) ||
      (transactionLimit > 0 && cartSubTotal > transactionLimit)
    );
  }

  async addToList(productId: number): Promise<void> {
    for (const product of this.cart.cartItems) {
      const data = {
        productListHeaderId: productId,
        productCode: product.itemId,
        quantity: product.qty,
      };
      await firstValueFrom(this.api.post(`${API_URL.ProductListDetail}`, data));
    }
  }

  public selectedItems(): ProductResult[] {
    let products = this.products as ProductResult[];
    return products.filter((product: ProductResult) => product.isSelected);
  }

  private handleError(error: string) {
    this.toast.showError(error);
  }

  public addToCart() {
    this.siteCart.addItem(this.selectedItems());
  }

  onRequestQuote(): void {
    this.actionClicked.emit('request-quote');
  }

  onCheckOut(): void {
    this.actionClicked.emit('checkout');
  }

  public deleteQuote() {
    this.actionClicked.emit('delete-quote');
  }

  public onSubmit() {
    this.actionClicked.emit('submit-quote');
  }

  public submitForm() {
    this.actionClicked.emit('review-order');
  }

  public sumbitOrder() {
    this.actionClicked.emit('submit-order');
  }

  // TODO: In the template but not used?
  onRestrictiveMessage(): void {}

  // TODO: Same as above...
  onCheckoutWithRestrict(): void {}
}
