<ngb-carousel *ngIf="slides.length">
  <ng-template *ngFor="let slide of slides" ngbSlide>
    <div class="hero" [ngClass]="slide.classes ? slide.classes : ''"
         [attr.data-testid]="DATA_TEST_ID.kontent.slider.container + '-' + currentComponentCount">
      <img
        [src]="slide.image"
        [alt]="slide.title"
        class="img-fluid"
        [attr.data-testid]="DATA_TEST_ID.kontent.slider.image + '-' + currentComponentCount"
      />
      <div class="carousel-caption d-flex container">
        <div class="col">
          <h5>{{ slide.title }}</h5>
          <p>{{ slide.description }}</p>
          <span *ngIf="slide.url"><a
            [attr.data-testid]="DATA_TEST_ID.kontent.slider.link + '-' + currentComponentCount"
            [href]="slide.url"
          >{{ slide.label }}</a></span>
        </div>
      </div>
    </div>
  </ng-template>
</ngb-carousel>
