<section *ngIf="tableData">
  <h2>{{ title }}</h2>
  <table>
    <tbody>
      <tr *ngFor="let row of tableData">
        <td *ngFor="let spec of row" [innerHTML]="spec"></td>
      </tr>
    </tbody>
  </table>
</section>
