<div class="form-group mb-0" [formGroup]="form">
  <div class="input-group">
    <input
      type="text"
      class="form-control personal-code"
      id="personalCode"
      [formControlName]="'personalCode'"
      placeholder="New Personal Code"
      [attr.data-testid]="DATA_TEST_ID.product.personalCode.input"
    />
    <div class="input-group-append">
      <div
        class="done mt-1 cursor-pointer"
        (click)="createPersonalCode()"
        [attr.data-testid]="DATA_TEST_ID.product.personalCode.create"
      >
        <i class="material-icons-outlined fs-6">done</i>
      </div>
    </div>
    <div *ngIf="toggle" class="input-group-append">
      <div
        class="close mt-1 cursor-pointer"
        (click)="onClose()"
        [attr.data-testid]="DATA_TEST_ID.product.personalCode.close"
      >
        <i class="material-icons-outlined fs-6">close</i>
      </div>
    </div>
    <val-loading-spinner [loading]="loading"></val-loading-spinner>
  </div>
</div>
