import { Pipe, PipeTransform } from '@angular/core';
import { WarehouseNameNumber } from 'src/app/types/warehouse.model';

@Pipe({
  name: 'warehouseName',
})
export class WarehouseNamePipe implements PipeTransform {
  transform(value: unknown, warehouses: WarehouseNameNumber[]): unknown {
    if (value && warehouses) {
      return warehouses.find((item) => item.warehouseNumber === value)?.warehouseName;
    }
    return '';
  }
}
