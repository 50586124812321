<!--TODO - Dynamic product menu-->
<ul class="navbar-nav _main">
  <li class="nav-item dropdown products">
    <a
      class="nav-link dropdown-toggle"
      data-bs-toggle="dropdown"
      href=""
      role="button"
      aria-expanded="false"
      [attr.data-testid]="DATA_TEST_ID.navbar.products.dropdown"
      >Products</a
    >
    <div class="dropdown-menu" id="proMenuContent" *ngIf="categories.length">
      <ul class="navbar-nav level1">
        <li
          *ngFor="let category1 of categories; index as index1"
          class="nav-item dropdown"
          (mouseover)="toggleActive(index1)"
          [ngClass]="{ active: activeSubcategory(index1), show: showSubcategory(index1) }"
          id="category-level1-{{ index1 }}"
          [attr.data-testid]="DATA_TEST_ID.navbar.products.categoryLevel + '-level1-' + index1 | idFormatter"
        >
          <label>
            <a
              routerLink="{{ category1.url }}"
              [attr.data-testid]="DATA_TEST_ID.navbar.products.category + '-' + '-level1-' + index1 | idFormatter"
            >
              {{ category1.name }}
            </a>
          </label>
          <a
            (click)="toggleSubCategory($event, index1)"
            class="nav-link _mobile"
            href=""
            id="level2-{{ index1 }}"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            [attr.data-testid]="
              DATA_TEST_ID.navbar.products.subCategoryDropdown + '-level2-' + index1 + '-mobile' | idFormatter
            "
          ></a>
          <div class="dropdown-menu roll-out" [attr.aria-labelledby]="'level2-' + index1">
            <div class="left" [ngClass]="{ 'has-banner': category1['bannerAd'].length }">
              <div class="title">
                <label>{{ category1.name }}</label>
                <a
                  class="links"
                  routerLink="{{ category1.url }}"
                  [attr.data-testid]="DATA_TEST_ID.navbar.products.viewAllProducts + '-' + index1 | idFormatter"
                  >View all Products</a
                >
              </div>
              <ul class="navbar-nav level2 scroll-content">
                <li
                  *ngFor="let category2 of category1.categories; index as index2"
                  class="dropdown-item"
                  id="category-level2-{{ index1 }}-{{ index2 }}"
                >
                  <a
                    class="nav-link"
                    routerLink="{{ category2.url }}"
                    [attr.data-testid]="DATA_TEST_ID.navbar.products.category + index1 + '-' + index2 | idFormatter"
                    >{{ category2.name }}</a
                  >
                  <ul *ngIf="category2.categories" class="navbar-nav level3">
                    <li
                      *ngFor="let category3 of category2.categories; index as index3"
                      class="dropdown-item"
                      id="category-level3-{{ index1 }}-{{ index2 }}-{{ index3 }}"
                    >
                      <a
                        class="nav-link"
                        routerLink="{{ category3.url }}"
                        [attr.data-testid]="
                          DATA_TEST_ID.navbar.products.category + '-' + index1 + '-' + index2 + '-' + index3
                            | idFormatter
                        "
                        >{{ category3.name }}</a
                      >
                    </li>
                    <a *ngIf="category2.more" class="links" routerLink="{{ category2.url }}">View all</a>
                  </ul>
                </li>
              </ul>

              <div *ngIf="category1['vendorAd'].length" class="vendors">
                <a *ngFor="let vendor of category1['vendorAd']" [href]="vendor.url" [target]="vendor.target || '_self'">
                  <img [src]="vendor.image" alt=""
                /></a>
              </div>
            </div>
            <div *ngIf="category1['bannerAd'].length" class="right">
              <div class="banner">
                <a
                  *ngFor="let banner of category1['bannerAd']"
                  [href]="banner.url"
                  [target]="banner.target || '_self'"
                  [attr.data-testid]="DATA_TEST_ID.navbar.products.imageBanner"
                >
                  <img [src]="banner.image" alt="" />
                </a>
              </div>
            </div>
          </div>
        </li>
        <div class="browse">
          <a
            [routerLink]="'/products/categories'"
            [attr.data-testid]="DATA_TEST_ID.navbar.products.viewAllCategories | idFormatter"
            >View all Categories</a
          >
        </div>
      </ul>
    </div>
  </li>
</ul>
