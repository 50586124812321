<div class="pagination" *ngIf="numPages > 1">
  <div class="icon" [ngClass]="{ disabled: page <= 1 }" (click)="firstPage()">
    <i class="material-icons-outlined">keyboard_double_arrow_left</i>
  </div>
  <div class="icon" [ngClass]="{ disabled: page <= 1 }" (click)="prevPage()">
    <i class="material-icons-outlined">keyboard_arrow_left</i>
  </div>
  <div class="page">
    Page
    <input
      type="number"
      id="pagination-1"
      class="form-control"
      name="page"
      [(ngModel)]="page"
      (change)="changePage()"
      min="1"
      [max]="numPages"
      [ngClass]="{ 'input-validation-error': invalidPage() }"
    />
    of <span>{{ numPages }}</span>
  </div>
  <div class="icon" [ngClass]="{ disabled: page >= numPages }" (click)="nextPage()">
    <i class="material-icons-outlined">keyboard_arrow_right</i>
  </div>
  <div class="icon" [ngClass]="{ disabled: page >= numPages }" (click)="lastPage()">
    <i class="material-icons-outlined">keyboard_double_arrow_right</i>
  </div>
</div>
