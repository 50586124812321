import { createReHydrationReducer } from '../hydration.reducer';
import { on } from '@ngrx/store';
import * as PunchoutActions from './punchout.actions';
import { logout } from '../authentication/auth.actions';
import { PunchOut } from 'src/app/types/punchout.model';

// Define the scoped state of this reducer
export interface PunchoutState {
  punchout: PunchOut | null;
}

// Set initial state values
const initialState: PunchoutState = {
  punchout: null,
};

export const punchoutReducer = createReHydrationReducer(
  'punchout',
  initialState,
  on(PunchoutActions.setPunchout, (state, action) => ({
    ...state,
    ...action,
  })),
  on(logout, (state) => ({
    ...state,
    punchout: null,
  }))
);
