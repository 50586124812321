<owl-carousel-o *ngIf="isBrowser && slides.length" [options]="carouselOptions">
  <ng-container *ngFor="let slide of slides; let idx = index">
    <ng-template carouselSlide [id]="slide.id">
      <div class="slide">
        <a
          *ngIf="slide.url"
          [href]="slide.url"
          [attr.data-testid]="DATA_TEST_ID.kontent.carousel.image + '-' + (idx + 1)"
        >
          <div class="img">
            <img [src]="slide.image" [alt]="slide.title" [title]="slide.title" class="img-fluid" />
          </div>
        </a>
        <a
          *ngIf="slide.title && slide.url"
          [href]="slide.url"
          [attr.data-testid]="DATA_TEST_ID.kontent.carousel.title + '-' + (idx + 1)"
        >
          <h4>{{ slide.title }}</h4>
        </a>
        <div class="img" *ngIf="!slide.url">
          <img [src]="slide.image" [alt]="slide.title" [title]="slide.title" class="img-fluid" />
        </div>
        <h4 *ngIf="slide.title && !slide.url">{{ slide.title }}</h4>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>
