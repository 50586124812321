<a
  *ngIf="link.route; else useHref"
  class="nav-link"
  [target]="link.target ?? '_self'"
  [routerLink]="link.route"
  [attr.data-testid]="location + '-' + (link.id || link.title) | idFormatter"
  >{{ link.title }}</a
>
<ng-template #useHref>
  <a
    *ngIf="link.href"
    class="nav-link"
    [target]="link.target ?? '_self'"
    [href]="link.href"
    [attr.data-testid]="location + '-' + (link.id || link.title) | idFormatter"
    >{{ link.title }}</a
  >
</ng-template>
