import { AppStateInterface } from '../../../types/app-state.interface';
import { createSelector } from '@ngrx/store';

/**
 * Create select feature: In this case we bring in our global app state
 * interface
 * @param state
 *
 * since we added customer to our app state we can grab it using state.customer.
 */
export const selectFeature = (state: AppStateInterface) => state.customer;

/**
 * Create your selector
 *
 * Pass your selectFeature, and your projector function takes state and
 * returns the customer state.
 */
export const customerSelector = createSelector(selectFeature, (state) => state.customer);
