<div *ngIf="page" class="generic-template" [id]="page.system.id">
  <div class="container d-none d-lg-block"></div>

  <!--Page Title-->
  <section *ngIf="page.elements.title.value" class="header text-center">
    <h1 class="pg-title">{{ page.elements.title.value }}</h1>
  </section>

  <!--Sections-->
  <section *ngFor="let section of content?.items" [id]="section.system.id" [ngClass]="sectionStyles[section.system.id]">
    <!--Section Title-->
    <h5 *ngIf="section.elements.title?.value && section.system.type.indexOf('banner') < 0">
      {{ section.elements.title.value }}
    </h5>

    <!--Section Header-->
    <h5 *ngIf="section.elements.header?.value">{{ section.elements.header.value }}</h5>

    <!-- Form-->
    <val-kontent-form *ngIf="section.system.type === 'formstack_form'"></val-kontent-form>

    <!-- Hero Slider-->
    <val-kontent-hero-slider
      *ngIf="section.system.type === 'hero_banner'"
      [content]="section"
      [items]="content.linkedItems"
    ></val-kontent-hero-slider>

    <!--Banner Ad-->
    <val-kontent-banner *ngIf="section.system.type === 'banner'" [content]="section"></val-kontent-banner>

    <!--Two Column Tiny Banner-->
    <val-kontent-tiny-banner
      *ngIf="section.system.type === 'two_columns_tiny_banner'"
      [content]="section"
      [items]="content.linkedItems"
    ></val-kontent-tiny-banner>

    <!--Fancy Image Banner-->
    <val-kontent-fancy-image-banner
      *ngIf="section.system.type === 'fancy_image_banner'"
      [content]="section"
    ></val-kontent-fancy-image-banner>

    <!--Image Carousel-->
    <val-kontent-shared-asset-carousel
      *ngIf="section.system.type === 'shared_asset_carousel'"
      [content]="section"
      [items]="content.linkedItems"
    ></val-kontent-shared-asset-carousel>

    <div *ngIf="section.elements.contentItems" class="row">
      <!--Content Blocks-->
      <div
        *ngFor="let block of section.elements.contentItems?.value; let i = index"
        [id]="content.linkedItems[block].system.id"
        [class]="content.linkedItems[block].system.type"
        [attr.data-testid]="DATA_TEST_ID.kontent.generic.block + '-' + (i + 1)"
        class="block"
      >
        <val-kontent-generic-page-base-content
          *ngIf="content.linkedItems[block].system.type === 'generic_page_base_content'"
          [content]="content.linkedItems[block]"
          [testKey]="i + 1"
        ></val-kontent-generic-page-base-content>
      </div>
    </div>
  </section>
</div>
