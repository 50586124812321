<div class="row list-actions" [class]="pageType" [ngClass]="!display.middleSection ? 'mb-2' : 'mb-3 mt-5'">
  <div
    *ngIf="display.productCount"
    class="col-12 d-flex align-items-center"
    [ngClass]="isHistory ? '' : display.searchResults ? 'col-md-3' : 'col-md-6'"
  >
    <div class="result-count mb-0 mb-md-3">
      <span *ngIf="results?.nbHits"
        ><span class="label">{{ results.nbHits | number }}</span> Products</span
      >
    </div>
  </div>
  <ng-container *ngIf="display.searchResults">
    <div class="page-nav mb-md-0 mb-3">
      <val-item-list-pagination
        *ngIf="results"
        [page]="page"
        [numPages]="results.nbPages"
        (changePageEvent)="onChangePage($event)"
      ></val-item-list-pagination>
    </div>

    <div class="col-3 sort-results d-flex justify-content-end">
      <div ngbDropdown>
        <button type="button" class="btn" id="sortDropdown" ngbDropdownToggle>Sort By</button>
        <div ngbDropdownMenu aria-labelledby="sortDropdown">
          <button
            *ngFor="let sort of sortOptions"
            (click)="onSort(sort)"
            [ngClass]="{ active: sort.value === currentIndex }"
            ngbDropdownItem
          >
            {{ sort.label }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!display.rightSection && !display.searchResults">
    <div class="action-right col-12 col-md-6 d-flex justify-content-center justify-content-md-end mb-2 mt-3 mt-md-0">
      <a
        *ngIf="display['import'] && canUpdateQuicklist"
        class="action-link icon import"
        (click)="togglePanel('import')"
      >
        Import
      </a>
      <a
        class="action-link icon export"
        role="button"
        *ngIf="listProductCount > 0"
        (click)="togglePanel('exportExcel')"
        [ngClass]="{ 'text-primary fw-bold': isCollapsed === 'exportExcel' }"
      >
        Export Excel
      </a>
      <a
        class="action-link icon export"
        role="button"
        *ngIf="listProductCount > 0 && listProductCount < 1000"
        (click)="togglePanel('exportPDF')"
        [ngClass]="{ 'text-primary fw-bold': isCollapsed === 'exportPDF' }"
      >
        Export PDF
      </a>
      <a *ngIf="display.empty" class="action-link icon empty-cart" (click)="onEmpty()">Empty {{ pageType }}</a>
    </div>

    <!-- ! Collapsed Content -->
    <div class="col-sm-12 collapse" [class.show]="isCollapsed === 'import'" #import>
      <val-import-export
        [actionType]="importActions"
        [user]="user"
        (importedData)="dataFromImport($event)"
        (importSuccess)="onImportSuccess()"
        style="width: 100%"
        (cancel)="collapseOnCancel()"
      ></val-import-export>
    </div>
    <div class="col-sm-12 collapse" [class.show]="isCollapsed === 'exportExcel'" #exportExcel>
      <val-import-export
        [results]="products"
        [actionType]="exportExcelActions"
        [pageType]="pageType"
        style="width: 100%"
        (cancel)="collapseOnCancel()"
      ></val-import-export>
    </div>

    <div class="col-sm-12 collapse" [class.show]="isCollapsed === 'exportPDF'" #exportPDF>
      <val-import-export
        [results]="products"
        [actionType]="exportPDFActions"
        [pageType]="pageType"
        style="width: 100%"
        (cancel)="collapseOnCancel()"
      ></val-import-export>
    </div>

    <div *ngIf="importedProducts.invalidProducts!.length > 0" class="col-sm-12 mb-4">
      <div class="d-flex justify-content-between">
        <h6><span class="val-icon i-18 text-danger">block</span> Invalid products found in file</h6>
        <a class="align-items-center d-flex" (click)="importedProducts.invalidProducts = []"
          >close<i class="val-icon i-18 close"></i
        ></a>
      </div>

      <div class="row mx-1 invalid-products align-items-center" id="invalidResults">
        <div
          *ngFor="let product of importedProducts.invalidProducts"
          class="col-sm-6 col-md-4 d-flex justify-content-between py-1"
        >
          <span>{{ product.value }}</span>
          <span *ngIf="product.quantity" class="mt-2">{{ product.quantity }}</span>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="activeFilters?.length" class="row selected-facet">
    <span *ngFor="let filter of activeFilters"
      ><a (click)="onFilter(filter.facet, filter.value)">{{ filter.label }}: {{ filter.value }}</a></span
    >
  </div>

  <!-- ! Left Section -->
  <div *ngIf="display.leftSection" class="action-left col">
    <div class="d-flex justify-content-center justify-content-md-start mb-2 mb-md-0">
      <div *ngIf="display.checkAll || isQuoteDetails" class="checkbox me-2 d-flex align-items-center">
        <label *ngIf="!isLoading.price || isQuoteDetails" class="custom-checkbox">
          <input type="checkbox" class="custom-input" id="chkAll" (change)="onCheckAll($event)" />
          <span class="custom-check"></span>
          <span class="label">Check all</span>
        </label>
      </div>
      <a
        *ngIf="display.delete && (!this.pageType.includes('quicklist') || canUpdateQuicklist)"
        class="action-link icon delete"
        (click)="onDelete()"
        [ngClass]="{ disabled: !selectedItems().length }"
        >Delete</a
      >
      <a
        *ngIf="display.showDetails"
        class="action-link"
        [ngClass]="showDetailsIcon"
        data-bs-toggle="collapse"
        href="#orderDetails"
        aria-expanded="false"
        aria-controls="orderDetails"
        (click)="toggleCollapse()"
      >
        {{ showDetailsValue }} Details
      </a>
      <a
        *ngIf="display.downloadResults && documents.length"
        class="action-link icon download"
        (click)="downloadAllResults()"
        >Download all documents</a
      >
      <a *ngIf="display.downloadDocs && documents.length" class="action-link icon download" (click)="downloadAll()"
        >Download all documents</a
      >
      <a *ngIf="display.addProduct" class="action-link icon add-product" role="button" (click)="onAddProduct()"
        >Add products</a
      >
    </div>
  </div>

  <!-- ! Middle Section -->
  <div *ngIf="display.middleSection" class="action-middle col text-center">
    <button class="btn btn-sm btn-outline-primary" (click)="onAddProduct()">Add products</button>
  </div>

  <!-- ! Right Section -->
  <div *ngIf="display.rightSection" class="action-right col">
    <div class="d-flex justify-content-center justify-content-md-end mb-2 mb-md-0">
      <a *ngIf="display['import']" class="action-link icon import me-3" (click)="togglePanel('import')">Import</a>
      <a
        *ngIf="display.exports"
        class="action-link icon export"
        role="button"
        (click)="togglePanel('exportExcel')"
        [ngClass]="{ 'text-primary fw-bold': isCollapsed === 'exportExcel' }"
      >
        Export Excel
      </a>
      <a
        *ngIf="display.exports"
        class="action-link icon export"
        role="button"
        (click)="togglePanel('exportPDF')"
        [ngClass]="{ 'text-primary fw-bold': isCollapsed === 'exportPDF' }"
      >
        Export PDF</a
      >
      <a
        *ngIf="display.empty"
        class="action-link icon"
        [ngClass]="'empty-' + (pageType | pageType).toLowerCase()"
        (click)="onEmpty()"
        >Empty {{ pageType | pageType }}</a
      >
    </div>
  </div>

  <!-- ! Collapsed Content -->
  <div *ngIf="!display.productCount" class="col-sm-12 collapse" [class.show]="isCollapsed === 'import'" #import>
    <val-import-export
      [actionType]="importActions"
      [user]="user"
      (importedData)="dataFromImport($event)"
      (importSuccess)="onImportSuccess()"
      style="width: 100%"
      (cancel)="collapseOnCancel()"
    >
    </val-import-export>
  </div>
  <div
    *ngIf="!display.productCount"
    class="col-sm-12 collapse"
    [class.show]="isCollapsed === 'exportExcel'"
    #exportExcel
  >
    <val-import-export
      [results]="products"
      [actionType]="exportExcelActions"
      [pageType]="pageType"
      style="width: 100%"
      (cancel)="collapseOnCancel()"
    >
    </val-import-export>
  </div>

  <div *ngIf="!display.productCount" class="col-sm-12 collapse" [class.show]="isCollapsed === 'exportPDF'" #exportPDF>
    <val-import-export
      [results]="products"
      [actionType]="exportPDFActions"
      [pageType]="pageType"
      style="width: 100%"
      (cancel)="collapseOnCancel()"
    ></val-import-export>
  </div>

  <!-- ! Add To Actions -->
  <div
    *ngIf="display['addButtons']"
    class="col-12 d-flex add-buttons justify-content-center justify-content-md-end"
    [ngClass]="!display.middleSection ? 'col-md-6' : ''"
  >
    <button
      class="btn btn-sm btn-primary add-to-cart"
      type="button"
      [disabled]="!selectedItems().length"
      (click)="onAddToCart(selectedItems())"
    >
      Add to Cart
    </button>
    <button
      *ngIf="hasProductLists"
      class="btn btn-sm btn-outline-primary ms-2"
      type="button"
      [disabled]="!selectedItems().length"
      (click)="listService.toggleProductListForm()"
    >
      Add to List
    </button>
  </div>
  <val-product-list-form class="mt-2" *ngIf="hasProductLists" [user]="user" [products]="selectedItems()"></val-product-list-form>
</div>
