<div role="tabpane" class="tab-pane fade show active d-flex row" id="home1" aria-labelledby="hometab1">
  <section class="col-sm-12 justify-content-around">
    <div class="text-center">
      <val-page-title [pageTitle]="pageTitle"></val-page-title>
    </div>
    <form
      [formGroup]="loginForm"
      (ngSubmit)="onSubmit()"
      class="forms disable-double-submission needs-validation"
      novalidate
    >
      <div class="login-form-group">
        <div class="e-row">
          <label class="form-label user" for="username"> Username / Email: <span>*</span> </label>
          <input
            class="form-control"
            [ngClass]="controls.username.errors && controls.username.touched ? inputValidationError : ''"
            type="text"
            placeholder="example@email.com"
            aria-label="user"
            id="username"
            formControlName="username"
            required="true"
            [attr.data-testid]="DATA_TEST_ID.loginForm.username"
          />
          <val-form-alerts
            *ngIf="loginForm.status === 'INVALID' && controls.username.touched"
            [errors]="validationErrors"
            [control]="'username'"
          ></val-form-alerts>
        </div>
        <div class="e-row">
          <label class="e-label password" for="password"> Password: <span>*</span> </label>
          <input
            class="form-control"
            [ngClass]="controls.password.errors && controls.password.touched ? inputValidationError : ''"
            type="password"
            placeholder="Enter Password"
            aria-label="password"
            id="password"
            formControlName="password"
            required="true"
            [attr.data-testid]="DATA_TEST_ID.loginForm.password"
          />
          <val-form-alerts
            *ngIf="controls.password.errors && controls.password.touched"
            [errors]="validationErrors"
            [control]="'password'"
          ></val-form-alerts>
          <p class="links float-end mt-2 small">
            <a
              id="lnkForgotPassword"
              [routerLink]="'/account/forgotpassword'"
              [attr.data-testid]="DATA_TEST_ID.loginForm.forgotPassword"
              >Forgot password?</a
            >
          </p>
        </div>
        <div class="e-row">
          <label class="custom-checkbox mt-5 mb-3">
            <input
              class="custom-input"
              type="checkbox"
              placeholder=""
              aria-label="rememberme"
              data-val="true"
              data-val-required="The RememberMe field is required."
              id="RememberMe"
              name="RememberMe"
              [value]="rememberMe"
              [checked]="rememberMe"
              (click)="updateRememberMe()"
              [attr.data-testid]="DATA_TEST_ID.loginForm.rememberMe"
            />
            <div class="custom-check"></div>
            <div>
              <p class="text-start">
                Keep me logged in<br />
                <small class="text-muted">Uncheck if using a public device</small>
              </p>
            </div>
          </label>
        </div>

        <div class="d-grid gap-2 w-100">
          <button
            id="login"
            class="btn btn-primary btn-lg mx-auto"
            type="submit"
            [disabled]="disableBtn"
            [attr.data-testid]="DATA_TEST_ID.loginForm.submitBtn"
          >
            Login
          </button>
        </div>
      </div>
      <input
        name="__RequestVerificationToken"
        type="hidden"
        value="CfDJ8NztrvQMRGZPj5GkAMZIXUtvw6zWgJpG8bpnUh6DMa4snE6Dy1M8WQiFZDgCLisFHgbQMdUQnuMUNN1-KPChyh-nHHQB1B-xyocz6n-avXBba2v1LadqMveMGO70ShDSJF4z_e8mckTlbEV76aWJo2c"
      /><input name="RememberMe" type="hidden" value="false" />
    </form>
  </section>
</div>
