import { Injectable } from '@angular/core';
import { UserModel } from '../user/user.model';
import { FormOption } from '../../../types/form-option.model';
import { Permissions } from '../../../shared/consts/permissions.enum';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  itemsPerPage = 16; // default data table limit

  permissions = {
    default: [
      Permissions.AO,
      Permissions.PO,
      Permissions.CUQL,
      Permissions.CVAA,
      Permissions.CVAST,
      Permissions.CVP,
    ],
    shoppingAccount: [
      Permissions.AO,
      Permissions.PO,
      Permissions.CUQL,
      Permissions.CVP,
      Permissions.ECE,
      Permissions.EIE,
    ],
  };

  defaultShipTo: FormOption[] = [
    {
      label: 'Default',
      value: '',
    },
  ];

  statusOptions: FormOption[] = [
    { label: 'Active', value: 1 },
    { label: 'Inactive', value: 2 },
    { label: 'Suspended', value: 3 },
  ];

  userIcon: Record<string, Record<string, string>> = {
    personal: {
      icon: 'person',
      label: 'Personal',
    },
    business: {
      icon: 'group',
      label: 'Business',
    },
    customerAdmin: {
      icon: 'manage_accounts',
      label: 'Cust. Admin',
    },
    admin: {
      icon: 'lock_person',
      label: 'Admin',
    },
  };

  getUserIcon(user: UserModel): any {
    let icon;
    if (user.roles?.includes('ROLE_ADMIN')) {
      icon = this.userIcon['admin'];
    } else if (user.roles?.includes('ROLE_CUSTOMER_ADMIN')) {
      icon = this.userIcon['customerAdmin'];
    } else if (user.userTypeId !== 'B2C') {
      icon = this.userIcon['business'];
    } else {
      icon = this.userIcon['personal'];
    }
    return icon;
  }
}
