import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiErrorResponse, ErrorsModel, validationTypes } from './errors.model';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ErrorMessages, responseStatus } from '../../../shared/components/forms/data/errorMessages';
import { SuccessfulResModel } from './success.model';
import { HttpResponse } from '@angular/common/http';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormService extends BaseSubscriptionComponent {
  private _errors: any = new BehaviorSubject<ErrorsModel[]>([]);
  private _responseError = new BehaviorSubject<ErrorsModel | null>(null);
  private _successfulRes = new BehaviorSubject<SuccessfulResModel | null>(null);

  get errors() {
    return this._errors.getValue();
  }

  set errors(val) {
    this._errors.next(val);
  }

  get responseError() {
    return this._responseError.getValue();
  }

  set responseError(val) {
    this._responseError.next(val);
  }

  get successfulRes() {
    return this._successfulRes.getValue();
  }

  set successfulRes(val) {
    this._successfulRes.next(val);
  }

  public handleResponse(response: HttpResponse<ApiErrorResponse>) {
    responseStatus.forEach((status) => {
      if (response.status === 200) {
        this.successfulRes = {
          message: response.statusText,
        };
      } else if (response.status === status) {
        this.responseError = {
          message: 'API error: ' + response.statusText,
        };
      }
    });
  }

  /**
   * If you are adding a new form control, you need to make sure to
   * add it to the errorMessages.ts file in the assets/data folder.
   *
   * You also need to make sure that the validationTypes array is updated
   * with any new validation types that you add to the errorMessages.ts file.
   *
   * @param form: FormGroup
   */
  public sortValidationErrors(form: FormGroup) {
    const controlNameArray = Object.keys(form.controls).map((key) => key);
    const controlsArray = Object.keys(form.controls).map((key) => ({
      [key]: form.controls[key],
    }));

    this.constructErrorObj(controlsArray, controlNameArray).then(() => {
      if (this.errors?.length) {
        this.errors.forEach((val: any) => {
          /**
           * Make sure to update the validationTypes array if you add a new validation type
           *
           * You can use ctrl(cmd for mac) + left click to navigate to the definition of the validationTypes array
           */
          this.setValidationMessages(validationTypes, val);
        });
      }
    });
  }

  private setValidationMessages(types: any[], error: ErrorsModel) {
    const messages: string[] = [];
    types.forEach((val, index) => {
      if (error?.validationErrorType?.hasOwnProperty(types[index])) {
        if (Object.entries(error.validationErrorType).length > 1) {
          messages.push(FormService.errorMessage(error.control, types[index]));
          error.message = messages;
        } else {
          error.message = FormService.errorMessage(error.control, types[index]);
        }
      }
    });
  }

  private async constructErrorObj(formControls: any[], formControlNames: any[]) {
    const errors: any[] = [];
    formControls.forEach((control, index) => {
      const controlName: any = formControlNames[index];
      // @ts-ignore
      if (control[controlName]['errors']) {
        const error: ErrorsModel = {
          // @ts-ignore
          validationErrorType: control[controlName]['errors'],
          control: controlName,
        };
        errors.push(error);
      }
    });
    this.errors = errors;
  }

  private static errorMessage(field: string | undefined, error: string) {
    // Display custom error, else display default error, else display error key
    // @ts-ignore
    const customMsg = ErrorMessages.invalid[field][error] || '';
    // @ts-ignore
    const defaultMsg = ErrorMessages.invalid.default[error] || '';
    return customMsg ? customMsg : defaultMsg ? defaultMsg : error;
  }

  public decimalRegEx = /^(\d*)(\.\d{1,4})?$/;
  public emailListRegEx = /^(\w+([-+.']\w+)*@\w+([-.]\w+)*\.[a-z]{2,6}(,\s*\w+([-+.']\w+)*@\w+([-.]\w+)*\.[a-z]{2,6})*)(?<!,)$/i;
  public numericRegEx = /^\d*$/;
  public phoneRegEx = /^\d{10}$/;
  public postalCodeRegEx = /^[A-Za-z][0-9][A-Za-z][ ]?[0-9][A-Za-z][0-9]$/;

  constructor() {
    super();
  }

  initRequiredFormControl(ctrl: FormControl | AbstractControl) {
    if (ctrl && ctrl.validator) {
      // @ts-ignore
      const validators = ctrl.validator({} as AbstractControl);
      return validators && validators['required'];
    }
    return false;
  }

  inputClass(ctrl: FormControl | AbstractControl, errClass?: string) {
    if (!errClass) {
      errClass = 'input-validation-error';
    }
    return ctrl && ctrl.errors && ctrl.touched ? errClass : '';
  }

  watchErrors(form: FormGroup, id: string, errorList: string[]): void {
    const customErrors = ErrorMessages.invalid[id] || {};
    const defaultErrors = ErrorMessages.invalid.default;

    form.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(() => {
      errorList.length = 0;
      const errors = form.controls[id].errors;
      if (errors) {
        Object.keys(errors).forEach((e: string) => {
          const message = customErrors[e] ? customErrors[e] : defaultErrors[e] ? defaultErrors[e] : `${e} error`;
          errorList.push(message);
        });
      }
    });
  }
}
