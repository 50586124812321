import { ResolveFn } from '@angular/router';
import { Type } from '@angular/core';

export class Breadcrumb {
  constructor(
    public label:
      | string
      | Type<{
          resolve: ResolveFn<string>;
        }>
      | ResolveFn<string>,
    public route: string | undefined
  ) {}
}
