import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../../../types/address.model';
import { CartAddress } from '../../../types/cart.model';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(addr: Address | CartAddress, separator?: string): string {
    const cityState = [];

    if (!addr) {
      return '';
    }

    if (!separator) {
      separator = '<br>';
    }
    if (addr.city) {
      cityState.push(addr.city);
    }
    if (addr.province) {
      cityState.push(addr.province);
    }

    const location = [];
    if (cityState.length) {
      location.push(cityState.join(', '));
    }
    if (addr.postalCode) {
      location.push(addr.postalCode);
    }

    const addrLine = [];
    if (addr.address1) {
      addrLine.push(addr.address1);
    }
    if (addr.address2) {
      addrLine.push(addr.address2);
    }
    if (addr.address3) {
      addrLine.push(addr.address3);
    }
    if (location.length) {
      addrLine.push(location.join(' '));
    }

    const addrRow = [];
    if (addr.name) {
      addrRow.push(addr.name);
    }
    if (addrLine.length) {
      addrRow.push(addrLine.join(separator));
    }
    return addrRow.length ? addrRow.join('<br>') : '';
  }
}
