import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/*
<img src="imageSource" fallbackImage>
<img src="imageSource" fallbackImage="fbImageUrl">
<img src="imageSource" [fallbackImage]="variableWithImageUrl">
 */

@Directive({
  selector: 'img[fallbackImage]',
})
export class FallbackImageDirective {
  @Input() fallbackImage: string;

  constructor(private eRef: ElementRef) {}

  @HostListener('error')
  loadFallbackOnError() {
    const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
    element.src = this.fallbackImage || '/assets/img/no-image-en.png';
  }
}
