import { Component, Input, OnInit } from '@angular/core';
import { LineItem } from 'src/app/modules/order/order-detail.model';
import { SelectedCheckboxAndRadio } from '../import-export.model';
import { ProductResult } from 'src/app/core/services/search/search.model';
import { PermissionService } from '../../../../core/services/permission/permission.service';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../../../types/app-state.interface';

@Component({
  selector: 'val-pdf-table-content',
  templateUrl: './pdf-table-content.component.html',
  styleUrls: ['./pdf-table-content.component.scss'],
})
export class PdfTableContentComponent implements OnInit {
  @Input() results: ProductResult[];
  @Input() selectedCheckboxes: SelectedCheckboxAndRadio[];
  @Input() subTotal?: number;
  @Input() salesTaxAmount?: number;
  @Input() pageType?: string | null;
  quantityExists: boolean | undefined;
  canViewPrices: boolean;
  quickListId?: number;

  constructor(private permissionService: PermissionService, private store: Store<AppStateInterface>) {
    this.canViewPrices = this.permissionService.canViewPrices();

    this.store.select('user').subscribe((data) => {
      this.quickListId = data?.user?.currentShoppingAccount?.quickListHeader?.id || undefined;
    });
  }

  getValueForKey(item: any, key: string): any {
    let val = item[key];
    if (key === 'total' && !item[key]) {
      val = item.price * item.qty;
    } else if (key === 'personalCodes') {
      val = this.quickListId && item[key] && item[key][this.quickListId] ? item[key][this.quickListId] : '';
    }
    return val;
  }

  ngOnInit(): void {
    this.checkQuantityExists();
  }

  private checkQuantityExists(): void {
    if (this.selectedCheckboxes && this.selectedCheckboxes.length > 0) {
      this.quantityExists = this.selectedCheckboxes[0].checkboxes?.some((checkbox) => checkbox.label === 'Quantity');
    }
  }

  calculateTotal(result: LineItem): number {
    return result.netAmount * result.quantityOrdered;
  }
}
