<div class="container mb-5">
  <div class="row listPadding">
    <div class="col">
      <val-page-title [pageTitle]="pageTitle"></val-page-title>
    </div>
  </div>

  <div class="col-12 toggleView" *ngIf="isSmall">
    <ul>
      <li>
        <span (click)="toggleView('listing')" [class.activeLink]="toggleSection === 'listing'"
          ><i class="material-icons-outlined iconStyle">listing</i>Listing</span
        >
      </li>
      <li>
        <span (click)="toggleView('map')" [class.activeLink]="toggleSection === 'map'"
          ><i class="material-icons-outlined">location_on</i>Map</span
        >
      </li>
    </ul>
  </div>
  <div class="row">
    <div class="col pe-0" [ngClass]="{ 'col-12 w-50 mx-auto': hideMap }">
      <hr class="mt-0" *ngIf="!isSmall" />
      <div class="listSection" *ngIf="!isSmall || toggleSection === 'listing'">
        <div *ngFor="let location of locations; index as i" [class.mt-2]="isSmall">
          <div class="row">
            <div class="col-12 col-sm-6">
              <a
                [routerLink]="
                  '/contact/' +
                  location.elements.actionUrl.value.substring(location.elements.actionUrl.value.lastIndexOf('/') + 1)
                "
                class="h5"
              >
                <u>{{ location.elements.name.value }}</u>
              </a>
              <div class="font-dark locationAddress">
                {{ location.elements.address.value }}<br />
                {{ location.elements.city.value }}
                ({{ location.elements.province.value }}),&nbsp;
                {{ location.elements.postalCode.value }}
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <a
                href="https://www.google.com/maps/search/?api=1&query={{ location.elements.latitude.value }},{{
                  location.elements.longitude.value
                }}"
                target="_blank"
                class="alink"
                >Get Directions</a
              >
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6 locationSize" [ngClass]="!isSmall ? 'mt-3' : 'mt-1'">
              Tel:
              <span class="font-dark locationWeight">{{ location.elements.phoneNumber.value }}</span>
              <br />
              <div *ngIf="location.elements.faxNumber.value">
                Fax:
                <span class="font-dark locationWeight">{{ location.elements.faxNumber.value }}</span>
              </div>
              <div *ngIf="!isSmall && !location.elements.faxNumber.value" class="locationHeight"></div>
              <br *ngIf="!isSmall" />
              <div *ngFor="let x of location.elements.businessHours.value" class="locationSize">
                {{ linkedItems[x].elements.dayS.value }}&nbsp;
                <span class="font-dark locationWeight">{{ linkedItems[x].elements.hours.value }}</span>
              </div>
            </div>
            <div class="col-12 col-sm-6 locationSize" [ngClass]="!isSmall ? 'mt-3' : 'mt-1'">
              <div *ngIf="location.elements.contactName.value">
                Branch Manager:
                <span class="font-dark">{{ location.elements.contactName.value }}</span>
              </div>
              <div *ngIf="location.elements.contactEmailAddress.value">
                Email:
                <u
                  ><a href="mailto:{{ location.elements.contactEmailAddress.value }}" class="font-dark">{{
                    location.elements.contactEmailAddress.value
                  }}</a>
                </u>
              </div>
              <br *ngIf="!isSmall" />
              <div class="tag p-1" *ngIf="location.elements.tag.value && !isSmall">
                {{ location.elements.tag.value }}
              </div>
            </div>
          </div>
          <hr class="mt-3 mb-3" />
        </div>
      </div>
    </div>
    <div
      [ngClass]="{ 'ps-0 col': !isSmall, 'col-12': isSmall, 'd-none': hideMap }"
      [ngStyle]="{
        display: !isSmall || toggleSection === 'map' ? 'block' : 'none'
      }"
    >
      <div #mapDiv name="mapDiv" [ngClass]="{ mapSmall: isSmall, mapLarge: !isSmall }" id="map"></div>
    </div>
  </div>
</div>
