import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'val-item-list-pagination',
  templateUrl: './item-list-pagination.component.html',
  styleUrls: ['./item-list-pagination.component.scss'],
})
export class ItemListPaginationComponent implements OnInit {
  @Input() page: any;
  @Input() numPages: any;
  @Output() changePageEvent = new EventEmitter<number>();

  paginate(page: number) {
    this.changePageEvent.emit(page);
  }

  constructor() {}

  ngOnInit(): void {}

  invalidPage() {
    return this.page < 1 || this.page > this.numPages;
  }

  firstPage() {
    if (this.page > 1) {
      this.paginate(1);
    }
  }

  prevPage() {
    if (this.page > 1) {
      this.paginate(Math.min(this.page - 1, this.numPages));
    }
  }

  lastPage() {
    if (this.page < this.numPages) {
      this.paginate(this.numPages);
    }
  }

  nextPage() {
    if (this.page < this.numPages) {
      this.paginate(Math.max(this.page + 1, 1));
    }
  }

  changePage() {
    if (this.page >= 1 && this.page <= this.numPages) {
      setTimeout(() => {
        this.paginate(this.page);
      }, 750);
    }
  }
}
