import { Component, OnInit } from '@angular/core';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';

@Component({
  selector: 'val-support-phone',
  template: ` <div>
    <a href="tel:18664498016" [attr.data-testid]="DATA_TEST_ID.navbar.phone.number">
      <i class="material-icons-outlined md-18 phone-icon">phone</i>
    </a>
    <label>&nbsp;Support:&nbsp;</label>
    <span
      ><a href="tel:18664498016" [attr.data-testid]="DATA_TEST_ID.navbar.phone.supportNumber">1-866-449-8016</a></span
    >
  </div>`,
  styleUrls: ['./support-phone.component.scss'],
})
export class SupportPhoneComponent extends BaseSubscriptionComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
