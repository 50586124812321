import { Injectable } from '@angular/core';
import { ProductResult, SearchOptions } from '../search/search.model';
import { CartLine, CheckoutFlow } from '../../../types/cart.model';
import { ToastService } from '../../../shared/components/toast/toast.service';
import { select, Store } from '@ngrx/store';
import { ApiService } from '../api/api.service';
import { UtilityService } from '../utility/utility.service';
import { API_URL } from '../../../shared/consts/api-urls';
import { CartType } from '../../../shared/consts/cart-types';
import { SearchService } from '../search/search.service';
import { AppStateInterface } from '../../../types/app-state.interface';
import { UserService } from '../user/user.service';
import {
  showCheckoutWithRestrictions,
  Permissions,
  showRestrictionsMsg,
  showSendForApproval,
  showCheckout,
} from '../../../shared/consts/permissions.enum';
import { LoadingSpinnerService } from '../../../shared/components/loading-spinner/loading-spinner.service';
import { forkJoin, Observable, takeUntil, Subject } from 'rxjs';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { currentShoppingContextSelector } from '../../store/user/user.selectors';
import { UserShoppingContext } from '../customer-company/user-shopping-context.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class OrderApprovalService extends BaseSubscriptionComponent {
  cartType = CartType.Order;
  userState: any;
  currentCart: any;
  loggedOutItems: [] = [];
  query = '';
  private shoppingContext$ = this.store.pipe(select(currentShoppingContextSelector));
  private shoppingContext: UserShoppingContext | null;
  private currentCartSubject = new Subject<any>();

  setCurrentCart() {
    this.currentCartSubject.next(this.currentCart);
  }

  clearCurrentCart() {
    this.currentCartSubject.next(null);
  }

  getCurrentCart(): Observable<any> {
    return this.currentCartSubject.asObservable();
  }

  private requestOptions: SearchOptions = {
    facetFilters: [],
    facets: [],
    hitsPerPage: 1000,
    page: 0,
  };

  initCartObj = {
    userId: '',
    cartItems: [],
    customerPoNumber: '',
    orderNotes: '',
    allowSms: false,
    mobileNumber: '',
    instructions: '',
    shoppingContextId: '',
    selectedWarehouse: '',
    shippingMethod: '',
    cartId: '',
    warehouseNumber: '',
  };

  constructor(
    public toast: ToastService,
    private api: ApiService,
    private utility: UtilityService,
    private store: Store<AppStateInterface>,
    private search: SearchService,
    private loading: LoadingSpinnerService,
    private userService: UserService,
    public cookieService: CookieService
  ) {
    super();

    this.store
      .select('user')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.userState = data;
      });
    this.shoppingContext$.pipe(takeUntil(this.destroyed)).subscribe((shoppingContext) => {
      this.shoppingContext = shoppingContext as UserShoppingContext;
      if (!this.shoppingContext?.id && this.userState.user) {
        this.toast.showError('Missing shopping context id');
      }
    });
  }

  public getCart(id?: string) {
    return this.api.get(`${API_URL.Carts}/${id}`, '', false);
  }

  public initiateCart(data: any) {
    if (data) {
      this.currentCart = data;
      this.setCurrentCart();
      if (this.loggedOutItems.length) {
        data.cartItems = this.reduceAllItems(data.cartItems.concat(this.loggedOutItems));
        this.api.put(`${API_URL.Carts}/${this.currentCart.cartId}`, data).subscribe({
          error: (error) => {
            console.log(error);
          },
        });
        this.loggedOutItems = [];
      }

      this.requestOptions.facetFilters = this.itemIdFacetFilters(data.cartItems);
      let builtItems: any = [];
      this.search.getProductResults(this.query, this.requestOptions).then((res) => {
        data.cartItems.forEach((item: any) => {
          res.hits.forEach((hit: any) => {
            if (item.itemId.toUpperCase() === hit.itemId.toUpperCase()) {
              hit.price = item.price;
              hit.qty = item.qty;
              hit.id = item.id;
              hit.cart = this.currentCart.cartId;
              builtItems.push(hit);
            }
          });
        });
        data.cartItems = builtItems;
        data.userId = this.userState.user.userId;
        this.setCurrentCart();
      });
    }
  }

  public saveLoggedOutItems() {
    if (this.currentCart && this.currentCart.cartItems) {
      this.loggedOutItems = this.currentCart.cartItems;
      this.currentCart.cartItems = [];
    }
  }

  public createNewCart() {
    // TODO - get explanation from Aaron about how this works
    //No need to change this.currentCart.items with reduced line data.
    let cartCopy = Object.assign({}, this.currentCart);
    if (this.userState.user?.userId && this.shoppingContext?.id) {
      cartCopy.cartId = `${this.cartType}-${this.userState.user.userTypeId}-${this.shoppingContext.id}`;
    }
    cartCopy.userId = this.userState?.user?.userId || null;
    cartCopy.shoppingContextId = this.shoppingContext?.id || null;
    cartCopy.cartType = this.cartType;
    cartCopy.cartItems = this.reduceAllItems(this.currentCart.cartItems);

    // Need to revisit this - these fields (especially id/@id - fatal error) should not be part of a new cart POST
    delete cartCopy.id;
    delete cartCopy['@id'];
    delete cartCopy.approvalCartId;
    delete cartCopy.approvers;
    delete cartCopy.approvalRequestDate;
    delete cartCopy.cartName;
    delete cartCopy.fulfillmentDate;
    delete cartCopy.subTotal;
    delete cartCopy.goodsServiceTax;
    delete cartCopy.totalOrderValue;

    return this.api.post(API_URL.Carts, cartCopy);
  }

  get itemCount(): number {
    return this.currentCart.cartItems ? this.currentCart.cartItems.length : 0;
  }

  public addItem(products: ProductResult | ProductResult[], updateTotals?: boolean) {
    if (products && !Array.isArray(products)) {
      products = [products];
    }
    if (products) {
      products = products.map((p: any) => {
        p.isSelected = false;
        return p;
      });

      let cartId = this.currentCart && this.currentCart.cartId ? '/api/carts/' + this.currentCart.cartId : null;
      if (this.userState?.user?.userId && !cartId) {
        this.toast.showError(`Cart error`);
      } else {
        products.forEach((x: any) => {
          let obj = {
            objectID: x.objectID,
            itemId: x.itemId,
            title: x.title,
            manufacturer: x.manufacturer,
            manufacturerPartNo: x.manufacturerPartNo,
            isInStock: x.isInStock,
            price: x.price,
            uom: x.uom,
            qty: x.qty ? +x.qty : 1,
            cart: cartId,
            image: x.image,
            sellMult: x.sellMult,
            unspsc: x.unspsc,
            id: null,
          };
          if (this.userState?.user?.userId) {
            this.api.post(API_URL.CartItems, this.reduceItem(obj)).subscribe({
              next: (x: any) => {
                obj.id = x.id;
                this.currentCart.cartItems.push(obj);
                this.setCurrentCart();
                if (updateTotals) {
                  this.submitToSxe(['summary'], false);
                }
              },
              error: () => {
                this.toast.showError(`Error adding item #${obj.itemId} to cart`);
              },
            });
          } else {
            this.currentCart.cartItems.push(obj);
            this.setCurrentCart();
            if (updateTotals) {
              this.submitToSxe(['summary'], false);
            }
          }
        });

        const msg = products.length === 1 ? `Item #${products[0].itemId} added` : `${products.length} items added`;
        this.toast.showSuccess(msg);
      }
    }
  }

  public removeByIndex(productIndex: number) {
    const product = this.currentCart.cartItems?.[productIndex];

    if (product) {
      this.loading.setLoadingPrices(['summary']);
      this.currentCart.cartItems.splice(productIndex, 1);
      this.calculateSubTotal();

      if (this.userState.user?.userId) {
        this.api.delete(`${API_URL.CartItems}/${product['id']}`).subscribe({
          next: () => this.loading.setLoadingPrices(),
        });
      } else {
        this.loading.setLoadingPrices();
      }
      return true;
    } else {
      this.toast.showError('Item removal failed');
      return false;
    }
  }

  public updateItem(productIndex: number, product: CartLine) {
    this.loading.setLoadingPrices(['summary']);
    this.calculateSubTotal();
    if (this.currentCart.cartId) {
      this.api.patch(`${API_URL.CartItems}/${product.id}`, JSON.stringify({ qty: product.qty })).subscribe({
        next: () => {
          this.loading.setLoadingPrices();
        },
      });
    } else {
      this.loading.setLoadingPrices();
    }

    if (this.currentCart.cartItems && this.currentCart.cartItems[productIndex]) {
      this.currentCart.cartItems[productIndex] = product;
      this.setCurrentCart();
      return true;
    } else {
      this.toast.showError('Item update failed');
      return false;
    }
  }

  public removeItems(products: CartLine[]) {
    if (!products.length) {
      return;
    }
    this.loading.setLoadingPrices(['summary']);

    const handleSuccess = (length: number) => {
      this.calculateSubTotal();
      this.loading.setLoadingPrices();
      this.toast.showSuccess(`${length} item(s) removed`);
      this.setCurrentCart();
    };

    if (this.userState.user && this.userState.user.userId) {
      let itemIds: any = products.map((obj) => obj.id);
      // removes items from local storage if IDs are an exact match
      this.currentCart.cartItems = this.currentCart.cartItems.filter((item: any) => !itemIds.includes(item.id));
      this.api.put(`${API_URL.Carts}/${this.currentCart?.cartId}/delete_items`, { itemIds: itemIds }).subscribe({
        next: () => handleSuccess(itemIds.length),
      });
    } else {
      // remove items from local storage if the two objects are an exact match
      products.forEach((product: any) => {
        const index = this.currentCart.cartItems.findIndex(
          (obj: any) => JSON.stringify(obj) === JSON.stringify(product)
        );
        if (index !== -1) {
          this.currentCart.cartItems.splice(index, 1);
        }
      });
      handleSuccess(this.currentCart.cartItems.length);
    }
  }

  public emptyCart() {
    this.clearLocalCart();
    this.api.delete(`${API_URL.Carts}/${this.currentCart.cartId}/delete_all`).subscribe();
    this.toast.showSuccess('All items removed from cart');
  }

  public clearLocalCart() {
    this.currentCart.cartItems = [];
    this.setCurrentCart();
  }

  public itemIdFacetFilters(items: any) {
    const itemIds = this.utility.arrayUnique(items.map((x: any) => x.itemId));
    return [itemIds.map((item: any) => `itemId:${item}`)];
  }

  public reduceItem(item: any) {
    let cartId = this.currentCart && this.currentCart.cartId ? '/api/carts/' + this.currentCart.cartId : null;
    return {
      qty: item.qty,
      cart: cartId,
      itemId: item.itemId,
      price: item.price,
      id: item.id,
      uom: item.uom,
    };
  }

  public reduceAllItems(items: any) {
    return items.length ? items.map((item: any) => this.reduceItem(item)) : [];
  }

  public checkoutPermissions() {
    if (this.userService.isPunchOut(this.userState.user)) {
      return {
        cart: 'showCheckout',
        checkout: [],
      };
    }

    let checkout: CheckoutFlow[] = [];
    let cart = '';
    let userPermissions = (this.shoppingContext?.permissions as unknown as string[]) || [];
    let customerCompany = this.shoppingContext?.customerCompanyNumber;
    userPermissions = userPermissions.filter((x: any) => {
      return Object.values(Permissions).includes(x.toUpperCase());
    });

    // Add APPROVAL_WORKFLOW to user permissions if AW assigned to customer AND user is not an approver
    if (customerCompany?.approvalWorkflow && !userPermissions.includes(Permissions.APO)) {
      userPermissions.push(Permissions.AW);
    }

    // returns then name of the matched permission array.
    cart = this.findMatch(userPermissions);

    let hasPO = false;
    if (userPermissions.includes(Permissions.PO)) {
      hasPO = true;
      // PO is always the default.
      checkout.push({ type: 'PO', label: 'Bill to my account', value: 1 });
    } else if (userPermissions.includes(Permissions.CC)) {
      // PO is always the default.
      checkout.push({ type: 'CC', label: 'Pay by credit card', value: hasPO ? 0 : 1 });
    }

    // resets billing method if one has been already saved in the database.
    if (checkout.length > 1 && this.currentCart?.billingMethod) {
      checkout = checkout.map((x: any) => ({
        ...x,
        value: x.type === this.currentCart.billingMethod ? 1 : 0,
      }));
    }
    // Perhaps flow should be moved
    if (userPermissions.includes(Permissions.AW) && customerCompany?.approvalWorkflow) {
      cart = 'showSendForApproval';
      checkout = [{ type: 'AO', label: 'Approve Order', value: hasPO ? 0 : 1 }];
    }

    return <any>{ checkout: checkout, cart: cart };
  }

  findMatch(user: string[]) {
    const arrays = { showCheckout, showCheckoutWithRestrictions, showRestrictionsMsg, showSendForApproval };
    for (const [name, array] of Object.entries(arrays)) {
      const found = array.some((permissions) => user.every((p: any) => permissions.includes(p)));
      if (found) {
        return name;
      }
    }
    return '';
  }

  public submitToSxe(loadingOpts: string[], submit: boolean, monerisTicket?: string): Observable<any> {
    this.loading.setLoadingPrices(loadingOpts);
    let postObj = {
      cartId: this.currentCart.cartId,
      isSubmitToSxe: submit,
    };

    return new Observable((observer) => {
      this.api.post(`${API_URL.OrdersSubmit}`, postObj).subscribe(
        (data: any) => {
          this.loading.setLoadingPrices();
          this.handlePricingUpdates(data).subscribe(
            () => {
              this.setCurrentCart();
              observer.next(data);
              observer.complete();
            },
            (error) => {
              console.error(error);
              observer.error(error);
            }
          );
          observer.next(data);
          observer.complete();
        },
        (error) => {
          console.error(error);
          observer.error(error);
        }
      );
    });
  }

  public getGuesttSxePricing(loadingOpts: string[]): Observable<any> {
    this.loading.setLoadingPrices(loadingOpts);

    let postObj = this.currentCart.cartItems.map((item: CartLine) => {
      return {
        itemId: item.itemId,
        title: item.title,
        qty: item.qty,
        uom: item.uom,
      };
    });
    return new Observable((observer) => {
      this.api.post(`${API_URL.OrdersGuestPricing}`, { items: postObj }).subscribe(
        (data: any) => {
          this.loading.setLoadingPrices();
          this.handlePricingUpdates(data).subscribe(
            () => {
              this.setCurrentCart();
              observer.next(data);
              observer.complete();
            },
            (error) => {
              console.error(error);
              observer.error(error);
            }
          );

          observer.next(data);
          observer.complete();
        },
        (error) => {
          console.error(error);
          observer.error(error);
        }
      );
    });
  }

  private handlePricingUpdates(data: any): Observable<void> {
    return new Observable((observer) => {
      const observables: Observable<any>[] = [];
      this.currentCart.subTotal = data.results.totalLineAmount;
      this.currentCart.goodsServiceTax = data.results.salesTaxAmount;
      this.currentCart.totalOrderValue = data.results.totalOrderValue;

      this.currentCart.cartItems.forEach((cartItem: any) => {
        const lineItem = data.results.lineItems.find(
          (item: any) => item.productNumber.toUpperCase() === cartItem.itemId.toUpperCase()
        );

        if (lineItem && lineItem.uom) {
          cartItem.uom = lineItem.uom;
        }

        if (lineItem && (lineItem.sellPrice !== cartItem.price || lineItem.quantityOrdered !== cartItem.qty)) {
          cartItem.price = lineItem.sellPrice;
          cartItem.quantityOrdered = lineItem.qty;
          if (this.currentCart.cartId) {
            observables.push(
              this.api.patch(`${API_URL.CartItems}/${cartItem.id}`, JSON.stringify({ price: lineItem.sellPrice }))
            );
          }
        } else if (!lineItem) {
          // Remove cart line if it is not within the orderSubmit return. Do not want to display data that hasn't been properly calculated.
          this.currentCart.cartItems = this.currentCart.cartItems.filter((item: any) => item.id !== cartItem.id);
          if (this.currentCart.cartId) {
            observables.push(this.api.delete(`${API_URL.CartItems}/${cartItem.id}`));
          }
        }
      });

      if (observables.length > 0) {
        forkJoin(observables).subscribe(
          () => {
            observer.next();
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
      } else {
        observer.next();
        observer.complete();
        this.calculateSubTotal();
        this.loading.setLoadingPrices();
      }
    });
  }

  public getOrderApprovers() {
    return this.api.post(API_URL.OrderApprovers, { shoppingContextId: this.shoppingContext?.id });
  }

  public getOrderApprovalPending() {
    return this.api.post(API_URL.OrderApprovalPending, { shoppingContextId: this.shoppingContext?.id });
  }

  public getOrderApprovalReview(id: string) {
    return this.api.post(API_URL.OrderApprovalReview, { cartId: id });
  }

  public rejectOrderPendingApproval(id: string) {
    return this.api.post(API_URL.OrderApprovalReject, { cartId: id });
  }

  calculateSubTotal() {
    this.currentCart.subTotal = 0;
    this.currentCart.cartItems.forEach((product: any) => {
      this.currentCart.subTotal += product.qty * product.price;
    });
    this.setCurrentCart();
  }
}
