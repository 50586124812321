import { Component, OnInit } from '@angular/core';
import { PageTitle } from '../../shared/components/page-title/page-title.model';

@Component({
  selector: 'val-redirect',
  template: `<val-page-title [pageTitle]="pageTitle"></val-page-title>
    <div style="height: 200px;">
      <val-loading-spinner [global]="false" [loading]="true"></val-loading-spinner>
    </div>`,
})
export class RedirectComponent implements OnInit {
  constructor() {}

  pageTitle: PageTitle;

  ngOnInit(): void {
    this.pageTitle = new PageTitle('Redirecting to External Site', 'exit_to_app', '');
  }
}
