<div class="container-fluid products-listing" [ngClass]="pageType">
  <div class="container">
    <div class="row mt-4 results-header">
      <div class="col-12 col-lg-4 offset-lg-4">
        <val-page-title [pageTitle]="pageTitle"></val-page-title>
      </div>
      <div class="col-12 col-lg-4 text-center text-lg-end">
        <val-shared-list [list]="list" [user]="user"></val-shared-list>
      </div>
      <div class="col-12 item-list-search-within" [ngStyle]="{ visibility: searchWithin() ? 'visible' : 'hidden' }">
        <val-dt-search [placeholder]="'Search within results'" (onFilter)="onFilterQuery($event)"></val-dt-search>
      </div>
    </div>

    <div class="row item-list-wrapper mb-5 listing">
      <div class="col-3 left product-lists">
        <button (click)="toggleFilterModal()" type="button" class="btn" data-toggle="modal" data-target="#myAllLists">
          My Lists
        </button>
        <div
          class="modal fade"
          id="myAllLists"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myAllListsTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <button
                (click)="toggleFilterModal()"
                type="button"
                class="btn d-flex flex-row-reverse d-lg-none"
                data-toggle="modal"
                data-target="#myAllLists"
              >
                <i class="material-icons-outlined">close</i>
              </button>

              <val-product-list-menu
                class="mb-3"
                [lists]="lists"
                [user]="user"
                [listType]="listType()"
                (onDeletedList)="onDeletedList($event)"
              ></val-product-list-menu>
              <val-product-facet-list
                [categoryFilters]="categoryFilters"
                [facetFilters]="facetFilters"
                [maxFilters]="maxFilters"
                [activeFilters]="activeFilters"
                (onDeletedList)="onDeletedList($event)"
                (onFilter)="onFilterFacets($event)"
                (onToggleFacets)="toggleFacets($event)"
              ></val-product-facet-list>
            </div>
          </div>
        </div>
      </div>

      <div class="col-9 right">
        <ng-container *ngIf="results?.hits?.length; else noResults">
          <val-item-list-actions
            [results]="results"
            [user]="user"
            [cart]="cart"
            [listType]="listType()"
            [pageType]="pageType"
            [activeFilters]="activeFilters"
            [listId]="listId"
            [listName]="listName"
            (changePageEvent)="onChangePage($event)"
            (onSortEvent)="onSort($event)"
            (onToggleFilterEvent)="onToggleFilter($event)"
            (onDeletedItems)="onDeletedItems($event)"
            (importSuccess)="onImportSuccess()"
            (onAddProductToList)="onAddProduct($event)"
          ></val-item-list-actions>
          <val-item-list
            [products]="results.hits"
            [pageType]="pageType"
            [collapse]="isCollapsedDetails"
          ></val-item-list>
          <val-item-list-pagination
            [page]="page"
            [numPages]="results.nbPages"
            (changePageEvent)="onChangePage($event)"
          ></val-item-list-pagination>
        </ng-container>
        <ng-template #noResults>
          <val-no-results *ngIf="results" class="mt-3"></val-no-results>
        </ng-template>
      </div>
    </div>
  </div>
</div>
