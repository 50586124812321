import { Component, Input, OnInit } from '@angular/core';
import { ProductResult } from '../../../../core/services/search/search.model';
import { LineItem } from 'src/app/modules/order/order-detail.model';

@Component({
  selector: 'val-product-status',
  templateUrl: './product-status.component.html',
  styleUrls: ['./product-status.component.scss'],
})
export class ProductStatusComponent {
  @Input() product?: ProductResult;
  @Input() lineItem?: LineItem;
  @Input() b2b: boolean;
  @Input() isCart?: boolean;
  @Input() index?: number = 0;

  constructor() {}
}
