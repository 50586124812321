<div id="search-box" [ngClass]="{ _desktop: !showMobile }">
  <div *ngIf="showResults" id="search-panel" class="el-search-panel container-fluid">
    <div *ngIf="savedQueries?.length" class="el-block-wrapper">
      <div class="row">
        <div class="col">
          <h4 class="el-block-heading">Recent Queries</h4>
        </div>
      </div>
      <div
        *ngFor="let query of savedQueries; let i = index"
        (click)="q = query; submitForm()"
        class="el-category-wrapper"
        [attr.data-testid]="DATA_TEST_ID.search.recentQueryLink + '-' + i"
      >
        <span>
          <strong>{{ query }}</strong>
        </span>
      </div>
    </div>

    <!-- Suggestions results -->
    <div *ngIf="suggestions.length" class="el-block-wrapper">
      <div class="row">
        <div class="col">
          <h4 class="el-block-heading">Suggestions</h4>
        </div>
      </div>
      <div
        *ngFor="let suggestion of suggestions; let i = index"
        (click)="seeSuggestion(suggestion)"
        class="el-category-wrapper"
      >
        <span>
          <strong>{{ suggestion.objectID | titlecase }}</strong>
        </span>
      </div>
    </div>

    <!-- category results -->
    <div *ngIf="categories.length" class="el-block-wrapper">
      <div class="row">
        <div class="col">
          <h4 class="el-block-heading">Categories</h4>
        </div>
      </div>
      <div
        *ngFor="let category of categories; let i = index"
        (click)="seeCategory(category)"
        class="el-category-wrapper"
        [attr.data-testid]="DATA_TEST_ID.search.categoryLink + '-' + i"
      >
        <span>
          <strong>{{ category.name }}</strong>
        </span>
      </div>
    </div>

    <!--    quick list results-->
    <val-product-results
      [products]="quickLists"
      [searchType]="'Quick List'"
      (closeSearch)="closeSearch()"
    ></val-product-results>

    <!--    full catalog results-->
    <val-product-results
      [products]="products"
      [searchType]="'Products'"
      (closeSearch)="closeSearch()"
      (submitSearch)="submitForm()"
    ></val-product-results>
  </div>
  <form id="SearchBar" class="form-inline" (ngSubmit)="submitForm()">
    <div class="input-group">
      <input
        type="text"
        name="q"
        class="form-control"
        placeholder="Products Search or Product Code"
        aria-label="Products Search or Product Code"
        aria-describedby="search-button"
        autocomplete="off"
        [(ngModel)]="q"
        (keyup)="onSearch($event)"
        (blur)="saveSearch()"
        [attr.data-testid]="DATA_TEST_ID.search.input"
      />
      <button
        class="btn btn-primary"
        type="submit"
        (click)="submitForm()"
        [disabled]="!q.trim()"
        id="search-button"
        [attr.data-testid]="DATA_TEST_ID.search.button"
      >
        <i class="material-icons-outlined md-36">search</i>
      </button>
    </div>
  </form>
</div>
