import { ActionFormat, ActionType, ImportExportAction } from '../components/import-export/import-export.model';

export interface DisplayOptions {
  addButtons?: boolean;
  addProduct?: boolean;
  leftSection?: boolean;
  middleSection?: boolean;
  rightSection?: boolean;
  checkAll?: boolean;
  delete?: boolean;
  productCount?: boolean;
  searchResults?: boolean;
  showDetails?: boolean;
  downloadResults?: boolean;
  downloadDocs?: boolean;
  pagination?: boolean;
  sortBy?: boolean;
  exports?: boolean;
  import?: boolean;
  empty?: boolean;
}

export enum PageType {
  APPROVAL = 'approval',
  CART = 'cart',
  CATEGORY = 'category',
  CHECKOUT = 'checkout',
  REVIEW_ORDER = 'review-order',
  PAYMENT_DETAILS = 'payment-details',
  HISTORY = 'product-history',
  IMPORT_ORDER = 'import-order',
  LISTS = 'my-lists',
  ORDERDETAILS = 'order-details',
  PRODUCT_LIST = 'product-list',
  PROMOTIONS = 'promotions',
  PUBLIC = 'public',
  QUICKLIST = 'quicklist',
  QUOTE = 'create-quote',
  QUOTE_DETAILS = 'quote-details',
  RESULTS = 'results',
  SEARCH = 'search',
  SHARED = 'shared',
  SHOWTOTAL = 'showTotal',
  USER = 'user',
}

export interface PageTypeConfigs {
  displayOptions: DisplayOptions;
  importActions?: ImportExportAction;
  exportExcelActions?: ImportExportAction;
  exportPDFActions?: ImportExportAction;
}

export const initDisplayOptions = (type: PageType | string, listId?: any): PageTypeConfigs => {
  const pageTypes = Object.values(PageType);
  let configurations: PageTypeConfigs = { displayOptions: {} };
  pageTypes.forEach(() => {
    if (type === PageType.CART) {
      return (configurations = {
        displayOptions: {
          addProduct: true,
          checkAll: true,
          delete: true,
          downloadDocs: true,
          empty: true,
          exports: true,
          leftSection: true,
          rightSection: true,
          showDetails: true,
        },
        importActions: {
          format: ActionFormat.IMPORT_ORDER,
          type: ActionType.IMPORT,
        },
        exportExcelActions: {
          format: ActionFormat.EXPORT_EXCEL,
          type: ActionType.EXPORT,
        },
        exportPDFActions: {
          format: ActionFormat.EXPORT_CART_PDF,
          type: ActionType.EXPORT,
        },
      });
    } else if (type === PageType.QUOTE) {
      return (configurations = {
        displayOptions: {
          downloadDocs: true,
          empty: true,
          leftSection: true,
          middleSection: true,
          productCount: false,
          rightSection: true,
          showDetails: true,
        },
        importActions: {
          format: ActionFormat.IMPORT_ORDER,
          type: ActionType.IMPORT,
        },
        exportExcelActions: {
          format: ActionFormat.EXPORT_EXCEL,
          type: ActionType.EXPORT,
        },
        exportPDFActions: {
          format: ActionFormat.EXPORT_CART_PDF,
          type: ActionType.EXPORT,
        },
      });
    } else if (type === PageType.QUOTE_DETAILS) {
      return (configurations = {
        displayOptions: {
          downloadDocs: true,
          exports: false,
          checkAll: true,
          leftSection: true,
          rightSection: true,
          searchResults: false,
          showDetails: true,
        },
      });
    } else if (type === PageType.ORDERDETAILS) {
      return (configurations = {
        displayOptions: {
          checkAll: true,
          downloadDocs: true,
          exports: false,
          leftSection: true,
          rightSection: true,
          searchResults: false,
          showDetails: true,
        },
      });
    } else if (type === PageType.RESULTS) {
      return (configurations = {
        displayOptions: {
          addButtons: true,
          checkAll: true,
          delete: false,
          downloadDocs: false,
          leftSection: true,
          pagination: true,
          productCount: true,
          searchResults: true,
          showDetails: false,
          sortBy: true,
        },
      });
    } else if (type === PageType.LISTS + ' ' + PageType.SHARED) {
      return (configurations = {
        displayOptions: {
          addButtons: true,
          checkAll: true,
          delete: true,
          downloadDocs: true,
          exports: true,
          import: true,
          leftSection: true,
          productCount: true,
          rightSection: false,
        },
        importActions: {
          format: ActionFormat.IMPORT_PRODUCT_LIST,
          listId: listId,
          type: ActionType.IMPORT,
        },
        exportExcelActions: {
          format: ActionFormat.EXPORT_EXCEL,
          listId: listId,
          type: ActionType.EXPORT,
        },
        exportPDFActions: {
          format: ActionFormat.EXPORT_SHARED_LIST_PDF,
          listId: listId,
          type: ActionType.EXPORT,
        },
      });
    } else if (type === PageType.LISTS + ' ' + PageType.PUBLIC) {
      return (configurations = {
        displayOptions: {
          addButtons: true,
          checkAll: true,
          delete: true,
          downloadDocs: true,
          exports: true,
          import: true,
          leftSection: true,
          productCount: true,
          rightSection: false,
        },
        importActions: {
          format: ActionFormat.IMPORT_PRODUCT_LIST,
          listId: listId,
          type: ActionType.IMPORT,
        },
        exportExcelActions: {
          format: ActionFormat.EXPORT_EXCEL,
          listId: listId,
          type: ActionType.EXPORT,
        },
        exportPDFActions: {
          format: ActionFormat.EXPORT_PUBLIC_LIST_PDF,
          listId: listId,
          type: ActionType.EXPORT,
        },
      });
    } else if (type === PageType.LISTS + ' ' + PageType.USER) {
      return (configurations = {
        displayOptions: {
          addButtons: true,
          addProduct: true,
          checkAll: true,
          delete: true,
          downloadDocs: true,
          exports: true,
          import: true,
          leftSection: true,
          productCount: true,
          rightSection: false,
        },
        importActions: {
          format: ActionFormat.IMPORT_PRODUCT_LIST,
          listId: listId,
          pageType: PageType.LISTS,
          type: ActionType.IMPORT,
        },
        exportExcelActions: {
          format: ActionFormat.EXPORT_EXCEL,
          listId: listId,
          type: ActionType.EXPORT,
        },
        exportPDFActions: {
          format: ActionFormat.EXPORT_PRODUCT_LIST_PDF,
          listId: listId,
          type: ActionType.EXPORT,
        },
      });
    } else if (type === PageType.LISTS + ' ' + PageType.QUICKLIST) {
      return (configurations = {
        displayOptions: {
          addButtons: true,
          checkAll: true,
          delete: true,
          downloadResults: true,
          exports: true,
          import: true,
          leftSection: true,
          productCount: true,
          rightSection: false,
        },
        importActions: {
          format: ActionFormat.IMPORT_QUICKLIST,
          listId: listId,
          pageType: PageType.QUICKLIST,
          type: ActionType.IMPORT,
        },
        exportExcelActions: {
          format: ActionFormat.EXPORT_QUICKLIST_EXCEL,
          listId: listId,
          type: ActionType.EXPORT,
        },
        exportPDFActions: {
          format: ActionFormat.EXPORT_QUICKLIST_PDF,
          listId: listId,
          type: ActionType.EXPORT,
        },
      });
    } else if (type === PageType.HISTORY) {
      return (configurations = {
        displayOptions: {
          addButtons: true,
          checkAll: true,
          delete: true,
          downloadDocs: false,
          exports: false,
          import: false,
          leftSection: true,
          productCount: true,
          rightSection: true,
        },
      });
    } else {
      return (configurations = {
        displayOptions: {
          addButtons: false,
          addProduct: false,
          checkAll: false,
          delete: false,
          downloadDocs: false,
          empty: false,
          exports: false,
          import: false,
          pagination: false,
          searchResults: false,
          showDetails: false,
          sortBy: false,
        },
      });
    }
  });

  return configurations;
};
