import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pageType',
})
export class PageTypePipe implements PipeTransform {
  transform(value: string): string {
    // Converting value to lowercase to make the comparison case-insensitive
    const lowerCaseValue = value.toLowerCase();
    if (lowerCaseValue.includes('cart')) {
      return 'Cart';
    } else if (lowerCaseValue.includes('quote')) {
      return 'Quote';
    }
    return value;
  }
}
