import { Component, Input, OnInit } from '@angular/core';
import { CounterService } from 'src/app/core/services/counter/counter.service';
import { DATA_TEST_ID } from 'src/app/shared/consts/data-test-ids';
@Component({
  selector: 'val-kontent-dashboard-banner',
  templateUrl: './kontent-dashboard-banner.component.html',
})
export class KontentDashboardBannerComponent implements OnInit {
  @Input() content: any;
  banners: {
    url: string;
    image: string;
    target: string;
  }[] = [];
  DATA_TEST_ID = DATA_TEST_ID;
  currentComponentCount: number;

  constructor(private counterService: CounterService) {
    this.currentComponentCount = this.counterService.getComponentCount('KontentDashboardBannerComponent');
  }

  ngOnInit(): void {
    this.formatData();
  }

  private formatData(): void {
    this.content.item.elements.contentItems.value.forEach((codeName: string) => {
      const e = this.content.linkedItems[codeName].elements;
      this.banners.push({
        url: e.actionUrl.value || '',
        image: e.image.value[0].url || '',
        target: e.target.value[0].name || '_self',
      });
      this.banners = this.banners.filter((x) => !!x.image);
    });
  }
}
