import { Component, OnInit } from '@angular/core';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';

@Component({
  selector: 'val-no-footer',
  templateUrl: './no-footer.component.html',
})
export class NoFooterComponent extends BaseSubscriptionComponent implements OnInit {
  constructor() {
    super();
  }

  currentYear = new Date().getFullYear();

  ngOnInit(): void {}
}
