import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastService } from '../../toast/toast.service';
import { API_URL } from 'src/app/shared/consts/api-urls';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ProductResult } from '../../../../core/services/search/search.model';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';

@Component({
  selector: 'val-personal-code',
  templateUrl: './personal-code.component.html',
  styleUrls: ['./personal-code.component.scss'],
})
export class PersonalCodeComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() product: ProductResult;
  @Input() quickListHeaderId: number;
  @Input() toggle?: boolean;
  @Output() closePersonalCode = new EventEmitter<void>();

  form: FormGroup;
  loading = false;

  constructor(private formBuilder: FormBuilder, public toast: ToastService, private api: ApiService) {
    super();
  }

  ngOnInit(): void {
    if (!this.product.personalCodes) {
      this.product.personalCodes = {};
    }
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      personalCode: this.formBuilder.control(this.product.personalCodes[this.quickListHeaderId] || '', {
        updateOn: 'change',
      }),
    });
  }

  createPersonalCode() {
    const payload = {
      productCode: this.product.itemId,
      personalCode: this.form.value.personalCode.trim(),
    };

    this.loading = true;

    this.api.put(`${API_URL.QuickListHeader}/${this.quickListHeaderId}/personal_code`, payload).subscribe({
      next: (res) => {
        if (res) {
          this.toast.showSuccess('Personal Code updated successfully');
          this.product.personalCodes[this.quickListHeaderId] = payload.personalCode;
          if (this.toggle) {
            this.onClose();
          }
          this.initForm();
          this.loading = false;
        }
      },
      error: (error) => {
        this.toast.showError(error);
        this.loading = false;
      },
    });
  }

  onClose(): void {
    this.closePersonalCode.emit();
  }
}
