import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { getLastSixMonths } from '../../consts/dates';
import { ApiService } from 'src/app/core/services/api/api.service';
import { LoadingSpinnerService } from '../loading-spinner/loading-spinner.service';
import { API_URL } from '../../consts/api-urls';
import { UserModel } from 'src/app/core/services/user/user.model';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'val-bar-chart',
  templateUrl: './bar-chart.component.html',
})
export class BarChartComponent implements OnInit {
  @Input() user: UserModel | null | undefined;

  private dateLabels = getLastSixMonths();
  private chartData: any[] = [];

  /**
   * Specific items for out barchart package
   */
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData: ChartConfiguration<'bar'>['data'];
  // Display options
  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
        ticks: {
          backdropPadding: 0,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  constructor(private api: ApiService, private loading: LoadingSpinnerService, private toast: ToastService) {}

  ngOnInit(): void {
    this.fetchMonthlyOrders();
  }

  structureChartData(): number[] {
    let invoiceTotals: number[] = [];
    this.dateLabels.map(({ number }) => {
      const matches = this.chartData.find((item) => item.month.includes(`-${number}`));
      const totals = matches ? parseFloat(matches.invoicetotal) : 0;
      invoiceTotals.push(totals);
    });
    return invoiceTotals;
  }

  private fetchMonthlyOrders(): void {
    this.loading.start();

    this.api
      .post(`${API_URL.OrdersMonthly}`, {
        companyNumber: this.user!.companyNumber,
        customerNumbers: this.user!.currentShoppingAccount!.customerNumber.toString(),
      })
      .subscribe({
        next: (res: any) => {
          const months: string[] = this.dateLabels.map((x) => x.month);
          this.chartData = res.results;
          this.barChartData = {
            labels: months,
            datasets: [
              {
                data: this.structureChartData(),
                base: 0,
                label: 'Invoice $',
                backgroundColor: 'rgb(251, 228, 181, 0.8)',
                borderColor: '#fbe4b5',
              },
            ],
          };
          this.loading.stop();
        },
        error: (err: any) => {
          this.loading.stop();
          this.toast.showError(err);
        },
      });
  }
}
