<div class="img">
  <img *ngIf="content.elements['bannerImage']" [src]="content.elements['bannerImage'].value[0].url" class="img-fluid"/>
</div>
<div class="details container" *ngIf="content.elements['title'].value">
  <div class="title">{{ content.elements["title"].value }}</div>
</div>
<!--      buttonLabel-->
<!--      actionUrl-->
<!--      target-->
<!--      isImageClickable-->
