<div id="cookieConsent" [ngClass]="determineDisplay()">
  <div class="container">
    <div class="row d-flex">
      <div class="col-9">
        This website uses cookies to ensure you get the best experience on our website.
        <a routerLink="/content/cookie-policy" target="_blank">Read more</a>
      </div>
      <div class="col-3">
        <a
          (click)="setCookie()"
          [attr.data-testid]="DATA_TEST_ID.cookieConsentBtn"
          role="button"
          class="btn btn-sm btn-primary"
          >OK</a
        >
      </div>
    </div>
  </div>
</div>
