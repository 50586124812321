// Arrange values in alphabetical order for ease of use
export const FieldLabels = {
  accessPricing: 'Access Pricing',
  addProducts: 'Add Products',
  address: 'Address',
  addressName: 'Address Name',
  address1: 'Address',
  address2: 'Unit #',
  approver: 'Approver',
  approvalWorkflow: 'Approval Workflow',
  associatedCustomerNumber: 'Customer Number',
  associatedShipTo: 'Ship-to Account',
  billAddressName: 'Address Name',
  billAddress1: 'Address',
  billAddress2: 'Unit #',
  billCity: 'City',
  billPostalCode: 'Postal Code',
  billProvince: 'Province',
  city: 'City',
  comments: 'Comments',
  companyName: 'Company Name',
  company: 'Company',
  confirmPassword: 'Confirm Password',
  contactMobileNumber: 'Mobile Number',
  contactPhoneNumber: 'Contact Number',
  currentPassword: 'Current Password',
  customer: 'SX.e Customer',
  customerCompanyName: 'Customer Company Name',
  customerCompanyNumber: 'Customer Company Number',
  customerCompanyNumberId: 'Customer Company Name',
  customerName: 'Customer Name',
  customerNumber: 'Customer Number',
  customerPoNumber: 'Customer PO #',
  defaultShoppingAccountId: 'Default Ship-To Account',
  email: 'Email Address',
  emailCc: 'Email CC',
  emailSubscription:
    'Stay connected with Vallen by subscribing to updates. By checking the box you are opting in to receive Vallen newsletters, updates, and offers. You can unsubscribe at any time.',
  excludeVendors: 'Exclude All Vendors',
  existingAccount: 'Existing Account',
  externalSalesRepCode: 'Representative',
  externalUserEmail: 'Email Address',
  externalUserFirstName: 'First Name',
  externalUserId: 'External User ID',
  externalUserIdentifier: 'External User',
  externalUserLastName: 'Last Name',
  firstName: 'First Name',
  fullName: 'Full Name',
  homeBranch: 'Home Vallen Branch',
  inquiry: 'Inquiry',
  instructions: 'Instructions',
  isBlocked: 'Is Blocked',
  isCustomerAdmin: 'Customer Admin',
  isInternalUser: 'Internal User',
  isSmsNotificationEnabled:
    'Yes, I agree to receive SMS notifications for updates on online orders. Consent to receive notifications is not a condition of purchase.',
  lastName: 'Last Name',
  listName: 'Public List Name',
  maximumAmountByMonth: 'Maximum Monthly Limit',
  maximumAmountByOrder: 'Maximum Order Value',
  maximumMonthlyLimit: 'Maximum Monthly Limit',
  maximumOrderValue: 'Maximum Order Value',
  message: 'Message',
  shipTos: 'Ship-to Account(s)',
  nameRequiredForApproval: 'Name Required for Approval',
  newPassword: 'New Password',
  orderNotes: 'Order Notes',
  password: 'Password',
  paymentPreference: 'Payment Preference',
  phone: 'Phone Number',
  phoneNumber: 'Phone Number',
  postalCode: 'Postal Code',
  province: 'Province',
  punchoutDefaultUser: 'PunchOut Default User',
  punchoutUsername: 'Username',
  quickList: 'Quick List',
  quickListHeaderId: 'Quick List',
  quickListPersistId: 'Quick List',
  repEmail: 'Representative Email Address',
  repName: 'Representative Name',
  shipTo: 'Ship-to Account',
  siteName: 'Site Name',
  smsNotification: 'By checking it you are opting in to receive SMS notification(s) for updates on online orders',
  state: 'State',
  subscribeToUpdates: 'Subscribe to Updates',
  termsAndConditions: 'Please check the box to accept the above terms.',
  unitNumber: 'Unit #',
  updateMultipleShipTos: 'Update Multiple Ship-to Accounts',
  username: 'Username / Email',
  usersToRegister: 'Users to Register',
  valAccountNumber: 'Vallen Account Number (If Known)',
  valShipTo: 'Vallen Account Ship-to (If Known)',
  personalCode: 'Personal Code',
};

export const FieldHints = {
  address1: 'Street and number, p.o. box, c/o.',
  address2: 'Apartment, suite, building, floor, etc.',
  addressName: 'Ex: home address or office address',
  billAddress1: 'Street and number, p.o. box, c/o.',
  billAddress2: 'Apartment, suite, building, floor, etc.',
  billAddressName: 'Ex: home address or office address',
  city: 'Auto-populated based on the address',
  contactMobileNumber: '10-digits without spaces or special characters',
  contactPhoneNumber: '10-digits without spaces or special characters',
  customerPoNumber: 'Enter the po # linked to this order',
  emailCc: 'Comma separated email addresses',
  instructions: 'Maximum 30 characters',
  postalCode: 'Auto-populated based on the address',
  province: 'Auto-populated based on the address',
  punchoutUsername: 'Alphanumeric, special characters: ( _ , -, . ) without spaces',
  siteName: 'Company name or site name',
};
