import { Injectable } from '@angular/core';
import { DashboardWidget, WidgetId, WidgetType } from './widget/widget.model';
import { dashboardModel } from 'src/app/shared/consts/dashboard-data-model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor() {}

  public widgetIds: string[] = [];
  public widgets: DashboardWidget[] = [
    {
      id: WidgetId.OVERVIEW,
      title: 'Account Overview',
      type: WidgetType.INFO,
      description: 'Your name, contact and ship-to account details.',
      route: '/account/accountsettings',
      linkText: 'View account settings',
    },
    {
      id: WidgetId.ORDERS_MONTHLY,
      title: 'Monthly Orders',
      type: WidgetType.BARCHART,
      description: 'Recent 6 months orders with invoiced and paid statuses.',
      route: '/order',
      linkText: 'View order history',
    },
    {
      id: WidgetId.ORDERS_ACTIVE,
      title: 'Active Orders',
      type: WidgetType.TABLE,
      description: 'All ongoing orders.',
      route: '/order',
      linkText: 'View all orders',
      data: dashboardModel.activeOrders,
    },
    {
      id: WidgetId.ORDERS_APPROVAL,
      title: 'Orders Awaiting Approval',
      type: WidgetType.TABLE,
      description: 'Orders that are awaiting an approval to be processed.\n',
      route: '/ordersawaitingapproval',
      linkText: 'View all orders awaiting approval',
      data: dashboardModel.orderApprovals,
    },
    {
      id: WidgetId.QUOTES,
      title: 'Quotes',
      type: WidgetType.TABLE,
      description: 'Your ongoing quotes.',
      route: '/quotes',
      linkText: 'View all quotes',
      data: dashboardModel.quotes,
    },
    {
      id: WidgetId.LISTS_USER,
      title: 'Product Lists',
      type: WidgetType.TABLE,
      description: 'Your product lists.',
      route: '', // TODO - link to latest list
      linkText: 'View latest product list',
      data: dashboardModel.productLists,
    },
    {
      id: WidgetId.LISTS_SHARED,
      title: 'Shared Lists',
      type: WidgetType.TABLE,
      description: 'Your shared lists.',
      route: '', // TODO - link to latest list
      linkText: 'View latest shared list',
      data: dashboardModel.sharedLists,
    },
    {
      id: WidgetId.LISTS_PUBLIC,
      title: 'Public Lists',
      type: WidgetType.TABLE,
      description: 'Your public lists.',
      route: '', // TODO - link to latest list
      linkText: 'View latest public list',
      data: dashboardModel.publicLists,
    },
  ];

  activeWidgets() {
    return this.widgets.filter((w) => this.widgetIds.includes(w.id));
  }

  widget(id: string) {
    const widget = this.widgets.filter((w) => w.id === id);
    return widget ? widget[0] : null;
  }

  setWidgetIds(ids?: string[]) {
    if (!ids) {
      ids = [];
    }
    if (!ids.includes(WidgetId.OVERVIEW)) {
      ids.unshift(WidgetId.OVERVIEW);
    }
    this.widgetIds = ids;
  }
}
