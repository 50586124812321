import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';

export class CustomValidator {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }

      const valid = regex.test(control.value);

      return valid ? null : error;
    };
  }

  // TODO - This doesn't work quite right depending on sequence of changes - might be good enough though
  static passwordMatchValidator(source: string, target: string): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      // @ts-ignore
      const sourceControl = control.parent?.controls[source];
      // @ts-ignore
      const targetControl = control.parent?.controls[target];
      if (targetControl.value !== sourceControl.value) {
        targetControl.setErrors({ mustMatch: true });
        return of(targetControl.errors);
      }

      return of(null);
    };
  }

  // TODO - This doesn't work quite right depending on sequence of changes - might be good enough though
  static passwordsCannotMatchValidator(source: string, target: string): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      // @ts-ignore
      const sourceControl = control.parent?.controls[source];
      // @ts-ignore
      const targetControl = control.parent?.controls[target];
      if (targetControl.value === sourceControl.value) {
        targetControl.setErrors({ cannotMatch: true });
        return of(targetControl.errors);
      } else if (targetControl.hasError('cannotMatch')) {
        targetControl.setErrors({ cannotMatch: null });
        targetControl.updateValueAndValidity();
      }
      return of(null);
    };
  }
}
