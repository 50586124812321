import * as UserActions from './user.actions';
import { logout } from '../authentication/auth.actions';
import { UserModel } from '../../services/user/user.model';
import { UserShoppingContext } from '../../services/customer-company/user-shopping-context.model';
import { createReHydrationReducer } from '../hydration.reducer';
import { on } from '@ngrx/store';
import { ShoppingContextPunchout } from '../../../types/punchout.model';

// Define the scoped state of this reducer
export interface UserState {
  user?: UserModel | null;
  shoppingContext: UserShoppingContext | ShoppingContextPunchout | null;
  defaultShoppingContext?: UserShoppingContext | null;
}

// Set initial state values
const initialState: UserState = {
  user: null,
  shoppingContext: null,
  defaultShoppingContext: null,
};

/**
 * Creat your reducer
 *
 * Pass the storage key the reducer needs to use.
 *
 * Then pass the initial store state for user
 *
 * Use the on() method to define your actions, it also takes
 * a callback function, we pass state and action to it, then use spread
 * state and define user with the action attached to it.
 */
export const userReducer = createReHydrationReducer(
  'user',
  initialState,
  on(UserActions.setUser, (state, action) => ({
    ...state,
    ...action,
  })),
  on(logout, (state) => ({
    ...state,
    user: null,
    shoppingContext: null,
    defaultShoppingContext: null,
  }))
);
