import { ValidationErrors } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';

export interface ErrorsModel {
  validationErrorType?: ValidationErrors | null;
  responseErrorType?: string;
  control?: string;
  code?: string;
  status?: number;
  message?: string[] | string;
  propertyPath?: string | null;
  type?: string | null;
}

export interface ApiErrorResponse {
  ok?: boolean;
  status?: number;
  statusText?: string;
  message?: string;
}

export const responseStatuses = new HttpResponse();

export const validationTypes = [
  'cannotMatch',
  'email',
  'hasLowerCase',
  'hasNumber',
  'hasSpecialCharacters',
  'hasUpperCase',
  'isEmailList',
  'isNumber',
  'isPostalCode',
  'max',
  'maxlength',
  'min',
  'minlength',
  'mustMatch',
  'required',
];
