export interface Month {
  month: string;
  number: string;
}

export const getLastSixMonths = (): Month[] => {
  const months: Month[] = Array.from({ length: 6 }, (_, i) => {
    const today = new Date();
    today.setMonth(today.getMonth() - i);
    const month = today.toLocaleString('default', { month: 'short' });
    const monthNumber = (today.getMonth() + 1).toString().padStart(2, '0');
    return { month, number: monthNumber };
  });

  return months.reverse();
};
