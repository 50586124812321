import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { FormAlertsComponent } from './components/form-alerts/form-alerts.component';
import { NavLinkComponent } from './components/nav-link/nav-link.component';
import { RouterModule } from '@angular/router';
import { ModalComponent } from './components/modal/modal.component';
import { HtmlEntityDecodePipe } from './pipes/html-entity-decode/html-entity-decode.pipe';
import { NgbDropdownModule, NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SpecsTableComponent } from './components/spec-table/specs-table.component';
import { FallbackImageDirective } from './directives/fallback-image.directive';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ProductStatusComponent } from './components/products/product-status/product-status.component';
import { ProductPriceComponent } from './components/products/product-price/product-price.component';
import { ProductQuantityComponent } from './components/products/product-quantity/product-quantity.component';
import { ProductDocumentsComponent } from './components/products/product-documents/product-documents.component';
import { ProductAttachmentModalComponent } from './components/products/product-attachment-modal/product-attachment-modal.component';
import { SupportPhoneComponent } from './components/support-phone/support-phone.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemListComponent } from './components/products/item-list/item-list.component';
import { FormInputComponent } from './components/forms/form-input/form-input.component';
import { DtSearchComponent } from './components/datatables/dt-search/dt-search.component';
import { PhonePipe } from './pipes/phone/phone.pipe';
import { IdFormatter } from './pipes/id-formatter/id-formatter.pipe';
import { NaPipe } from './pipes/na/na.pipe';
import { BooleanTextPipe } from './pipes/boolean-text/boolean-text.pipe';
import { DtSelectComponent } from './components/datatables/dt-select/dt-select.component';
import { AddressPipe } from './pipes/address/address.pipe';
import { CustomerAddressFormComponent } from './components/forms/customer-address-form/customer-address-form.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { WidgetTableComponent } from '../modules/dashboard/widget/widget-table/widget-table.component';
import { ProductListFormComponent } from './components/products/product-list-form/product-list-form.component';
import { ItemSummaryComponent } from './components/products/item-summary/item-summary.component';
import { ItemListPaginationComponent } from './components/products/item-list-pagination/item-list-pagination.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { OrderStatusPipe } from './pipes/order-status/order-status.pipe';
import { OrderTypePipe } from './pipes/order-type/order-type.pipe';
import { OrderNumberPipe } from './pipes/order-number/order-number.pipe';
import { ProductFacetListComponent } from './components/products/product-facet-list/product-facet-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ShippingBillingComponent } from './components/shipping-billing/shipping-billing.component';
import { ExportActionsComponent } from './components/export-actions/export-actions.component';
import { AddProductsFormComponent } from './components/products/add-products-form/add-products-form.component';
import { WarehouseNamePipe } from './pipes/warehouse-name/warehouse-name.pipe';
import { BaseSubscriptionComponent } from './components/base-subscription/base-subscription.component';
import { AddVendorsFormComponent } from './components/add-vendors-form/add-vendors-form.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ProductAvailabilityComponent } from './components/products/product-availability/product-availability.component';
import { ImportExportComponent } from './components/import-export/import-export.component';
import { PersonalCodeComponent } from './components/products/personal-code/personal-code.component';
import { PdfTableContentComponent } from './components/import-export/pdf-table-content/pdf-table-content.component';
import { ExportPdfComponent } from './components/import-export/export-pdf/export-pdf.component';
import { PickupWarehouseComponent } from './components/pickup-warehouse/pickup-warehouse.component';
import { ShipPickupWhenComponent } from './components/ship-pickup-when/ship-pickup-when.component';
import { ItemListActionsComponent } from './components/products/item-list-actions/item-list-actions.component';
import { PageTypePipe } from './pipes/page-type/page-type.pipe';
import { ItemTagsComponent } from './components/products/item-tags/item-tags.component';
import { OrderApprovalComponent } from '../modules/cart/order-approval-request/order-approval-request.component';
import { DisableQuantityPipe } from './pipes/disable-quantity-pipe/disable-quantity-pipe.pipe';
import { OrderFilterComponent } from '../modules/order/order-filter/order-filter.component';
import { OrderTableComponent } from '../modules/order/order-table/order-table.component';

@NgModule({
  declarations: [
    OrderTableComponent,
    OrderFilterComponent,
    PageTitleComponent,
    FormAlertsComponent,
    NavLinkComponent,
    ModalComponent,
    HtmlEntityDecodePipe,
    SpecsTableComponent,
    FallbackImageDirective,
    LoadingSpinnerComponent,
    ProductStatusComponent,
    ProductPriceComponent,
    ProductQuantityComponent,
    ProductDocumentsComponent,
    ProductAttachmentModalComponent,
    SupportPhoneComponent,
    ItemListComponent,
    FormInputComponent,
    DtSearchComponent,
    PhonePipe,
    IdFormatter,
    NaPipe,
    BooleanTextPipe,
    DtSelectComponent,
    DtSearchComponent,
    AddressPipe,
    CustomerAddressFormComponent,
    BarChartComponent,
    WidgetTableComponent,
    ProductListFormComponent,
    ItemSummaryComponent,
    ItemListPaginationComponent,
    NoResultsComponent,
    OrderStatusPipe,
    OrderTypePipe,
    OrderNumberPipe,
    ProductFacetListComponent,
    ShippingBillingComponent,
    ExportActionsComponent,
    AddProductsFormComponent,
    WarehouseNamePipe,
    BaseSubscriptionComponent,
    AddVendorsFormComponent,
    ProductAvailabilityComponent,
    ImportExportComponent,
    PersonalCodeComponent,
    PdfTableContentComponent,
    ExportPdfComponent,
    PickupWarehouseComponent,
    ShipPickupWhenComponent,
    ItemListActionsComponent,
    PageTypePipe,
    ItemTagsComponent,
    OrderApprovalComponent,
    DisableQuantityPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    FormsModule,
    NgChartsModule,
    NgbDropdownModule,
    NgbModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    OrderTableComponent,
    OrderFilterComponent,
    PageTitleComponent,
    FallbackImageDirective,
    FormAlertsComponent,
    NavLinkComponent,
    ModalComponent,
    HtmlEntityDecodePipe,
    SpecsTableComponent,
    LoadingSpinnerComponent,
    NaPipe,
    PickupWarehouseComponent,
    PhonePipe,
    IdFormatter,
    ProductStatusComponent,
    ProductPriceComponent,
    ProductQuantityComponent,
    ProductDocumentsComponent,
    SupportPhoneComponent,
    ItemListComponent,
    NoResultsComponent,
    FormInputComponent,
    DtSearchComponent,
    DtSelectComponent,
    CustomerAddressFormComponent,
    BarChartComponent,
    WidgetTableComponent,
    ProductListFormComponent,
    ItemSummaryComponent,
    ItemListPaginationComponent,
    OrderStatusPipe,
    OrderTypePipe,
    OrderNumberPipe,
    AddressPipe,
    ProductFacetListComponent,
    AddProductsFormComponent,
    ExportActionsComponent,
    WarehouseNamePipe,
    AddVendorsFormComponent,
    ImportExportComponent,
    PersonalCodeComponent,
    ShipPickupWhenComponent,
    ItemListActionsComponent,
    ItemTagsComponent,
    OrderApprovalComponent,
  ],
  providers: [
    DatePipe,
    NaPipe,
    PhonePipe,
    IdFormatter,
    BooleanTextPipe,
    AddressPipe,
    HtmlEntityDecodePipe,
    OrderStatusPipe,
    OrderTypePipe,
    OrderNumberPipe,
  ],
})
export class SharedModule {}
