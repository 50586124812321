import { ScrollbarHelper } from '@swimlane/ngx-datatable';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ServerScrollBarHelper extends ScrollbarHelper {
  override width: number;

  constructor(@Inject(DOCUMENT) document: any) {
    super(document);
    this.width = 16; // use default value
  }

  override getWidth(): number {
    return this.width;
  }
}
