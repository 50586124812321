import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs';
import { Breadcrumb } from './breadcrumb.model';
import { BreadcrumbsService } from './breadcrumbs.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';

@Component({
  selector: 'val-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent extends BaseSubscriptionComponent implements OnInit {
  class: string;
  private previousUrl: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public breadcrumbService: BreadcrumbsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.breadCrumbData();
  }

  breadCrumbData(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let currentRoute = this.activatedRoute.root;
          let routePath = '';
          while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
            if (currentRoute.outlet === PRIMARY_OUTLET) {
              routePath += '/' + currentRoute.snapshot.url.map((segment) => segment.path).join('/');
            }
          }
          return { route: currentRoute, path: routePath };
        }),
        filter(({ path }) => {
          const isPathChanged = this.previousUrl !== path;
          this.previousUrl = path;
          return isPathChanged;
        })
      )
      .pipe(takeUntil(this.destroyed))
      .subscribe(({ route }) => {
        this.class = route.snapshot.data['breadcrumbClass'] || '';
        const breadcrumb: string | boolean | Breadcrumb[] = route.snapshot.data['breadcrumb'];
        const crumbs = this.buildBreadcrumbs(breadcrumb, route);
        this.breadcrumbService.setBreadcrumbs(crumbs);
      });
  }

  private buildBreadcrumbs(breadcrumbData: string | boolean | Breadcrumb[], route: ActivatedRoute): Breadcrumb[] {
    const crumbs = [];
    if (breadcrumbData) {
      const title = route.snapshot.routeConfig?.title;
      if (breadcrumbData === true && title) {
        crumbs.push(new Breadcrumb(title, ''));
      } else if (breadcrumbData instanceof Array) {
        breadcrumbData.forEach((c) => {
          if (c instanceof Breadcrumb) {
            crumbs.push(c);
          } else {
            crumbs.push(new Breadcrumb(c, ''));
          }
        });
      } else if (breadcrumbData && breadcrumbData !== true) {
        crumbs.push(new Breadcrumb(breadcrumbData.toString(), ''));
      }
    }
    return crumbs;
  }
}
