<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classname"
  [autohide]="true"
  [delay]="toast.delay || 3000"
  (hidden)="toastService.remove(toast)"
  [attr.data-testid]="DATA_TEST_ID.toast.message"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text><i class="val-icon toast-icon" [class]="toast.classname"></i>{{ toast.textOrTpl }}</ng-template>
</ngb-toast>
