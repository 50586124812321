import { createAction, props } from '@ngrx/store';
import { ProductList } from 'src/app/types/product-list.model';

export const setProductLists = createAction(
  '[Set ProductList] ProductList',
  props<{
    activeProductList: ProductList | null;
    sharedProductList: ProductList | null;
    publicProductList: ProductList | null;
  }>()
);
