import { ApiOptions } from 'src/app/core/services/api/api.model';

export interface OrderHistoryRequest {
  // TODO - verify that we need all of these properties
  companyNumber?: number;
  customerNumbers?: string;
  startDate?: string;
  endDate?: string;
  shipTo?: string;
  transType?: string;
  orderNumbers?: string;
  poNumber?: string;
  filter?: string;
  userId?: string | number;
  stageCd?: string;
  transportMode?: string;
  page?: number;
  itemsPerPage?: number;
  results?: OrderHistory[];
  apiOptions?: ApiOptions;
  sourcePros?: string | null;
}

export interface OrderHistory {
  canceldt: string;
  custno: number | string;
  custpo: string;
  employeeid: number;
  employeename: string;
  enterdt: string;
  invoice?: boolean;
  invoicedt: string;
  nolineitem: number;
  orderno: number | string;
  ordersuf: number;
  shipdt: string;
  shipto: string;
  sourcedb: string;
  sourcepros: string;
  stagecd: number | string;
  totalrecords: number;
  totinvamt: number;
  totlineord: number;
  transtype: string;
  whse: string;
}

export const orderStatusTypes = [
  { label: '0', value: 'Entered' },
  { label: '1', value: 'Ordered' },
  { label: '2', value: 'Picked' },
  { label: '3', value: 'Shipped' },
  { label: '4', value: 'Invoiced' },
  { label: '5', value: 'Paid' },
  { label: '9', value: 'Cancelled' },
];

export enum OrderStatus {
  'ENTERED' = 'Entered',
  'ORDERED' = 'Ordered',
  'PICKED' = 'Picked',
  'SHIPPED' = 'Shipped',
  'INVOICED' = 'Invoiced',
  'PAID' = 'Paid',
  'CANCELLED' = 'Cancelled',
}

export const orderTypes = [
  { label: 'o', value: 'Order' },
  { label: 'so', value: 'Order' },
  { label: 'q', value: 'Quote' },
  { label: 'qu', value: 'Quote' },
  { label: 'b', value: 'Backorder' },
  { label: 'd', value: 'Direct' },
  { label: 'do', value: 'Direct' },
  { label: 'f', value: 'Future' },
  { label: 'fo', value: 'Future' },
  { label: 's', value: 'Standing' },
  { label: 'st', value: 'Standing' },
  { label: 'k', value: 'Blanket' },
  { label: 'bl', value: 'Blanket' },
  { label: 'c', value: 'Counter Sale' },
  { label: 'cs', value: 'Counter Sale' },
  { label: 'cr', value: 'Correction' },
  { label: 'r', value: 'Return' },
  { label: 'rm', value: 'Return' },
];

export enum OrderTypes {
  'o' = 'Order',
  'so' = 'Order',
  'q' = 'Quote',
  'qu' = 'Quote',
  'b' = 'Backorder',
  'd' = 'Direct',
  'do' = 'Direct',
  'f' = 'Future',
  'fo' = 'Future',
  's' = 'Standing',
  'st' = 'Standing',
  'k' = 'Blanket',
  'bl' = 'Blanket',
  'c' = 'Counter Sale',
  'cs' = 'Counter Sale',
  'cr' = 'Correction',
  'r' = 'Return',
  'rm' = 'Return',
}
