import { AppStateInterface } from '../../../types/app-state.interface';
import { createSelector } from '@ngrx/store';
import { CartState } from '../cart/cart.reducer';
import { Cart } from '../../../types/cart.model';

/**
 * Creat select feature: In this case we bring in our global app state
 * interface
 * @param state
 *
 * since we added cart to our app state we can grab it using state.cart.
 */
export const selectFeature = (state: AppStateInterface) => state.cart;

/**
 * Create your selector
 *
 * Pass your selectFeature, and your projector function takes state and
 * returns the cart state.
 */
export const cartSelector = createSelector(selectFeature, (state) => state.cart);

export const selectCartState = (state: AppStateInterface) => state.cart;

/**
 * Select the cart object from the cart state.
 */
export const selectCart = createSelector(selectCartState, (state: CartState) => state.cart);

/**
 * Dynamic selector to select specific parts of the cart based on a key.
 * @param key The key of the cart property to select.
 */
export const selectCartProperty = (key: keyof Cart) =>
  createSelector(selectCart, (cart) => (cart ? cart[key] : undefined));
