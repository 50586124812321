import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { KontentService } from '../kontent/kontent.service';

type BranchAddress = {
  street: string;
  cityStateZip: string;
};

type SocialLink = {
  label: string;
  link: string;
  asset: string;
};

@Component({
  selector: 'val-footer',
  templateUrl: './footer.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  footerData: any = {};
  branchAddress: BranchAddress = {
    street: '',
    cityStateZip: '',
  };
  socialLinks: SocialLink[] = [];
  footerNavMenu: any[] = [];

  constructor(private viewport: ViewportScroller, private kontentService: KontentService) {}

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.kontentService.getGlobalFooter().then((res: any) => {
      this.footerData = res.elements;

      this.footerData.copyrightText.value = this.footerData.copyrightText.value.replace('{0}', this.currentYear);
      this.branchAddress = this.formatAddress(this.footerData.footerBranch.linkedItems[0]);
      this.formatSocialLinks(this.footerData.socialMediaList.linkedItems);
      this.footerNavMenu = this.footerData.footerNavigationMenu.linkedItems;
    });
  }

  scrollToTop(): void {
    this.viewport.scrollToPosition([0, 0]);
  }

  private formatAddress(address: any) {
    const formattedAddress: BranchAddress = {
      street: address.address,
      cityStateZip: `${address.city}, ${address.province} ${address.postalCode}`,
    };
    return formattedAddress;
  }

  private formatSocialLinks(socialLinks: any[]) {
    socialLinks.forEach((link: any) => {
      this.socialLinks.push({
        ...link,
        asset: `/assets/img/${link.label.toLowerCase()}.svg`,
      });
    });
  }
}
