import { Component, Input, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { FormOption } from '../../../../types/form-option.model';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { FieldHints, FieldLabels } from '../data/fieldDefinitions';
import { FormService } from '../../../../core/services/form/form.service';
import { environment } from '../../../../../environments/environment';
import { WINDOW } from 'src/app/app.module';

@Component({
  selector: 'val-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent implements OnInit {
  @Input() groupClass: string; // optional, class to 'form-group'
  @Input() form: FormGroup;
  @Input() hint: string; // optional, overrides predefined hint
  @Input() id: string;
  @Input() label: string; // optional, overrides predefined label
  @Input() options: any[] | string[] | FormOption[];
  @Input() placeholder: string;
  @Input() readonly: boolean;
  @Input() selectSearch = false;
  @Input() value: string;
  @Input() type: string; // optional, defaults to 'text';
  @Input() footnote?: string;
  @Input() textAreaRows?: number = 1;
  @Input() testId?: string;

  fieldLabel = '';
  inputHint = '';
  errors: string[] = [];
  errorMessages: any;
  required = false;
  fieldType = 'text';
  autocomplete: string;
  selectedOptions: string[] = [];
  isStandardInput: boolean;
  customSelectValue: string | number = '';
  recaptchaSiteKey: string;

  constructor(public formService: FormService, @Inject(WINDOW) private window: Window) {
    this.recaptchaSiteKey = environment.recaptchaSiteKey;
  }

  ngOnInit(): void {
    // @ts-ignore
    this.fieldLabel = this.label || FieldLabels[this.id] || '';
    this.groupClass = this.groupClass || '';
    if (this.footnote) {
      this.groupClass += ' b0';
    }
    // @ts-ignore
    this.inputHint = typeof this.hint !== 'undefined' ? this.hint : FieldHints[this.id] || '';
    this.required = this.formService.initRequiredFormControl(this.form.controls[this.id]);
    this.fieldType = this.type || 'text';
    if (this.isSelect() || this.isMultiSelect()) {
      if (!this.options) {
        this.options = [];
      }
      // If options is string[], convert it to FormOption[]
      this.options = this.options.map((o: number | string | FormOption) => {
        return o.hasOwnProperty('value') ? o : { value: o, label: o };
      });
    }
    this.isStandardInput = this.isTextInput() || this.isTextArea() || this.isSelect() || this.isMultiSelect();
    this.placeholder = this.placeholder || '';
    this.autocomplete = `new-${this.id}`; // this should disable autocomplete for most forms

    this.formService.watchErrors(this.form, this.id, this.errors);
  }

  initLabel(id: number) {
    if (this.options) {
      for (const x of this.options) {
        if (x.value === id) {
          return x.label;
        }
      }
    }
  }

  @ViewChild('instance', { static: false }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) => (term === '' ? this.getAllResults() : this.getResults(term as string)))
    );
  };

  selectedItem(item: string) {
    const notEqual = this.form.value[this.id] !== item && parseInt(item.substring(0, item.indexOf(' ')));
    notEqual && this.form.controls[this.id].setValue(parseInt(item.substring(0, item.indexOf(' '))));
  }

  getAllResults() {
    const results = [];
    for (const x of this.options) {
      results.push(x.label);
    }
    return results;
  }

  getResults(term: string) {
    const results = [];
    for (const x of this.options) {
      const label = x.label;
      label.toLowerCase().includes(term.toLowerCase()) && results.push(x.label);
    }
    return results;
  }

  selectOptions(type: string) {
    this.selectedOptions.map((x) => {
      type === 'Selected' && !this.form.value[this.id].includes(x) && this.form.value[this.id].push(x);
      type === 'Chosen' &&
        this.form.value[this.id].includes(x) &&
        this.form.value[this.id].splice(this.form.value[this.id].indexOf(x), 1);
      return;
    });
  }

  selectOption(type: string) {
    this.selectedOptions = [];
    const list =
      type === 'Selected'
        ? Array.from(this.window?.document.querySelectorAll(`#${this.id}-Selected`) as NodeListOf<HTMLOptionElement>)
        : Array.from(this.window?.document.querySelectorAll(`#${this.id}-Chosen`) as NodeListOf<HTMLOptionElement>);

    // TODO - Refactor this code.
    if (list !== null && list.length > 0) {
      list.map((x: HTMLOptionElement) => {
        !this.selectedOptions.includes(x.value) && x.selected && this.selectedOptions.push(x.value);
      });
    }
  }

  selectAll() {
    this.form.controls[this.id].setValue(this.options.map((o) => o.value));
  }

  deselectAll() {
    this.form.controls[this.id].setValue([]);
  }

  optionLabel(value: string) {
    const option = this.options.filter((o) => o.value === value);
    return option[0] ? option[0].label : value;
  }

  public isTextInput() {
    return ['text', 'number', 'email', 'password', 'search'].includes(this.fieldType);
  }

  public isSelect() {
    return this.fieldType === 'select';
  }

  public isMultiSelect() {
    return this.fieldType === 'multiSelect';
  }

  public isCheckBox() {
    return this.fieldType === 'checkbox';
  }

  public isRadio() {
    return this.fieldType === 'radio';
  }

  public isTextArea() {
    return this.fieldType === 'textarea';
  }

  public isPasswordField() {
    return this.fieldType === 'password';
  }

  public trimText(): void {
    const value = this.form.value[this.id];
    if (value != null) {
      this.form.controls[this.id].setValue(value.trim());
    }
  }
}
