export const DATA_TEST_ID = {
  accountSelect: {
    customerLink: 'account-selection-customer', // suffix is custNo || custNo-shipTo
    defaultLink: 'account-selection-default', // suffix is custNo || custNo-shipTo
    selectLink: 'account-selection-select', // suffix is custNo || custNo-shipTo
  },
  articles: {
    list: {
      categoryLink: 'article-category-link', // uses suffix
      article: 'article-list', // uses suffix
      image: 'article-list-image', // uses suffix
      title: 'article-list-title', // uses suffix
      button: 'article-list-button', // uses suffix
    },
    details: {
      back: 'article-details-back-button',
    },
  },
  connectWithUs: {
    form: 'connect-with-us-form',
    name: 'connect-with-us-name',
    company: 'connect-with-us-company',
    email: 'connect-with-us-email',
    phone: 'connect-with-us-phone',
    optIn: 'connect-with-us-opt-in',
    recaptcha: 'connect-with-us-recaptcha',
    subscribe: 'connect-with-us-subscribe',
  },
  contact: {
    getDirections: 'contact-get-directions',
    emailLink: 'contact-email-link',
    form: {
      formName: 'contact-form',
      name: 'contact-form-name',
      email: 'contact-form-email',
      phone: 'contact-form-phone',
      city: 'contact-form-city',
      inquiry: 'contact-form-inquiry',
      message: 'contact-form-message',
      recaptcha: 'contact-form-recaptcha',
      submit: 'contact-form-submit',
    },
  },
  cookieConsentBtn: 'cookie-consent-btn',
  createAccount: {
    b2bBtn: 'create-business-account-btn',
    b2cBtn: 'create-guest-account-btn',
  },
  dashboard: {
    banner: 'dashboard-banner', //uses suffix
    bannerLink: 'dashboard-banner-link', //uses suffix
    addWidget: 'dashboard-add-widget',
  },
  dashboardModal: {
    save: 'dashboard-modal-save',
  },
  header: {
    cart: 'header-cart',
    home: 'header-home',
    link: 'header-link',
    locations: 'header-location',
    logInBtn: 'header-login-btn',
    logOutBtn: 'header-logout-btn',
    mobileSearch: 'header-mobile-search',
    myAccount: 'header-my-account',
    quickLink: {
      change: 'header-quicklink-change-account',
      connectWithUs: 'header-quicklink-connect-with-us',
      newCustomer: 'header-quicklink-new-customer',
      upgradeAccount: 'header-quicklink-upgrade-account',
      vallenUsa: 'header-quicklink-vallen-usa',
    },
  },
  kontent: {
    carousel: {
      image: 'kontent-carousel-image', // uses suffix
      title: 'kontent-carousel-title', // uses suffix
    },
    fancyImageBanner: {
      actionUrl: 'kontent-fancy-image-banner-action-url',
    },
    generic: {
      block: 'kontent-generic-block', // uses suffix
    },
    page: {
      button: 'kontent-page-button',
      iframe: 'kontent-page-iframe',
      imageLink: 'kontent-page-image-link',
      image: 'kontent-page-image',
    },
    slider: {
      container: 'kontent-slider-container',
      image: 'kontent-slider-image',
      link: 'kontent-slider-link',
    },
    tinyBanner: {
      actionUrl: 'kontent-tiny-banner-action-url',
      link: 'kontent-tiny-banner-link',
    },
  },
  loginForm: {
    forgotPassword: 'login-form-forgot-password',
    password: 'login-form-password',
    rememberMe: 'login-form-remember-me',
    submitBtn: 'login-form-login-btn',
    username: 'login-form-username',
  },
  navbar: {
    dashboard: 'navbar-dashboard',
    dropDown: 'navbar-drop-down',
    link: 'navbar-link',
    locations: 'navbar-locations',
    logout: 'navbar-logout',
    phone: {
      number: 'navbar-phone-number',
      supportNumber: 'navbar-phone-support-number',
    },
    productList: {
      cart: 'navbar-product-list-cart',
      edit: 'navbar-product-list-edit',
      link: 'navbar-product-list-link',
    },
    products: {
      category: 'navbar-products-category',
      categoryLevel: 'navbar-products-category-level',
      dropdown: 'navbar-products-dropdown',
      subCategoryDropdown: 'navbar-products-sub-category-dropdown',
      viewAllCategories: 'navbar-products-view-all-categories',
      viewAllProducts: 'navbar-products-view-all-products',
      imageBanner: 'navbar-products-image-banner',
    },
    solutions: {
      dropdown: 'navbar-solutions-dropdown',
      link: 'navbar-solutions-link',
    },
    subLink: 'navbar-sub-link',
    toggleNavigation: 'navbar-toggle-navigation',
  },
  noFooter: {
    logoHomeRedirect: 'no-footer-logo-home-redirect',
    termsAndConditions: 'no-footer-terms-and-conditions',
  },
  product: {
    detail: {
      addToCart: 'product-detail-add-to-cart',
      addToList: 'product-detail-add-to-list',
      closeModal: 'product-detail-close-modal',
      imageModalLink: 'product-detail-image-link',
      slide: 'product-detail-slide',
      toggleImageModal: 'product-detail-toggle-image-modal',
      video: 'product-detail-video-link',
    },
    itemList: {
      addToCart: 'product-item-list-add-to-cart',
      checkbox: 'product-item-list-checkbox',
      idLink: 'product-item-list-id-link',
      imageLink: 'product-item-list-image-link',
      personalCode: 'product-item-list-personal-code',
      productNotes: 'product-item-list-product-notes',
      quantityRemoveAlert: 'product-item-list-quantity-Remove-alert',
      quantityUpdatedAlert: 'product-item-list-quantity-updated-alert',
      removeFromCart: 'product-item-list-remove-from-cart',
      titleLink: 'product-item-list-title-link',
    },
    personalCode: {
      close: 'product-personal-code-create',
      create: 'product-personal-code-create',
      input: 'product-personal-code-create',
    },
    price: {
      loading: 'product-price-loading',
      uom: 'product-price-uom',
      unavailable: 'product-price-unavailable',
      value: 'product-price-value',
    },
    quantity: {
      error: 'product-quantity-error',
      lineItemInput: 'product-quantity-line-item-input',
      productInput: 'product-quantity-product-input',
    },
    total: {
      loading: 'product-total-loading',
      value: 'product-total-value',
    },
    downloadDocuments: 'product-download-documents',
    warehouseAvailability: 'product-warehouse-availability',
    listForm: {
      nameInput: 'product-list-form-list-name-input',
      radio: 'product-list-form',
      select: 'product-list-form-select',
    },
  },
  search: {
    button: 'val-search-button',
    categoryLink: 'val-search-recent-category-link',
    input: 'val-search-input',
    productResults: {
      link: 'val-search-product-results-link',
      viewAll: 'val-search-product-results-view-all',
    },
    recentQueryLink: 'val-search-recent-query-link',
  },
  supplier: {
    link: 'supplier-link',
    linkButtonFilter: 'supplier-link-button-filter',
    noLink: 'supplier-no-link',
  },
  toast: {
    message: 'toast-message',
  },
  valWidget: {
    close: 'val-widget-close',
  },
  itemSummary: {
    addToCart: 'add-to-cart-btn',
    addToList: 'add-to-list-btn',
    checkout: 'checkout-btn',
    deleteQuote: 'cancel-quote-btn',
    guestCheckout: 'guest-checkout-btn',
    loginToCheckout: 'login-to-checkout-btn',
    proceedToPay: 'proceed-to-pay-btn',
    requestQuote: 'request-quote-btn',
    restrictionCheckout: 'restriction-checkout-btn',
    restrictionMessage: 'restriction-message-btn',
    reviewOrder: 'review-order-btn',
    saveList: 'save-list-btn',
    submitOrder: 'submit-order-btn',
    submitQuote: 'submit-quote-btn',
  },
};
