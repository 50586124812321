import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { LoadingSpinnerService } from '../../../shared/components/loading-spinner/loading-spinner.service';
import { HTTP_STATUS_CODE_TEXT } from '../../../shared/consts/http-status-code-text';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private loading: LoadingSpinnerService, private auth: AuthenticationService, private router: Router) {}

  // maybe add punchout logic
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Make sure loading spinner is disabled on error, so users don't get stuck
        this.loading.stop();

        // Error is 401, user's token has expired, and user has selected 'keep me logged in'
        if (
          error.status === 401 &&
          this.auth.isTokenExpired() &&
          this.auth.checkRefreshToken() &&
          this.auth.isLoggedIn()
        ) {
          return this.handle401Error(request, next);
        } else if (error.status === 401) {
          // When to call invalidate vs modal (auth guard)
          let currentState: RouterStateSnapshot = this.router.routerState.snapshot;
          let currentRoute: ActivatedRouteSnapshot = currentState.root;

          // Initialize protectedRoute as false
          let protectedRoute = false;

          // Traverse through the route hierarchy
          do {
            if (currentRoute.data?.['protected']) {
              protectedRoute = true;
              break;
            }
            currentRoute = currentRoute.firstChild as ActivatedRouteSnapshot;
          } while (currentRoute);

          // Decide action based on protectedRoute value
          protectedRoute ? this.auth.openLoginModal() : this.auth.invalidate();
        }

        let errorMessage = this.getServerErrorMessage(error);

        // If it is not an authentication error, just return the error message
        return throwError(() => errorMessage);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    // If not already refreshing token make api call
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);

      return this.auth.refreshToken().pipe(
        switchMap((res: any) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(res);
          setTimeout(() => {
            this.auth.scheduleRefreshToken(res.jwt);
          }, 0);
          return next.handle(this.addAuthenticationToken(request, res));
        }),
        catchError((err) => {
          this.refreshTokenInProgress = false;
          this.auth.logout();
          return throwError(() => err);
        })
      );
    }
    // if refresh token already in progress and another api call is made, set next call to be chained after refresh token is complete
    else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addAuthenticationToken(request, token));
        })
      );
    }
  }

  private addAuthenticationToken(request: HttpRequest<any>, data: any) {
    if (!data) {
      return request;
    }
    // set new token values and attempt failed call due to 401
    this.auth.setRefreshToken(data);
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${data.token}`,
      },
    });
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    console.log(error);
    let errMsg = 'API error';
    if (error.message) {
      errMsg = error.message;
    }
    if (error.error && error.error.error) {
      errMsg = error.error.error;
    }
    if (error.error && error.error.detail) {
      errMsg = error.error.detail;
    }
    if (error.error && error.error.message) {
      errMsg = error.error.message;
    }
    if (error.error && error.error['hydra:description']) {
      errMsg = `${error.error['hydra:title']}: ${error.error['hydra:description']}`;
    }
    const errType = HTTP_STATUS_CODE_TEXT[error.status] || error.status;
    return `${errType} - ${errMsg}`;
  }
}
