import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingSpinnerService } from './loading-spinner.service';

@Component({
  selector: 'val-loading-spinner',
  template: ` <div
    class="spinner-container"
    *ngIf="(global && isLoading) || (!global && loading)"
    [ngClass]="{ local: !global }"
  >
    <svg class="spinner" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
    <div class="spinner-progress" *ngIf="progressBar" [innerHTML]="(progressBarValue || 0)+'%'"></div>
  </div>`,
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
  // Whole page loading spinner can be activated/deactivated using loadingService start/stop
  // Component-level loading spinner can be activated using the 'loading' parameter
  private loadingSub: Subscription;
  isLoading: boolean = false;
  private progressBarSub: Subscription;
  progressBar: boolean = false;
  private progressBarValueSub: Subscription;
  progressBarValue: number = 0;
  @Input() global: boolean | undefined;
  @Input() loading: boolean | undefined;

  constructor(private loadingService: LoadingSpinnerService) {}

  ngOnInit(): void {
    this.loadingSub = this.loadingService.isLoading$.subscribe((loading) => {
      this.isLoading = loading;
    });
    this.progressBarSub = this.loadingService.progressBar$.subscribe((hasProgressBar: boolean) => {
      this.progressBar = hasProgressBar;
    });
    this.progressBarValueSub = this.loadingService.progressBarValue$.subscribe((progress: number) => {
      this.progressBarValue = progress;
    });
  }

  ngOnDestroy() {
    this.loadingSub.unsubscribe();
  }
}
