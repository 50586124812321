<div *ngIf="!loadingApprovers" class="order-approval-request">
  <div *ngIf="!approversDisplayNames.length; else awForm" class="alert alert-danger mt-3 mb-0">
    Order approvals are not available at this time. Please contact support.
  </div>

  <ng-template #awForm>
    <form [formGroup]="orderApprovalForm" (ngSubmit)="onSubmit()" novalidate>
      <div class="row">
        <val-form-input
          class="col-sm-12"
          [form]="orderApprovalForm"
          [id]="'cartName'"
          [label]="'Cart Name'"
        ></val-form-input>
        <div class="mb-4" *ngIf="approversDisplayNames.length">
          <label>To Email Address{{ approversDisplayNames.length > 1 ? 'es' : ''}}</label><br>
          {{ approversDisplayNames.join(", ") }}
        </div>
        <val-form-input
          class="col-sm-12"
          [form]="orderApprovalForm"
          [id]="'message'"
          [type]="'textarea'"
          [label]="'Message'"
          [textAreaRows]="3"
        ></val-form-input>
      </div>
      <button
        id="contactSubmit"
        class="btn btn-primary btn-lg mx-auto"
        type="submit"
        [disabled]="!orderApprovalForm.valid || isLoading.summary"
      >
        Submit
      </button>
    </form>
  </ng-template>
</div>
