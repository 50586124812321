import { ApiService } from 'src/app/core/services/api/api.service';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { UserModel } from './user.model';
import { UserRoleEnum } from 'src/app/core/services/user/user-role.enum';
import { ErrorsModel } from 'src/app/core/services/form/errors.model';
import { SuccessfulResModel } from 'src/app/core/services/form/success.model';
import { ApiOptions } from '../api/api.model';
import { Result } from 'src/app/core/services/user/userShoppingContext.model';
import { parseJwt, token } from 'src/app/shared/consts/parse-jwt';
import { API_URL } from 'src/app/shared/consts/api-urls';
import { Buffer } from 'buffer';
import { Permissions } from '../../../shared/consts/permissions.enum';
import { UserShoppingContext } from '../customer-company/user-shopping-context.model';
import { BuyerUserTypes, DashboardUserTypes } from './user-type.enum';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private api: ApiService) {}

  @Output() responseError = new EventEmitter<ErrorsModel | null>();
  @Output() successfulRes = new EventEmitter<SuccessfulResModel | null>();
  private isModalOpen = false;

  public getModalText(
    type: string | null | undefined,
    userIdentifier?: string,
    data?: unknown
  ): Record<string, string | null | undefined> {
    switch (type) {
      case 'deleteShoppingAccount':
        return {
          title: 'Delete Shopping Account',
          body: `Are you sure you want to permanently delete<br>${(data as Result).customerNumber} / ${
            (data as Result).shipTo
          } / ${(data as Result).customerCompanyNumber.id}'?`,
        };
      case 'deleteUser':
        return {
          title: 'Delete User',
          body: `Are you sure you want to permanently delete this user?`,
          bodyClass: 'text-center',
        };
      default:
        return {
          title: '',
          body: `Are you sure?`,
          bodyClass: 'text-center',
        };
    }
  }

  public getPermissions() {
    return this.api.getAll(API_URL.Permissions);
  }

  public getUsers(searchParams: ApiOptions) {
    return this.api.get(API_URL.UserList, searchParams);
  }

  public getUser(userId: number | string) {
    return this.api.get(`${API_URL.User}/${userId}`, '', false);
  }

  public isAdmin(user: UserModel): boolean {
    return user && !!user.roles && user.roles.join('').includes('ADMIN');
  }

  public isB2B(user: UserModel | null | undefined): boolean {
    return user && user.userTypeId && user.userTypeId === 'B2B';
  }

  public isB2C(user: UserModel | null | undefined): boolean {
    return user && user.userTypeId && user.userTypeId === 'B2C';
  }

  public isPunchOut(user: UserModel | null | undefined): boolean {
    return user && user.userTypeId && user.userTypeId === 'POU';
  }

  public isBuyer(user: UserModel | null | undefined): boolean {
    return !!user && BuyerUserTypes.includes(user?.userTypeId);
  }

  public hasDashboard(user: UserModel | null | undefined): boolean {
    return DashboardUserTypes.includes(user?.userTypeId);
  }

  public hasProductLists(user: UserModel | null | undefined): boolean {
    return this.isBuyer(user);
  }

  public requestPasswordReset(email: string | null) {
    return this.api.post(API_URL.PasswordRequestReset, { email });
  }

  public resetPassword(
    password: string | null | undefined,
    confirmPassword: string | null | undefined,
    jwtToken: string | null | undefined
  ) {
    const decodedToken = Buffer.from(<string>jwtToken, 'base64').toString('utf8');
    parseJwt(decodedToken);
    const getToken: Record<string, string> = token.getValue();
    const recoveryToken = getToken['r'];
    return this.api.post(API_URL.PasswordReset, {
      password,
      confirmPassword,
      recoveryToken,
    });
  }

  public changePassword(
    password: string | null | undefined,
    confirmPassword: string | null | undefined,
    userId: number | undefined
  ) {
    return this.api.post(`${API_URL.User}/${userId}/password/reset`, {
      password,
      confirmPassword,
    });
  }

  public putUser(user: UserModel) {
    return this.api.put(`${API_URL.User}/${user.userId}`, user);
  }

  public postUser(user: UserModel, role: UserRoleEnum) {
    return this.api.post(API_URL.UserCreate, this.formatUser(user, role));
  }

  private formatUser(user: UserModel, role: UserRoleEnum): UserModel {
    return {
      username: user.email,
      userTypeId: 'B2C',
      roles: [role],
      permissions: [Permissions.CVP, Permissions.AO, Permissions.CC],
      ...user,
    };
  }

  public getAccounts(userId: number, params?: any) {
    return this.api.get(`${API_URL.User}/${userId}/accounts`, params, false);
  }

  public updateDefaultShipTo(
    userId: number,
    data: {
      currentShoppingAccountId?: number;
      defaultShoppingAccountId?: number;
      shoppingContext?: UserShoppingContext;
    }
  ) {
    return this.api.patch(`${API_URL.UserList}/${userId}`, data);
  }

  public emailCheck(emailInput: string): Observable<any>{
    const obj = { username: emailInput };

    return new Observable<any[]>((observer) => {
      this.api.get(`${API_URL.UsernameCheck}`, obj).subscribe({
        next: (data: any) => {
          if (data.id) {
            const msg = data.username?.toLowerCase() === emailInput ? 'username' : 'email address';
            observer.next([false, {
              title: `An account already exists with this ${msg}`,
              titleClass: 'text-center',
              body: `You can either go to Login or you can continue with another email address.`,
              bodyClass: 'text-center',
              confirmText: 'LOGIN',
              rejectText: 'USE ANOTHER EMAIL',
            }]);
          } else {
            observer.next([true]);
          }
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  }
}
