<div *ngIf="importError" class="row">
  <div class="col-12">
    <div class="alert alert-danger text-center" role="alert">{{ importError }}</div>
  </div>
</div>
<div class="row import-export-actions">
  <div *ngIf="actionType" class="col-sm-12">
    <div *ngFor="let section of this.section" class="row">
      <div class="col-md-3 border-end border-bottom py-2 title">
        <span *ngIf="section.title">{{ section.title }}</span>
      </div>
      <div *ngIf="section.checkboxes" class="col-md-9 border-bottom">
        <div class="row align-items-center">
          <div *ngFor="let checkbox of section.checkboxes" class="col-6 col-lg-3 py-2">
            <div class="checkbox">
              <label class="custom-checkbox">
                <input type="checkbox" class="custom-input" [(ngModel)]="checkbox.checked" [checked]="checkbox.checked"
                />
                <span class="custom-check"></span>
                <span class="label">{{ checkbox.label }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="section.radioboxes" class="col-md-9 border-bottom">
        <div class="row align-items-center">
          <div *ngFor="let radio of section.radioboxes" class="col-6 col-lg-3 py-1">
            <label class="custom-radiobox">
              <input
                class="custom-input"
                type="radio"
                [name]="radio.name"
                [value]="radio.value"
                [checked]="radio.checked"
                (click)="onRadioClick(radio, section.radioboxes)"
              />
              <span class="custom-radio check"></span>
              <span class="label">{{ radio.value }}</span>
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="section.content" class="col-md-9 border-bottom pb-3">
        <p class="d-flex justify-content-start mt-3">{{ section.subTitle1 }}</p>
        <span class="d-flex justify-content-start">{{ section.subTitle2 }}</span>
        <div class="row">
          <div class="col-sm-6">
            <img src="{{ section.image }}" alt="product-import" class="img-fluid d-flex" />
          </div>
          <div class="col-sm-6">
            <ul class="my-3 list-unstyled">
              <li *ngFor="let content of section.content">{{ content }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center my-3">
      <input
        type="file"
        class="file-input d-none"
        (click)="onFileClick($event)"
        (change)="fileChangeListener($event, this.section)"
        #fileUpload
      />
      <button *ngIf="pageType !== 'import-order'" class="btn btn-sm btn-secondary mx-3" (click)="onCancel()">Cancel</button>

      <button
        *ngIf="actionType.type === ActionType.IMPORT"
        class="btn btn-sm btn-primary mx-3"
        (click)="fileUpload.click()"
      >
        {{ actionType.type }}
      </button>
      <button
        *ngIf="actionType.type === ActionType.EXPORT"
        class="btn btn-sm btn-primary mx-3"
        [disabled]="!isCheckboxChecked()"
        (click)="exportAction(actionType.format)"
      >
        {{ actionType.type }}
      </button>
    </div>
  </div>
</div>
