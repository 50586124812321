<div class="header-main">
  <div class="container d-flex justify-content-between">
    <div class="logo col-xs-3">
      <a routerLink="/" [attr.data-testid]="DATA_TEST_ID.header.home">
        <img src="/assets/img/Vallen_NoTagline.jpeg" alt="Vallen" />
      </a>
    </div>

    <div class="search col-lg-9">
      <val-search-form [showMobile]="showMobileSearch" (hideMobileEvent)="showMobileSearch = false"></val-search-form>

      <div class="location icon">
        <a routerLink="/locations" [attr.data-testid]="DATA_TEST_ID.header.locations">
          <i class="material-icons-outlined">place</i>
          <span>Locations</span>
        </a>
      </div>

      <div class="location icon">
        <a routerLink="/promotions">
          <i class="material-icons-outlined">star_outline</i>
          <span>Specials</span>
        </a>
      </div>

      <div class="cart icon">
        <a routerLink="/cart" [attr.data-testid]="DATA_TEST_ID.header.cart">
          <i class="material-icons-outlined">shopping_cart</i><span class="count">{{ cartCount }}</span>
        </a>
      </div>

      <div class="login icon">
        <a routerLink="/account/login" [attr.data-testid]="DATA_TEST_ID.header.logInBtn">
          <i class="material-icons-outlined">login</i><span>Login</span>
        </a>
      </div>

      <div class="account icon">
        <a
          [routerLink]="userService.isB2B(user) ? '/account/accountsettings' : null"
          [attr.data-testid]="DATA_TEST_ID.header.myAccount"
        >
          <i class="material-icons-outlined">person</i><span> My Account </span>
        </a>
        <div class="roll-out dropdown">
          <p class="title">Welcome</p>
          <p *ngIf="user?.username" class="user-details">{{ user?.username }} / {{ selectedCustomer }}</p>
          <ul *ngIf="menuService.accountLinks.links?.length">
            <li *ngFor="let link of menuService.accountLinks.links">
              <a
                [routerLink]="link.route"
                [attr.data-testid]="DATA_TEST_ID.header.link + '-' + link.title | idFormatter"
                >{{ link.title }}</a
              >
            </li>
          </ul>
          <div class="d-grid">
            <a
              role="button"
              class="btn btn-lg btn-primary"
              (click)="logout()"
              [attr.data-testid]="DATA_TEST_ID.header.logOutBtn"
              >Logout</a
            >
          </div>
        </div>
      </div>
      <div class="srch-mob _mobile">
        <i
          class="material-icons-outlined"
          (click)="toggleSearch()"
          [attr.data-testid]="DATA_TEST_ID.header.mobileSearch"
          >{{ showMobileSearch ? "close" : "search" }}</i
        >
      </div>
    </div>
  </div>
</div>
