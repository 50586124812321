import { Injectable } from '@angular/core';
import { ProductResult } from '../search/search.model';
import { Observable, Subscription, of } from 'rxjs';
import { UserModel } from '../user/user.model';
import { select, Store } from '@ngrx/store';
import { AppStateInterface } from '../../../types/app-state.interface';
import { currentShoppingContextSelector, userSelector } from '../../store/user/user.selectors';
import { ApiService } from 'src/app/core/services/api/api.service';
import { API_URL } from 'src/app/shared/consts/api-urls';
import { LoadingSpinnerService } from '../../../shared/components/loading-spinner/loading-spinner.service';
import { environment } from 'src/environments/environment';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { UserShoppingContext } from '../customer-company/user-shopping-context.model';

@Injectable({
  providedIn: 'root',
})
export class PricingService extends BaseSubscriptionComponent {
  private userSub: Subscription;
  private user$: Observable<UserModel | null | undefined>;
  user?: UserModel;
  private shoppingContext$ = this.store.pipe(select(currentShoppingContextSelector));
  private shoppingContext?: UserShoppingContext;
  private priceCache: { id: string; items: any } = { id: '', items: {} };
  private isPricingRequestInProgress = false; // Flag to indicate if getPricing is in progress
  private pricingQueue: (() => void)[] = []; // Queue for delayed getPricing calls

  constructor(
    private store: Store<AppStateInterface>,
    private api: ApiService,
    private loading: LoadingSpinnerService
  ) {
    super();
    this.user$ = this.store.pipe(select(userSelector));
    this.userSub = this.user$.pipe(takeUntil(this.destroyed)).subscribe((user) => {
      this.user = user || undefined;
    });
    this.shoppingContext$.pipe(takeUntil(this.destroyed)).subscribe((shoppingContext) => {
      this.shoppingContext = shoppingContext as UserShoppingContext;
    });
  }

  public getPricing(products: ProductResult[], warehouse?: string): Observable<ProductResult[]> {
    return new Observable((subscriber) => {
      const executeGetPricing = () => {
        this.isPricingRequestInProgress = true;
        this.loading.setLoadingPrices(['summary', 'price', 'total']);
        let warehouseKeys = [];

        if (this.user) {
          this.shoppingContext?.warehouses?.length && warehouseKeys.push(this.shoppingContext.warehouses[0]);
          if (
            this.shoppingContext?.deliveryWarehouses?.length &&
            warehouseKeys[0] !== this.shoppingContext.deliveryWarehouses[0]
          ) {
            warehouseKeys.push(this.shoppingContext.deliveryWarehouses[0]);
          }
        }

        warehouseKeys = warehouseKeys.filter((x) => !!x);
        if (!warehouseKeys.length) {
          warehouseKeys = [environment.warehouse];
        }

        let deliveryWarehouse = warehouse || warehouseKeys[warehouseKeys.length > 1 ? 1 : 0];
        const cacheId = this.getCacheKey([deliveryWarehouse]);
        if (this.priceCache.id !== cacheId) {
          this.priceCache = { id: cacheId, items: {} };
        }

        const unCachedProducts: string[] = [];
        products.forEach((product: ProductResult) => {
          if (this.priceCache.items[product.itemId.toUpperCase()]) {
            const cachedPrice = this.priceCache.items[product.itemId.toUpperCase()];
            product.price = cachedPrice.price;
            product.uom = cachedPrice.uom;
            product.warehouse = cachedPrice.warehouse;
            product.isAvailable = cachedPrice.isAvailable;
            product.isInStock = cachedPrice.isInStock;
            product.qtyAvail = cachedPrice.qtyAvail;
            product.leadTime = cachedPrice.leadTime;
            product.priceReady = cachedPrice.priceReady;
            product.uomList = cachedPrice.uomList;
            product.sellMult = cachedPrice.sellMult || 1;
            if (!product.qty) {
              product.qty = product.sellMult;
            }
          } else {
            if (!product.qty) {
              product.qty = product.sellMult || 1;
            }
            unCachedProducts.push(product.itemId);
          }
        });

        if (!unCachedProducts.length) {
          setTimeout(() => {
            subscriber.next(products);
            subscriber.complete();
            this.loading.setLoadingPrices();
            this.isPricingRequestInProgress = false;
            this.processQueue(); // Process the next item in the queue
          }, 0);
          return;
        }

        this.getPriceAvailability(unCachedProducts, deliveryWarehouse ? [deliveryWarehouse] : warehouseKeys).subscribe({
          next: (response: any) => {
            products.forEach((product: any) => {
              response?.priceAvailability?.forEach((item: any) => {
                if (product.itemId == item.productID && item.warehouse == deliveryWarehouse) {
                  const cachedPrice: any = {};
                  let allUoms = [];
                  if (item.uomList?.ProductUomList) {
                    allUoms = item.uomList.ProductUomList[0]
                      ? item.uomList.ProductUomList
                      : [item.uomList.ProductUomList];
                    cachedPrice.uomList = product.uomList = allUoms;
                  }
                  cachedPrice.price = product.price = item.price;
                  cachedPrice.uom = product.uom = item.units;
                  cachedPrice.warehouse = product.warehouse = item.warehouse;
                  cachedPrice.isAvailable = product.isAvailable = !!item.qtyAvail;
                  cachedPrice.isInStock = product.isInStock = !!item.qtyAvail;
                  cachedPrice.qtyAvail = product.qtyAvail = item.qtyAvail;
                  cachedPrice.leadTime = product.leadTime = !item.qtyAvail && item.leadTime ? item.leadTime + 3 : null;
                  cachedPrice.priceReady = product.priceReady = true;
                  if (!item.sellMult) {
                    item.sellMult = 1;
                  }
                  cachedPrice.sellMult = product.sellMult = item.sellMult;
                  if (product.qty % item.sellMult !== 0) {
                    product.qty = item.sellMult;
                  }
                  this.priceCache.items[item.productID.toUpperCase()] = cachedPrice;
                }
              });
            });
            subscriber.next(products);
            subscriber.complete();
            this.loading.setLoadingPrices();
            this.isPricingRequestInProgress = false;
            this.processQueue(); // Process the next item in the queue
          },
          error: (err) => {
            subscriber.next(products);
            subscriber.complete();
            this.loading.setLoadingPrices();
            this.isPricingRequestInProgress = false;
            this.processQueue(); // Process the next item in the queue
          },
        });
      };

      if (this.isPricingRequestInProgress) {
        this.pricingQueue.push(executeGetPricing); // Add to queue if a request is in progress
      } else {
        executeGetPricing(); // Execute immediately if no request is in progress
      }
    });
  }

  private processQueue(): void {
    if (this.pricingQueue.length > 0) {
      const nextRequest = this.pricingQueue.shift();
      nextRequest && nextRequest();
    }
  }

  public getWarehouseAvailability(product: ProductResult, warehouseKeys: string[], warehouseResults: any) {
    this.getPriceAvailability(this.getErpItemKeys([product]), warehouseKeys).subscribe({
      next: (response: any) => {
        response.priceAvailability.forEach((item: any) => {
          if (product.itemId == item.productID) {
            warehouseResults.push({
              warehouse: item.warehouse,
              available: !!item.qtyAvail,
              quantity: item.qtyAvail || 0,
              uom: item.units,
            });
          }
        });
        if (warehouseResults.length) {
          warehouseResults = warehouseResults.sort((a: any, b: any) => (a.available > b.available ? -1 : 1));
        }
      },
      error: () => {
        console.log('Error getting Warehouse Availability');
      },
    });
  }

  public getPriceAvailability(erpItemKeys: any, warehouseKeys?: string[] | any | undefined) {
    if (!erpItemKeys.length) {
      return of([]);
    }

    //set default warehouse if not logged in
    if (!warehouseKeys?.length) {
      warehouseKeys = this.shoppingContext?.warehouses.length
        ? this.shoppingContext.warehouses
        : [environment.warehouse];
    }
    let data = {
      companyNumber: this.user?.companyNumber || environment.companyNumber,
      customerNumber: this.shoppingContext?.customerNumber
        ? +this.shoppingContext.customerNumber
        : environment.customerNumber,
      erpItemKeys: erpItemKeys,
      warehouseKeys,
    };
    return this.api.post(`${API_URL.PriceAvailability}`, data);
  }

  private getErpItemKeys(products: ProductResult[]): string[] {
    let erpItemKeys: string[] = [];
    products.forEach((product) => erpItemKeys.push(product.itemId));
    return erpItemKeys;
  }

  private getCacheKey(warehouseKeys: string[]): string {
    const customerNumber = this.shoppingContext?.customerNumber
      ? +this.shoppingContext.customerNumber
      : environment.customerNumber;
    return [customerNumber, [...new Set(warehouseKeys)].sort().join(',')].join('|');
  }
}
