import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleItem } from '../../models/content-types/article_item';
import { KontentService } from '../../kontent.service';
import { BreadcrumbsService } from '../../../breadcrumbs/breadcrumbs.service';
import { Breadcrumb } from '../../../breadcrumbs/breadcrumb.model';
import { LoadingSpinnerService } from '../../../../../shared/components/loading-spinner/loading-spinner.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'val-kontent-article-details',
  templateUrl: './kontent-article-details.component.html',
  styleUrls: ['./kontent-article-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KontentArticleDetailsComponent extends BaseSubscriptionComponent {
  slug: string;
  article: ArticleItem;
  category: any;

  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbsService,
    public kontentService: KontentService,
    private loadingService: LoadingSpinnerService,
    private router: Router
  ) {
    super();
    this.route.params.pipe(takeUntil(this.destroyed)).subscribe((params) => {
      if (params['slug']) {
        this.loadData(params['slug']);
      }
    });
  }

  loadData(slug: string): void {
    this.loadingService.start();
    this.kontentService
      .getArticle(slug)
      .then((res) => {
        this.article = res.data.items[0];
        return this.article.elements.category.value[0];
      })
      .then((catId) =>
        this.kontentService.getArticleCategory(catId).then((res) => {
          this.category = res.data.item.elements;
        })
      )
      .finally(() => {
        this.kontentService.setMeta(this.article, false);
        this.breadcrumbService.setBreadcrumbs([
          new Breadcrumb('News', '/articles'),
          new Breadcrumb(this.category.label.value, `/articles/${this.category.urlSlug.value}`),
          new Breadcrumb(this.article.elements.title.value, ''),
        ]);
      })
      .catch((err) => {
        console.error(err);
        this.goTo404();
        return;
      })
      .finally(() => {
        this.loadingService.stop();
      });
  }

  private goTo404(): void {
    this.router.navigate(['/404']);
  }
}
