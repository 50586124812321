import { Component, Input, OnInit } from '@angular/core';
import { CartLine } from '../../../../types/cart.model';
import { UserModel } from '../../../../core/services/user/user.model';
import { ProductResult } from '../../../../core/services/search/search.model';

@Component({
  selector: 'val-item-tags',
  templateUrl: './item-tags.component.html',
  styleUrls: ['./item-tags.component.scss'],
})
export class ItemTagsComponent implements OnInit {
  @Input() product: CartLine | ProductResult;
  @Input() user?: UserModel | null;
  @Input() quickListHeaderId?: number | undefined;
  isClearance = false;
  isFinalSale = false;
  isQuickList = false;
  showTags = false;

  ngOnInit(): void {
    this.isClearance = this.product.isClearance;
    this.isFinalSale = this.product.isFinalSale;
    this.isQuickList =
      this.product.quickList && this.quickListHeaderId
        ? this.product.quickList.includes(this.quickListHeaderId)
        : false;
    this.showTags = this.isFinalSale || this.isQuickList || this.isClearance;
  }
}
