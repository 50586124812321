export enum UserTypeEnum {
  B2B = 'B2B', // Business to Business
  B2C = 'B2C', // Business to Customer
  BRA = 'BRA', // Branch
  CUS = 'CUS', // Customer
  POU = 'POU', // PunchOut User
  REP = 'REP', // Representative
  SYS = 'SYS', // System Operator
  XPA = 'XPA', // XPA User
}

export const DashboardUserTypes = ['B2B', 'BRA', 'REP'];
export const BuyerUserTypes = ['B2B', 'BRA', 'CUS', 'POU', 'REP', 'SYS', 'XPA'];
