<div class="container val-cart">
  <val-page-title [pageTitle]="pageTitle"></val-page-title>

  <div *ngIf="cart?.cartItems?.length && !hasError">
    <val-item-list-actions
      *ngIf="!disableEdit"
      [cart]="cart"
      [products]="cart.cartItems"
      [pageType]="pageTypeEnum.CART"
    ></val-item-list-actions>

    <div class="row">
      <div class="col-md-8 col-lg-9 mb-5">
        <val-item-list
          [products]="cart.cartItems"
          [pageType]="pageType"
          [disableEdit]="disableEdit"
          [collapse]="isCollapsedDetails"
        ></val-item-list>
      </div>
      <div class="col-md-4 col-lg-3 mb-5">
        <div class="sticky-md-top order-summary">
          <div class="val-th">Summary</div>
          <div class="val-details order-totals border-top-0">
            <div class="sub-total">
              <span>Order items</span><span>{{ cart.cartItems.length }}</span>
            </div>
            <div class="sub-total" *ngIf="canViewPrices">
              <span>Sub-total</span>
              <div *ngIf="isLoading.summary" class="spinner"></div>
              <span *ngIf="!isLoading.summary">{{ cart.subTotal | currency }}</span>
            </div>
            <p class="supp text-center text-md-start">Taxes & shipping will be calculated during Review Order.</p>
          </div>

          <ngb-accordion [activeIds]="activePanelIds" class="facet-filters gray-bg">
            <ngb-panel title="Save To Product List" id="saveToProductList" class="first-panel">
              <ng-template ngbPanelContent>
                <form [formGroup]="form" (ngSubmit)="createList()" class="my-3">
                  <div class="row">
                    <div class="col-sm-12">
                      <label class="e-label">Product list name: <span>*</span></label>
                      <div class="form-group">
                        <input class="form-control list-name" type="text" maxlength="30" formControlName="listName" />
                        <p class="supp">Maximum 30 characters</p>
                      </div>
                    </div>
                  </div>
                  <button
                    id="contactSubmit"
                    class="btn btn-primary btn-lg mx-auto"
                    type="submit"
                    [disabled]="!form.valid"
                  >
                    Save
                  </button>
                </form>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>

          <div *ngIf="!disableEdit" class="val-details text-center border-top-0">
            <button (click)="onApproveCart(cart)" class="btn btn-lg btn-primary" [disabled]="isLoading.summary">
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template *ngIf="!cart?.cartItems?.length && !hasError">
    <div class="row justify-content-center">
      <div class="col-lg-6 my-4">
        <div class="alert alert-info text-center" role="alert">Your cart is empty</div>
      </div>
    </div>
  </ng-template>
  <div class="row justify-content-center" *ngIf="hasError">
    <div class="col-lg-6 my-4">
      <div class="alert alert-danger text-center" role="alert">There is an issue with your cart.</div>
    </div>
  </div>
</div>
