<ng-container *ngIf="router.url !== '/maintenance'">
  <val-toast></val-toast>
  <val-loading-spinner [global]="true"></val-loading-spinner>
  <val-no-header *ngIf="noHeader; else header"></val-no-header>
  <ng-template #header>
    <val-header *ngIf="!router.url.includes('admin')"></val-header>
  </ng-template>
  <div class="page-content">
    <router-outlet></router-outlet>
  </div>
  <ng-container *ngIf="!noFooter">
    <val-no-footer *ngIf="noHeader; else footer"></val-no-footer>
    <ng-template #footer>
      <val-footer *ngIf="!router.url.includes('admin')"></val-footer>
    </ng-template>
  </ng-container>

  <val-cookie-consent></val-cookie-consent>
</ng-container>

<ng-container *ngIf="router.url === '/maintenance'">
  <val-under-maintenance></val-under-maintenance>
</ng-container>
