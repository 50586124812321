<div id="add-to-product-list" class="add-to-product-list" [ngClass]="{ stacked: stacked }">
  <form [formGroup]="form" *ngIf="form">
    <div class="form-selection font-dark font-secondary">Add to Product List</div>

    <div id="mode-selection" class="mode-selection mode-list show">
      <div *ngIf="productLists.length > 0" class="mode">
        <label class="custom-radiobox">
          <input
            class="custom-input"
            type="radio"
            formControLName="listType"
            name="listType"
            (click)="setMode('update')"
            [checked]="mode === 'update'"
            [attr.data-testid]="DATA_TEST_ID.product.listForm.radio + '-' + 'update' | idFormatter"
          />
          <span class="custom-radio check"></span>
          <p>Select a List</p>
          <div class="form-group">
            <label class="dnArw"></label>
            <select class="form-control" (focus)="setMode('update')" formControlName="listId">
              <option
                *ngFor="let x of productLists; let i = index"
                [value]="x.id"
                [attr.data-testid]="DATA_TEST_ID.product.listForm.select + '-' + i | idFormatter"
              >
                {{ x.name }}
              </option>
            </select>
          </div>
        </label>
      </div>
      <p *ngIf="productLists.length > 0" class="center">Or</p>
      <div class="mode">
        <label class="custom-radiobox">
          <input
            class="custom-input"
            type="radio"
            formControLName="listType"
            name="listType"
            (click)="setMode('create')"
            [checked]="mode === 'create'"
            [attr.data-testid]="DATA_TEST_ID.product.listForm.radio + '-' + 'create' | idFormatter"
          />
          <span class="custom-radio check"></span>
          <p>Create a List</p>
          <div class="form-group">
            <input
              class="form-control"
              type="text"
              maxlength="30"
              placeholder="Enter New List Name"
              (focus)="setMode('create')"
              formControlName="listName"
              [ngClass]="{ 'input-validation-error': invalidList }"
              [attr.data-testid]="DATA_TEST_ID.product.listForm.nameInput"
            />
          </div>
        </label>
      </div>
      <a class="submit save" (click)="submit()"></a>
      <a class="submit cancel" (click)="cancel()"></a>
    </div>
  </form>
</div>
