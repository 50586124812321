import { Injectable, TemplateRef, Inject } from '@angular/core';
import { WINDOW } from 'src/app/app.module';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(@Inject(WINDOW) private window: Window) {}
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    if (!textOrTpl || !this.window) {
      return;
    }
    const isActive = this.toasts.filter(
      (toast) => toast.textOrTpl === textOrTpl && toast.classname === options?.classname
    ).length;
    if (isActive) {
      return;
    }
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }

  showError(str: string) {
    this.show(str, { classname: 'alert-danger' });
  }

  showSuccess(str: string) {
    this.show(str, { classname: 'alert-success' });
  }

  showWarning(str: string) {
    this.show(str, { classname: 'alert-warning' });
  }
}
