<ng-template [ngIf]="product !== undefined">
  <div *ngIf="!product.isInStock && product.isAvailable">
    <div class="available">Out of Stock<span>Available for backorder</span></div>
    <div class="leadtime" *ngIf="b2b && product.leadTime">
      Lead time: <span>{{ product.leadTime }} days</span>
    </div>
  </div>

  <div class="inv status">
    <val-product-availability [isCart]="isCart" [product]="product" [index]="index"></val-product-availability>
  </div>
</ng-template>

<!-- Not sure if this is needed -->
<ng-template [ngIf]="lineItem !== undefined">
  <div class="in-stock">In Stock</div>
</ng-template>
