import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderApprovalService } from '../../../core/services/order/order-approval.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { LoadingSpinnerService } from 'src/app/shared/components/loading-spinner/loading-spinner.service';
import { ApiOptions } from 'src/app/core/services/api/api.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin/admin.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { ApiService } from 'src/app/core/services/api/api.service';
import { AppStateInterface } from '../../../types/app-state.interface';
import { Store } from '@ngrx/store';
import { Cart, OrderApproval, OrderApprovalCart, OrderReviewCartResponse } from 'src/app/types/cart.model';
import { PageTitle } from '../../../shared/components/page-title/page-title.model';
import { PermissionService } from '../../../core/services/permission/permission.service';
import { setCart } from '../../../core/store/cart/cart.actions';

@Component({
  selector: 'val-orders-awaiting-approval',
  templateUrl: './orders-awaiting-approval.component.html',
  styleUrls: ['./orders-awaiting-approval.component.scss'],
})
export class OrdersAwaitingApprovalComponent extends BaseSubscriptionComponent implements OnInit {
  cartsInfo: OrderApproval;
  carts: OrderApprovalCart[] = [];
  apiOptions: ApiOptions;
  pageTitle = new PageTitle('Orders Awaiting Approval', 'info', '');
  email: string | undefined | null;
  allowApproveOrder: boolean;

  constructor(
    public orderApprovalService: OrderApprovalService,
    private toast: ToastService,
    private loading: LoadingSpinnerService,
    private router: Router,
    public admin: AdminService,
    private modalService: NgbModal,
    private api: ApiService,
    private store: Store<AppStateInterface>,
    private permissionService: PermissionService
  ) {
    super();
    this.store
      .select('user')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.email = data?.user?.email;
        this.allowApproveOrder = this.permissionService.allowApproveOrder();
      });
  }

  ngOnInit(): void {
    this.loading.start();
    this.initApiOptions();
    this.orderApprovalService
      .getOrderApprovalPending()
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (res) => {
          this.cartsInfo = res as OrderApproval;
          this.carts = this.cartsInfo?.carts || [];
          this.loading.stop();
        },
        error: (error) => {
          this.toast.showError(error);
          this.loading.stop();
        },
      });
  }

  initApiOptions() {
    this.apiOptions = {
      count: 0,
      page: 1,
      itemsPerPage: this.admin.itemsPerPage,
      order: {
        userTypeId: 'asc',
      },
    };
  }

  onDelete(cart: any) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.options = {
      title: 'Reject Approval Order',
      body: `Are you sure you want to reject ${cart.cartName}?`,
      bodyClass: 'text-center',
    };

    modalRef.result.then((result: boolean) => {
      if (result) {
        this.orderApprovalService
          .rejectOrderPendingApproval(cart.cartId)
          .pipe(takeUntil(this.destroyed))
          .subscribe({
            next: () => {
              this.carts = this.carts.filter((item) => item.cartId !== cart.cartId);
              this.toast.showSuccess('Order Approval successfully deleted.');
            },
            error: () => {
              this.api.toastError('Deleting user failed. Please contact technical support.');
            },
          });
      }
    });
  }

  onApproveCart(cart: any) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.options = {
      title: 'Replace Cart',
      body: `Are you sure you want to replace your current cart with the selected items?`,
      bodyClass: 'text-center',
    };

    modalRef.result.then((result: boolean) => {
      if (result) {
        this.orderApprovalService
          .getOrderApprovalReview(cart.cartId)
          .pipe(takeUntil(this.destroyed))
          .subscribe({
            next: (res: any | OrderReviewCartResponse) => {
              this.orderApprovalService.getCart(res.cartId).subscribe({
                next: (cart: any | Cart) => {
                  this.store.dispatch(setCart({ cart }));
                  this.router.navigate(['/cart']);
                },
                error: () => {
                  this.api.toastError('Approval order failed. Unable to fetch new cart.');
                },
              });
            },
            error: () => {
              this.api.toastError('Approval order failed. Please contact technical support.');
            },
          });
      }
    });
  }

  onEditCart(id: string) {
    this.router.navigate(['ordersawaitingapproval/', id]);
  }
}
