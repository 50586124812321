import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageTitle } from '../../shared/components/page-title/page-title.model';
import { DashboardService } from './dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { AppStateInterface } from 'src/app/types/app-state.interface';
import { UserModel } from 'src/app/core/services/user/user.model';
import { Observable, Subscription } from 'rxjs';
import { currentShoppingContextSelector, userSelector } from 'src/app/core/store/user/user.selectors';
import { dashboardModel } from 'src/app/shared/consts/dashboard-data-model';
import { ProductListService } from 'src/app/core/services/product-list/product-list.service';
import { ProductLists } from 'src/app/types/product-list.model';
import { UserShoppingContext } from '../../core/services/customer-company/user-shopping-context.model';
import { DashboardModalComponent } from './dashboard-modal/dashboard-modal.component';
import { LoadingSpinnerService } from '../../shared/components/loading-spinner/loading-spinner.service';
import { ApiService } from '../../core/services/api/api.service';
import { API_URL } from '../../shared/consts/api-urls';
import { setUser } from '../../core/store/user/user.actions';
import { ToastService } from '../../shared/components/toast/toast.service';
import { ShoppingContextPunchout } from '../../types/punchout.model';
import { KontentService } from '../../core/components/kontent/kontent.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { OrderApproval } from '../../types/cart.model';
import { OrderApprovalService } from '../../core/services/order/order-approval.service';
import { ApiOptions } from 'src/app/core/services/api/api.model';
import { OrderStatusPipe } from '../../shared/pipes/order-status/order-status.pipe';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'val-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent extends BaseSubscriptionComponent implements OnInit {
  public pageTitle = new PageTitle('Account Dashboard', 'dashboard', '');
  public data: any;

  public user: UserModel | null | undefined;
  private userSub: Subscription;
  private user$: Observable<UserModel | null | undefined>;
  private shoppingContext$: Observable<UserShoppingContext | ShoppingContextPunchout | null>;
  private shoppingContext: UserShoppingContext | null;
  private shoppingContextSub: Subscription;
  private listSub: Subscription;
  private lists: ProductLists | null;
  public banner: any;
  public alert: string = '';

  public apiOptions: ApiOptions = {
    count: 0,
    itemsPerPage: 10,
    page: 1,
  };

  constructor(
    private api: ApiService,
    public dashboardService: DashboardService,
    private datePipe: DatePipe,
    private kontent: KontentService,
    private modalService: NgbModal,
    private store: Store<AppStateInterface>,
    private listService: ProductListService,
    public orderApprovalService: OrderApprovalService,
    private orderStatusPipe: OrderStatusPipe,
    private loading: LoadingSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService
  ) {
    super();
    this.user$ = this.store.pipe(select(userSelector));
    this.shoppingContext$ = this.store.pipe(select(currentShoppingContextSelector));
  }

  ngOnInit(): void {
    this.kontent.getDashboardBanner().then((banner: any) => {
      this.banner = banner;
    });

    this.route.queryParams.pipe(takeUntil(this.destroyed)).subscribe((params: any) => {
      if (params.customerError) {
        this.alert =
          'There was an error retrieving your customer information for customer number: ' +
          params.customerNumber +
          '. You are still using the site with your previously selected account. Please contact your admin for further assistance.';
      }
    });

    this.userSub = this.user$.pipe(takeUntil(this.destroyed)).subscribe((user) => {
      this.user = user;
    });

    this.shoppingContextSub = this.shoppingContext$.subscribe((shoppingContext) => {
      this.shoppingContext = <UserShoppingContext>shoppingContext;
      this.dashboardService.setWidgetIds(this.shoppingContext?.dashboardWidgets);
    });

    this.listSub = this.listService.productLists$.pipe(takeUntil(this.destroyed)).subscribe((lists) => {
      if (lists) {
        this.lists = lists;
      }
      this.getProductLists();
    });

    this.getOrderApprovals();
    this.getActiveOrders();
    this.getQuotes();
  }

  public openModal() {
    const modalRef = this.modalService.open(DashboardModalComponent, { size: 'lg' });
    modalRef.result.then(
      (widgetIds: string[]) => {
        // build array in expected order, for comparison/determine if changes were made
        const ids: string[] = this.dashboardService.widgets.filter((x) => widgetIds.includes(x.id)).map((x) => x.id);
        if (ids.join('|') !== this.dashboardService.widgetIds.join('|')) {
          // settings have changed... update database
          this.dashboardService.setWidgetIds(ids);
          this.updateDatabase();
        }
      },
      () => {
        // User dismissed the modal - nothing to do
      }
    );
  }

  public onRemoveWidget(widgetId: string): void {
    this.dashboardService.setWidgetIds(this.dashboardService.widgetIds.filter((x) => x !== widgetId));
    this.updateDatabase();
  }

  private updateDatabase() {
    if (this.shoppingContext?.id) {
      this.loading.start();
      this.api
        .patch(`${API_URL.ShoppingContext}/${this.shoppingContext!.id}`, {
          dashboardWidgets: this.dashboardService.widgetIds,
        })
        .subscribe({
          next: (res: any) => {
            // @ts-ignore
            this.store.dispatch(setUser({ shoppingContext: res }));
            this.loading.stop();
          },
          error: () => {
            this.toast.showError('Error updating dashboard');
            this.loading.stop();
          },
        });
    } else {
      this.toast.showError('No valid shopping context ID found for updating');
    }
  }

  private getProductLists() {
    if (this.lists) {
      dashboardModel.productLists.data = this.lists?.user || [];
      dashboardModel.productLists.columns[0].link = (id: number) => this.navigateToListLink(id);
      dashboardModel.publicLists.data = this.lists?.public || [];
      dashboardModel.publicLists.columns[0].link = (id: number) => this.navigateToListLink(id);
      dashboardModel.sharedLists.data = this.lists?.shared || [];
      dashboardModel.sharedLists.columns[0].link = (id: number) => this.navigateToListLink(id);
    }
    this.data = dashboardModel;
  }

  private navigateToListLink(id: number): void {
    this.router.navigate([`/product-lists/product-list/${id}`]);
  }

  private getOrderApprovals(): void {
    dashboardModel.orderApprovals.data = [];
    this.orderApprovalService
      .getOrderApprovalPending()
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (res) => {
          const approvals = res as OrderApproval;
          const approvalCarts = approvals?.carts.map((cart) => {
            cart.approvalRequestDate = cart.approvalRequestDate.substring(0, 10);
            return cart;
          });
          dashboardModel.orderApprovals.data = approvalCarts || [];
          dashboardModel.orderApprovals.columns[0].link = (cartId: string) => this.navigateToOrderApprovalLink(cartId);
        },
        error: (error) => {
          this.toast.showError(error);
        },
      });
  }

  private getOrders(transType: string) {
    let postObj: any = {
      companyNumber: this.user?.companyNumber,
      customerNumbers: this.user?.currentShoppingAccount?.customerNumber,
      transType,
    };
    if (this.user?.currentShoppingAccount?.shipTo) {
      postObj.shipTo = this.user?.currentShoppingAccount?.shipTo;
    }
    this.loading.start();

    this.api.post(API_URL.Orders, { ...postObj, ...this.apiOptions }).subscribe({
      next: (res: any) => {
        const orders = res.results.map((x: any) => {
          x.orderNumber = `${x.orderno}-${x.ordersuf.toString().padStart(2, '0')}`;
          x.status = this.orderStatusPipe.transform(x.stagecd);
          x.enterdt = this.datePipe.transform(x.enterdt, 'yyyy-MM-dd');
          x.canceldt = this.datePipe.transform(x.canceldt, 'yyyy-MM-dd');
          return x;
        });
        if (transType.toLowerCase() === 'qu') {
          dashboardModel.quotes.data = orders;
        } else {
          dashboardModel.activeOrders.data = orders;
        }
        this.loading.stop();
      },
      error: (error) => {
        this.toast.showError(error);
        this.loading.stop();
      },
    });
  }

  private getActiveOrders(): void {
    this.getOrders('so');
  }

  private getQuotes(): void {
    this.getOrders('qu');
  }

  private navigateToOrderApprovalLink(cartId: string): void {
    this.router.navigate([`/ordersawaitingapproval/${cartId}`]);
  }
}
