<div class="container-fluid article detail" *ngIf="category">
  <div class="container">
    <div class="header">
      <h2 class="cat-title">{{ category.label.value }}</h2>
      <h1 class="pg-title">{{ article.elements.title.value }}</h1>
      <div class="posted">
        <span class="date">{{ article.elements.date.value | date : "longDate" }}</span>
      </div>
    </div>
    <div class="featured-img" *ngIf="article.elements.featuredImage.value.length">
      <img [src]="article.elements.featuredImage.value[0].url"/>
    </div>
    <div class="d-flex justify-content-center">
      <div class="article-body col-sm-8">
        <article>
          <p *ngIf="article.elements.headline.value" class="lg-txt bold-txt btm-space">{{ article.elements.headline.value }}</p>
          <div [innerHTML]="article.elements.content.value | htmlEntityDecode"></div>
        </article>
      </div>
    </div>
  </div>
  <div class="text-center mb-5">
    <a [routerLink]="'/articles'" class="alink back" [attr.data-testid]="DATA_TEST_ID.articles.details.back">
      Back to News</a>
  </div>
</div>
