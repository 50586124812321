import { Injectable } from '@angular/core';
import { BaseCartComponent } from '../../../shared/components/base-cart/base-cart.component';
import { ApiService } from '../api/api.service';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../../types/app-state.interface';
import { SearchService } from '../search/search.service';
import { CartType } from '../../../shared/consts/cart-types';
import { ToastService } from '../../../shared/components/toast/toast.service';
import { LoadingSpinnerService } from '../../../shared/components/loading-spinner/loading-spinner.service';
import { Router } from '@angular/router';
import { PricingService } from '../pricing/pricing.service';

@Injectable({
  providedIn: 'root',
})
export class SiteCartService extends BaseCartComponent {
  constructor(
    api: ApiService,
    loading: LoadingSpinnerService,
    router: Router,
    search: SearchService,
    store: Store<AppStateInterface>,
    toast: ToastService,
    pricing: PricingService
  ) {
    super(api, loading, router, search, store, toast, pricing);
  }

  // Init site cart, this is now async to allow dev to await the call
  async initSiteCart(checkInvalidItems: boolean = false): Promise<void> {
    const cartId = this.getDefaultCartId(CartType.Order);
    try {
      await this.initCart(cartId, true, false, checkInvalidItems);
    } catch (error) {
      console.error('Error initializing site cart:', error);
    }
  }

  resetSiteCart(): void {
    const cartId = this.getDefaultCartId(CartType.Order);
    this.resetCart(cartId);
  }
}
