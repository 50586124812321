<div class="col-sm-4">
  <img *ngIf="content.elements.bannerImages.value[0]" [src]="content.elements.bannerImages.value[0].url" />
</div>
<div class="col-sm-4 d-flex">
  <div class="col-sm-6">
    <img *ngIf="content.elements.bannerImages.value[1]" [src]="content.elements.bannerImages.value[1].url" />
  </div>
  <div class="col-sm-6">
    <img *ngIf="content.elements.bannerImages.value[2]" [src]="content.elements.bannerImages.value[2].url" />
  </div>
  <div class="col-sm-12 text">
    <h5>{{ content.elements.title.value }}</h5>
    <p></p>
    <span>
      <a
        *ngIf="content.elements.actionUrl.value; else noLink"
        [href]="content.elements.actionUrl.value"
        [target]="content.elements.target.value.length ? content.elements.target.value[0] : ''"
        [attr.data-testid]="
          DATA_TEST_ID.kontent.fancyImageBanner.actionUrl + '-' + content.elements.title.value | idFormatter
        "
      >
        {{ content.elements.buttonLabel.value }}
      </a>
      <ng-template #noLink>
        {{ content.elements.buttonLabel.value }}
      </ng-template>
    </span>
  </div>
</div>
<div class="col-sm-4">
  <img *ngIf="content.elements.bannerImages.value[3]" [src]="content.elements.bannerImages.value[3].url" />
</div>
<!--description-->
