import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idFormatter',
})
export class IdFormatter implements PipeTransform {
  // Format html id's by replacing whitespace or special characters with '-'
  transform(value: string): string {
    return value.replace(/[^A-Z0-9]/gi, '-').toLowerCase();
  }
}
