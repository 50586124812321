<table class="product-data">
  <thead>
    <tr>
      <th *ngFor="let checkbox of selectedCheckboxes[0].checkboxes" [ngClass]="checkbox.value">{{ checkbox.label }}</th>
      <ng-container *ngIf="quantityExists">
        <th>UOM</th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let result of results">
      <tr>
        <td
          *ngFor="let checkbox of selectedCheckboxes[0].checkboxes"
          [ngClass]="checkbox.value + (checkbox.label === 'Barcode' ? ' barcode' : '')"
          [ngSwitch]="checkbox.label"
        >
          <ng-container *ngIf="checkbox.checked">
            <ng-container *ngSwitchCase="'Image'">
              <img *ngIf="result.image" [src]="result.image" alt="" />
            </ng-container>
            <ng-container *ngSwitchCase="'Barcode'">
              <img *ngIf="result.barcodeDataUrl" [src]="result.barcodeDataUrl" />
            </ng-container>
            <ng-container *ngSwitchCase="'Price'">{{ getValueForKey(result, checkbox.value) | currency }}</ng-container>
            <ng-container *ngSwitchCase="'Total'">{{ getValueForKey(result, checkbox.value) | currency }}</ng-container>
            <ng-container *ngSwitchDefault>
              {{ getValueForKey(result, checkbox.value) }}
            </ng-container>
          </ng-container>
        </td>
        <ng-container *ngIf="quantityExists">
          <td>{{ result.uom }}</td>
        </ng-container>
      </tr>
    </ng-container>
  </tbody>
</table>

<table class="summary">
  <tbody>
    <tr class="p-1">
      <td class="items pt-3 pe-3 pb-1">
        <span class="left">Order items</span><span class="right"> {{ results.length }} </span>
      </td>
    </tr>
    <ng-container *ngIf="pageType === 'order' || pageType === 'cart'">
      <tr>
        <td class="sub-total pe-3" *ngIf="canViewPrices">
          <span class="left">Sub-total</span><span class="right"> {{ subTotal | currency }} </span>
        </td>
      </tr>
      <tr>
        <td class="sub-total pb-3 pe-3" *ngIf="canViewPrices">
          <span class="supp" *ngIf="pageType === 'cart'">Final taxes will be calculated during Review Order.</span>
          <span class="left" *ngIf="pageType === 'order'">Total Taxes </span
          ><span class="right"> {{ salesTaxAmount | currency }} </span>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
