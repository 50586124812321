import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../../core/services/authentication/authentication.service';
import { PageTitle } from '../../../../shared/components/page-title/page-title.model';
import { FormService } from '../../../../core/services/form/form.service';
import { ErrorsModel } from '../../../../core/services/form/errors.model';
import { API_URL } from 'src/app/shared/consts/api-urls';
import { ApiService } from 'src/app/core/services/api/api.service';
import { Router } from '@angular/router';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { SiteCartService } from '../../../../core/services/cart/site-cart.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'val-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent extends BaseSubscriptionComponent {
  @Output() loginSuccess: EventEmitter<void> = new EventEmitter<void>();
  @Input() isModal: boolean = false;

  public pageTitle = new PageTitle(
    'Login to access your account',
    'lock',
    'If you already have an account with us, login here.'
  );
  public loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });
  public controls = this.loginForm.controls;
  public inputValidationError: string = 'input-validation-error';
  public rememberMe: boolean;

  constructor(
    private authService: AuthenticationService,
    private formService: FormService,
    private api: ApiService,
    private router: Router,
    public siteCart: SiteCartService,
    public cookieService: CookieService
  ) {
    super();
    this.rememberMe = !!this.cookieService.get('rememberMe');
    this.loginForm.reset();
    this.formService.sortValidationErrors(this.loginForm);
    this.validationErrors = this.formService.errors;
    this.loginForm.statusChanges.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.formService.sortValidationErrors(this.loginForm);
      this.validationErrors = this.formService.errors;
    });
  }

  public validationErrors: ErrorsModel[] | null;
  public disableBtn: boolean = false;

  onSubmit() {
    this.disableBtn = true;
    this.loginForm.markAllAsTouched();
    const url: string = `${API_URL.Authenticate}`;
    const controlValues = {
      username: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value,
    };
    if (this.loginForm.valid) {
      this.formService.errors = [];
      this.api.post(url, controlValues).subscribe({
        next: (res: unknown) => {
          this.authService.login(res);
          this.disableBtn = false;
          this.loginSuccess.emit();
          this.siteCart.savePreLoginItems();
          this.router.navigate(['/account/select']);
        },
        error: (err) => {
          this.api.toastError(err);
          this.authService.invalidate();
          this.disableBtn = false;
        },
      });
      this.disableBtn = false;
    } else {
      /**
       * Call sortErrors from the error service
       *
       * param: the form obj
       */
      this.formService.sortValidationErrors(this.loginForm);
      this.validationErrors = this.formService.errors;
      this.disableBtn = false;
    }
  }

  updateRememberMe() {
    this.rememberMe = !this.rememberMe;

    if (this.rememberMe) {
      // If this.rememberMe is true, set the cookie with a long expiration
      this.cookieService.set('rememberMe', 'true', {
        path: '/',
        expires: new Date(new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000), // 10 years
        secure: true,
        sameSite: 'None',
      });
    } else {
      // If this.rememberMe is false, remove the cookie
      this.cookieService.delete('rememberMe', '/');
    }
  }
}
