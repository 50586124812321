import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductResult } from '../../../../services/search/search.model';
import { Router } from '@angular/router';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';

@Component({
  selector: 'val-product-results',
  templateUrl: './product-results.component.html',
  styleUrls: ['./product-results.component.scss'],
})
export class ProductResultsComponent extends BaseSubscriptionComponent {
  @Input() products: ProductResult[];
  @Input() searchType: string;
  @Output() closeSearch = new EventEmitter<boolean>();
  @Output() submitSearch = new EventEmitter<true>();

  constructor(private router: Router) {
    super();
  }

  seeProduct(product: ProductResult): void {
    this.router.navigate([product.seoUrl || product.url]);
    this.closeSearch.emit(true);
  }

  submitForm(): void {
    this.submitSearch.emit(true);
  }
}
