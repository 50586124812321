import { Component, Input, OnInit } from '@angular/core';
import { NavLink } from './nav-link.model';

@Component({
  selector: 'val-nav-link',
  templateUrl: './nav-link.component.html',
})
export class NavLinkComponent implements OnInit {
  @Input() link: NavLink;
  @Input() location: string;

  constructor() {}

  ngOnInit(): void {}
}
