<div class="mt-2">
  <ngb-accordion [activeIds]="activeFilterAccordion" class="facet-filters">
    <!--  Category filters-->
    <ngb-panel *ngIf="categoryFilters" [title]="categoryFilters.name" [id]="'facet-group-' + categoryFilters.id">
      <ng-template ngbPanelContent>
        <ul class="ps-0 mb-0">
          <li *ngFor="let filter of categoryFilters.values">
            <a (click)="filterFacet(categoryFilters.id, filter.value)" class="d-block">
              <span class="facet-value">{{ filter.label }}</span>
            </a>
          </li>
        </ul>
      </ng-template>
    </ngb-panel>

    <!--  Facet filters-->
    <ngb-panel *ngFor="let facetGroup of facetFilters" [title]="facetGroup.name" [id]="'facet-group-' + facetGroup.id">
      <ng-template ngbPanelContent>
        <label
          *ngFor="let filter of facetGroup.values; let i = index"
          class="custom-control custom-checkbox"
          [ngClass]="{ 'd-none': i >= maxFilters }"
        >
          <input
            type="checkbox"
            class="custom-input"
            (click)="filterFacet(facetGroup.id, filter.value)"
            autocomplete="off"
            [checked]="activeFilter(facetGroup.id, filter.value)"
          />
          <!-- {{ facetGroup.id }} -->
          <span class="custom-check"></span> <span class="facet-value">{{ filter.label }}</span>
          <span class="facet-count">{{ filter.count }}</span>
        </label>
        <a
          *ngIf="facetGroup.values.length > maxFilters"
          (click)="toggleFacets('facet-group-' + facetGroup.id)"
          class="facet-toggle"
        ></a>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
