import { environment } from '../../../environments/environment';

const urlRoot: string = environment.apiRoot;
const url: string = urlRoot + 'api';

export const API_URL = {
  AdminNotes: `${url}/admin_notes`,
  ApiPath: url,
  ApiRoot: urlRoot,
  Authenticate: `${urlRoot}authentication_token`,
  AuthLogOut: `${urlRoot}logout`,
  AvailableWarehouses: `${url}/context/available_warehouses`,
  CartItems: `${url}/cart_items`,
  Carts: `${url}/carts`,
  Category: `${url}/categories`,
  CategoryExclusion: `${url}/category_exclusions`,
  ConnectWithUs: `${url}/connect_with_us`,
  Contact: `${url}/contacts`,
  CustomerAddress: `${url}/customer_addresses`,
  CustomerCompany: `${url}/customer_companies`,
  CustomerQuickList: `${url}/customer_quick_lists`,
  ErpCustomerMaster: `${url}/erp/customer_master`,
  ErpSalesRep: `${url}/erp/sales_rep`,
  ErpShipTo: `${url}/erp/ship_to`,
  ImportOrder: `${url}/orders/import`,
  LogOut: `${url}/logout`,
  OrderDetails: `${url}/orders/order_details`,
  Moneris: `${url}/moneris`,
  Orders: `${url}/orders`,
  OrderApprovers: `${url}/order_approval/approvers`,
  OrderApprovalPending: `${url}/order_approval/pending`,
  OrderApprovalReject: `${url}/order_approval/reject`,
  OrderApprovalRequest: `${url}/order_approval/request`,
  OrderApprovalReview: `${url}/order_approval/review`,
  OrdersGuestPricing: `${url}/orders/guest_pricing`,
  OrdersMonthly: `${url}/orders/monthly_totals`,
  OrdersSubmit: `${url}/orders/submit`,
  PasswordRequestReset: `${url}/user/password/request-reset`,
  PasswordReset: `${url}/user/password/reset`,
  Permissions: `${url}/context/permissions`,
  PriceAvailability: `${url}/pricing_availability`,
  Product: `${url}/products`,
  ProductHistory: `${url}/product-history`,
  ProductInclusionByProduct: `${url}/product_inclusion_by_products`,
  ProductInclusionByVendor: `${url}/product_inclusion_by_vendors`,
  ProductListDetail: `${url}/product_list_details`,
  ProductListHeader: `${url}/product_list_headers`,
  Punchout: `${url}/punchout`,
  PunchoutAddresses: `${url}/punchout_addresses`,
  PunchoutSubmit: `${url}/punchout-submit`,
  QuickListDetails: `${url}/quick_list_details`,
  QuickListHeader: `${url}/quick_list_headers`,
  Registration: `${url}/registrations`,
  RegistrationUser: `${url}/registration_users`,
  RefreshToken: `${urlRoot}api/token/refresh`,
  Roles: `${url}/context/roles`,
  ShoppingContext: `${url}/shopping_contexts`,
  ShoppingContextPunchout: `${url}/shopping_context_punchouts`,
  ShoppingContextPunchoutDefault: `${url}/shopping_context_punchout_defaults`,
  ShoppingContextRepDefault: `${url}/shopping_context_rep_defaults`,
  ShoppingContextWarehouse: `${url}/shopping_context_warehouses`,
  ShoppingContextWarehousePunchout: `${url}/shopping_context_warehouse_punchouts`,
  User: `${url}/user`,
  UserList: `${url}/users`,
  UsernameCheck: `${url}/username-check`,
  UserCreate: `${url}/users`,
};
