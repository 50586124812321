import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilityService } from '../utility/utility.service';
import { ToastService } from '../../../shared/components/toast/toast.service';
import { WINDOW } from 'src/app/app.module';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private utility: UtilityService,
    private toast: ToastService,
    public cookieService: CookieService,
    @Inject(WINDOW) private window: Window
  ) {}

  public getAuthHeader() {
    const token = this.cookieService.get('jwt');
    return new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
  }

  public get(url: string, queryParamObject?: any, useCached?: boolean) {
    let options: any;
    if (useCached === false) {
      options = { valBypassCache: 'true' };
    }
    const queryString = queryParamObject ? '?' : '';

    return this.httpClient.get(
      `${url}${queryString}` + this.utility.qsFromObj(queryParamObject),
      this.requestOptions(options)
    );
  }

  public getAll(url: string, queryParamObject?: any, useCached?: boolean) {
    // Bypass API's default pagination by returning 'all' results
    const paginate = { page: 1, itemsPerPage: 10000 };
    return this.get(url, { ...queryParamObject, ...paginate }, useCached);
  }

  public post(url: string, data: any) {
    return this.httpClient.post(url, data, this.requestOptions());
  }

  public put(url: string, data: any) {
    return this.httpClient.put(url, data, this.requestOptions());
  }

  public patch(url: string, data: any) {
    return this.httpClient.patch(url, data, this.requestOptions({ 'Content-Type': 'application/merge-patch+json' }));
  }

  public delete(url: string) {
    return this.httpClient.delete(url, this.requestOptions());
  }

  public toastError(error: string) {
    this.toast.showError(error);
  }

  public getToken() {
    return this.cookieService.get('jwt');
  }

  private requestOptions(options?: any) {
    let authHeader = {};
    const token = this.cookieService.get('jwt');
    if (token) {
      authHeader = {
        Authorization: `Bearer ${token}`,
      };
    }
    return {
      headers: new HttpHeaders({ ...authHeader, ...options }),
    };
  }
}
