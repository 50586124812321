import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/core/services/category/category.service';
import { CategoryMenu } from 'src/app/types/category-menu.model';
import { ErrorsModel } from 'src/app/core/services/form/errors.model';
import { Store } from '@ngrx/store';
import { AppStateInterface } from 'src/app/types/app-state.interface';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'val-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.scss'],
})
export class ProductCategoriesComponent extends BaseSubscriptionComponent implements OnInit {
  categoryList: CategoryMenu[];
  responseError: ErrorsModel | null;

  constructor(private categoryService: CategoryService, private store: Store<AppStateInterface>) {
    super();
  }

  ngOnInit() {
    this.categoryService.categories$.pipe(takeUntil(this.destroyed)).subscribe({
      next: (categories: CategoryMenu[]) => {
        this.categoryList = categories;
      },
      error: (error: any) => {
        this.responseError = {
          responseErrorType: 'getCategoryList',
          message: error.message || 'An error occurred while fetching categories',
        };
      },
      complete: () => {
        console.log('Categories fetched successfully');
      },
    });
  }
}
