import { Component, TemplateRef } from '@angular/core';
import { ToastService } from './toast.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';

@Component({
  selector: 'val-toast',
  templateUrl: './toast.component.html',
  host: {
    class: 'toast-container position-fixed top-0 end-0 p-3',
    style: 'z-index: 1200',
  },
})
export class ToastComponent extends BaseSubscriptionComponent {

  constructor(public toastService: ToastService) {
    super();
  }

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
