import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CounterService {
  private counters = new Map<string, number>();

  constructor(private router: Router) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.resetAllCounters();
    });
  }

  getComponentCount(componentName: string): number {
    if (!this.counters.has(componentName)) {
      this.counters.set(componentName, 0);
    }

    const count = this.counters.get(componentName)! + 1;
    this.counters.set(componentName, count);
    return count;
  }

  private resetAllCounters(): void {
    this.counters.clear();
  }
}
