import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MenuService } from '../../services/menu/menu.service';
import { UserModel } from '../../services/user/user.model';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { HeaderToggleService } from 'src/app/shared/services/header-toggle.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'val-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent extends BaseSubscriptionComponent {
  @Input() user: UserModel | null;
  isMenuOpen: boolean = false;

  constructor(
    public menuService: MenuService,
    public authService: AuthenticationService,
    public headerToggleService: HeaderToggleService,
    private router: Router
  ) {
    super();
    this.headerToggleService.navbarAndSearchFormState$.pipe(takeUntil(this.destroyed)).subscribe((isOpen) => {
      this.isMenuOpen = isOpen;
    });

    this.router.events.pipe(takeUntil(this.destroyed)).subscribe((event: any) => {
      if (event instanceof NavigationEnd && this.isMenuOpen) {
        this.toggleMenu();
      }
    });
  }

  logout() {
    this.authService.logout();
    this.isMenuOpen = false;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.headerToggleService.toggleMenuAndSearchForm(this.isMenuOpen);
  }
}
