export interface DashboardWidget {
  id: string;
  type?: WidgetType;
  title: string;
  description: string;
  route: string;
  linkText: string;
  data?: any;
}

export enum WidgetType {
  BARCHART = 'barchart',
  TABLE = 'table',
  INFO = 'info',
}

export enum WidgetId {
  OVERVIEW = 'overview',
  ORDERS_MONTHLY = 'orders-monthly',
  ORDERS_ACTIVE = 'orders-active',
  ORDERS_APPROVAL = 'orders-approval',
  QUOTES = 'quotes',
  LISTS_USER = 'lists-user',
  LISTS_SHARED = 'lists-shared',
  LISTS_PUBLIC = 'lists-public',
}
