<div *ngIf="products.length" class="el-block-wrapper">
  <div class="row">
    <div class="col-6">
      <h4 class="el-block-heading">{{ searchType }}</h4>
    </div>
    <div *ngIf="searchType === 'Products'" class="col-6">
      <h5 class="el-block-heading text-right">
        <a (click)="submitForm()" [attr.data-testid]="DATA_TEST_ID.search.productResults.viewAll">View All</a>
      </h5>
    </div>
  </div>
  <div
    *ngFor="let product of products; let i = index"
    (click)="seeProduct(product)"
    class="media el-product-wrapper"
    [attr.data-testid]="DATA_TEST_ID.search.productResults.link + '-' + i | idFormatter"
  >
    <div class="media-left">
      <img
        class="media-object"
        [src]="product.image || '/assets/img/no-image-en.png'"
        [alt]="product.title"
        fallbackImage
      />
    </div>
    <div class="media-body">
      <span class="media-heading">
        {{ product.title }}
      </span>
      <div class="sku"><span>Product Code</span>: {{ product.itemId }}</div>
      <div class="vendor"><span>Manufacturer</span>: {{ product.manufacturer }}</div>
    </div>
  </div>
</div>
