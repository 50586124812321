import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'val-no-results',
  template: `<div class="alert alert-info">
    {{ message }}
  </div>`,
})
export class NoResultsComponent implements OnInit {
  @Input() message?: string;

  constructor() {}

  ngOnInit(): void {
    if (!this.message) {
      this.message = 'No items found. Please try another search.';
    }
  }
}
