import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'na',
})
export class NaPipe implements PipeTransform {
  // For displaying data - if no data, display 'n/a'
  transform(value: any): any {
    return value || 'n/a';
  }
}
