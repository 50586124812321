<div class="modal-header">
  <h4 class="modal-title">Documents</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <p class="mb-1"><span class="material-icons-outlined md-18">description</span> Additional Documents</p>
  <ul>
    <li *ngFor="let file of product.attachments">
      <a [href]="file" target="_blank">{{ fileName(file) }}</a>
    </li>
  </ul>
</div>
<div class="modal-footer">
  <button class="btn btn-sm btn-primary mx-auto" (click)="download()">
    <span class="material-icons-outlined">file_download</span>Download All Documents
  </button>
</div>
