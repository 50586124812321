import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { Warehouse, WarehouseNameNumber } from 'src/app/types/warehouse.model';
import { FormOption } from 'src/app/types/form-option.model';
import { API_URL } from 'src/app/shared/consts/api-urls';
import { setWarehouse } from '../../store/warehouse/warehouse.actions';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../../types/app-state.interface';

@Injectable({
  providedIn: 'root',
})
export class WarehouseService {
  constructor(private api: ApiService, private store: Store<AppStateInterface>) {}

  getWarehouses() {
    this.api.getAll(API_URL.AvailableWarehouses, {showDetails: true}, false).subscribe({
      next: (res: unknown) => {
        if (res !== null) {
          const data = res as Warehouse;
          this.store.dispatch(setWarehouse({ warehouses: data['hydra:member'] }));
        }
      },
    });
  }

  getWarehouseLabels(warehouses: WarehouseNameNumber[]): FormOption[] {
    return warehouses.map((x: WarehouseNameNumber) => {
      return {
        label: `${x.warehouseNumber} - ${x.warehouseName}`,
        value: x.warehouseNumber,
      };
    });
  }
}
