import { NgModule } from '@angular/core';
import { PreloadingStrategy, Route, RouterModule, Routes, UrlSegment } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthGuard } from './core/guards/auth.guard';
import { PermissionGuard } from './core/guards/permission.guard';
import { KontentGenericComponent } from './core/components/kontent/templates/kontent-generic/kontent-generic.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { ProductListingPageComponent } from './modules/product-listing-page/product-listing-page.component';
import { ProductDetailPageComponent } from './modules/product-detail-page/product-detail-page.component';
import { CartComponent } from './modules/cart/cart.component';
import { ImportOrderComponent } from './modules/order/import-order/import-order.component';
import { OrdersAwaitingApprovalComponent } from './modules/order/orders-awaiting-approval/orders-awaiting-approval.component';
import { OrderAwaitingApprovalEditComponent } from './modules/order/order-awaiting-approval-edit/order-awaiting-approval-edit.component';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { KontentArticleDetailsComponent } from './core/components/kontent/templates/kontent-article-details/kontent-article-details.component';
import { KontentArticleListComponent } from './core/components/kontent/templates/kontent-article-list/kontent-article-list.component';
import { KontentSuppliersComponent } from './core/components/kontent/templates/kontent-suppliers/kontent-suppliers.component';
import { KontentLocationsComponent } from './core/components/kontent/templates/kontent-locations/kontent-locations.component';
import { KontentContactComponent } from './core/components/kontent/templates/kontent-contact/kontent-contact.component';
import { KontentConnectWithUsComponent } from './core/components/kontent/templates/kontent-connect-with-us/kontent-connect-with-us.component';
import { UnauthorizedComponent } from './modules/unauthorized/unauthorized.component';
import { ProductListComponent } from './modules/product-lists/product-list/product-list.component';
import { ProductCategoriesComponent } from './modules/product-categories/product-categories.component';
import { PunchoutResolver } from './core/resolvers/punchout.resolver';
import { RedirectGuard } from './core/guards/redirect.guard';
import { RedirectComponent } from './modules/redirect/redirect.component';
import { UnderMaintenanceComponent } from './modules/under-maintenance/under-maintenance.component';
import { MaintenanceGuard } from './core/guards/maintenance.guard';
import { ExportPdfComponent } from './shared/components/import-export/export-pdf/export-pdf.component';
import { Permissions } from './shared/consts/permissions.enum';
import { environment } from 'src/environments/environment';
import { BuyerUserTypes, DashboardUserTypes } from './core/services/user/user-type.enum';

export class AppCustomPreloader implements PreloadingStrategy {
  preload(route: Route, load: any): Observable<any> {
    return route.data && route.data['preload'] ? load() : of(null);
  }
}

function productRoute(url: UrlSegment[]): { urlId?: string; seoUrl?: string } | null {
  if (url.length > 1 && url[0].path.match('products')) {
    const dir = url[url.length - 1].path;
    const prodData = dir.match(/^p-([a-zA-Z0-9\=\+]+)-([a-zA-Z0-9\-]+)$/);
    if (prodData) {
      return { urlId: prodData[1] };
    } else {
      return {
        seoUrl:
          '/' +
          url
            .map((u) => {
              return u.path;
            })
            .join('/'),
      };
    }
  }
  return null;
}

const routes: Routes = [
  {
    path: '',
    canActivate: [MaintenanceGuard],
    component: KontentGenericComponent,
    title: 'Vallen Canada - Industry Leader in Industrial Supplies and Safety Products',
    data: {
      breadcrumb: false,
      slug: 'home'
    },
  },
  {
    path: 'content/:slug',
    component: KontentGenericComponent,
    data: {
      breadcrumb: true,
    },
  },
  {
    path: 'articles/detail/:slug',
    component: KontentArticleDetailsComponent,
    data: {
      breadcrumb: true,
    },
  },
  {
    path: 'articles/:category/:slug',
    component: KontentArticleDetailsComponent,
    data: {
      breadcrumb: true,
    },
  },
  {
    path: 'articles',
    component: KontentArticleListComponent,
    data: {
      breadcrumb: true,
    },
  },
  {
    path: 'suppliers',
    component: KontentSuppliersComponent,
    data: {
      breadcrumb: 'Suppliers',
    },
    title: 'Suppliers',
  },
  {
    path: 'locations',
    component: KontentLocationsComponent,
    data: {
      breadcrumb: 'Locations',
    },
    title: 'Locations',
  },
  {
    path: 'contact/:slug',
    component: KontentContactComponent,
    data: {
      breadcrumb: true,
    },
    title: 'Contact',
  },
  {
    path: 'contact',
    component: KontentContactComponent,
    data: {
      breadcrumb: true,
    },
    title: 'Contact',
  },
  {
    path: 'connect-with-us',
    component: KontentConnectWithUsComponent,
    data: {
      breadcrumb: true,
    },
    title: 'Connect With Us',
  },
  {
    path: 'articles/:category',
    component: KontentArticleListComponent,
    data: {
      breadcrumb: true,
    },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    title: 'Account Dashboard',
    data: {
      breadcrumb: false,
      userType: DashboardUserTypes,
      protected: true,
    },
    canActivate: [PermissionGuard],
  },
  {
    path: 'search/:q',
    component: ProductListingPageComponent,
    title: 'Search Results',
    data: {
      breadcrumb: true,
      breadcrumbClass: 'plp',
    },
  },
  {
    path: 'addresses',
    loadChildren: () => import('./modules/addresses/addresses.module').then((m) => m.AddressesModule),
    data: {
      userType: ['B2C'],
      protected: true,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'products/details/:urlId',
    component: ProductDetailPageComponent,
    title: 'Product Page',
    data: {
      breadcrumb: true,
    },
  },
  {
    path: 'products/categories',
    component: ProductCategoriesComponent,
    title: 'Browse By Categories',
  },
  {
    path: 'cart',
    component: CartComponent,
    title: 'Shopping Cart',
    data: {
      breadcrumb: true,
    },
  },
  {
    path: 'ordersawaitingapproval/:id',
    component: OrderAwaitingApprovalEditComponent,
    loadChildren: () => import('./modules/order/order.module').then((m) => m.OrderModule),
    title: 'Order Awaiting Approval Cart',
    data: {
      breadcrumb: true,
      protected: true,
    },
    canActivate: [AuthGuard],
  },
  {
    matcher: (url: UrlSegment[]) => {
      const data = productRoute(url);
      if (data && data.seoUrl) {
        return {
          consumed: url,
          posParams: { seoUrl: new UrlSegment(data.seoUrl, {}) },
        };
      } else {
        return null;
      }
    },
    component: ProductListingPageComponent,
    data: {
      breadcrumb: 'Browse Category',
      breadcrumbClass: 'plp',
    },
  },
  {
    matcher: (url: UrlSegment[]) => {
      const data = productRoute(url);
      if (data && data.urlId) {
        return {
          consumed: url,
          posParams: { urlId: new UrlSegment(data.urlId, {}) },
        };
      } else {
        return null;
      }
    },
    component: ProductDetailPageComponent,
    title: 'Product Page',
    data: {
      breadcrumb: true,
    },
  },
  {
    path: 'importorder',
    component: ImportOrderComponent,
    title: 'Import Order',
    data: {
      userType: BuyerUserTypes,
      breadcrumb: true,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'quicklist',
    loadChildren: () => import('./modules/product-lists/product-lists.module').then((m) => m.ProductListsModule),
    component: ProductListComponent,
    title: 'Quick List',
    data: {
      breadcrumb: true,
      // TODO maybe add userType
      // userType: ['B2B'],
      quicklist: true,
      protected: true,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./modules/account/register-b2b/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'upgrade-your-account',
    loadChildren: () => import('./modules/account/register-b2b/register.module').then((m) => m.RegisterModule),
    data: {
      upgrade: true,
      userType: ['B2C'],
      protected: true,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'checkout',
    loadChildren: () => import('./modules/checkout/checkout.module').then((m) => m.CheckoutModule),
  },
  {
    path: 'product-lists',
    loadChildren: () => import('./modules/product-lists/product-lists.module').then((m) => m.ProductListsModule),
  },
  {
    path: 'quotes',
    loadChildren: () => import('./modules/quotes/quotes.module').then((m) => m.QuotesModule),
    canActivate: [PermissionGuard],
    data: {
      permissions: [Permissions.EIE],
      protected: true,
    },
  },
  {
    path: 'order',
    loadChildren: () => import('./modules/order/order.module').then((m) => m.OrderModule),
  },
  {
    path: 'punchout/login/:sessionId',
    component: ProductCategoriesComponent,
    resolve: {
      punchoutData: PunchoutResolver,
    },
  },
  {
    path: 'careers',
    canActivate: [RedirectGuard],
    component: RedirectComponent,
    data: {
      externalUrl: 'https://careers.vallen.com/ca/en/',
    },
  },
  {
    path: 'ordersawaitingapproval',
    component: OrdersAwaitingApprovalComponent,
    title: 'Orders Awaiting Approval',
    data: {
      breadcrumb: true,
      protected: true,
    },
    canActivate: [AuthGuard],
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    title: 'Page Not Found',
    data: {
      breadcrumb: true,
    },
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    title: 'Access Denied',
    data: {
      breadcrumb: true,
    },
  },
  {
    path: 'product-lists/exportpdf/:listId',
    component: ExportPdfComponent,
    title: 'Product List',
    data: {
      noHeader: true,
      noFooter: true,
    },
  },
  {
    path: 'quicklist/exportpdf/:listId',
    component: ExportPdfComponent,
    title: 'Quick List',
    data: {
      noHeader: true,
      noFooter: true,
    },
  },
  {
    path: 'order/exportpdf',
    component: ExportPdfComponent,
    title: 'Print - Order Details',
    data: {
      noHeader: true,
      noFooter: true,
    },
  },
  {
    path: 'cart/exportpdf',
    component: ExportPdfComponent,
    title: 'Shopping Cart',
    data: {
      noHeader: true,
      noFooter: true,
    },
  },
  {
    path: 'promotions',
    component: ProductListingPageComponent,
    title: 'Specials',
    data: {
      breadcrumb: true,
      breadcrumbClass: 'plp',
    },
  },
];

if (environment.underMaintenance) {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].path !== 'maintenance') {
      routes[i].canActivate = [MaintenanceGuard];
    }
  }
}

routes.push({
  path: 'maintenance',
  component: UnderMaintenanceComponent,
  title: 'Site Under Maintenance',
  data: {
    noHeader: true,
  },
});
routes.push({ path: '**', redirectTo: '/404' });

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
