import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { WINDOW } from 'src/app/app.module';

@Injectable()
export class RedirectGuard {
  constructor(@Inject(WINDOW) private window: Window) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(this.window?.location?.href){
      this.window.location.href = route.data['externalUrl'];
    }
    return true;
  }
}
