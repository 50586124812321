<div class="form-group" [formGroup]="form" [class]="groupClass" [ngClass]="{ 'mb-0': fieldType === 'recaptcha' }">
  <ng-container *ngIf="isStandardInput">
    <label class="form-label {{ id.replace('.', '') }}" [for]="id" [ngClass]="{ dnArw: isSelect() }">
      {{ fieldLabel }}
      <span *ngIf="required" class="reqfld"></span>
    </label>

    <ng-container *ngIf="isTextInput()">
      <input
        class="form-control"
        [ngClass]="formService.inputClass(form.controls[id])"
        [type]="fieldType"
        id="{{ id }}"
        [readOnly]="readonly"
        [formControlName]="id"
        [autocomplete]="autocomplete"
        [placeholder]="placeholder"
        [value]="value"
        [attr.data-testid]="testId"
        (blur)="trimText()"
      />
    </ng-container>

    <ng-container *ngIf="isTextArea()">
      <textarea
        class="form-control h-100"
        [ngClass]="formService.inputClass(form.controls[id])"
        id="{{ id }}"
        [formControlName]="id"
        [readOnly]="readonly"
        [rows]="textAreaRows"
        [attr.data-testid]="testId"
      ></textarea>
    </ng-container>

    <ng-container *ngIf="isSelect()">
      <select *ngIf="!selectSearch" class="form-control" id="{{ id }}" [formControlName]="id"
              [attr.data-testid]="testId">
        <option *ngIf="placeholder" value="">{{ placeholder }}</option>
        <option
          *ngFor="let option of options"
          [ngValue]="option.value"
          [selected]="form.controls[id].value === option.value"
        >
          {{ option.label }}
        </option>
      </select>

      <input
        *ngIf="selectSearch"
        type="text"
        class="form-control search"
        [ngbTypeahead]="search"
        [popupClass]="'custom-search-window'"
        (focus)="focus$.next($any($event).target.value)"
        (click)="click$.next($any($event).target.value)"
        (selectItem)="selectedItem($event.item)"
        [value]="initLabel(form.value[id]) || ''"
        placeholder="Type to search ..."
        #instance="ngbTypeahead"
        [attr.data-testid]="testId"
      />
    </ng-container>

    <ng-container *ngIf="isMultiSelect()">
      <div class="d-flex multi-select">
        <div class="col-lg-6 p-2 select-wrapper">
          <div class="small mb-1 text-dark">Showing all {{ options.length }}</div>
          <div class="d-flex select-btn-wrapper mx-auto">
            <button class="col-sm-6 btn btn-light" (click)="selectAll()">Select All</button>
            <button class="col-sm-6 btn btn-light" (click)="selectOptions('Selected')">
              <i class="material-icons-outlined md-24">chevron_right</i>
            </button>
          </div>
          <select class="form-control multiple-selects mx-auto" [multiple]="true" id="{{ id }}_a"
                  (change)="selectOption('Selected')">
            <ng-container *ngFor="let option of options">
              <option
                *ngIf="!form.value[id].includes(option.value)"
                [id]="id + '-' + 'Selected'"
                [value]="option.value"
              >
                {{ option.label }}
              </option>
            </ng-container>
          </select>
        </div>
        <div class="col-lg-6 p-2 select-wrapper">
          <div class="small mb-1 text-dark">Showing all {{ form.value[id].length }}</div>
          <div class="d-flex select-btn-wrapper mx-auto">
            <button class="col-sm-6 btn btn-light" (click)="selectOptions('Chosen')">
              <i class="material-icons-outlined md-24">chevron_left</i>
            </button>
            <button class="col-sm-6 btn btn-light" (click)="deselectAll()">Deselect All</button>
          </div>
          <select class="form-control multiple-selects mx-auto" [multiple]="true" id="{{ id }}">
            <option
              *ngFor="let selectedOption of form.value[id]"
              (click)="selectOption('Chosen')"
              [id]="id + '-' + 'Chosen'"
              [value]="selectedOption"
            >
              {{ optionLabel(selectedOption) }}
            </option>
          </select>
        </div>
      </div>
    </ng-container>

    <val-form-alerts *ngIf="this.errors.length && form.controls[id].touched" [errorMsg]="errors.join(', ')">
    </val-form-alerts>
    <p *ngIf="inputHint && !this.errors.length" class="input-hint">{{ inputHint }}</p>
  </ng-container>

  <ng-container class="checkbox" *ngIf="isCheckBox()">
    <br/>
    <div class="d-flex checkbox-wrapper">
      <label class="custom-checkbox" [for]="id" [id]="id + 'Wrapper'" [attr.data-testid]="testId">
        <input class="custom-input" type="checkbox" [formControlName]="id" [name]="id" [id]="id"/>
        <div class="custom-check"></div>
      </label>
      <label class="form-label" [id]="id + 'Label'" [for]="id">{{ fieldLabel }}</label>
    </div>
  </ng-container>

  <ng-container *ngIf="fieldType === 'radio'">
    <div class="e-row radiobox mt-md-3">
      <label *ngIf="fieldLabel" class="form-label d-block"
      >{{ fieldLabel }}
        <span *ngIf="required" class="reqfld"></span>
      </label>
      <label *ngFor="let opt of options" [for]="opt.value" [id]="id + 'Wrapper'" class="custom-radiobox">
        <input class="custom-input" type="radio" [formControlName]="id" [id]="opt.value" [value]="opt.value"/>
        <span class="custom-radio"></span>
        <p>{{ opt.label }}</p>
      </label>
    </div>
  </ng-container>
  <ng-container *ngIf="fieldType === 'recaptcha'">
    <re-captcha
      class="form-control bg-transparent border-0"
      [ngClass]="formService.inputClass(form.controls[id])"
      id="{{ id }}"
      [formControlName]="id"
      [siteKey]="recaptchaSiteKey"
      [attr.data-testid]="testId"
    ></re-captcha>
  </ng-container>
  <div *ngIf="footnote" class="footnote" [innerHTML]="footnote"></div>
</div>
