import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { AuthenticationService } from '../../../services/authentication/authentication.service';

@Component({
  selector: 'val-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent extends BaseSubscriptionComponent implements OnInit {
  constructor(
    public cookieService: CookieService,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthenticationService
  ) {
    super();
  }

  setCookie() {
    this.cookieService.set('CookieConsent', 'Consented', {
      path: '/',
      expires: 365,
      secure: true,
      sameSite: 'None',
    });
  }

  determineDisplay() {
    return this.cookieService.get('CookieConsent') ? 'd-none' : 'd-flex';
  }

  cookieAlertModal(): void {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.options = {
      title: 'Cookies are not enabled',
      body: 'Please ensure cookies are enabled and allowed for this site, otherwise it will not function as intended',
      bodyClass: 'text-center',
    };
  }

  // Checks if cookies are allowed and not disabled for the site
  checkCookies(): void {
    if (isPlatformBrowser(this.platformId) && !this.authService.checkCookiesAllowed('cookieAlertModalTest')) {
      this.cookieAlertModal();
    }
  }

  ngOnInit(): void {
    this.checkCookies();
  }
}
