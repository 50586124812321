<div class="container-fluid" *ngIf="bannerContent" [ngClass]="!isSmall ? 'pb-2' : 'pb-4'">
  <div class="banner">
    <img [src]="bannerContent.elements['bannerImage'].value[0].url" alt="" />
    <div [ngStyle]="{ fontSize: !isSmall ? '3rem' : '2rem' }">
      {{ bannerContent.elements["title"].value }}
    </div>
  </div>
</div>

<div class="container mb-5" [ngClass]="{ 'p-5': !isSmall }">
  <div class="row">
    <div class="col-lg-6">
      <div class="row pb-2" *ngIf="location">
        <div class="col-sm-6">
          <u class="h5 valBlue">{{ location.elements["name"].value }}</u>
          <div class="font-dark locationSize locationWeight">
            {{ location.elements["address"].value }}<br />
            {{ location.elements["city"].value }}
            ({{ location.elements["province"].value }}),&nbsp;
            {{ location.elements["postalCode"].value }}
          </div>
        </div>
        <div class="col-sm-6">
          <a
            href="https://www.google.com/maps/search/?api=1&query={{ location.elements['latitude'].value }},{{
              location.elements['longitude'].value
            }}"
            target="_blank"
            class="alink"
            [attr.data-testid]="DATA_TEST_ID.contact.getDirections"
            >Get Directions</a
          >
        </div>
      </div>
      <div class="row" *ngIf="location" [ngClass]="!isSmall ? 'pb-0' : 'pb-3'">
        <div class="col-sm-6 locationSize">
          Tel:
          <span class="font-dark locationWeight">{{ location.elements["phoneNumber"].value }}</span>
          <br />
          <div *ngIf="location.elements['faxNumber'].value">
            Fax:
            <span class="font-dark locationWeight">{{ location.elements["faxNumber"].value }}</span>
          </div>

          <div *ngFor="let x of businessHours" class="locationSize">
            {{ x["dayS"].value }}&nbsp;
            <span class="font-dark locationWeight">{{ x["hours"].value }}</span>
          </div>
        </div>
        <div class="col-sm-6 locationSize" [ngClass]="{ 'pt-1': isSmall }">
          <div *ngIf="location.elements['contactName'].value">
            Branch Manager:
            <span class="font-dark locationWeight">{{ location.elements["contactName"].value }}</span>
          </div>
          <div *ngIf="location.elements['contactEmailAddress'].value">
            Email:
            <u
              ><a
                href="mailto:{{ location.elements['contactEmailAddress'].value }}"
                class="font-dark locationWeight"
                [attr.data-testid]="DATA_TEST_ID.contact.emailLink"
                >{{ location.elements["contactEmailAddress"].value }}</a
              >
            </u>
          </div>
          <br *ngIf="!isSmall" />
          <div class="tag p-1" *ngIf="location.elements['tag'].value && !isSmall">
            {{ location.elements["tag"].value }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <hr *ngIf="isSmall" class="mt-3 mb-0" />
      <div class="row align-items-center" [ngStyle]="{ height: !isSmall ? '100%' : '150px' }">
        <div class="col text-center">
          <h5>Real People. Real Answers.</h5>
          <h5 class="valBlue bold">WE ARE HERE TO HELP!</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6" [ngClass]="{ 'pe-4': !isSmall, 'd-none': hideMap }">
      <div
        class="p-2 pt-5"
        [ngStyle]="{
          display: !isSmall ? 'block' : 'none'
        }"
      >
        <div #mapDiv name="mapDiv" id="map"></div>
      </div>
    </div>
    <div
      [ngClass]="{
        'ps-4': !isSmall,
        'col-lg-12 w-50 mx-auto': hideMap,
        'col-lg-6': !hideMap
      }"
    >
      <form
        [formGroup]="contactForm"
        (ngSubmit)="onSubmit()"
        novalidate
        [attr.data-testid]="DATA_TEST_ID.contact.form.formName"
      >
        <div class="row">
          <val-form-input
            class="col-sm-6"
            [form]="contactForm"
            [id]="'fullName'"
            [testId]="DATA_TEST_ID.contact.form.name"
          ></val-form-input>
          <val-form-input
            class="col-sm-6"
            [form]="contactForm"
            [id]="'email'"
            [testId]="DATA_TEST_ID.contact.form.email"
          ></val-form-input>
          <val-form-input
            class="col-sm-6"
            [form]="contactForm"
            [id]="'phoneNumber'"
            [testId]="DATA_TEST_ID.contact.form.phone"
          ></val-form-input>
          <val-form-input
            class="col-sm-6"
            [form]="contactForm"
            [id]="'city'"
            [hint]="''"
            [testId]="DATA_TEST_ID.contact.form.city"
          ></val-form-input>
          <val-form-input
            class="col-sm-12"
            [form]="contactForm"
            [id]="'inquiry'"
            [type]="'select'"
            [options]="inquiryArr"
            [label]="'Please help us direct your inquiry'"
            [testId]="DATA_TEST_ID.contact.form.inquiry"
          ></val-form-input>
          <val-form-input
            class="col-sm-12"
            [form]="contactForm"
            [id]="'message'"
            [type]="'textarea'"
            [label]="'Comments / Message'"
            [testId]="DATA_TEST_ID.contact.form.message"
          ></val-form-input>
        </div>
        <div class="text-center col-sm-12 d-grid gap-2">
          <val-form-input
            *ngIf="showRecaptcha"
            class="mx-auto"
            [form]="contactForm"
            [id]="'recaptcha'"
            [type]="'recaptcha'"
            [testId]="DATA_TEST_ID.contact.form.recaptcha"
          ></val-form-input>

          <div class="pb-4"><span class="reqfld"></span> mandatory fields</div>
          <button
            id="contactSubmit"
            class="btn btn-primary btn-lg mx-auto"
            type="submit"
            [disabled]="!contactForm.valid"
            [attr.data-testid]="DATA_TEST_ID.contact.form.submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
