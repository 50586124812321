<div class="container">
  <val-page-title [pageTitle]="pageTitle"></val-page-title>

  <div class="table-wrapper mt-2 align-ngx-row-center">
    <ngx-datatable
      class="bootstrap"
      [columnMode]="'flex'"
      [rows]="carts"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [limit]="apiOptions.itemsPerPage"
    >
      <ngx-datatable-column name="Cart Name" prop="cartName" [flexGrow]="3">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <a role="button" class="datatable-anchor-tag" (click)="onEditCart(row.cartId)">{{ row.cartName }}</a>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Created By" prop="instructions" [flexGrow]="3"></ngx-datatable-column>
      <ngx-datatable-column name="Date Modified" prop="approvalRequestDate" [flexGrow]="3">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="d-flex">
            {{ row.approvalRequestDate | date : "MM-dd-YYYY" }}
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Message" prop="orderNotes" [flexGrow]="3"></ngx-datatable-column>
      <ngx-datatable-column
        [flexGrow]="2"
        [sortable]="false"
        [headerClass]="'text-end'"
        [cellClass]="'d-flex justify-content-end text-nowrap'"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <button
            class="btn p-2"
            title="Edit Order"
            (click)="onEditCart(row.cartId)"
            [disabled]="!row.approvers?.includes(email) || !allowApproveOrder"
          >
            <i class="material-icons-outlined">edit</i>
          </button>
          <button
            class="btn p-2"
            title="Approve Order"
            (click)="onApproveCart(row)"
            [disabled]="!row.approvers?.includes(email) || !allowApproveOrder"
          >
            <i class="material-icons-outlined">shopping_cart</i>
          </button>
          <button
            class="btn p-2"
            title="Delete Order"
            (click)="onDelete(row)"
            [disabled]="!row.approvers?.includes(email)"
          >
            <i class="material-icons-outlined">delete</i>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
