<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.close(false)"
    data-bs-dismiss="modal"
    aria-label="Close"
  ></button>
</div>
<div class="modal-body px-5">
  <div class="add-product-form align-items-center">
    <div class="add-product">
      <div id="search-box">
        <div id="search-panel" class="el-search-panel container-fluid">
          <div *ngIf="allowNonStock && q" class="el-block-wrapper text-center">
            <button class="btn btn-primary btn-sm add-non-stock-item" (click)="addNonStockItem()">
              Add Non-Stock Item
            </button>
          </div>
          <div *ngIf="showResults && products.length > 0" class="el-block-wrapper">
            <div class="row">
              <div class="col-6">
                <h4 class="el-block-heading">Products</h4>
              </div>
            </div>
            <div *ngFor="let product of products" (click)="addProduct(product)" class="media el-product-wrapper">
              <div class="media-left">
                <img
                  class="media-object"
                  [src]="product.image || '/assets/img/no-image-en.png'"
                  [alt]="product.title"
                  fallbackImage
                />
              </div>
              <div class="media-body">
                <span class="media-heading">
                  {{ product.title }}
                </span>
                <div class="sku font-medium"><span>Product Code</span>: {{ product.itemId }}</div>
                <div class="vendor font-medium"><span>Manufacturer</span>: {{ product.manufacturer }}</div>
              </div>
              <span class="btn btn-sm btn-success">
                <i class="material-icons-outlined icon">add</i>
              </span>
            </div>
          </div>
          <div *ngIf="showNoResult && !allowNonStock" class="el-block-wrapper">
            <p>No results found</p>
          </div>
        </div>
        <form id="SearchBar" class="form-inline">
          <div class="input-group">
            <input
              type="text"
              name="q"
              class="form-control"
              placeholder="Product Code/Name"
              aria-label="Product Code/Name"
              aria-describedby="search-button"
              autocomplete="off"
              [(ngModel)]="q"
              (keyup)="onSearch()"
            />
          </div>
        </form>
      </div>
    </div>
  </div>

  <div *ngIf="addedProducts.length" id="addedSelection">
    <div class="val-th">Added Products</div>
    <div class="val-details small">
      <ol class="ps-2 mb-0">
        <li *ngFor="let product of addedProducts" class="border-bottom pb-2 mb-2">
          {{ product.itemId }}
          <div class="font-medium">{{ product.title }}</div>
        </li>
      </ol>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-sm" (click)="activeModal.close(false)">Close</button>
</div>
