import { Action, ActionCreator, ActionReducer, ActionType, createReducer, ReducerTypes } from '@ngrx/store';

/**
 * S = generic state
 * A = generic Action
 *
 * IMPORTANT!
 * Only use this for state items that need to persist through
 * page refresh.
 *
 * @param key
 * @param initialState
 * @param ons(reducer on() methods)
 */
export function createReHydrationReducer<S, A extends Action = Action>(
  key: string,
  initialState: S,
  ...ons: ReducerTypes<S, ActionCreator[]>[]
): ActionReducer<S, A> {
  // Check for the presence of the window object
  const isBrowser = typeof window !== 'undefined';

  // get item from localStorage only if window object exists
  const item = isBrowser ? window.localStorage.getItem(key) : null;

  // set newInitialState to the item if it exists, or use the initial state provided.
  const newInitialState = (item && JSON.parse(item)) ?? initialState;

  // Create new On action array.
  const newOns: ReducerTypes<S, ActionCreator[]>[] = [];

  // Loop through provided on actions
  ons.forEach((oldOn: ReducerTypes<S, ActionCreator[]>) => {
    // Create new reducer
    const newReducer: ActionReducer<S, A> = (state: S | undefined, action: ActionType<ActionCreator[][number]>) => {
      // @ts-ignore
      const newState = oldOn.reducer(state, action);

      // Update client storage only if window object exists
      if (isBrowser) {
        window.localStorage.setItem(key, JSON.stringify(newState));
      }

      return newState;
    };

    // Push new on actions
    // @ts-ignore
    newOns.push({ ...oldOn, reducer: newReducer });
  });

  /**
   * Return new reducer using Store's createReducer method
   *
   * Pass the newInitialState value and the new on actions
   * created in the above forEach.
   */
  return createReducer(newInitialState, ...newOns);
}
