<div class="container-fluid listing">
  <div class="container">
    <val-page-title [pageTitle]="pageTitle"></val-page-title>
    <div class="container">
      <val-import-export
        [actionType]="importActions"
        [pageType]="PageType.IMPORT_ORDER"
        (importedData)="dataFromImport($event)"
      ></val-import-export>
    </div>

    <div *ngIf="importedProducts.invalidProducts!.length > 0" class="col-sm-12">
      <div class="d-flex justify-content-between">
        <h6><span class="val-icon i-18 text-danger">block</span> Invalid products found in file</h6>
        <a class="align-items-center d-flex" (click)="importedProducts.invalidProducts = []"
          >close<i class="val-icon i-18 close"></i
        ></a>
      </div>

      <div class="row mx-1 invalid-products" id="invalidResults">
        <div
          *ngFor="let product of importedProducts.invalidProducts"
          class="col-sm-6 col-md-3 d-flex justify-content-between"
        >
          <span>{{ product.value }}</span>
          <span *ngIf="product.quantity" class="mt-2">{{ product.quantity }}</span>
        </div>
      </div>
    </div>

    <div *ngIf="importedProducts.products && importedProducts.products.length > 0">
      <div class="col-6 title pt-3">Products in <span>Import Order</span></div>
      <div class="row">
        <div class="col-md-8 col-lg-9 mb-5">
          <val-item-list
            (removeItem)="handleRemoveItem($event)"
            [products]="importedProducts.products || []"
            [pageType]="pageTypeEnum.IMPORT_ORDER"
          ></val-item-list>
        </div>

        <div class="col-md-4 col-lg-3 mb-5">
          <div class="sticky-md-top order-summary">
            <div class="val-th">Summary</div>
            <div class="val-details order-totals">
              <div class="sub-total">
                <span>Order items</span><span>{{ importedProducts.products.length }}</span>
              </div>
            </div>

            <div class="val-details">
              <button class="btn btn-lg btn-primary text-nowrap" type="button" (click)="addToCart()"   [disabled]="isLoading.price">
                Proceed to cart
              </button>
              <p class="supp text-center mt-3 mb-0">Imported items will be merged to the existing cart.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
