import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'val-footer-links',
  templateUrl: './footer-links.component.html',
  styleUrls: ['./footer-links.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterLinksComponent implements OnInit {
  @Input() links: any[];

  constructor() {}

  ngOnInit(): void {}
}
