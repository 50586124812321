<div style="height: 275px">
  <canvas
    baseChart
    [data]="barChartData"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [type]="'bar'"
    width="2"
    height="1"
  >
  </canvas>
</div>
