import { Injectable } from '@angular/core';
import { BaseCartComponent } from '../../../shared/components/base-cart/base-cart.component';
import { ApiService } from '../api/api.service';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../../types/app-state.interface';
import { SearchService } from '../search/search.service';
import { ToastService } from '../../../shared/components/toast/toast.service';
import { LoadingSpinnerService } from '../../../shared/components/loading-spinner/loading-spinner.service';
import { Router } from '@angular/router';
import { PricingService } from '../pricing/pricing.service';

@Injectable({
  providedIn: 'root',
})
export class ApprovalCartService extends BaseCartComponent {
  constructor(
    api: ApiService,
    loading: LoadingSpinnerService,
    router: Router,
    search: SearchService,
    store: Store<AppStateInterface>,
    toast: ToastService,
    pricing: PricingService
  ) {
    super(api, loading, router, search, store, toast, pricing);
  }

  // TODO - Move functionality from OrderApprovalService into this file
  // For now, we only use this to access the setSelectedWarehouse() method
}
