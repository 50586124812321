import { Component, Input, OnInit } from '@angular/core';
import { ProductResult } from '../../../../core/services/search/search.model';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';

@Component({
  selector: 'val-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss'],
})
export class ProductPriceComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() product: ProductResult;
  @Input() isLoading?: boolean;
  @Input() isList?: boolean;
  @Input() index?: number = 0;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.product && !this.product.price) {
      this.product.price = 0;
    }
  }
}
