import { FormGroup } from '@angular/forms';
import { Library, Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';

export const mapOptions: { apiKey: string; libraries: Library[] } = {
  apiKey: environment.googleMapsApi,
  libraries: ['places'],
};

export const initGoogleMaps = (form: FormGroup) => {
  new Loader(mapOptions).load().then(() => {
    initAutoComplete(form);
  });
};

export const initAutoComplete = (form: FormGroup) => {
  const addressId = 'address1';
  let address1Field = document?.querySelector('#' + addressId) as HTMLInputElement;
  let autocomplete: google.maps.places.Autocomplete;

  /**
   * Create the autocomplete object, restricting the search predictions to
   * addresses in the US and Canada.
   */
  autocomplete = new google.maps.places.Autocomplete(address1Field, {
    /**
     * TODO: Don't forget to add "us" back to componentRestrictions.
     */
    componentRestrictions: { country: ['ca'] },
    fields: ['address_components'],
    types: ['address'],
  });

  /**
   * When the user selects an address from the drop-down, populate the
   * address fields in the form.
   *
   * NOTE: Adding a callback function as the second argument to addListener
   * keeps the autocomplete object in scope.
   */
  autocomplete.addListener('place_changed', () => {
    fillInAddress(autocomplete, form, addressId);
  });
};

export const fillInAddress = (autocomplete: google.maps.places.Autocomplete, form: FormGroup, address: string) => {
  // Get the place details from the autocomplete object.
  const place = autocomplete.getPlace();
  let address1 = '';
  let postcode = '';
  let city = '';
  let state = '';
  let province = '';

  /**
   * Get each component of the address from the place details,
   * and then fill-in the corresponding field on the form.
   */

  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr
  if (place.address_components) {
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          address1 = `${component.long_name} ${address1}`;
          // Using patchValue here to properly update the form control.
          form.controls[address].patchValue(address1);
          break;
        }

        case 'route': {
          address1 += component.short_name;
          form.controls[address].patchValue(address1);
          break;
        }

        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`;
          form.controls['postalCode'].patchValue(postcode);
          break;
        }

        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case 'locality':
          if (window?.document) {
            (document.querySelector('#city') as HTMLInputElement).value = component.long_name;
            city = component.long_name;
            form.controls['city'].patchValue(city);
          }
          break;

        case 'administrative_area_level_1': {
          /**
           * TODO: Handle state option
           */
          // state = component.short_name;
          // this.addOrEditAddressForm.controls['state'].patchValue(state);
          province = component.short_name;
          form.controls['province'].patchValue(province);
          break;
        }
      }
    }
  }
};
