import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidator } from '../../../../core/custom-validators/custom-validator';
import { Loader } from '@googlemaps/js-api-loader';
import { ErrorsModel } from '../../../../core/services/form/errors.model';
import { WINDOW } from 'src/app/app.module';
import { mapOptions } from '../../../consts/google-maps';

@Component({
  selector: 'val-customer-address-form',
  templateUrl: './customer-address-form.component.html',
  styleUrls: ['./customer-address-form.component.scss'],
})
export class CustomerAddressFormComponent implements OnInit {
  @Output() formReady = new EventEmitter<FormGroup>();
  @ViewChild('addresstext', { static: false }) addresstext: ElementRef;
  public addOrEditAddressForm: FormGroup;
  public responseError: ErrorsModel | null;
  public errors: string[] = [];
  private autocomplete: google.maps.places.Autocomplete;
  public address1UniqueId = 'address1_' + (Math.random() + 1).toString(36).substring(7);

  constructor(@Inject(WINDOW) private window: Window) {}

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.responseError = null;
    this.addOrEditAddressForm = new FormGroup({
      siteName: new FormControl('', {
        validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50)],
        updateOn: 'blur',
      }),
      address1: new FormControl(''),
      [this.address1UniqueId]: new FormControl('', {
        validators: [Validators.required, Validators.minLength(2), Validators.maxLength(60)],
        updateOn: 'blur',
      }),
      unitNumber: new FormControl('', {
        validators: [
          CustomValidator.patternValidator(/\d/, { hasNumber: true }),
          Validators.min(1),
          Validators.max(9999),
        ],
        updateOn: 'blur',
      }),
      postalCode: new FormControl(
        { value: '', disabled: true },
        {
          validators: [Validators.required, CustomValidator.patternValidator(/\d/, { hasNumber: true })],
          updateOn: 'blur',
        }
      ),
      city: new FormControl(
        { value: '', disabled: true },
        {
          validators: [Validators.required],
          updateOn: 'change',
        }
      ),
      province: new FormControl(
        { value: '', disabled: true },
        {
          validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50)],
          updateOn: 'change',
        }
      ),
    });
    // this.addOrEditAddressForm.get('address1')?.patchValue(this.addOrEditAddressForm.value.address1);
    this.addOrEditAddressForm.get(this.address1UniqueId)?.valueChanges.subscribe((x) => {
      this.addOrEditAddressForm.get('address1')?.patchValue(x);
    });

    this.initGoogleMaps();
    this.formReady.emit(this.addOrEditAddressForm);
  }

  /**
   * Initialize the google maps loader.
   *
   * This will load the google maps api, library we are using,
   * and then call the initAutoComplete
   */
  private initGoogleMaps() {
    new Loader(mapOptions).load().then(() => {
      this.initAutoComplete();
    });
  }

  /**
   * TODO: Add in US vs CA logic from the environment file.
   */
  private initAutoComplete() {
    let address1Field = this.window?.document.querySelector('#' + this.address1UniqueId) as HTMLInputElement;

    /**
     * Create the autocomplete object, restricting the search predictions to
     * addresses in the US and Canada.
     */
    this.autocomplete = new google.maps.places.Autocomplete(address1Field, {
      /**
       * TODO: Don't forget to add "us" back to componentRestrictions.
       */
      componentRestrictions: { country: ['ca'] },
      fields: ['address_components'],
      types: ['address'],
    });

    /**
     * When the user selects an address from the drop-down, populate the
     * address fields in the form.
     *
     * NOTE: Adding a callback function as the second argument to addListener
     * keeps the autocomplete object in scope.
     */
    this.autocomplete.addListener('place_changed', () => {
      this.fillInAddress(this.autocomplete);
    });
  }

  private fillInAddress(autocomplete: google.maps.places.Autocomplete) {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();
    let address1 = '';
    let postcode = '';
    let city = '';
    let state = '';
    let province = '';

    /**
     * Get each component of the address from the place details,
     * and then fill-in the corresponding field on the form.
     */

    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    if (place.address_components) {
      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            // Using patchValue here to properly update the form control.
            this.addOrEditAddressForm.controls[this.address1UniqueId].patchValue(address1);
            break;
          }

          case 'route': {
            address1 += component.short_name;
            this.addOrEditAddressForm.controls[this.address1UniqueId].patchValue(address1);
            break;
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            this.addOrEditAddressForm.controls['postalCode'].patchValue(postcode);
            break;
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }

          case 'locality':
            (this.window?.document.querySelector('#city') as HTMLInputElement).value = component.long_name;
            city = component.long_name;
            this.addOrEditAddressForm.controls['city'].patchValue(city);
            break;

          case 'administrative_area_level_1': {
            /**
             * TODO: Handle state option
             */
            // state = component.short_name;
            // this.addOrEditAddressForm.controls['state'].patchValue(state);
            province = component.short_name;
            this.addOrEditAddressForm.controls['province'].patchValue(province);
            break;
          }
        }
      }
    }
  }
}
