import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { LoadingSpinnerService } from 'src/app/shared/components/loading-spinner/loading-spinner.service';
import { ToastService } from 'src/app/shared/components/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadDocumentsService {
  constructor(private loading: LoadingSpinnerService, private toast: ToastService) {}

  private fileName(file: string): string {
    const paths = file.split('/');
    return paths.pop() || 'default_filename.pdf';
  }

  private getFormattedDate(): string {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}_${day}_${year}`;
  }

  async downloadAllFiles(attachments: string[]): Promise<void> {
    if (!attachments || !attachments.length) return;

    this.loading.start();
    const zip = new JSZip();

    try {
      for (const file of attachments) {
        const blob = await fetch(file).then((resp) => {
          if (!resp.ok) {
            throw new Error(`Failed to fetch the file: ${file}. Status: ${resp.statusText}`);
          }
          return resp.blob();
        });
        zip.file(this.fileName(file), blob);
      }

      const formattedDate = this.getFormattedDate();
      zip.generateAsync({ type: 'blob' }).then((blob) => {
        saveAs(blob, `Documents_${formattedDate}.zip`);
        this.loading.stop();
      });
    } catch (error) {
      this.loading.stop();
      this.handleError('Error downloading the document(s)');
    }
  }

  private handleError(error: string) {
    this.toast.showError(error);
    this.loading.stop();
  }
}
