import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarLinkComponent } from './components/navbar/navbar-link/navbar-link.component';
import { RouterModule } from '@angular/router';
import { KontentBannerComponent } from './components/kontent/templates/kontent-banner/kontent-banner.component';
import { KontentGenericComponent } from './components/kontent/templates/kontent-generic/kontent-generic.component';
import { SharedModule } from '../shared/shared.module';
import { KontentGenericPageBaseContentComponent } from './components/kontent/templates/kontent-generic-page-base-content/kontent-generic-page-base-content.component';
import { KontentSharedAssetCarouselComponent } from './components/kontent/templates/kontent-shared-asset-carousel/kontent-shared-asset-carousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { KontentTinyBannerComponent } from './components/kontent/templates/kontent-tiny-banner/kontent-tiny-banner.component';
import { KontentHeroSliderComponent } from './components/kontent/templates/kontent-hero-slider/kontent-hero-slider.component';
import { KontentFormComponent } from './components/kontent/templates/kontent-form/kontent-form.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { KontentFancyImageBannerComponent } from './components/kontent/templates/kontent-fancy-image-banner/kontent-fancy-image-banner.component';
import { KontentArticleDetailsComponent } from './components/kontent/templates/kontent-article-details/kontent-article-details.component';
import { SearchFormComponent } from './components/header/search-form/search-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KontentArticleListComponent } from './components/kontent/templates/kontent-article-list/kontent-article-list.component';
import { KontentSuppliersComponent } from './components/kontent/templates/kontent-suppliers/kontent-suppliers.component';
import { KontentLocationsComponent } from './components/kontent/templates/kontent-locations/kontent-locations.component';
import { KontentContactComponent } from './components/kontent/templates/kontent-contact/kontent-contact.component';
import { NavbarProductListsComponent } from './components/navbar/navbar-product-lists/navbar-product-lists.component';
import { KontentDashboardBannerComponent } from './components/kontent/templates/kontent-dashboard-banner/kontent-dashboard-banner.component';
import { KontentConnectWithUsComponent } from './components/kontent/templates/kontent-connect-with-us/kontent-connect-with-us.component';
import { NewsletterSignupComponent } from './components/footer/newsletter-signup/newsletter-signup.component';
import { ProductResultsComponent } from './components/header/search-form/product-results/product-results.component';

@NgModule({
  declarations: [
    NavbarLinkComponent,
    KontentBannerComponent,
    KontentGenericComponent,
    KontentGenericPageBaseContentComponent,
    KontentSharedAssetCarouselComponent,
    KontentTinyBannerComponent,
    KontentHeroSliderComponent,
    KontentFormComponent,
    KontentFancyImageBannerComponent,
    KontentArticleDetailsComponent,
    SearchFormComponent,
    KontentArticleListComponent,
    KontentSuppliersComponent,
    KontentLocationsComponent,
    KontentContactComponent,
    NavbarProductListsComponent,
    KontentDashboardBannerComponent,
    KontentConnectWithUsComponent,
    NewsletterSignupComponent,
    ProductResultsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    NgbCarouselModule,
  ],
  exports: [
    NavbarLinkComponent,
    KontentBannerComponent,
    SearchFormComponent,
    KontentDashboardBannerComponent,
    NewsletterSignupComponent,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`CoreModule has already been loaded. Import CoreModule in the AppModule only.`);
    }
  }
}
