import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { PageTitle } from '../../shared/components/page-title/page-title.model';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { Cart, CartLine } from '../../types/cart.model';
import { NavigationExtras, Router } from '@angular/router';
import { ToastService } from '../../shared/components/toast/toast.service';
import { UserModel } from '../../core/services/user/user.model';
import { LoadingSpinnerService } from '../../shared/components/loading-spinner/loading-spinner.service';
import { Store } from '@ngrx/store';
import { API_URL } from '../../shared/consts/api-urls';
import { ApiService } from '../../core/services/api/api.service';
import { updateCartField } from '../../core/store/cart/cart.actions';
import { AppStateInterface } from 'src/app/types/app-state.interface';
import { PageType } from '../../shared/consts/page-type';
import { UserService } from '../../core/services/user/user.service';
import { PermissionService } from '../../core/services/permission/permission.service';
import { PunchoutService } from '../../core/services/punchout/punchout.service';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { WarehouseNameNumber } from '../../types/warehouse.model';
import { environment } from '../../../environments/environment';
import { Observable, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingStatus } from '../../types/loading-status.model';
import { FormGroup } from '@angular/forms';
import { ProductListService } from 'src/app/core/services/product-list/product-list.service';
import { SiteCartService } from '../../core/services/cart/site-cart.service';
import { PricingService } from '../../core/services/pricing/pricing.service';
import { ShowDetails } from 'src/app/shared/components/products/item-list-actions/item-list-actions.component';
import { WINDOW } from 'src/app/app.module';
import { selectCartProperty } from '../../core/store/cart/cart.selectors';

@Component({
  selector: 'val-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartComponent extends BaseSubscriptionComponent implements OnInit {
  user: UserModel | null | undefined;
  pageTitle = new PageTitle('Shopping Cart', 'shopping_cart', '');
  cartItems$: Observable<CartLine[] | undefined>;
  warehouse$: Observable<string | undefined>;
  shippingMethod$: Observable<string | undefined>;
  cart: Cart;
  shippingMethods = ['delivery', 'pickup'];
  whseError: string;
  submitBtn = '';
  isLoading: LoadingStatus;
  pageTypeEnum = PageType;
  isPunchOut = false;
  pickupWarehouses: string[] = [];
  deliveryWarehouses: string[] = [];
  warehouses: WarehouseNameNumber[];
  hasError: boolean = false;
  activePanelIds: string[] = [];
  checkoutInfo: any;
  form: FormGroup;
  checkOrderLimits: boolean;
  monthlyOrderTotal: number = 0;
  canViewPrices: boolean;
  isCollapsedDetails: ShowDetails = {
    collapsed: true,
    classes: ['details'],
  };

  constructor(
    private siteCart: SiteCartService,
    private router: Router,
    public toast: ToastService,
    private store: Store<AppStateInterface>,
    private api: ApiService,
    private loadingService: LoadingSpinnerService,
    private userService: UserService,
    private punchoutService: PunchoutService,
    private authenticationService: AuthenticationService,
    public listService: ProductListService,
    private permissionService: PermissionService,
    private pricingService: PricingService,
    @Inject(WINDOW) private window: Window
  ) {
    super();
    // Selecting observables from the store to track cart items, selected warehouse, and shipping method.
    this.cartItems$ = this.store.select(selectCartProperty('cartItems')) as Observable<CartLine[] | undefined>;
    this.warehouse$ = this.store.select(selectCartProperty('selectedWarehouse')) as Observable<string | undefined>;
    this.shippingMethod$ = this.store.select(selectCartProperty('shippingMethod')) as Observable<string | undefined>;
  }

  async ngOnInit(): Promise<void> {
    if (this.window) {
      try {
        // Initialize the site cart, checking for invalid items (only needed on the cart page).
        // This is awaited to ensure the cart initialization completes before proceeding.
        await this.siteCart.initSiteCart(true);

        // Subscribe to observables and call necessary methods after the cart is initialized.
        this.subscribeToLoadingPrices();
        this.subscribeToCartChanges();
        this.subscribeToUserChanges();
        this.subscribeToWarehouseChanges();
        this.checkCartAndUserConditions();
      } catch (error) {
        console.error('Error initializing site cart:', error);
      }
    }
  }

  private subscribeToLoadingPrices(): void {
    // Subscribe to loading status for pricing updates.
    // Uses takeUntil to automatically unsubscribe when the component is destroyed.
    this.loadingService
      .getLoadingPrices()
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.isLoading = data;
      });
  }

  private subscribeToCartChanges(): void {
    // Subscribe to changes in the cart state from the store.
    // Updates the local cart object and assigns a unique ID to each cart item.
    this.store
      .select('cart')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.cart = data?.cart as Cart;
        if (this.cart && this.cart.cartItems) {
          this.cart.cartItems = this.cart.cartItems.map((item, index) => {
            return {
              ...item,
              uniqueId: index,
            };
          });
        }
      });
  }

  private subscribeToWarehouseChanges(): void {
    // Subscribe to changes in the warehouse state from the store.
    // Updates the local warehouses array with the latest data.
    this.store
      .select('warehouse')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.warehouses = data?.warehouses || [];
      });
  }

  private subscribeToUserChanges(): void {
    // Subscribe to changes in the user state from the store.
    // Updates local variables based on the user's permissions and context.
    this.store
      .select('user')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.user = data.user;
        this.canViewPrices = this.permissionService.canViewPrices();

        // Check if order limits need to be enforced based on user type and company workflow.
        this.checkOrderLimits =
          !data.shoppingContext?.customerCompanyNumber.approvalWorkflow! && this.userService.isB2B(this.user);
        if (this.checkOrderLimits && !this.monthlyOrderTotal) {
          // Fetch the total order amount for the current month if not already fetched.
          this.api.get(`${API_URL.User}/${this.user?.userId}/orders/mtd`, null, false).subscribe((x: any) => {
            this.monthlyOrderTotal = x.total;
          });
        }

        this.isPunchOut = this.userService.isPunchOut(this.user);
        this.checkoutInfo = this.siteCart.checkoutPermissions();
        this.submitBtn = this.checkoutInfo.cart;
        if (this.submitBtn === 'showSendForApproval') {
          this.activePanelIds = ['showSendForApproval'];
        }
      });
  }

  private checkCartAndUserConditions(): void {
    // Determine available warehouses and reset the selected warehouse based on the user and cart context.
    if (this.warehouses && (!this.user || this.userService.isB2C(this.user))) {
      // For B2C users or no user, use the default warehouse.
      this.deliveryWarehouses = [environment.warehouse];
      this.pickupWarehouses = this.warehouses.map((x) => x.warehouseNumber);
      this.updateWarehouse(true);
    } else {
      // For B2B users, get the user's assigned warehouses.
      this.getUserWarehouses();
    }
  }

  showDetails(event: any) {
    // Toggle the collapse state for the product details section.
    this.isCollapsedDetails = event;
  }

  onProductsAdded(e: number) {
    // Refresh item availability when products are added to the cart.
    if (e) {
      this.getItemAvailability();
    }
  }

  // TODO: Remove
  isOverLimit(): boolean {
    // Check if the user's cart exceeds monthly or per-order limits, if specified.
    if (!this.checkOrderLimits) {
      return false;
    }

    const monthlyLimit = this.cart?.maximumAmountByMonth || 0;
    const transactionLimit = this.cart?.maximumAmountByOrder || 0;
    const cartSubTotal = this.cart?.subTotal || 0;

    return (
      (monthlyLimit > 0 && cartSubTotal + this.monthlyOrderTotal > monthlyLimit) ||
      (transactionLimit > 0 && cartSubTotal > transactionLimit)
    );
  }

  private updateCartSettings(
    shippingMethod?: string,
    billingMethod?: string,
    selectedWarehouse?: string,
    warehouseNumber?: string
  ): void {
    shippingMethod = shippingMethod || this.cart?.shippingMethod;
    billingMethod = billingMethod || this.cart?.billingMethod;
    selectedWarehouse = selectedWarehouse || this.cart?.selectedWarehouse;
    warehouseNumber = warehouseNumber || this.cart?.warehouseNumber;

    this.store.dispatch(updateCartField({ key: 'shippingMethod', value: shippingMethod }));
    this.store.dispatch(updateCartField({ key: 'billingMethod', value: billingMethod }));
    this.store.dispatch(updateCartField({ key: 'selectedWarehouse', value: selectedWarehouse }));
    this.store.dispatch(updateCartField({ key: 'warehouseNumber', value: warehouseNumber }));
    this.siteCart.setSelectedWarehouse(this.cart, this.pickupWarehouses, this.deliveryWarehouses);
  }

  // TODO: This method has been moved to base-cart-component
  private getUserWarehouses(): void {
    // Get the user's assigned delivery and pickup warehouses.
    this.deliveryWarehouses = this.user?.currentShoppingAccount?.deliveryWarehouses?.length
      ? this.user.currentShoppingAccount.deliveryWarehouses
      : [environment.warehouse];

    this.pickupWarehouses = this.user?.currentShoppingAccount?.warehouses?.length
      ? this.user.currentShoppingAccount.warehouses
      : [environment.warehouse];

    this.updateWarehouse(true);
  }

  private getItemAvailability() {
    // Check item availability and update pricing for the items in the cart.
    if (!this.cart?.cartItems || !this.cart?.cartItems?.length) {
      return;
    }
    this.loadingService.setLoadingPrices(['summary', 'price', 'total']);
    this.pricingService.getPricing(this.cart?.cartItems, this.cart.selectedWarehouse).subscribe({
      next: (data: any) => {
        this.loadingService.setLoadingPrices();
        if (this.cart) {
          this.store.dispatch(updateCartField({ key: 'cartItems', value: data }));
          // true flag enables item audit on cart page
          this.siteCart.calculateSubTotal(true, this.cart);
        } else {
          console.error('Cart is null');
        }
      },
      error: (err) => {
        console.error('Error updating prices:', err);
        this.toast.showError('Error fetching pricing');
      },
    });
  }

  handleEvent(event: string) {
    // Handle user events such as checkout or requesting a quote.
    if (event === 'checkout') {
      this.onCheckOut();
    }

    if (event === 'request-quote') {
      this.onRequestQuote();
    }
  }

  onCheckOut(paymentType?: string) {
    // Handle the checkout process, including checking for limits and processing PunchOut.
    this.cart.warehouseNumber = this.cart.selectedWarehouse ? this.cart.selectedWarehouse : environment.warehouse;

    if (!this.cart?.shippingMethod && !this.isPunchOut) {
      this.toast.show('Please select a shipping method', {
        classname: 'alert-danger',
      });
      return;
    }

    // Ensure the user does not exceed order limits.
    if (this.isOverLimit()) {
      return;
    }

    this.loadingService.start();
    const apiCall: Observable<object>[] = [];
    let postData: any = {};
    if (this.isPunchOut) {
      // Process PunchOut checkout
      postData = this.punchoutService.checkout();
      apiCall.push(this.api.post(`${API_URL.PunchoutSubmit}`, postData));
    }

    if (!this.isPunchOut) {
      const billingMethod = paymentType ? paymentType : this.cart.billingMethod;

      // Update cart with the selected shipping method, warehouse number, and billingMethod.
      this.updateCartSettings(this.cart.shippingMethod.toLowerCase(), billingMethod);
      if (this.user) {
        const cartCopy = { ...this.cart };
        apiCall.push(this.api.put(`${API_URL.Carts}/${this.cart.cartId}`, cartCopy));
      } else {
        this.router.navigate(['/checkout/shippingbilling']);
      }
    }

    if (apiCall.length) {
      // Execute API calls and handle the response.
      forkJoin(apiCall).subscribe({
        next: () => {
          this.loadingService.stop();
          if (this.isPunchOut) {
            this.api.delete(`${API_URL.Carts}/${this.cart.cartId}/delete_all`).subscribe({
              next: () => {
                this.authenticationService.logout();
              },
            });
          } else {
            this.router.navigate(['/checkout/shippingbilling']);
          }
        },
        error: (error) => {
          this.loadingService.stop();
          this.api.toastError(error);
        },
      });
    } else {
      this.loadingService.stop();
    }
  }

  updateWarehouse(onLoad?: boolean, method?: string) {
    // Update the cart's warehouse number and check item availability.
    if (!this.cart?.warehouseNumber || !onLoad) {
      const warehouses = this.cart.shippingMethod === 'pickup' ? this.pickupWarehouses : this.deliveryWarehouses;

      const warehouseNumber =
        warehouses.length === 1
          ? warehouses[0]
          : this.cart.selectedWarehouse
          ? this.cart.selectedWarehouse
          : environment.warehouse;

      if (this.cart?.cartId) {
        const newCart = { ...this.cart, shippingMethod: method ? method : this.cart.shippingMethod, warehouseNumber };
        this.loadingService.setLoadingPrices(['summary', 'price', 'total']);
        this.api.put(`${API_URL.Carts}/${this.cart.cartId}`, newCart).subscribe({
          next: () => {
            this.updateCartSettings(method, '', newCart.selectedWarehouse, warehouseNumber);
            this.getItemAvailability();
          },
          error: (error) => {
            this.toast.showError('Error Updating Cart');
            this.loadingService.setLoadingPrices();
            console.error(error);
          },
        });
      } else {
        this.updateCartSettings(method, '', this.cart?.selectedWarehouse, warehouseNumber);
        this.getItemAvailability();
      }
    } else {
      this.updateCartSettings(method, '', this.cart?.selectedWarehouse);
      this.getItemAvailability();
    }
  }

  onRequestQuote() {
    // Handle the request for a quote, navigating to the appropriate page.
    let params: NavigationExtras = {
      queryParams: {
        isFromCart: true,
      },
    };

    this.router.navigate(['/quotes/create'], params);
  }
}
