import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  // Convert phone number from ########## to ###-###-#### format
  transform(value: string | null | undefined): string | null | undefined {
    const regex = /^[0-9]{10}$/g;
    if (!value || !value.match(regex)) {
      return value;
    }
    return `${value.substring(0, 3)}-${value.substring(3, 6)}-${value.substring(6)}`;
  }
}
