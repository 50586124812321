import { Component, Input, OnInit } from '@angular/core';
import { KontentHeroAsset } from './kontent-hero-asset.model';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { CounterService } from 'src/app/core/services/counter/counter.service';

@Component({
  selector: 'val-kontent-hero-slider',
  templateUrl: './kontent-hero-slider.component.html',
  styleUrls: ['./kontent-hero-slider.component.scss'],
  providers: [NgbCarouselConfig],
})
export class KontentHeroSliderComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() content: any;
  @Input() items: any;
  slides: KontentHeroAsset[] = [];
  currentComponentCount: number;

  constructor(config: NgbCarouselConfig, private counterService: CounterService) {
    super();
    config.interval = 5000;
    config.keyboard = false;
    config.showNavigationArrows = false;
    this.currentComponentCount = this.counterService.getComponentCount('KontentHeroSliderComponent');
  }

  ngOnInit(): void {
    this.formatSlides();
  }

  formatSlides(): void {
    this.content.elements.slides.value.forEach((codeName: string) => {
      if (this.items[codeName]) {
        let item = this.items[codeName];
        this.slides.push({
          id: item.system.id,
          image: item.elements.bannerImage.value[0].url,
          title: item.elements.title.value,
          url: item.elements.actionUrl.value,
          target: item.elements.target.value.length ? item.elements.target.value[0].codename : '',
          classes: this.getClasses(item),
          label: item.elements.buttonLabel.value,
          description: item.elements.description.value,
        });
      }
    });
  }

  getClasses(item: any): string {
    let classes: string[] = [];
    ['displayStyle', 'textAlignment'].forEach((k) => {
      if (item.elements[k] && item.elements[k].value.length) {
        classes.push(
          item.elements[k].value
            .map((s: any) => {
              return s.codename;
            })
            .join(' ')
        );
      }
    });
    return classes.join(' ');
  }
}
