import { Pipe, PipeTransform } from '@angular/core';
import { ProductResult } from '../../../core/services/search/search.model';

@Pipe({
  name: 'disableQuantity',
})
export class DisableQuantityPipe implements PipeTransform {
  transform(
    product: ProductResult,
    isCheckout: boolean,
    isOrderDetail: boolean,
    isLoading: any,
    disableEdit: boolean,
    isHistory: boolean
  ): boolean {
    return isCheckout || (!product.price && !product.isNonStock) || isLoading.summary || disableEdit || (isHistory && !product.isInAlgolia);
  }
}
