<div class="container val-cart">
  <val-page-title [pageTitle]="pageTitle"></val-page-title>

  <div *ngIf="(cartItems$ | async)?.length && !hasError && cart">
    <div class="mode-selection d-flex pb-4" *ngIf="!isPunchOut">
      <div *ngIf="!(shippingMethod$ | async)" class="highlight">
        <p class="supp">Select a shipping method</p>
      </div>
      <div class="mode method" [ngClass]="{ unchecked: !(shippingMethod$ | async) }">
        <label *ngFor="let method of shippingMethods" class="custom-radiobox">
          <input
            [value]="method"
            name="deliveryGroup"
            class="custom-input"
            type="radio"
            (change)="updateWarehouse(false, method)"
            [checked]="(shippingMethod$ | async) === method"
          />
          <span class="custom-radio check"></span>
          <p>{{ method | titlecase }}</p>
        </label>
      </div>
      <div
        class="mode whse e-row select"
        *ngIf="
          ((shippingMethod$ | async) === 'delivery' && deliveryWarehouses.length > 1) ||
          ((shippingMethod$ | async) === 'pickup' && pickupWarehouses.length > 1)
        "
      >
        <label class="e-label dnArw"></label>
        <select class="form-control" [(ngModel)]="cart.selectedWarehouse" (change)="updateWarehouse()">
          <option
            *ngFor="let warehouse of (shippingMethod$ | async) === 'delivery' ? deliveryWarehouses : pickupWarehouses"
            [ngValue]="warehouse"
          >
            {{ warehouse | warehouseName : warehouses }}
          </option>
        </select>
        <p *ngIf="whseError" class="supp error">
          <span class="text-danger field-validation-valid">{{ whseError }}</span>
        </p>
      </div>
    </div>

    <div class="row mx-auto" *ngIf="isOverLimit()">
      <div class="col-lg-9 alert alert-danger text-center mx-auto">Account restrictions.</div>
    </div>

    <val-item-list-actions
      [cart]="cart"
      [products]="(cartItems$ | async) ?? []"
      [pageType]="pageTypeEnum.CART"
      [collapsedDetails]="isCollapsedDetails"
      (onProductsAdded)="onProductsAdded($event)"
      (toggleDetails)="showDetails($event)"
    ></val-item-list-actions>

    <div class="row">
      <div class="col-md-8 col-lg-9 mb-5">
        <val-item-list
          [products]="(cartItems$ | async) ?? []"
          [pageType]="pageTypeEnum.CART"
          [collapse]="isCollapsedDetails"
        ></val-item-list>
      </div>
      <div class="col-md-4 col-lg-3 mb-5">
        <val-item-summary
          [cart]="cart"
          [user]="user"
          [pageType]="pageTypeEnum.CART"
          (actionClicked)="handleEvent($event)"
        ></val-item-summary>
      </div>
    </div>
  </div>

  <div *ngIf="!(cartItems$ | async)?.length && !hasError" class="row justify-content-center">
    <div class="col-lg-6 my-4">
      <div class="alert alert-info text-center" role="alert">Your cart is empty</div>
    </div>
  </div>
  <div *ngIf="hasError" class="row justify-content-center">
    <div class="col-lg-6 my-4">
      <div class="alert alert-danger text-center" role="alert">There is an issue with your cart.</div>
    </div>
  </div>
</div>
