import { Component, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingSpinnerService } from 'src/app/shared/components/loading-spinner/loading-spinner.service';
import { KontentService } from '../../kontent.service';
import { PageTitle } from '../../../../../shared/components/page-title/page-title.model';
import { Loader } from '@googlemaps/js-api-loader';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { mapOptions } from '../../../../../shared/consts/google-maps';

@Component({
  selector: 'val-kontent-locations',
  templateUrl: './kontent-locations.component.html',
  styleUrls: ['./kontent-locations.component.scss'],
})
export class KontentLocationsComponent extends BaseSubscriptionComponent implements AfterViewInit {
  locations: any;
  linkedItems: any;
  map: any;
  pageTitle: PageTitle;
  isSmall: boolean;
  toggleSection: string = 'listing';
  hideMap: boolean = false;

  @ViewChild('mapDiv') mapDiv!: ElementRef;

  ngAfterViewInit() {
    this.getData();
  }

  constructor(
    public kontent: KontentService,
    private loadingService: LoadingSpinnerService,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
    super();
    this.breakpointObserver
      .observe(['(max-width: 991px)'])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result: BreakpointState) => {
        this.isSmall = result.matches;
      });
  }

  getData() {
    this.loadingService.start();

    this.kontent
      .getLocationsPage()
      .then((data) => {
        this.pageTitle = new PageTitle('Locations', 'place', data.data.items.length + ' Locations');
        this.locations = data.data.items.sort((a: any, b: any) => {
          if (a.elements.name.value < b.elements.name.value) return -1;
          if (a.elements.name.value > b.elements.name.value) return 1;
          return 0;
        });
        this.linkedItems = data.data.linkedItems;

        this.initMap();
      })
      .catch((err: any) => {
        console.log('Error: ', err);
        this.router.navigate(['/404']);
      })
      .finally(() => {
        this.loadingService.stop();
      });
  }

  initMap(): void {
    let latLongArr: { lat: string; long: string }[] = [];
    this.locations.forEach((x: any) => {
      latLongArr.push({
        lat: x.elements.latitude.value,
        long: x.elements.longitude.value,
      });
    });

    new Loader(mapOptions)
      .load()
      .then(() => {
        this.map = new google.maps.Map(this.mapDiv.nativeElement as HTMLElement, {
          center: { lat: 40.16516486567089, lng: -101.65547350534983 },
          zoom: 3,
        });
        latLongArr.forEach((x) => {
          let markerOptions = {
            position: new google.maps.LatLng(parseInt(x.lat), parseInt(x.long)),
          };

          let marker = new google.maps.Marker(markerOptions);

          marker.setMap(this.map);
        });
      })
      .catch((err: any) => {
        console.log('Error: ', err);
        this.hideMap = true;
      });
  }

  toggleView(section: string): void {
    if (section !== this.toggleSection) {
      this.toggleSection = section;
    }
  }
}
