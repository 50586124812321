import { Injectable } from '@angular/core';
import { Permissions } from '../../../shared/consts/permissions.enum';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { select, Store } from '@ngrx/store';
import { currentShoppingContextSelector } from '../../store/user/user.selectors';
import { takeUntil } from 'rxjs';
import { AppStateInterface } from '../../../types/app-state.interface';

@Injectable({
  providedIn: 'root',
})
export class PermissionService extends BaseSubscriptionComponent {
  permissions: string[] = [];

  constructor(private store: Store<AppStateInterface>) {
    super();
    this.store
      .pipe(select(currentShoppingContextSelector))
      .pipe(takeUntil(this.destroyed))
      .subscribe((shoppingContext) => {
        this.permissions = shoppingContext?.permissions || [];
      });
  }

  public canViewPrices(): boolean {
    return !this.permissions.length || this.permissions.includes(Permissions.CVP);
  }

  public allowCreditCard(): boolean {
    return this.permissions.includes(Permissions.CC) || !this.permissions.length;
  }

  public allowPurchaseOrder(): boolean {
    return this.permissions.includes(Permissions.PO);
  }

  public contractOnly(): boolean {
    return this.permissions.includes(Permissions.ICO);
  }

  public canViewAllShipTo(): boolean {
    return this.permissions.includes(Permissions.CVAST);
  }

  public allowApproveOrder(): boolean {
    return this.permissions.includes(Permissions.APO);
  }

  public enableCustomerEquote(): boolean {
    return this.permissions.includes(Permissions.ECE);
  }

  public enableISREquote(): boolean {
    return this.permissions.includes(Permissions.EIE);
  }

  public canCreateQuote(): boolean {
    return this.permissions.includes(Permissions.ECE);
  }

  public webOrdersOnly(): boolean {
    return this.permissions.includes(Permissions.IEO);
  }

  public canViewInvoice(): boolean {
    return this.permissions.includes(Permissions.CVI);
  }

  public canUpdateQuicklist(): boolean {
    return this.permissions.includes(Permissions.CUQL);
  }

  public canAllowOrder(): boolean {
    return this.permissions.includes(Permissions.AO);
  }
}
