import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard {
  isLoginModalOpen = false;

  constructor(private authService: AuthenticationService, private router: Router, private modalService: NgbModal) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isTokenExpired() && !this.authService.checkRefreshToken()) {
      this.authService.openLoginModal();
      return false;
    }

    return this.checkUserLogin(next);
  }

  hasPermisisons(permissions: string[], requiredPermissions: string[]): boolean {
    let count = 0;
    requiredPermissions.forEach((item) => permissions.includes(item) && count++);
    return requiredPermissions.length === count;
  }

  checkLockPermisisons(permissions: string[], requiredPermissions: string[]): boolean {
    let count = 0;
    requiredPermissions.forEach((item) => !permissions.includes(item) && count++);
    return count === 0;
  }

  checkUserLogin(route: ActivatedRouteSnapshot): boolean {
    const permissions = this.authService.getPermissions();
    const userType = this.authService.getUserType();

    if (
      !this.hasPermisisons(permissions, route?.data?.['permissions'] || []) ||
      !this.checkLockPermisisons(permissions, route?.data?.['lockPermissions'] || []) ||
      (route.data['userType'] && (!userType || route.data['userType'].indexOf(userType) === -1))
    ) {
      void this.router.navigate(['/unauthorized']);
      return false;
    }
    return true;
  }
}
