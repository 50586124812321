import { Injectable } from '@angular/core';
import { SearchService } from '../search/search.service';
import { CategoryResult } from '../search/search.model';
import { BehaviorSubject } from 'rxjs';
import { CategoryMenu } from '../../../types/category-menu.model';
import { KontentService } from '../../components/kontent/kontent.service';
import { adImage } from '../../../types/ad-image.model';
import { productMenuAd } from '../../../types/product-menu-ad.model';
import { UserState } from '../../store/user/user.reducer';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private search: SearchService, private kontent: KontentService) {}

  public menuAdList: productMenuAd = {}; // list of ad id's for each category
  public categoryAd: adImage = {}; // the actual ad image/URLs
  private categories = new BehaviorSubject(<any[]>[]);
  categories$ = this.categories.asObservable();
  private allCategories = new BehaviorSubject(<any[]>[]);
  allCategories$ = this.allCategories.asObservable();

  public getUserCategories(user: UserState): void {
    this.getCategories(true, user);
  }

  public getAllCategories(): void {
    this.getCategories(false);
  }

  private getCategories(filterByUser: boolean, user?: UserState) {
    let hits: CategoryResult[] = [];
    let facets = ['level:1', 'level:2', 'level:3'];

    const browseObjectsOptions: any = {
      query: '',
      facetFilters: [facets],
      batch: (batch: any) => {
        hits = hits.concat(batch as CategoryResult[]);
      },
    };

    if (filterByUser) {
      browseObjectsOptions.filters = 'hasItems:true';
      if (user && user.shoppingContext?.customerCompanyNumber?.id) {
        browseObjectsOptions.filters += ` AND NOT excludeCustomerCompany:${user.shoppingContext.customerCompanyNumber.id}`;
      }
    }

    const categoryObj = filterByUser ? this.categories : this.allCategories;
    this.search.client
      .initIndex(this.search.indexName.categories)
      .browseObjects(browseObjectsOptions)
      .then(() => {
        this.formatCategories(hits, filterByUser);
      })
      .catch((error) => {
        categoryObj.error(error);
      });
  }

  private formatCategories(hits: CategoryResult[], filterByUser: boolean) {
    const limit = filterByUser ? 5 : 1000;
    const formattedCategories = hits
      .filter((x: CategoryResult) => x.level === 1)
      .map((x: CategoryResult) => {
        return {
          id: x.categoryId,
          name: x.name,
          url: x.url,
          more: x.hasChildren,
          image: x.image,
          categories: hits
            .filter((y: CategoryResult) => y.parentId === x.categoryId)
            .map((y: CategoryResult) => {
              const subCats = hits
                .filter((z: CategoryResult) => z.parentId === y.categoryId)
                .map((z: CategoryResult) => {
                  return {
                    id: z.categoryId,
                    name: z.name,
                    url: z.url,
                    excludeCustomerCompany: x.excludeCustomerCompany,
                  } as CategoryMenu;
                });

              return {
                id: y.categoryId,
                name: y.name,
                url: y.url,
                more: subCats.length > limit,
                categories: subCats.slice(0, limit),
                excludeCustomerCompany: y.excludeCustomerCompany,
              } as CategoryMenu;
            }),
          excludeCustomerCompany: x.excludeCustomerCompany,
          vendorAd: [],
          bannerAd: [],
        };
      });
    if (filterByUser) {
      this.formatProductMenuAds();
    }
    const categoryObj = filterByUser ? this.categories : this.allCategories;
    categoryObj.next(formattedCategories || []);
  }

  private formatProductMenuAds() {
    let codeNames: string[] = [];
    this.kontent.getProductMenuAds().then((res) => {
      res.data.items.forEach((item) => {
        codeNames = codeNames.concat(item.elements.marketingBanner.value, item.elements.manufacturerLogos.value);
        this.menuAdList[item.elements.levelOneCategoryId.value] = {
          banner: item.elements.marketingBanner.value,
          vendor: item.elements.manufacturerLogos.value,
        };
      });
      // only keep unique values, and those that haven't already been fetched
      codeNames = [...new Set(codeNames)].filter((code) => !this.categoryAd[code]);

      if (codeNames.length) {
        // TODO - May need to batch these requests in the future. At time of development, worked fine all at once
        this.kontent.deliveryClient
          .items()
          .inFilter('system.codename', codeNames)
          .toPromise()
          .then((ads) => {
            ads.data.items.forEach((item) => {
              const img =
                item.system.type === 'product_menu_marketing_vendor_asset'
                  ? item.elements['logo']
                  : item.elements['image'];
              this.categoryAd[item.system.codename] = {
                image: img.value[0].url,
                url: item.elements['actionUrl'].value,
                target: item.elements['target'].value[0]?.codename,
              };
            });
            this.setAds();
          })
          .catch((err: any) => {
            console.error(err);
          });
      }
    });
  }

  private setAds() {
    let categories: CategoryMenu[] = [];

    this.categories.value.forEach((cat) => {
      cat.bannerAd = [];
      cat.vendorAd = [];
      if (this.menuAdList[cat.id]) {
        this.menuAdList[cat.id].banner.forEach((id) => {
          if (this.categoryAd[id]) {
            cat.bannerAd.push(this.categoryAd[id]);
          }
        });
        this.menuAdList[cat.id].vendor.forEach((id) => {
          if (this.categoryAd[id]) {
            cat.vendorAd.push(this.categoryAd[id]);
          }
        });
      }
      categories.push(cat);
    });
    this.categories.next(categories || []);
  }
}
