import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DATA_TEST_ID } from 'src/app/shared/consts/data-test-ids';

@Component({
  selector: 'val-base-subscription',
  template: '',
})
export class BaseSubscriptionComponent implements OnDestroy {
  destroyed = new Subject();
  DATA_TEST_ID = DATA_TEST_ID;

  constructor() {}

  ngOnDestroy() {
    this.destroyed.next(null);
    this.destroyed.complete();
  }
}
