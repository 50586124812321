import { Component, Input } from '@angular/core';
import { ProductResult } from '../../../../core/services/search/search.model';
import { select, Store } from '@ngrx/store';
import { AppStateInterface } from '../../../../types/app-state.interface';
import { WarehouseNameNumber } from 'src/app/types/warehouse.model';
import { PricingService } from '../../../../core/services/pricing/pricing.service';
import { BaseSubscriptionComponent } from '../../base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { UserModel } from '../../../../core/services/user/user.model';
import { LoadingSpinnerService } from '../../loading-spinner/loading-spinner.service';
import { UserShoppingContext } from '../../../../core/services/customer-company/user-shopping-context.model';
import { currentShoppingContextSelector } from '../../../../core/store/user/user.selectors';
import { UserService } from '../../../../core/services/user/user.service';

@Component({
  selector: 'val-product-availability',
  templateUrl: './product-availability.component.html',
  styleUrls: ['./product-availability.component.scss'],
})
export class ProductAvailabilityComponent extends BaseSubscriptionComponent {
  @Input() product?: ProductResult;
  @Input() isCart?: boolean;
  @Input() index?: number = 0;
  warehouses: WarehouseNameNumber[] = [];
  availableWarehouses: any = [];
  allWarehouses: string[] = [];
  user?: UserModel;
  pricesLoading: boolean;
  isBuyer: boolean;
  private shoppingContext$ = this.store.pipe(select(currentShoppingContextSelector));
  private shoppingContext?: UserShoppingContext;

  constructor(
    private store: Store<AppStateInterface>,
    private pricing: PricingService,
    private loading: LoadingSpinnerService,
    private userService: UserService
  ) {
    super();
    this.store
      .select('warehouse')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.warehouses = data?.warehouses || [];
      });
    this.store
      .select('user')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.user = data.user || undefined;
        this.isBuyer = this.userService.isBuyer(this.user);
      });
    this.loading
      .getLoadingPrices()
      .pipe(takeUntil(this.destroyed))
      .subscribe((loading: any) => {
        this.pricesLoading = !!loading['price'];
      });
    this.shoppingContext$.pipe(takeUntil(this.destroyed)).subscribe((shoppingContext) => {
      this.shoppingContext = shoppingContext as UserShoppingContext;
    });
  }

  getWarehouseAvailability(open: boolean) {
    if (this.product && open && this.userService.isBuyer(this.user)) {
      this.availableWarehouses = [];
      this.pricing.getWarehouseAvailability(this.product, this.getAllWarehouses(), this.availableWarehouses);
    }
  }

  hasOtherWarehouses(): boolean {
    return this.getAllWarehouses().length > 1;
  }

  private getAllWarehouses(): string[] {
    // return list of distinct warehouses
    if (this.allWarehouses.length) {
      return this.allWarehouses;
    }
    if (this.user) {
      // User - distinct list of combined delivery/pickup warehouses
      const merged = [].concat(
        this.shoppingContext?.deliveryWarehouses ? (this.shoppingContext.deliveryWarehouses as never[]) : [],
        this.shoppingContext?.warehouses ? (this.shoppingContext.warehouses as never[]) : []
      );
      this.allWarehouses = merged.filter((item, index) => merged.indexOf(item) === index);
    } else {
      // No user - use all warehouses
      this.allWarehouses = this.warehouses.map((w) => w.warehouseNumber);
    }
    return this.allWarehouses;
  }
}
