import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';
import { ToastService } from '../../../../shared/components/toast/toast.service';
import { API_URL } from '../../../../shared/consts/api-urls';
import { WINDOW } from 'src/app/app.module';

@Component({
  selector: 'val-newsletter-signup',
  templateUrl: './newsletter-signup.component.html',
  styles: ['input { font-family: var(--val-font-family-2),serif;}'],
})
export class NewsletterSignupComponent implements OnInit {
  form: FormGroup;

  constructor(
    private api: ApiService,
    private formBuilder: FormBuilder,
    private toast: ToastService,
    @Inject(WINDOW) private window: Window
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      email: this.formBuilder.control('', {
        validators: [Validators.required, Validators.email, Validators.maxLength(100)],
        updateOn: 'change',
      }),
    });
  }

  onSubmit() {
    const data = {
      ...this.form.value,
      ...{
        origin: this.window?.location.href,
        message: 'Please add me to your newsletter',
        inquiry: 'Newsletter Signup',
        spam: false,
      },
    };

    this.api.post(`${API_URL.Contact}`, data).subscribe({
      next: (res) => {
        res && this.toast.showSuccess('Your request has been submitted');
      },
      error: (error) => {
        this.toast.showError(error);
      },
    });

    this.initForm();
  }
}
