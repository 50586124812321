<div class="container">
  <val-page-title [pageTitle]="pageTitle"></val-page-title>

  <div class="text-center mb-5 alphabet">
    <a
      *ngFor="let supplier of suppliers | keyvalue; let i = index"
      (click)="onClick(supplier.key)"
      class="btn btn-outline-primary m-1"
      [attr.data-testid]="DATA_TEST_ID.supplier.linkButtonFilter + '-' + i | idFormatter"
      >{{ supplier.key }}</a
    >
  </div>

  <div *ngFor="let key of objectKeys(suppliers); let i = index">
    <div class="val-th mb-3" id="{{ key }}">{{ key }}</div>
    <div class="row mb-3">
      <div class="col-6 col-md-4 col-lg-3 ps-4 pb-2" *ngFor="let test of suppliers[key]">
        <a
          *ngIf="test.actionUrl.value; else noLink"
          href="{{ test.actionUrl.value }}"
          target="_blank"
          class="clink"
          [attr.data-testid]="DATA_TEST_ID.supplier.link + '-' + i | idFormatter"
        >
          {{ test.name.value }}
        </a>
        <ng-template #noLink [attr.data-testid]="DATA_TEST_ID.supplier.noLink + '-' + i | idFormatter">
          {{ test.name.value }}
        </ng-template>
      </div>
    </div>
  </div>
</div>
