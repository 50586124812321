<div class="quick-links">
  <div class="ribbon">
    <div></div>
    <div></div>
    <div></div>
  </div>

  <div class="container mt-2">
    <div class="row">
      <div class="col-4 phone _desktop">
        <val-support-phone></val-support-phone>
      </div>

      <div *ngIf="isBuyer()" class="col-12 col-lg-4 text-center account">
        <span>{{ user?.username }} ({{ selectedCustomer }})</span>
        <a
          [attr.data-testid]="DATA_TEST_ID.header.quickLink.change"
          *ngIf="!isPunchOut()"
          routerLink="/account/change"
          >change</a
        >
      </div>

      <div [ngClass]="isBuyer() ? 'col-4' : 'col-8'" class="links _desktop">
        <ul *ngIf="isB2B()" class="nav float-end">
          <li *ngFor="let link of menuService.quickLinks" class="nav-item">
            <val-nav-link [link]="link" location="quick-links"></val-nav-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
