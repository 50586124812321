import { Component, Input, EventEmitter, Output, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FacetGroup } from '../../../../core/services/search/search-filter/search-filter.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'val-product-facet-list',
  templateUrl: './product-facet-list.component.html',
})
export class ProductFacetListComponent implements OnInit, OnChanges {
  @Input() categoryFilters: FacetGroup | null = null;
  @Input() facetFilters: FacetGroup[] | null = null;
  @Input() maxFilters: number;
  @Input() activeFilters: any[] = [];
  @Output() onDeletedList = new EventEmitter<number>();
  @Output() onFilter = new EventEmitter<any>();
  @Output() onActiveFilter = new EventEmitter<any>();
  @Output() onToggleFacets = new EventEmitter<any>();
  public filterHeader: string[] = [];
  public activeFilterAccordion: string[] = [];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.filterHeader = Array.from(new Set(Object.keys(params).map((key) => decodeURIComponent(key.split('[')[0]))));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['categoryFilters'] || changes['facetFilters']) {
      this.updateInitialActivePanelIds();
    }
  }

  private updateInitialActivePanelIds(): void {
    this.activeFilterAccordion = [];
    if (this.categoryFilters && this.filterHeader.includes(this.categoryFilters.id)) {
      this.activeFilterAccordion.push('facet-group-' + this.categoryFilters.id);
    }
    this.facetFilters?.forEach((facetGroup) => {
      if (this.filterHeader.includes(facetGroup.id)) {
        this.activeFilterAccordion.push('facet-group-' + facetGroup.id);
      }
    });
  }

  filterFacet(facetKey: string, facetValue: string): void {
    this.onFilter.emit({ facetKey, facetValue });
  }

  activeFilter(facetKey: string, facetValue: string) {
    const filterVal = `${facetKey}:${facetValue}`;
    return this.activeFilters.filter((f) => {
      return f.filter === filterVal;
    }).length;
  }

  toggleFacets(id: string): void {
    this.onToggleFacets.emit(id);
  }
}
