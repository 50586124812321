import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
  Inject,
} from '@angular/core';
import { ProductResult } from '../../../../core/services/search/search.model';
import { CartLine } from '../../../../types/cart.model';
import { Observable, takeUntil } from 'rxjs';
import { UserModel } from '../../../../core/services/user/user.model';
import { select, Store } from '@ngrx/store';
import { AppStateInterface } from '../../../../types/app-state.interface';
import { userSelector } from '../../../../core/store/user/user.selectors';
import { UserService } from '../../../../core/services/user/user.service';
import { PermissionService } from '../../../../core/services/permission/permission.service';
import { ItemListService } from '../../../../core/services/item-list/item-list.service';
import { isProductResult } from 'src/app/core/guards/type.guard';
import { LoadingSpinnerService } from '../../loading-spinner/loading-spinner.service';
import { PersonalCodeService } from '../personal-code/personal-code.service';
import { OrderApprovalService } from '../../../../core/services/order/order-approval.service';
import { SiteCartService } from '../../../../core/services/cart/site-cart.service';
import { QuoteCartService } from 'src/app/core/services/cart/quote-cart.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { WINDOW } from 'src/app/app.module';
import { QtyUpdate } from 'src/app/modules/order/order-detail.model';

@Component({
  selector: 'val-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['../../../../../assets/styles/shared/products.scss', './item-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ItemListComponent extends BaseSubscriptionComponent implements OnInit, OnDestroy {
  @Input() products: any;
  @Input() pageType: string;
  @Input() collapse: any;
  @Input() disableEdit: boolean;
  @Input() isRevision: boolean;
  @Output() removeItem = new EventEmitter<number>();
  pageTypes: string[] | null;
  isHistory = false;
  isCart = false;
  isCheckout = false;
  isOrderDetail = false;
  showTotal = false;
  isBuyer = false;
  canViewPrices: boolean;
  isApproval = false;
  isGrid = true;
  isImportOrder = false;
  isQuote = false;
  isQuoteDetails = false;
  canUpdateQuickList: boolean = false;

  @Output() qtyUpdate = new EventEmitter<QtyUpdate[]>();
  private productsWithQtyUpdate: QtyUpdate[] = [];

  isLoading: any;
  quickListHeaderId: number | undefined;

  user$: Observable<UserModel | null | undefined>;
  user: UserModel | null;

  constructor(
    private siteCart: SiteCartService,
    private itemList: ItemListService,
    private store: Store<AppStateInterface>,
    public userService: UserService,
    private loading: LoadingSpinnerService,
    public personalCodeService: PersonalCodeService,
    public orderApprovalService: OrderApprovalService,
    private quoteCart: QuoteCartService,
    private permissionService: PermissionService,
    @Inject(WINDOW) private window: Window
  ) {
    super();
    this.user$ = this.store.pipe(select(userSelector));
  }

  ngOnInit(): void {
    this.pageTypes = this.pageType ? this.pageType.toLowerCase().split(',') : null;
    this.loading.setLoadingPrices(['summary', 'price', 'total']);

    const validPageTypes = ['order-details', 'quote-details', 'create-quote'];

    if (!validPageTypes.includes(this.pageType)) {
      this.loading.getLoadingPrices().subscribe((loading) => {
        this.isLoading = loading || this.isLoading;
      });
    } else {
      this.isLoading = false;
    }

    if (this.pageTypes) {
      this.isHistory = this.pageTypes.includes('product-history');
      this.isCart = this.pageTypes.includes('cart');
      this.isImportOrder = this.pageTypes.includes('import-order');
      this.isCheckout = this.pageTypes.includes('checkout');
      this.isOrderDetail = this.pageTypes.includes('order-details');
      this.isApproval = this.pageTypes.includes('approval');
      this.isQuote = this.pageTypes.includes('create-quote');
      this.isQuoteDetails = this.pageTypes.includes('quote-details');
      this.showTotal = this.isCart || this.isCheckout || this.isOrderDetail || this.isQuote || this.isQuoteDetails;
      //set overall pageType to cart for main class in template html
      if (this.isApproval) {
        this.pageType = 'cart';
      }
    }

    this.store
      .select('user')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.user = data?.user ?? null;
        this.isBuyer = this.userService.isBuyer(this.user);
        this.canViewPrices = this.permissionService.canViewPrices();
        this.canUpdateQuickList = this.permissionService.canUpdateQuicklist();
        this.showTotal =
          this.canViewPrices &&
          (this.isCart || this.isCheckout || this.isOrderDetail || this.isQuoteDetails || this.isQuote);
        this.quickListHeaderId =
          this.user?.currentShoppingAccount?.quickListHeader?.id || data?.shoppingContext?.quickListHeader?.id;
        this.isGrid = Boolean(!this.isBuyer && this.pageTypes && this.pageTypes.includes('results'));
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['collapse']) {
      this.collapse = changes['collapse'].currentValue;
    }
  }

  public showPersonalCodeColumn(product: any) {
    if (this.quickListHeaderId && this.isHistory && !product.isInAlgolia) {
      return false;
    }
    return this.isBuyer && !this.isCheckout && this.quickListHeaderId && !this.isImportOrder;
  }

  public showPersonalCode(itemId: string): void {
    this.personalCodeService.showPersonalCode(itemId);
  }

  public closePersonalCode(): void {
    this.personalCodeService.closePersonalCode();
  }

  public hasPersonalCode(product: ProductResult): boolean {
    return this.quickListHeaderId && product.personalCodes && product.personalCodes[this.quickListHeaderId];
  }

  addToCart(products: ProductResult | ProductResult[]) {
    // TODO - Initiate loading spinner when hitting API
    this.isApproval ? this.orderApprovalService.addItem(products) : this.siteCart.addItem(products);
    this.unCheckAll();
    if (isProductResult(this.products)) {
      this.products.map((x) => {
        x.qty = x.sellMult;
      });
    }
  }

  removeFromCart(productIndex: number, product?: CartLine) {
    const el = this.window?.document?.getElementById('product-' + productIndex) as HTMLDivElement | null;
    if (el) {
      el.classList.add('deleting');
      // TODO - remove fake delay and replace with API call to delete
      setTimeout(() => {
        let deleted: boolean;
        if (this.isApproval) {
          deleted = true;
          this.orderApprovalService.removeByIndex(productIndex);
        } else if (this.isQuote) {
          deleted = true;
          this.quoteCart.removeByIndex(productIndex);
        } else {
          deleted = false;
          this.siteCart.removeByIndex(productIndex);
        }

        if (!deleted) {
          el.classList.remove('deleting');
        }
      }, 500);
    }
  }

  removeFromImportOrder(index: number): void {
    this.removeItem.emit(index);
  }

  updateCartItem(product: CartLine, productIndex: number) {
    // cart object only gets updated if user is viewing item in the cart
    if (this.isCart && !this.qtyError(product)) {
      if (this.isApproval) {
        this.orderApprovalService.updateItem(productIndex, product);
      } else if (this.isQuote) {
        this.quoteCart.updateItem(productIndex, product);
      } else {
        this.siteCart.updateItem(productIndex, product);
      }
    } else if (this.isQuote) {
      const productNotes = this.window?.document?.getElementById(
        'productNotes-' + `${productIndex}`
      ) as HTMLInputElement;
      if (productNotes.value) {
        product.productNotes = productNotes.value;
      }
      this.quoteCart.updateItem(productIndex, product);
    }
    this.updatedProduct(product.sequence, product.qty);
  }

  updatedProduct(lineNumber: number, quantityOrdered: number) {
    const existingIndex = this.productsWithQtyUpdate.findIndex((item) => item.lineNumber === lineNumber);
    if (existingIndex > -1) {
      this.productsWithQtyUpdate[existingIndex].quantityOrdered = quantityOrdered;
    } else {
      this.productsWithQtyUpdate.push({ lineNumber, quantityOrdered });
    }

    this.qtyUpdate.emit(this.productsWithQtyUpdate);
  }

  onCheckItem(e: any, product: ProductResult) {
    const checkAll: HTMLInputElement | null = this.window?.document?.getElementById('chkAll') as HTMLInputElement;
    product.isSelected = e.target.checked;

    if (checkAll !== null) {
      if (checkAll && this.products) {
        checkAll.checked = this.selectedItems().length === this.products!.length;
      } else {
        checkAll.checked = false;
      }
    }
  }

  unCheckAll() {
    if (isProductResult(this.products)) {
      this.itemList.unCheckAll(this.products);
    }
  }

  selectedItems() {
    if (isProductResult(this.products)) {
      return this.itemList.selectedItems(this.products);
    } else {
      return [];
    }
  }

  productUrl(product: any) {
    return product.seoUrl || product.url || null;
  }

  qtyError(product: ProductResult) {
    const err = product.qty % product.sellMult !== 0 && !this.isLoading.price && product.price;
    if (err) {
      product.isSelected = false;
    }
    return err;
  }

  trackByProductId(index: number, product: any): any {
    return product.itemId;
  }

  disabledCart(product: any): boolean {
    return !product.price || ((this.isHistory || this.isOrderDetail) && !product.isInAlgolia);
  }

  showQuantityError(product: any): boolean {
    return !this.isQuote && product.qty % product.sellMult !== 0 && !this.isLoading.price && product.price;
  }

  public canShowAddToCart(): boolean {
    return this.products.some((product: ProductResult) => product.price != null && product.price > 0);
  }
}
