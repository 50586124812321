import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardWidget, WidgetId } from '../widget/widget.model';
import { UtilityService } from '../../../core/services/utility/utility.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';

@Component({
  selector: 'val-dashboard-modal',
  templateUrl: './dashboard-modal.component.html',
  styleUrls: ['./dashboard-modal.component.scss'],
})
export class DashboardModalComponent extends BaseSubscriptionComponent implements OnInit {
  constructor(
    public dashboardService: DashboardService,
    public activeModal: NgbActiveModal,
    private utilityService: UtilityService
  ) {
    super();
  }

  public widgetIds: string[];
  public widgetId = WidgetId;

  ngOnInit(): void {
    this.widgetIds = this.utilityService.clone(this.dashboardService.widgetIds) || [];
  }

  onToggle(widget: DashboardWidget) {
    if (!this.widgetIds.includes(widget.id)) {
      this.widgetIds.push(widget.id);
    } else {
      this.widgetIds = this.widgetIds.filter((x) => x !== widget.id);
    }
  }

  onSave() {
    this.activeModal.close(this.widgetIds);
  }

  onClose() {
    this.activeModal.dismiss();
  }
}
