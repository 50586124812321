import { Injectable } from '@angular/core';
import { Breadcrumb } from './breadcrumb.model';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  public breadcrumbs: Breadcrumb[] = [];

  constructor() {}

  public setBreadcrumbs(crumbs?: Breadcrumb[]): void {
    this.breadcrumbs = [];
    if (crumbs?.length) {
      crumbs.unshift(new Breadcrumb('Home', '/'));
      this.breadcrumbs = crumbs;
    }
  }
}
