import { Component, OnInit, Inject, Renderer2, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { WarehouseService } from './core/services/warehouse/warehouse.service';
import { AppStateInterface } from 'src/app/types/app-state.interface';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { UserState } from './core/store/user/user.reducer';
import { SiteCartService } from './core/services/cart/site-cart.service';
import { WINDOW } from 'src/app/app.module';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { isPlatformBrowser } from '@angular/common';
import { AuthenticationService } from './core/services/authentication/authentication.service';
import { QuoteCartService } from './core/services/cart/quote-cart.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends BaseSubscriptionComponent implements OnInit {
  public accountSelect$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public admin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  noHeader = false;
  noFooter = false;
  private user: UserState;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public siteCart: SiteCartService,
    public warehouseService: WarehouseService,
    private store: Store<AppStateInterface>,
    @Inject(WINDOW) private window: Window, // Inject the Window object here
    private gtmService: GoogleTagManagerService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public authService: AuthenticationService,
    public quoteCart: QuoteCartService
  ) {
    super();
  }

  title = 'vallen-ecomm';
  initSiteCart = false;
  initQuote = false;

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.authService.checkToken();
    }
    this.store
      .select('user')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.user = data;
      });

    this.warehouseService.getWarehouses();
    this.router.events.pipe(takeUntil(this.destroyed)).subscribe((x) => {
      if (x instanceof NavigationEnd) {
        this.window?.scrollTo(0, 0);
        this.accountSelect$.next(x.url.includes('account-select'));
        this.admin$.next(x.url.includes('admin'));
      }
      if (x instanceof ActivationEnd && Object.keys(x.snapshot.data).length) {
        this.noHeader = !!x.snapshot.data['noHeader'];
        this.noFooter = !!x.snapshot.data['noFooter'];
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          takeUntil(this.destroyed)
        )
        .subscribe((event) => {
          if (event instanceof NavigationEnd) {
            if (event.urlAfterRedirects === '/quotes/create') {
              this.initQuote = true;
              this.quoteCart.initQuoteCart();
            } else if (!this.initSiteCart) {
              this.initSiteCart = true;
              if (event.urlAfterRedirects !== '/cart') {
                this.siteCart.initSiteCart(false);
              }
            }

            this.gtmService
              .pushTag({
                event: 'page_view',
                page_path: event.urlAfterRedirects,
              })
              .catch((error) => {
                console.error('Error pushing GTM tag:', error);
              });
          }
        });
    }
  }
}
