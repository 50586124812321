<form [formGroup]="addOrEditAddressForm" class="forms">
  <val-form-alerts [error]="responseError"></val-form-alerts>
  <div class="row">
    <val-form-input class="col-md-12 col-lg-4 mb-4" [form]="addOrEditAddressForm" [id]="'siteName'"></val-form-input>
    <val-form-input
      class="col-md-12 col-lg-6 mb-4"
      [form]="addOrEditAddressForm"
      [id]="address1UniqueId"
      [label]="'Address'"
    ></val-form-input>
    <val-form-input class="col-md-6 col-lg-2 mb-4" [form]="addOrEditAddressForm" [id]="'unitNumber'"></val-form-input>
    <val-form-input class="col-md-6 col-lg-4 mb-4" [form]="addOrEditAddressForm" [id]="'postalCode'"></val-form-input>
    <val-form-input class="col-md-6 col-lg-4 mb-4" [form]="addOrEditAddressForm" [id]="'city'"></val-form-input>
    <val-form-input class="col-md-6 col-lg-4 mb-4" [form]="addOrEditAddressForm" [id]="'province'"></val-form-input>

    <!--   TODO: Grab location value from Environment to know if this will be state or province   -->
  </div>
</form>
