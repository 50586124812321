import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ImportExportService } from 'src/app/core/services/import-export/import-export.service';
import { ProductResult } from 'src/app/core/services/search/search.model';
import JsBarcode from 'jsbarcode';
import { ToastService } from '../../toast/toast.service';
import { LoadingSpinnerService } from '../../loading-spinner/loading-spinner.service';
import { SelectedCheckboxAndRadio } from '../import-export.model';
import { OrderDetail } from 'src/app/modules/order/order-detail.model';
import { AppStateInterface } from 'src/app/types/app-state.interface';
import { Store } from '@ngrx/store';
import { PageTitle } from '../../page-title/page-title.model';
import { OrderNumberPipe } from 'src/app/shared/pipes/order-number/order-number.pipe';
import { Subject, combineLatest, map, take, takeUntil } from 'rxjs';
import { CartState } from 'src/app/core/store/cart/cart.reducer';
import { WarehouseState } from 'src/app/core/store/warehouse/warehouse.reducer';
import { UrlKey } from '../import-export.model';
import { WINDOW } from 'src/app/app.module';

@Component({
  selector: 'val-export-pdf',
  templateUrl: './export-pdf.component.html',
  styleUrls: ['./export-pdf.component.scss'],
})
export class ExportPdfComponent implements OnInit {
  currentDate: string;
  currentTime: string;
  title: string;
  results: ProductResult[] = [];
  selectedCheckboxes: SelectedCheckboxAndRadio[];
  listId: string | null;
  order: OrderDetail | null;
  pageTitle: PageTitle;
  shippingMethod: string | undefined;
  wareHouseName: string | null | undefined;
  url: UrlKey | null = null;
  subTotal: number | undefined;
  salesTaxAmount: number | undefined;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private importExportService: ImportExportService,
    private el: ElementRef,
    private datePipe: DatePipe,
    private toast: ToastService,
    public loadingService: LoadingSpinnerService,
    private store: Store<AppStateInterface>,
    public orderNumberPipe: OrderNumberPipe,
    @Inject(WINDOW) private window: Window
  ) {
    this.setPrinttime();
  }

  ngOnInit() {
    this.subscribeToSelectedCheckboxes();
    this.subscribeToTitle();
    this.subscribeToRouteParams();
  }

  private subscribeToSelectedCheckboxes() {
    this.selectedCheckboxes = [...this.importExportService.getSelectedCheckboxes()];
  }

  private subscribeToTitle() {
    this.importExportService.getTitle().subscribe((title) => {
      this.title = title;
    });
  }

  private subscribeToRouteParams() {
    const handlerMap: Record<UrlKey, () => void> = {
      cart: () => this.handleCart(),
      order: () => this.handleOrder(),
      quicklist: () => this.handleQuicklist(),
      'product-lists': () => this.handleProductLists(),
    };

    this.route.paramMap.subscribe((params) => {
      this.listId = params.get('listId');
      this.url = this.route.snapshot.url[0].path as UrlKey;
      this.url ? handlerMap[this.url]?.() : console.error('Invalid URL segment');
    });
  }

  getValueForKey(item: any, key: string): any {
    return item[key] || 'NA';
  }

  private setPrinttime() {
    const now = new Date();
    this.currentDate = this.datePipe.transform(now, 'MM/dd/yyyy') || '';
    this.currentTime = this.datePipe.transform(now, 'hh:mm a z') || '';
  }

  private handleCart() {
    this.pageTitle = new PageTitle('Shopping Cart', 'shopping_cart', '');
    combineLatest([this.store.select('cart'), this.store.select('warehouse')])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([cartState, warehouseState]) => {
        this.processCartState(cartState);
        this.processWarehouseState(warehouseState, cartState?.cart?.selectedWarehouse);
      });
    this.preloadImages(this.results, () => setTimeout(() => this.window?.print(), 0));
  }

  private processCartState(cartState: CartState) {
    this.results = cartState?.cart?.cartItems as ProductResult[];
    this.generateBarcodesForResults();
    this.shippingMethod = cartState?.cart?.shippingMethod;
    this.subTotal = cartState?.cart?.subTotal;
  }

  private processWarehouseState(warehouseState: WarehouseState, selectedWarehouseNumber: string | undefined) {
    if (warehouseState?.warehouses) {
      let matchedWarehouse = warehouseState.warehouses.find(
        (warehouse) => warehouse.warehouseNumber === selectedWarehouseNumber
      );

      if (matchedWarehouse) {
        this.wareHouseName = matchedWarehouse.warehouseName;
      }
    }
  }

  private handleOrder() {
    this.importExportService.getOrder().subscribe((order) => {
      this.results = order?.lineItems as unknown as ProductResult[];
      this.generateBarcodesForResults();
      this.preloadImages(this.results, () => setTimeout(() => this.window?.print(), 0));
      this.order = order;
      this.subTotal = order?.totalLineAmount;
      this.salesTaxAmount = order?.totalLineAmount;
      if (this.order) {
        this.pageTitle = new PageTitle(
          `${this.order?.orderType} # ${this.orderNumberPipe.transform(this.order)}`,
          'description',
          ''
        );
      }
    });
  }

  private fetchDataForList(listId: string, fetchMethod: 'getExportProductsData' | 'getExportQuickListData') {
    const selectedCheckboxValues = this.selectedCheckboxes.flatMap((section) =>
      (section.checkboxes || []).map((checkbox) => checkbox.value)
    );
    this.loadingService.start();

    this.importExportService[fetchMethod](listId, selectedCheckboxValues).subscribe(
      (response: any) => {
        this.results = response;
        this.generateBarcodesForResults();
        this.preloadImages(this.results, () => setTimeout(() => this.window?.print(), 0));
        this.loadingService.stop();
      },
      (error) => {
        this.toast.showError(error);
        this.loadingService.stop();
      }
    );
  }

  private handleQuicklist() {
    this.pageTitle = new PageTitle(this.title, 'assignment', '');
    if (this.listId) {
      this.fetchDataForList(this.listId, 'getExportQuickListData');
    } else {
      this.toast.showError('List ID not provided for quicklist');
    }
  }

  private handleProductLists() {
    this.pageTitle = new PageTitle(this.title, 'assignment', '');
    if (this.listId) {
      this.fetchDataForList(this.listId, 'getExportProductsData');
    } else {
      this.toast.showError('List ID not provided for quicklist');
    }
  }

  generateBarcodesForResults(): void {
    this.results.forEach((result) => {
      if (result.mainUpc && result.mainUpc.trim() !== '') {
        result.barcodeDataUrl = this.generateBarcode(result.mainUpc);
      }
    });
  }

  preloadImages(results: ProductResult[], callback: () => void) {
    let imagesToLoad = results.map((result) => result.image).filter(Boolean);
    let imagesLoaded = 0;
    imagesToLoad.forEach((imageUrl) => {
      let img = new Image();
      img.onload = img.onerror = () => {
        imagesLoaded++;
        if (imagesLoaded === imagesToLoad.length) {
          callback();
        }
      };
      img.src = imageUrl;
    });
  }

  calculateTotal(result: ProductResult): number {
    return result.qty * result.price;
  }

  generateBarcode(upc: string): string {
    let svg = this.window?.document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    JsBarcode(svg, upc, {
      format: 'MSI',
      displayValue: true,
      fontSize: 14,
      height: 30,
      width: 1,
    });
    let serializer = new XMLSerializer();
    let svgStr = serializer.serializeToString(svg);
    return 'data:image/svg+xml;base64,' + this.window?.btoa(svgStr);
  }

  getRadioValueByName(name: string): string | null {
    const radioBox = this.selectedCheckboxes.flatMap((s) => s.radioboxes || []).find((r) => r.name === name);
    return radioBox?.value || null;
  }

  public billMethod(): string {
    return this.isCcOrder() ? 'Paid by credit card' : 'Billed to my account';
  }

  public isCcOrder(): boolean {
    return this.order?.paymentType?.toUpperCase() === 'CREDIT CARD';
  }

  public formatAddress(type: string): string {
    let addr: string[] = [];
    let fields: string[];
    if ('ship' === type) {
      fields = [
        'shipToAddress1',
        'shipToAddress2',
        'shipToAddress3',
        'shipToAddress4',
        'shipToCity',
        'shipToState',
        'shipToZipCode',
      ];
    } else {
      fields = [
        'customerAddress1',
        'customerAddress2',
        'customerAddress3',
        'customerAddress4',
        'billToCity',
        'billToState',
        'billToZipCode',
      ];
    }
    fields.forEach((field: string) => {
      // @ts-ignore
      if (this.order[field]) {
        // @ts-ignore
        addr.push(this.order[field]);
      }
    });
    return addr.join(', ');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
