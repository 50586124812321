<div class="table-responsive">
  <ngx-datatable
    #table
    class="bootstrap dt-small widget mb-0"
    [columnMode]="'flex'"
    [rows]="data.data"
    [rowHeight]="'auto'"
    [limit]="itemsPerPage"
    [sorts]="[{ prop: 'isDefault', dir: 'desc' }]"
    [style]="{ backgroundColor: 'white' }"
  >
    <ng-container>
      <ngx-datatable-column
        *ngFor="let column of data.columns"
        [name]="column.name"
        [prop]="column.prop"
        [flexGrow]="column.flexGrow || 1"
        [resizeable]="false"
      >
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <a *ngIf="column.link" class="nav-link" (click)="column.link(row[column.linkParam])">
            {{ value }}
          </a>
          <span *ngIf="!column.link">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>
    </ng-container>
  </ngx-datatable>
</div>
