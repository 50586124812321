<div class="container-fluid print-page">
  <div class="container">
    <div class="col-12 print-page">
      <val-page-title [pageTitle]="pageTitle"></val-page-title>
      <ng-container *ngIf="!loadingService.isLoading()">
        <div class="header d-flex">
          <div class="col-12 details order-info" *ngIf="getRadioValueByName('orderDetails') === 'Yes'">
            <div class="col-4 d-flex">
              <span class="label">Customer PO#: </span>
              <span> {{ order?.customerPurchaseOrder }} </span>
            </div>
            <div class="col-4 d-flex">
              <span class="label"> Order date: </span>
              <span> {{ order?.entryDate | date : "yyyy-MM-dd" }} </span>
            </div>
            <div class="col-4 d-flex">
              <span class="label">Ship-to: </span>
              <span> {{ order?.shipToCity }}</span>
            </div>
            <div class="col-4 d-flex">
              <span class="label"> Status: </span>
              <span> {{ order?.orderStatus }} </span>
            </div>
            <div class="col-4 d-flex">
              <span class="label"> Tracking number: </span>
              <span> {{ order?.trackingNumber }} </span>
            </div>
            <div class="col-4 d-flex">
              <span class="label">Mode:</span>
              <span> {{ order?.transportMode }} </span>
            </div>
            <div class="col-4 d-flex">
              <span class="label"> Branch:</span>
              <span> {{ order?.warehouseNumber }} </span>
            </div>
          </div>
          <div class="col-12 details shipping-info" *ngIf="getRadioValueByName('shippingDetails') === 'Yes'">
            <div class="col-6">
              <div class="title">Shipping Address</div>
              <div class="sub-title">{{ order?.shipToName }}</div>
              <p>
                {{ formatAddress("ship") }}
              </p>
            </div>
            <div class="col-6">
              <div class="title">Billing Address</div>
              <div class="sub-title">
                {{ order?.billToContact }}
              </div>
              <p>
                {{ formatAddress("bill") }}
              </p>
            </div>
            <div class="col-6 ship-when">
              <div class="col-6 order-info fw-semibold">Ship when items are available</div>
            </div>
            <div class="col-6 bill-method order-info fw-semibold">
              <p>{{ billMethod() }}</p>
            </div>
          </div>
          <div class="col-12 details" *ngIf="getRadioValueByName('modeAndBranch') === 'Yes'">
            <div class="mode">
              <span>Mode: </span>
              <span class="fw-semibold"> {{ shippingMethod ? shippingMethod : "NA" }} </span>
            </div>
            <div class="branch ms-4">
              <span>Branch:</span> <span class="fw-semibold"> {{ wareHouseName ? wareHouseName : "NA" }}</span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="results">
          <val-pdf-table-content
            [pageType]="url"
            [results]="results"
            [subTotal]="subTotal"
            [salesTaxAmount]="salesTaxAmount"
            [selectedCheckboxes]="selectedCheckboxes"
          ></val-pdf-table-content>
        </ng-container>
        <div class="col-12 time-stamp">
          Print on <span> {{ currentDate }} </span> | <span> {{ currentTime }} </span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
