// maintenance.guard.ts
import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { WINDOW } from 'src/app/app.module';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {
  constructor(private router: Router, @Inject(WINDOW) private window: Window) {}

  canActivate() {
    // TODO - Remove hostname portion of the where clause once new vallen.ca site is up and running
    if (environment.underMaintenance && this.window?.location.hostname !== 'prod.vallen.ca') {
      this.router.navigateByUrl('/maintenance');
      return false;
    }
    return true;
  }
}
