import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ProductListService } from 'src/app/core/services/product-list/product-list.service';
import { NavLink } from 'src/app/shared/components/nav-link/nav-link.model';
import { ProductList, ProductLists } from 'src/app/types/product-list.model';
import { UserModel } from 'src/app/core/services/user/user.model';
import { Router } from '@angular/router';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { ApiService } from '../../../services/api/api.service';
import { API_URL } from '../../../../shared/consts/api-urls';
import { ToastService } from '../../../../shared/components/toast/toast.service';
import { Cart } from '../../../../types/cart.model';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../../../types/app-state.interface';
import { SiteCartService } from '../../../services/cart/site-cart.service';

@Component({
  selector: 'val-navbar-product-lists',
  templateUrl: './navbar-product-lists.component.html',
})
export class NavbarProductListsComponent extends BaseSubscriptionComponent implements OnInit, OnDestroy {

  constructor(
    private formBuilder: FormBuilder,
    private listService: ProductListService,
    private router: Router,
    private api: ApiService,
    private toast: ToastService,
    private store: Store<AppStateInterface>,
    private siteCart: SiteCartService
  ) {
    super();
  }

  @Input() link: NavLink;
  @Input() user: UserModel | null;

  searchForm = this.formBuilder.group({
    searchQuery: this.formBuilder.control('', {
      updateOn: 'change',
    }),
  });
  lists: ProductLists;
  list: ProductList[] | null = [];
  showSearch = false;
  noResults = false;
  cart: Cart;

  ngOnInit() {
    this.listService.productLists$.pipe(takeUntil(this.destroyed)).subscribe((lists) => {
      if (lists) {
        this.initListData(lists);
      }
    });

    this.store
      .select('cart')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.cart = data?.cart as Cart;
      });
  }

  // Gets the correct list based on the link clicked
  getList(listType: string | undefined, type?: string) {
    let productList: ProductList[] | null = null;
    for (const [key, value] of Object.entries(this.lists)) {
      if (key === listType) {
        if (!type || type === 'getAll') {
          productList = value;
          this.showSearch = (productList as ProductList[]).length > 8;
          this.noResults = (productList as ProductList[]).length === 0;
        }
        if (type === 'search')
          productList = value.filter((x: ProductList) => {
            return x.name
              .toLowerCase()
              .includes(this.searchForm.controls['searchQuery'].value?.toLowerCase() as string);
          });
      }
    }
    this.list = productList;
  }

  addListToCart(id: number) {
    this.api.put(`${API_URL.ProductListHeader}/${id}`, { cartId: this.cart.cartId }).subscribe({
      next: () => {
        const foundList = this.list?.find((list) => list.id === id);
        const productCount = foundList?.productCount || 0;
        this.toast.showSuccess(`(${productCount}) Products added successfully!`);
        this.siteCart.initSiteCart();
      },
      error: (error) => {
        console.log(error);
        this.toast.showError('Failed to add items.');
      },
    });
  }

  private initListData(lists: ProductLists) {
    this.lists = {
      user: lists?.user || [],
      shared: lists?.shared || [],
      public: lists?.public || [],
    };
    this.getList(this.link.type);
  }
}
