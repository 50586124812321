import { Component, Input, Inject } from '@angular/core';
import { UserModel } from '../../../services/user/user.model';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { MenuService } from '../../../services/menu/menu.service';
import { Store } from '@ngrx/store';
import { CustomerCompanyService } from '../../../services/customer-company/customer-company.service';
import { UserService } from '../../../services/user/user.service';
import { HeaderToggleService } from 'src/app/shared/services/header-toggle.service';
import { AppStateInterface } from '../../../../types/app-state.interface';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { WINDOW } from 'src/app/app.module';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'val-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss'],
})
export class HeaderMainComponent extends BaseSubscriptionComponent {
  @Input() user: UserModel | null;
  selectedCustomer: string;

  cartCount: number = 0;
  showMobileSearch: boolean = false;

  constructor(
    public authService: AuthenticationService,
    public userService: UserService,
    public menuService: MenuService,
    public headerToggleService: HeaderToggleService,
    private store: Store<AppStateInterface>,
    private customerCompanyService: CustomerCompanyService,
    public cookieService: CookieService,
    @Inject(WINDOW) private window: Window
  ) {
    super();
    this.customerCompanyService.selectedCustomerAccount.pipe(takeUntil(this.destroyed)).subscribe((data) => {
      this.selectedCustomer = data;
    });

    this.headerToggleService.navbarAndSearchFormState$.pipe(takeUntil(this.destroyed)).subscribe((open) => {
      if (open) {
        this.showMobileSearch = false;
      }
    });
    this.store
      .select('cart')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.cartCount = data.cart?.cartItems.length || 0;
      });
  }

  logout() {
    this.authService.logout();
    if (this.window?.localStorage) {
      localStorage.removeItem('user');
      localStorage.removeItem('punchout');
      localStorage.removeItem('customer');
      localStorage.removeItem('productLists');
      localStorage.removeItem('shoppingContext');
      localStorage.removeItem('cart');
    }
    this.cookieService.delete('jwt', '/');
  }

  toggleSearch() {
    this.showMobileSearch = !this.showMobileSearch;
    this.headerToggleService.toggleMenuAndSearchForm(false);
  }
}
