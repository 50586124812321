import { Injectable, OnInit, Inject } from '@angular/core';
import { ActiveFilter, FacetGroup, FacetItem } from './search-filter.model';
import { PageType } from '../../../../shared/consts/page-type';
import { ActivatedRoute, NavigationEnd, Params, Router, Event as RouterEvent } from '@angular/router';
import { Observable, take } from 'rxjs';
import { SearchOptions } from '../search.model';
import { WINDOW } from 'src/app/app.module';

@Injectable({
  providedIn: 'root',
})
export class SearchFilterService implements OnInit {
  private lastUrl: string;
  private currentUrl: string;
  constructor(private route: ActivatedRoute, private router: Router, @Inject(WINDOW) private window: Window) {}

  ngOnInit(): void {
    this.lastUrl = '';
    this.currentUrl = '';

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        const urlWithoutQueryParams = event.urlAfterRedirects.split('?')[0];
        this.lastUrl = this.currentUrl;
        this.currentUrl = urlWithoutQueryParams;
      }
    });
  }

  public catRegExp = /^categories./;
  public filterType: string;
  public filters: any = {};
  public clearCategoryFilter = false;
  public onFilterCalled = false;

  public facetGroupName(name: string) {
    const regExp = /^facets./;
    if (name === 'brand') {
      name = 'Brand Name';
    } else if (name === 'manufacturer') {
      name = 'Manufacturers';
    } else if (name.match(this.catRegExp)) {
      name = 'Category';
    }
    return name.replace(regExp, '');
  }

  formatCategoryFilters(facets: any, currentFilter: FacetGroup | null) {
    const values: FacetItem[] = [];
    let categoryLevel = !currentFilter ? 0 : Number(currentFilter.id.replace('categories.lvl', ''));
    if (this.filterType === 'category' && currentFilter) {
      categoryLevel++;
    }

    const categoryFilters = Object.entries(facets).filter(([key]) => key.match(this.catRegExp))[categoryLevel] || [];

    if (!categoryFilters.length) {
      return null;
    }

    // @ts-ignore
    for (const [facet, ct] of Object.entries(categoryFilters[1])) {
      const name = facet.split(' > ');
      values.push({
        label: name.pop(),
        value: facet,
        count: <number>ct,
      });
    }

    const facetGroup: FacetGroup = {
      name: 'Category',
      id: categoryFilters[0],
      values: values,
    };
    return facetGroup;
  }

  formatFacetFilters(facets: any) {
    if (!facets) {
      facets = {};
    }
    const facetGroups: FacetGroup[] = [];
    const facetFilters = Object.entries(facets).filter(([key]) => !key.match(this.catRegExp));

    facetFilters.forEach((grp) => {
      let values: FacetItem[] = [];

      // @ts-ignore
      for (const [facet, ct] of Object.entries(grp[1])) {
        values.push({
          label: facet,
          value: facet,
          count: <number>ct,
        });
      }

      const filterGroup: FacetGroup = {
        name: this.facetGroupName(grp[0]),
        id: grp[0],
        values: values,
      };

      if (['brand', 'manufacturer'].includes(grp[0])) {
        facetGroups.unshift(filterGroup);
      } else {
        facetGroups.push(filterGroup);
      }
    });
    return facetGroups.slice(0, 20);
  }

  updateFacetFilters(facetKey: string, facetValue: string) {
    const filterVal = `${facetKey}:${facetValue}`;
    this.filterType = facetKey.match(this.catRegExp) ? 'category' : 'facets';
    if (!this.filters[this.filterType]) {
      this.filters[this.filterType] = [];
    }
    if (this.filters[this.filterType].includes(filterVal)) {
      this.filters[this.filterType] = this.filters[this.filterType].filter((f: string) => {
        return f !== filterVal;
      });
    } else if (this.filterType === 'category' && filterVal) {
      this.filters[this.filterType] = [filterVal];
    } else {
      this.filters[this.filterType].push(filterVal);
    }
    if (this.filterType === 'category' && !this.filters[this.filterType].length) {
      this.clearCategoryFilter = true;
    } else {
      this.clearCategoryFilter = false;
    }
    return Object.values(this.filters).filter((f: any) => {
      return f.length > 0;
    });
  }

  setQueryStringFacetFilters(queryParams: Observable<Params>, requestOptions: SearchOptions, itemFilters: string[]) {
    this.onFilterCalled = true;

    queryParams.pipe(take(1)).subscribe((params) => {
      Object.keys(params).forEach((key) => {
        const cleanKey = key.split('[')[0];
        const value = decodeURIComponent(params[key]);
        requestOptions.facetFilters = [...itemFilters, ...this.updateFacetFilters(cleanKey, value)];
      });
    });
  }

  setActiveFilters(facetOptions: any, pageType: string) {
    const activeFilters: ActiveFilter[] = [];
    let active: any = [];
    if (facetOptions) {
      facetOptions.forEach((f: any) => {
        active = active.concat(f);
      });
      active.forEach((filter: string) => {
        if (filter !== 'isClearance:true') {
          const x = filter.split(':');
          const facet = x.shift();
          // do not display category filter on category pages
          if (!(facet && facet.match(this.catRegExp) && pageType === PageType.CATEGORY)) {
            activeFilters.push({
              filter: filter,
              facet: <string>facet,
              value: x.join(':'),
              label: this.facetGroupName(<string>facet),
            });
          }
        }
      });
    }
    return activeFilters;
  }

  activeFilterQueryString(activeFilters: ActiveFilter[]) {
    const groupedFilters: { [key: string]: string[] } = {};
    const queryParams: { [key: string]: string } = {};

    if (this.lastUrl === this.currentUrl) {
      activeFilters.forEach((filter) => {
        const key = encodeURIComponent(filter.facet);
        if (!groupedFilters[key]) {
          groupedFilters[key] = [];
        }
        groupedFilters[key].push(encodeURIComponent(filter.value));
      });

      Object.keys(groupedFilters).forEach((key) => {
        if (groupedFilters[key].length > 1) {
          groupedFilters[key].forEach((value, index) => {
            queryParams[`${key}[${index}]`] = value;
          });
        } else {
          queryParams[key] = groupedFilters[key][0];
        }
      });
    }

    const queryParamsHandling = this.onFilterCalled ? '' : 'merge';
    this.onFilterCalled = true;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: queryParamsHandling,
    });
  }

  toggleFacets(id: any) {
    const el = this.window?.document.getElementById(id) as HTMLDivElement | null;
    if (el) {
      const openClass = 'opened';
      if (el.classList.contains(openClass)) {
        el.classList.remove(openClass);
      } else {
        el.classList.add(openClass);
      }
    }
  }

  toggleFacetPanel(id: string) {
    const el: HTMLInputElement = this.window?.document.getElementById(id) as HTMLInputElement;
    if (el.classList.contains('show')) {
      el.classList.remove('show');
      el.classList.remove('d-block');
    } else {
      el.classList.add('show');
      el.classList.add('d-block');
    }
  }

  clear(): void {
    this.onFilterCalled = false;
    this.filters = {};
  }
}
