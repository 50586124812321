import { HttpStatusCode } from '@angular/common/http';

export const ErrorMessages: any = {
  invalid: {
    default: {
      required: 'Required',
      email: 'Invalid email address',
      minlength: 'Minimum length not met',
      maxlength: 'Maximum length exceeded',
      isEmailList: 'Invalid email address(es)',
      isPostalCode: 'Invalid postal code',
      isPhone: 'Please provide a 10-digit phone number without country code, spaces, or special characters.',
      isNumber: 'Must be a number',
      isDecimal: 'Must be numeric (up to 4 decimals)',
      hasUpperCase: 'Needs an uppercase letter',
      hasLowerCase: 'Needs a lowercase letter',
      hasNumber: 'Needs at least one number',
      hasSpecialCharacters: 'Needs a special character',
    },
    fullName: {
      required: 'Full name is required',
    },
    inquiry: {
      required: 'Inquiry is required',
    },
    email: {
      email: 'Invalid email address',
      required: 'Email is required',
    },
    username: {
      required: 'Username/email is required.',
      minlength: 'Should contain minimum 3 characters.',
      maxlength: 'Should contain maximum 60 characters.',
    },
    firstName: {
      required: 'First Name is required',
      minlength: 'Should contain minimum 2 characters',
    },
    emailCc: {
      email: 'Invalid email address',
      isEmailList: 'Invalid email address(es)',
    },
    lastName: {
      required: 'Last Name is required',
      minlength: 'Should contain minimum 2 characters',
    },
    shipTo: {
      minLength: 'Must be at least 1 character',
    },
    password: {
      required: 'Password is required',
      hasUpperCase: 'Needs an uppercase letter',
      hasLowerCase: 'Needs a lowercase letter',
      hasNumber: 'Needs at least one number',
      hasSpecialCharacters: 'Needs a special character',
      minlength: 'Should contain minimum 8 characters',
    },
    newPassword: {
      hasUpperCase: 'Needs an uppercase letter',
      hasLowerCase: 'Needs a lowercase letter',
      hasNumber: 'Needs at least one number',
      hasSpecialCharacters: 'Needs a special character',
      minlength: 'Should contain minimum 8 characters',
      required: 'Password is required',
      cannotMatch: 'New password cannot match current password',
    },
    currentPassword: {
      required: 'Your current password is required',
    },
    customerNumber: {
      required: 'Customer Number is required',
      isNumber: 'Must be a number',
    },
    confirmPassword: {
      required: 'Confirm Password is required',
      mustMatch: 'Your passwords do not match',
    },
    contactPhoneNumber: {
      isNumber: 'Must be a number',
      minlength: 'Must be 10 digits long without spaces or special characters',
      maxlength: 'Must be 10 digits long without spaces or special characters',
    },
    contactMobileNumber: {
      required: 'Mobile Number is required',
      isNumber: 'Must be a number',
      minlength: 'Must be 10 digits long without spaces or special characters',
      maxlength: 'Must be 10 digits long without spaces or special characters',
    },
    phoneNumber: {
      required: 'Mobile Number is required',
      isNumber: 'Must be a number',
      minlength: 'Must be 10 digits long without spaces or special characters',
      maxlength: 'Must be 10 digits long without spaces or special characters',
    },
    credentials: {
      invalid: 'Wrong username/email or password',
    },
    siteName: {
      required: 'Site Name is required',
      minlength: 'Should contain minimum 2 characters',
      maxlength: 'Should contain maximum 50 characters',
    },
    address1: {
      required: 'Address is required',
    },
    unitNumber: {
      hasNumber: 'Must be a number',
      min: 'Must be between 1 and 9999',
      max: 'Must be between 1 and 9999',
    },
    postalCode: {
      required: 'Postal Code is required',
      hasNumber: 'Must be a number',
      min: 'Must be between 5 and 10 characters',
      max: 'Must be between 5 and 10 characters',
    },
    city: {
      required: 'City is required',
    },
    province: {
      required: 'Province is required',
      minlength: 'Must be between 2 and 50 characters',
      maxlength: 'Must be between 2 and 50 characters',
    },
    customerCompanyName: {
      isList: 'CustomerName must be a string or array with a minimum length of 3',
    },
    punchoutUsername: {
      minlength: 'Should contain minimum 5 characters',
      maxlength: 'Should contain maximum 50 characters',
    },
    maximumAmountByMonth: {
      required: 'This field is required.',
      maxlength: 'Must contain a maximum of 9 numbers.',
      isDecimal: 'Must be numeric (up to 4 decimals)',
    },
    maximumAmountByOrder: {
      required: 'This field is required',
      maxlength: 'Must contain a maximum of 9 numbers.',
      isDecimal: 'Must be numeric (up to 4 decimals)',
    },
  },
  modal: {
    emailExists: {
      title: 'An account already exists with this email address',
      body: 'You can either go to Login or do Forgot password or even continue with another email address.',
      buttonOneText: 'LOGIN',
      buttonTwoText: 'USE ANOTHER EMAIL',
    },
  },
  default: undefined,
};

export const responseStatus = [
  HttpStatusCode.InternalServerError,
  HttpStatusCode.NotFound,
  HttpStatusCode.NoContent,
  HttpStatusCode.BadRequest,
  HttpStatusCode.RequestTimeout,
  HttpStatusCode.GatewayTimeout,
  HttpStatusCode.Ok,
  HttpStatusCode.Created,
  HttpStatusCode.Unauthorized,
];
