import { Injectable, Inject } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { BehaviorSubject, Observable, forkJoin, takeUntil } from 'rxjs';
import { ProductList, ProductLists } from 'src/app/types/product-list.model';
import { UserModel } from 'src/app/core/services/user/user.model';
import { ApiResponse } from 'src/app/types/api-response.model';
import { NavbarLink } from 'src/app/core/components/navbar/navbar-link/navbar-link.model';
import { API_URL } from 'src/app/shared/consts/api-urls';
import { select, Store } from '@ngrx/store';
import { currentShoppingContextSelector } from '../../store/user/user.selectors';
import { UserShoppingContext } from '../customer-company/user-shopping-context.model';
import { AppStateInterface } from '../../../types/app-state.interface';
import { BaseSubscriptionComponent } from '../../../shared/components/base-subscription/base-subscription.component';
import { WINDOW } from 'src/app/app.module';
import { AuthenticationService } from '../authentication/authentication.service';
import { ShoppingContextPunchout } from '../../../types/punchout.model';
import { UserTypeEnum } from '../user/user-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ProductListService extends BaseSubscriptionComponent {
  constructor(
    private api: ApiService,
    private userService: UserService,
    private store: Store<AppStateInterface>,
    @Inject(WINDOW) private window: Window,
    private authService: AuthenticationService
  ) {
    super();
    this.shoppingContext$.pipe(takeUntil(this.destroyed)).subscribe((shoppingContext: any) => {
      this.shoppingContext =
        shoppingContext?.defaultUser?.userTypeId === UserTypeEnum.POU
          ? (shoppingContext as ShoppingContextPunchout)
          : (shoppingContext as UserShoppingContext);
    });
  }

  private formId = 'add-to-product-list';
  private productLists = new BehaviorSubject<ProductLists | null>(null);
  userListIds: number[] = [];
  productLists$ = this.productLists.asObservable();
  private shoppingContext$ = this.store.pipe(select(currentShoppingContextSelector));
  private shoppingContext: UserShoppingContext | ShoppingContextPunchout | null;

  getLists(user?: UserModel | null) {

    const lists: ProductLists = {
      user: [],
      shared: [],
      public: [],
    };
    // Public lists (all users see public lists)
    const requestKey: string[] = ['public'];
    const requests: Observable<object>[] = [this.getPublicLists()];

    if (
      user &&
      this.userService.hasProductLists(user) &&
      (this.userService.hasDashboard(user) || this.userService.isPunchOut(user)) &&
      this.authService.getToken()
    ) {
      // User lists
      if (this.shoppingContext?.id) {
        requestKey.push('user');
        requests.push(this.getUserLists(user));
      }
    }

    if (user && this.userService.isBuyer(user) && this.authService.getToken()) {
      // Shared lists
      if (this.shoppingContext?.customerNumber) {
        requestKey.push('shared');
        requests.push(this.getSharedLists(user));
      }
    }

    forkJoin(requests).subscribe({
      next: (res: unknown[]) => {
        requestKey.forEach((listId, key) => {
          lists[listId as keyof ProductLists] = (res[key] as ApiResponse)['hydra:member'] as unknown as ProductList[];
        });
        // exclude user's own lists from 'shared'
        lists['shared'] = lists['shared'].filter((list) => list.createdBy !== user?.username);

        this.productLists.next(lists);
        this.setUserListIds(lists);
      },
      error: () => {
        this.productLists.next(null);
      },
    });
  }

  getPublicLists(isAdmin: boolean = false) {
    if (isAdmin) {
      return this.api.getAll(`${API_URL.ProductListHeader}`, { isCompanyList: true }, false);
    } else {
      return this.api.getAll(`${API_URL.ProductListHeader}`, { isCompanyList: true, statusId: 1 }, true);
    }
  }

  private getUserLists(user: UserModel) {
    const params: any = {
      statusId: 1,
    };

    if (this.userService.isPunchOut(user) && this.shoppingContext?.id) {
      params['shoppingContextPunchOut'] = this.shoppingContext.id;
    } else {
      (params['shoppingContext.user'] = user.userId),
        (params['quickListHeader.id'] = user?.currentShoppingAccount?.quickListHeader?.id),
        (params['shoppingContext.customerNumber'] = this.shoppingContext?.customerNumber);
    }
    return this.api.getAll(`${API_URL.ProductListHeader}`, params, false);
  }

  private getSharedLists(user: UserModel) {
    const params = {
      customerCompanyNumberId: this.shoppingContext?.customerCompanyNumber?.id,
      shared: true,
    };
    return this.api.getAll(`${API_URL.ProductListHeader}`, params, true);
  }

  private setUserListIds(lists: ProductLists): void {
    this.userListIds = [];
    for (const [listType, listData] of Object.entries(lists)) {
      if (listData.length) {
        this.userListIds = this.userListIds.concat(listData.map((i: ProductList) => i.id));
      }
    }
    this.window?.localStorage.setItem('userListIds', JSON.stringify(this.userListIds));
  }

  userCanViewList(listId: number) {
    const storedUserListIds = JSON.parse(this.window?.localStorage.getItem('userListIds') || '[]');
    if (!this.userListIds.length) {
      this.userListIds = storedUserListIds;
    }
    return this.userListIds.includes(listId);
  }

  toggleProductListForm() {
    const ele = this.window?.document.getElementById(this.formId) as HTMLElement;
    if (this.formOpen()) {
      ele.classList.remove('show');
    } else {
      ele.classList.add('show');
    }
  }

  formOpen(): boolean {
    const ele = this.window?.document.getElementById(this.formId) as HTMLElement;
    return ele.classList.contains('show');
  }

  // Shows and hides lists based on list type
  showList(e: Event, link: NavbarLink, subLink: NavbarLink) {
    e.stopPropagation();
    let y = 0;
    (link.links as NavbarLink[]).map((x: NavbarLink) => {
      const ele = this.window?.document.getElementById(x.type as string) as HTMLElement;
      if (ele !== null) {
        subLink.type && subLink.type === x.type && !ele.classList.contains('d-block')
          ? ele.classList.add('d-block')
          : ele.classList.remove('d-block');
        subLink.type && subLink.type !== x.type && ele.classList.remove('d-block');
      }
      y++;
    });
  }

  deleteProductListItems(id: number, list: string[], listType?: string) {
    const url = listType && listType === 'quicklist' ? API_URL.QuickListHeader : API_URL.ProductListHeader;
    return this.api.put(`${url}/${id}/delete_items`, { productCodes: list });
  }

  setCurrentListItemCount(count: number) {
    sessionStorage.setItem('currentListItemCount', String(count));
  }

  getCurrentListItemCount() {
    return Number(sessionStorage.getItem('currentListItemCount') || 0);
  }
}
