import { AppStateInterface } from '../../../types/app-state.interface';
import { createSelector } from '@ngrx/store';

/**
 * Creat select feature: In this case we bring in our global app state
 * interface
 * @param state
 *
 * since we added user to our app state we can grab it using state.user.
 */
export const selectFeature = (state: AppStateInterface) => state.user;

/**
 * Create your selector
 *
 * Pass your selectFeature, and your projector function takes state and
 * returns the user state.
 */
export const userSelector = createSelector(selectFeature, (state) => state.user);

// TODO: Refactor to our new shoppingContext store
export const currentShoppingContextSelector = createSelector(selectFeature, (state) => {
  // Make sure punchout warehouses are assigned to proper shopping context
  // @ts-ignore
  if (state.shoppingContext?.shoppingContextPunchoutDefault?.warehouses) {
    // @ts-ignore
    state.shoppingContext.warehouses = state.shoppingContext?.shoppingContextPunchoutDefault?.warehouses;
  }
  return state.shoppingContext;
});
