<div class="container-fluid article generic-template">
  <!--banner-->
  <div *ngIf="banner" class="wide-banner">
    <div class="img">
      <img [src]="banner.bannerImage.value[0].url" alt="" />
    </div>
    <div *ngIf="banner.title.value" class="details">
      <div class="title">{{ banner.title.value }}</div>
    </div>
  </div>

  <!--category links-->
  <div class="scrolling-wrapper"  #articlesTop>
    <div class="container d-flex">
      <div class="link" [ngClass]="{ active: !selectedCategory }">
        <a [routerLink]="'/articles'" [attr.data-testid]="DATA_TEST_ID.articles.list.categoryLink + '-all'">All</a>
      </div>
      <div
        class="link"
        *ngFor="let category of categories"
        [ngClass]="{ active: category.urlSlug === selectedCategory?.urlSlug }"
      >
        <a
          [routerLink]="'/articles/' + category.urlSlug"
          [attr.data-testid]="DATA_TEST_ID.articles.list.categoryLink + '-' + category.urlSlug"
          >{{ category.label }}</a
        >
      </div>
    </div>
  </div>

  <div class="container pt-5 text-center" *ngIf="noResults">
    <val-no-results></val-no-results>
  </div>

  <!--articles-->
  <section class="box-4 news-post container">
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
      <div
        class="col"
        *ngFor="let article of articles; let i = index"
        [attr.data-testid]="DATA_TEST_ID.articles.list.article + '-' + (i + 1)"
      >
        <div class="card">
          <div class="card-header">
            <a [routerLink]="articleUrl(article)" [attr.data-testid]="DATA_TEST_ID.articles.list.image + '-' + (i + 1)">
              <img class="img-fluid" [src]="article.elements.thumbnail.value[0].url" fallbackImage alt="" />
            </a>
          </div>

          <div class="card-body px-0">
            <div *ngIf="kontent.categoryObject[article.elements.category.value[0]]?.label" class="cat-title">
              {{ kontent.categoryObject[article.elements.category.value[0]].label }}
            </div>
            <div class="posted">
              {{ article.elements.date.value | date : "longDate" }}
            </div>
            <div class="title">
              <a
                [routerLink]="articleUrl(article)"
                [attr.data-testid]="DATA_TEST_ID.articles.list.title + '-' + (i + 1)"
                >{{ article.elements.title.value }}</a
              >
            </div>
            <article class="mt-2 mb-3">
              {{ article.elements.headline.value }}
            </article>
            <div>
              <a
                class="alink"
                [routerLink]="articleUrl(article)"
                [attr.data-testid]="DATA_TEST_ID.articles.list.button + '-' + (i + 1)"
                >Read More</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <val-item-list-pagination
      [page]="currentPage"
      [numPages]="totalPages"
      (changePageEvent)="onChangePage($event)"
    ></val-item-list-pagination>
  </section>
</div>
