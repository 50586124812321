import { Component, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchService } from 'src/app/core/services/search/search.service';
import { ProductResult, ProductItemId, SearchOptions } from 'src/app/core/services/search/search.model';
import { PricingService } from 'src/app/core/services/pricing/pricing.service';
import { ToastService } from '../../toast/toast.service';

@Component({
  selector: 'val-add-products-form',
  templateUrl: './add-products-form.component.html',
  styleUrls: ['../../../../../assets/styles/shared/product-and-vendor-form.scss'],
})
export class AddProductsFormComponent {
  @Output() productSelected = new EventEmitter<ProductResult[]>();
  @Output() nonStockProduct = new EventEmitter<ProductItemId[]>();
  @Input() allowNonStock?: boolean;
  @Input() title: string;

  constructor(private search: SearchService, public activeModal: NgbActiveModal, private pricing: PricingService,
    private toast: ToastService,
    ) {}

  q = '';
  products: ProductResult[] = [];
  addedProducts: ProductResult[] = [];
  showResults: boolean = false;
  showNoResult: boolean = false;

  onSearch() {
    const query = this.q.trim();

    if (!query) {
      this.clearResults();
      return;
    }

    const requestOptions: SearchOptions = {
      hitsPerPage: 5,
      facets: [],
      page: 0,
    };

    this.search
      .getProductResults(query, requestOptions)
      .then((res) => {
        this.products = res.hits as ProductResult[];
        this.showResults = this.products.length > 0;
        this.showNoResult = this.products.length === 0;
      })
      .catch((err: any) => {
        this.clearResults();
      });
  }

  setOptions(options: any) {
    this.title = options.title;
    this.allowNonStock = options.allowNonStock;
  }

  addNonStockItem() {
    const trimmedItemId = this.q.trim().toUpperCase();
    const productItemId: ProductItemId = { itemId: trimmedItemId, qty: 1 };
    let item: ProductResult = {
      ...productItemId,
      isNonStock: true,
    } as ProductResult;
    this.addedProducts.push(item);
    this.nonStockProduct.emit([productItemId]);
    this.productSelected.emit([item]);
    this.closeSearch();
  }

  clearResults() {
    this.showResults = false;
    this.q = '';
  }

  addProduct(product: ProductResult) {
    const selectedProducts: ProductResult[] = [product];
    this.pricing.getPricing(selectedProducts).subscribe({
      next: (updatedProducts) => {
        this.addedProducts.push(...updatedProducts);
        this.productSelected.emit(updatedProducts);
      },
      error: (err) => {
        console.error('Error updating prices:', err);
        this.toast.showError('Error fetching pricing');
      },
    });

    this.closeSearch();
}

  closeSearch() {
    this.clearResults();
  }
}
