import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { select, Store } from '@ngrx/store';
import { AppStateInterface } from '../../../../types/app-state.interface';
import { BaseSubscriptionComponent } from '../../../../shared/components/base-subscription/base-subscription.component';
import { userSelector } from '../../../store/user/user.selectors';
import { Observable, takeUntil } from 'rxjs';
import { UserModel } from '../../../services/user/user.model';

@Component({
  selector: 'val-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertBannerComponent extends BaseSubscriptionComponent implements OnInit {
  public user$: Observable<UserModel | null | undefined>;
  user: UserModel | null;
  alertMsg: string = '';
  hidden = true;
  cookieName = 'HideAlertBannerReset'; // Change this value every time you create a new message

  constructor(public cookieService: CookieService, private store: Store<AppStateInterface>) {
    super();
    this.user$ = this.store.pipe(select(userSelector));
  }

  ngOnInit(): void {
    this.hidden = !!this.cookieService.get(this.cookieName);
    this.user$.pipe(takeUntil(this.destroyed)).subscribe((user) => {
      this.user = user ?? null;
      if (!this.hidden) {
        this.getAlertMessage();
      }
    });
  }

  getAlertMessage(): void {
    // Adjust logic and set this.alertMsg as needed for the alert banner to show
    // Alert banner will not display unless this.alertMsg has a value
    this.alertMsg = '';

    // if (this.user?.userId) {
    //   this.alertMsg = '';
    //   return;
    // }
  }

  closeSiteBanner(): void {
    const cookieVal = new Date().toISOString();
    this.cookieService.set(this.cookieName, cookieVal, {
      path: '/',
      expires: 1,
      secure: true,
      sameSite: 'None',
    });
    this.hidden = true;
  }
}
