<div class="breadcrumbs {{ class }}" *ngIf="breadcrumbService.breadcrumbs.length">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          class="breadcrumb-item"
          *ngFor="let item of breadcrumbService.breadcrumbs; let i = index"
          [ngClass]="{ active: breadcrumbService.breadcrumbs.length === i + 1 }"
        >
          <a *ngIf="item?.route" [routerLink]="item?.route">{{ item.label }}</a>
          <span *ngIf="!item?.route">{{ item.label }}</span>
        </li>
      </ol>
    </nav>
  </div>
</div>
