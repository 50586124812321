import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CategoryService } from '../../../services/category/category.service';
import { Subscription } from 'rxjs';
import { CategoryMenu } from '../../../../types/category-menu.model';
import { Store } from '@ngrx/store';
import { AppStateInterface } from 'src/app/types/app-state.interface';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'val-product-menu',
  templateUrl: './product-menu.component.html',
  styleUrls: ['./product-menu.component.scss'],
})
export class ProductMenuComponent extends BaseSubscriptionComponent implements OnInit {
  categories: any = [];
  selected: any = {};
  activeSubCat = 0;
  customerCompanyNumber: number | undefined;

  constructor(
    private httpClient: HttpClient,
    private categoryService: CategoryService,
    private store: Store<AppStateInterface>
  ) {
    super();
    this.categoryService.categories$.pipe(takeUntil(this.destroyed)).subscribe((x: CategoryMenu[]) => {
      this.categories = x;
    });
  }

  ngOnInit(): void {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.categoryService.getUserCategories(data);
      });
  }

  toggleActive(id: number) {
    this.activeSubCat = id;
  }

  activeSubcategory(id: number) {
    return this.activeSubCat === id;
  }

  showSubcategory(id: number) {
    return this.selected[id] && this.selected[id] % 2 === 1;
  }

  toggleSubCategory(e: Event, id: number) {
    if (!this.selected[id]) {
      this.selected[id] = 0;
    }
    this.selected[id]++;
    e.stopPropagation();
  }
}
