import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KontentService } from '../../kontent.service';
import { GenericPage } from '../../models/content-types/generic_page';
import { LoadingSpinnerService } from '../../../../../shared/components/loading-spinner/loading-spinner.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'val-kontent-generic',
  templateUrl: './kontent-generic.component.html',
  styleUrls: ['./kontent-generic.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KontentGenericComponent extends BaseSubscriptionComponent implements OnInit {
  slug: string;
  page: GenericPage;
  content: any;
  sectionStyles: any;
  crumbs: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public kontentService: KontentService,
    private loadingService: LoadingSpinnerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.destroyed)).subscribe((data: any) => {
      this.crumbs = !!data.breadcrumb;
      if (data.slug) {
        this.loadData(data.slug);
      }
    });
    this.route.params.pipe(takeUntil(this.destroyed)).subscribe((params) => {
      if (params['slug']) {
        this.loadData(params['slug']);
      }
    });
  }

  loadData(slug: string): void {
    this.loadingService.start();
    this.kontentService
      .getGenericPage(slug)
      .then((res) => {
        this.page = res.data.items[0];
        // Set meta data
        this.kontentService.setMeta(this.page, this.crumbs);
        this.kontentService.formatData(this.page).then((x) => {
          if (!x) {
            this.goTo404();
            return;
          } else {
            this.content = x;
            this.sectionStyles = this.kontentService.getSectionStyles(this.content);
          }
        });
      })
      .catch((err) => {
        console.error('Generic Component Error: ', err);
        this.goTo404();
        return;
      })
      .finally(() => {
        this.loadingService.stop();
      });
  }

  private goTo404(): void {
    this.router.navigate(['/404']);
  }
}
