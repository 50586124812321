import { Component, HostListener, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { SearchService } from '../../../services/search/search.service';
import { CategoryResult } from '../../../services/search/search.model';
import { Router } from '@angular/router';
import { SearchResponse } from '@algolia/client-search';
import { AppStateInterface } from '../../../../types/app-state.interface';
import { Store } from '@ngrx/store';
import { Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { PermissionService } from '../../../services/permission/permission.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { WINDOW } from 'src/app/app.module';

@Component({
  selector: 'val-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
})
export class SearchFormComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() showMobile: boolean;
  @Output() hideMobileEvent = new EventEmitter<boolean>();

  private searchSubject = new Subject<string>();

  constructor(
    private router: Router,
    private search: SearchService,
    private store: Store<AppStateInterface>,
    private permissionService: PermissionService,
    @Inject(WINDOW) private window: Window
  ) {
    super();
    this.savedQueries = (this.window?.localStorage && this.window?.localStorage?.getItem('savedQueries')) ? JSON.parse(this.window?.localStorage.getItem('savedQueries')!) : [];

    this.searchSubject
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((term: string) => this.search.unifiedSearch(term).catch((error) => Promise.reject(error)))
      )
      .subscribe({
        next: (res) => {
          let results = res.results as SearchResponse[];
          this.categories = results[0].hits;
          this.products = results[1].hits;
          this.suggestions = results[2] ? results[2].hits : [];
          this.quickLists = results[3] ? results[3].hits : [];

          this.showResults =
            this.categories.length + this.products.length + this.quickLists.length > 0 && this.q.length > 0;
        },
        error: (err) => {
          this.clearResults();
        },
      });
  }

  q = '';
  categories: Array<any> = [];
  products: Array<any> = [];
  quickLists: Array<any> = [];
  savedQueries: string[] = [];
  showResults: boolean = false;
  insideClick = false;
  suggestions: Array<any> = [];

  @HostListener('click')
  clicked() {
    this.insideClick = true;
  }

  @HostListener('document:click')
  clickedOut() {
    if (!this.insideClick) {
      this.showResults = false;
    }
    this.insideClick = false;
  }

  ngOnInit(): void {}

  onSearch(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.submitForm();
      return;
    }

    if (!this.q.trim()) {
      this.clearResults();
      return;
    }

    this.searchSubject.next(this.q.trim());
  }

  seeSuggestion(suggestion: any) {
    this.q = suggestion.objectID;
    this.saveSearch();
    this.router.navigate([`/search/${encodeURIComponent(this.q)}`]);
    this.closeSearch();
  }

  saveSearch() {
    const term = this.q.slice().trim().toLowerCase();
    if (this.products.length > 0 && term.length > 1 && !this.savedQueries.includes(term)) {
      this.savedQueries.unshift(term);
      localStorage.setItem('savedQueries', JSON.stringify(this.savedQueries.slice(0, 5)));
    }
  }

  submitForm() {
    if (this.q.length) {
      this.saveSearch();
      this.router.navigate([`/search/${encodeURIComponent(this.q)}`]);
      this.closeSearch();
    }
  }

  clearResults() {
    this.categories = this.products = [];
    this.showResults = false;
    this.q = '';
  }

  seeCategory(category: CategoryResult) {
    const slugs = category.path.map((c: any) => c.slug);
    if (slugs.length) {
      this.router.navigate(['/products/' + slugs.join('/')]);
      this.closeSearch();
    }
  }

  closeSearch() {
    this.clearResults();
    this.hideMobileEvent.emit(true);
  }
}
