import { Component, Input, OnInit } from '@angular/core';
import { IContentItem } from '@kontent-ai/delivery-sdk/lib/models/item-models';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';

@Component({
  selector: 'val-kontent-tiny-banner',
  templateUrl: './kontent-tiny-banner.component.html',
})
export class KontentTinyBannerComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() content: any;
  @Input() items: any;
  banners: IContentItem[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.formatData();
  }

  private formatData(): void {
    this.content.elements.banners.value.forEach((codeName: string) => {
      this.banners.push(this.items[codeName]);
    });
  }
}
