import { UserModel } from 'src/app/core/services/user/user.model';
import { ProductResult, ProductSearchResults, SearchOptions } from 'src/app/core/services/search/search.model';
import { BehaviorSubject, from, forkJoin, Observable, takeUntil } from 'rxjs';
import { Cart, CartLine, CheckoutFlow } from '../../../types/cart.model';
import { BaseSubscriptionComponent } from '../base-subscription/base-subscription.component';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../../types/app-state.interface';
import { UserState } from '../../../core/store/user/user.reducer';
import { UserShoppingContext } from '../../../core/services/customer-company/user-shopping-context.model';
import { ShoppingContextPunchout } from '../../../types/punchout.model';
import { ApiService } from '../../../core/services/api/api.service';
import { API_URL } from '../../consts/api-urls';
import { setCart } from '../../../core/store/cart/cart.actions';
import { SearchService } from '../../../core/services/search/search.service';
import { ToastService } from '../toast/toast.service';
import { LoadingSpinnerService } from '../loading-spinner/loading-spinner.service';
import { objStringKey } from '../../../types/object.model';
import {
  Permissions,
  showCheckout,
  showCheckoutWithRestrictions,
  showRestrictionsMsg,
  showSendForApproval,
  checkoutPermissions,
} from '../../consts/permissions.enum';
import { CartType } from '../../consts/cart-types';
import { Router } from '@angular/router';
import { PricingService } from 'src/app/core/services/pricing/pricing.service';
import { UserRoleEnum } from 'src/app/core/services/user/user-role.enum';
import { firstValueFrom } from 'rxjs';

export class BaseCartComponent extends BaseSubscriptionComponent {
  private _activeCart: Cart;
  private _allowNonStock = false;
  private _userState: UserState;
  private _useStore = false;
  private preLoginItems: CartLine[] = [];
  private activeCartSubject = new BehaviorSubject<Cart>(this.defaultCart);

  constructor(
    public api: ApiService,
    private loading: LoadingSpinnerService,
    private router: Router,
    private search: SearchService,
    private store: Store<AppStateInterface>,
    private toast: ToastService,
    private pricing: PricingService
  ) {
    super();

    this.store
      .select('user')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.userState = data;
      });
  }

  // activeCartSubject for use outside of the service
  getActiveCart(): Observable<Cart> {
    return this.activeCartSubject.asObservable();
  }

  setActiveCart() {
    this.activeCartSubject.next(this._activeCart);
  }

  clearActiveCart() {
    this._activeCart = this.defaultCart;
    this.activeCartSubject.next(this._activeCart);
  }

  // get / set activeCart for internal/service use
  public set activeCart(cart: Cart) {
    this._activeCart = cart || this.defaultCart;
    this.setActiveCart();
  }

  public get activeCart(): Cart {
    return this._activeCart;
  }

  public set allowNonStock(allowNonStock: boolean) {
    this._allowNonStock = allowNonStock;
  }

  public get allowNonStock(): boolean {
    return this._allowNonStock;
  }

  public set userState(userState: UserState) {
    this._userState = userState;
  }

  public get userState(): UserState {
    return this._userState;
  }

  public get user(): UserModel | null {
    return this._userState.user || null;
  }

  public get shoppingContext(): UserShoppingContext | ShoppingContextPunchout | null {
    return this._userState.shoppingContext || null;
  }

  public set useStore(useStore: boolean) {
    this._useStore = useStore;
  }

  public get useStore(): boolean {
    return this._useStore;
  }

  getDefaultCartId(cartPrefix: string): string {
    // build cartId based on user's current shoppingContext and the prefix that was provided
    return cartPrefix && this.user?.userId && this.shoppingContext?.id && this.api.getToken()
      ? `${cartPrefix}-${this.user.userTypeId}-${this.shoppingContext.id}`
      : '';
  }

  async initCart(
    cartId: string,
    useStore: boolean,
    allowNonStock: boolean,
    checkInvalidItems: boolean = false
  ): Promise<void> {
    // Initialize cart - check if a cart exists; if not, create a new one.
    // Set allowNonStock and useStore flags, which will remain until the next cart initialization.
    this.allowNonStock = allowNonStock;
    this.useStore = useStore;

    try {
      if (useStore) {
        // If we're using the store, fetch the current cart state from the store.
        // Using firstValueFrom to treat the Observable as a Promise and retrieve the first emitted value.
        // This is a one-time subscription that auto-unsubscribes after the first emission, ensuring clean-up.
        const data = await firstValueFrom(this.store.select('cart'));
        // Set the active cart with the fetched data or fall back to a default empty cart.
        this.activeCart = data.cart || this.defaultCart;
      } else if (!cartId) {
        // If cartId is not provided and we're not using the store, reset to the default cart.
        this.updateActiveCart(this.defaultCart);
        return; // Exit early since there's no cart to initialize.
      }

      if (!cartId) {
        return; // If there's no cartId, there's nothing to proceed with.
      }

      try {
        // Attempt to retrieve an existing cart using the provided cartId.
        // If successful, save the initialized cart data.
        const data = await firstValueFrom(this.getCart(cartId));
        await this.saveInitCart(data as Cart, checkInvalidItems);
      } catch (error) {
        // If fetching the cart fails (e.g., the cart doesn't exist), attempt to create a new cart.
        // Check if the user is a guest; create a guest cart if true, otherwise create a regular cart.
        const createCartObservable = this.user?.roles?.includes(UserRoleEnum.ROLE_GUEST)
          ? this.createGuestCart()
          : this.createCart(cartId);

        try {
          // Try to create the cart and save the initialized cart data.
          const data = await firstValueFrom(createCartObservable);
          await this.saveInitCart(data as Cart, checkInvalidItems);
        } catch (error) {
          // Log any errors encountered during cart creation.
          console.error('Error creating cart:', error);
        }
      }
    } catch (error) {
      // Catch any other unexpected errors during the cart initialization process.
      console.error('Error initializing cart:', error);
    }
  }

  getCart(cartId: string): Observable<Object> {
    return this.api.get(`${API_URL.Carts}/${cartId}`, '', false);
  }

  createCart(cartId: string): Observable<Object> {
    const data = {
      cartId,
      cartType: cartId.split('-').shift(),
      shoppingContextId: this.shoppingContext?.id,
      userId: this.user?.userId,
    };
    return this.api.post(API_URL.Carts, { ...this.defaultCart, ...data });
  }

  createGuestCart(): Observable<Object> {
    const data = {
      cartId: this.getDefaultCartId(CartType.Order),
      cartType: CartType.Order,
      shoppingContextId: this.shoppingContext?.id,
      userId: this.user?.userId,
    };
    return this.api.post(API_URL.Carts, { ...this.defaultCart, ...this.activeCart, ...data });
  }

  resetCart(cartId: string) {
    this.createCart(cartId).subscribe({
      next: (data: any) => {
        this.updateActiveCart(data as Cart);
      },
      error: () => {
        this.toast.showError('Error resetting cart');
      },
    });
  }

  submitForApproval(data: objStringKey): Observable<any> {
    let postObj = {
      cartId: this.activeCart.cartId,
      ...data,
    };
    return this.api.post(API_URL.OrderApprovalRequest, postObj);
  }

  addItem(products: ProductResult | ProductResult[], updateTotals?: boolean, goToCart?: boolean) {
    const productsArray = this.ensureArray(products);
    if (productsArray.length === 0) return;

    const cartIri = this.cartIri();
    if (this.user?.userId && !cartIri) {
      this.toast.showError('Cart error');
      return;
    }

    const cartItems = this.createCartItems(productsArray);

    if (this.user?.userId) {
      this.addItemsForUser(cartItems, updateTotals, goToCart);
    } else {
      this.addItemsLocally(cartItems, updateTotals);
    }
  }

  private ensureArray(products: ProductResult | ProductResult[]): ProductResult[] {
    return Array.isArray(products) ? products : [products];
  }

  private createCartItems(productsArray: any[]): any[] {
    return productsArray.map((item) => ({
      title: item.title,
      itemId: item.itemId,
      manufacturer: item.manufacturer,
      attachments: item.attachments,
      image: item.image,
      isAvailable: item.isAvailable,
      isFinalSale: item.isFinalSale,
      isInStock: item.isInStock,
      leadTime: item.leadTime,
      mainUpc: item.mainUpc,
      manufacturerPartNo: item.manufacturerPartNo,
      personalCodes: item.personalCodes,
      sellMult: item.sellMult || 1,
      seoUrl: item.seoUrl,
      unspsc: item.unspsc,
      isClearance: item.isClearance,
      objectID: item.objectID,
      price: item.price,
      id: item.id,
      uom: item.uom,
      url: item.url,
      cart: item.cart,
      uniqueId: item.uniqueId,
      qty: item.qty && item.qty % (item.sellMult || 1) === 0 ? item.qty : (item.sellMult || 1) * (item.qty || 1),
      isNonStock: typeof item.isIndexed === 'boolean' ? !item.isIndexed : !!item.isNonStock, // Set isNonStock based on isIndexed
      productNotes: item.productNotes || '',
      isSelected: false, // Setting it here to avoid altering original products array
      orderNumber: item.orderNumber || undefined,
    }));
  }

  private addItemsForUser(cartItems: any[], updateTotals?: boolean, goToCart?: boolean): void {
    this.loading.start();
    this.api.patch(`${API_URL.Carts}/${this.activeCart?.cartId}/add_to_cart`, { cartItems }).subscribe({
      next: (data: any) => {
        data = data as Cart;
        const cartItemIds: number[] = []; // store list of ids so we do not use duplicates
        cartItems.forEach((item) => {
          // We need to make sure the cartItems object contains an id, otherwise future pricing updates fail
          if (!item.id) {
            // pull list of ids from database for this particular item
            // if the item exists more than once, we have to make sure we don't assign the id more than once
            const itemIds: number[] = Object.values(data.cartItems)
              .filter((sxe: any) => sxe.itemId === item.itemId) // only return db id for current itemId
              .map((x: any) => x.id) // return array of id numbers
              .filter((x) => !cartItemIds.includes(x)); // exclude ids that have already been used
            if (itemIds.length) {
              const newItemId: number = itemIds[0];
              item.id = newItemId;
              cartItemIds.push(newItemId); // add to cartItemIds so we can exclude if this item exists multiple times
            }
          }
          this.itemAdded(item, updateTotals);
        });
        this.loading.stop();
        if (goToCart) {
          this.router.navigate(['/cart']);
        }
        this.showAdditionMessage(cartItems);
      },
      error: () => {
        this.loading.stop();
        this.toast.showError('Error adding item(s) to cart');
      },
    });
  }

  private addItemsLocally(cartItems: any[], updateTotals?: boolean): void {
    cartItems.forEach((item) => this.itemAdded(item, updateTotals));
    this.showAdditionMessage(cartItems);
  }

  private showAdditionMessage(cartItems: any[]): void {
    const msg = cartItems.length === 1 ? `Item #${cartItems[0].itemId} added` : `${cartItems.length} items added`;
    this.toast.showSuccess(msg);
  }

  removeItems(products: CartLine[]) {
    if (!products.length) {
      return;
    }
    this.loading.setLoadingPrices(['summary']);

    const handleSuccess = (length: number) => {
      this.calculateSubTotal();
      this.loading.setLoadingPrices();
      this.toast.showSuccess(`${length} item(s) removed`);
    };

    if (this.userState.user && this.userState.user.userId) {
      let itemIds: any = products.map((obj) => obj.id);
      let uniqueIds: any = products.map((obj) => obj.uniqueId);

      // removes items from local storage if IDs are an exact match
      this.activeCart.cartItems = this.activeCart.cartItems.filter((item: any, index: number) => {
        return !uniqueIds.includes(index) || !itemIds.includes(item.id);
      });
      this.api.put(`${API_URL.Carts}/${this.activeCart?.cartId}/delete_items`, { itemIds: itemIds }).subscribe({
        next: () => {
          handleSuccess(itemIds.length);
          this.updateActiveCart(this.activeCart);
        },
      });
    } else {
      // remove items from local storage if the two objects are an exact match
      products.forEach((product: any) => {
        const index = this.activeCart.cartItems.findIndex(
          (obj: any) => JSON.stringify(obj) === JSON.stringify(product)
        );
        if (index !== -1) {
          this.activeCart.cartItems.splice(index, 1);
        }
      });
      handleSuccess(this.activeCart.cartItems.length);
      this.updateActiveCart(this.activeCart);
    }
  }

  removeByIndex(productIndex: number) {
    const product = this.activeCart.cartItems?.[productIndex];

    if (product) {
      this.loading.setLoadingPrices(['summary']);
      this.activeCart.cartItems = this.activeCart.cartItems.filter((value: any, index: any) => index !== productIndex);
      this.calculateSubTotal();

      if (this.userState.user?.userId) {
        this.api.delete(`${API_URL.CartItems}/${product['id']}`).subscribe({
          next: () => this.loading.setLoadingPrices(),
        });
      } else {
        this.loading.setLoadingPrices();
      }
      return true;
    } else {
      this.toast.showError('Item removal failed');
      return false;
    }
  }

  updateItem(productIndex: number, product: CartLine) {
    this.loading.setLoadingPrices(['summary']);
    this.calculateSubTotal();
    if (this.activeCart.cartId) {
      this.api
        .patch(
          `${API_URL.CartItems}/${product.id}`,
          JSON.stringify({ qty: product.qty, productNotes: product.productNotes })
        )
        .subscribe({
          next: () => {
            this.loading.setLoadingPrices();
          },
        });
    } else {
      this.loading.setLoadingPrices();
    }
    if (this.activeCart.cartItems && this.activeCart.cartItems[productIndex]) {
      this.activeCart.cartItems[productIndex] = product;
      this.updateActiveCart(this.activeCart);
      return true;
    } else {
      this.toast.showError('Item update failed');
      return false;
    }
  }

  emptyCartItems(confirmationMsg: boolean = true) {
    this.clearLocalCartItems();
    if (this.activeCart.cartId) {
      this.api.delete(`${API_URL.Carts}/${this.activeCart.cartId}/delete_all`).subscribe();
    }
    if (confirmationMsg) {
      this.toast.showSuccess('All items removed from cart');
    }
  }

  clearLocalCartItems() {
    this.activeCart.cartItems = [];
    this.updateActiveCart(this.activeCart);
  }
  private async saveInitCart(cartData: Cart, checkInvalidItems: boolean = false): Promise<void> {
    // If there's an existing active cart, merge its data with the incoming cartData.
    // This ensures that any new cart data is combined with the current cart state.
    if (this.activeCart) {
      cartData = { ...this.activeCart, ...cartData } as Cart;
    }

    // Update the active cart with the combined cart data, either newly fetched or merged.
    this.updateActiveCart(cartData);

    // Check if there are items added before user login (preLoginItems) and if the cart has a valid cartId.
    // If so, merge these pre-login items into the current cart.
    // This handles scenarios where a guest user adds items to the cart and then logs in, ensuring those items persist.
    if (this.preLoginItems.length && cartData.cartId) {
      await this.mergePreLoginItems(cartData);
    }

    // Always enhance the items in the cart to ensure they contain the complete and most up-to-date data.
    // This includes additional details fetched from an external service, such as pricing or availability.
    // The `checkInvalidItems` flag allows for optional validation of cart items.
    await this.enhanceItems(cartData, checkInvalidItems);
  }

  private itemAdded(item: CartLine, updateTotals?: boolean): void {
    // sequence of formatting events used when adding items
    this.activeCart.cartItems.push(item);
    this.updateActiveCart(this.activeCart);
    if (updateTotals) {
      // this.sxeGetPricing(['summary']);
    }
  }

  private updateActiveCart(cartData: Cart): void {
    this.activeCart = cartData;
    if (this.useStore) {
      this.store.dispatch(setCart({ cart: cartData }));
    }
  }

  private async mergePreLoginItems(cartData: Cart): Promise<void> {
    // Merge items added to the cart prior to user login with the existing cart.
    // This ensures that items added while the user was a guest are not lost after login.

    // Combine the current cart items with the preLoginItems and reduce them to a basic state.
    // The reduceItems method ensures that duplicate or unnecessary data is stripped away, keeping only essential information.
    cartData.cartItems = this.reduceItems(cartData.cartItems.concat(this.preLoginItems));

    try {
      // Send the updated cart data to the server to update the cart in the database.
      // This is necessary to synchronize the server-side cart state with the client-side state.
      await firstValueFrom(this.api.put(`${API_URL.Carts}/${cartData.cartId}`, cartData));

      // Enhance the cart items with additional details (e.g., updated pricing, availability).
      // This step ensures that the merged cart items have the most accurate and complete information.
      await this.enhanceItems(cartData);
    } catch (error) {
      // Handle any errors that occur during the merge or update process.
      // Display an error message to the user if something goes wrong, ensuring that they are informed of the issue.
      this.toast.showError('Error merging new items into existing cart');
    }

    // Clear the preLoginItems array as they have now been successfully merged into the cart.
    this.preLoginItems = [];
  }

  savePreLoginItems() {
    // occurs when a guest with items in cart logs in, so items can be transferred to actual cart
    if (this.activeCart && this.activeCart.cartItems) {
      this.preLoginItems = this.activeCart.cartItems;
      this.activeCart.cartItems = [];
    }
  }

  reduceItems(items: CartLine[]): any[] {
    // reformat list of items to 'basic' state (without additional Algolia data)
    return items.length ? items.map((item: CartLine) => this.reduceItem(item)) : [];
  }

  reduceItem(item: CartLine): any {
    // reformat item to 'basic' state (without additional Algolia data)
    return {
      qty: item.qty,
      cart: this.cartIri(),
      itemId: item.itemId,
      isNonStock: !!item.isNonStock,
      productNotes: item.productNotes,
      price: item.price,
      id: item.id,
      uom: item.uom,
    };
  }

  private async enhanceItems(cartData: Cart, checkInvalidItems: boolean = false): Promise<void> {
    // Ensure cartData has items to enhance; if not, update the active cart and return.
    if (!cartData.cartItems.length) {
      this.updateActiveCart(cartData);
      return;
    }

    // Prepare request options for fetching additional item data from Algolia.
    // Specifies the attributes to retrieve and any necessary filters.
    const requestOptions: SearchOptions = {
      attributesToHighlight: [],
      attributesToRetrieve: [
        'attachments',
        'image',
        'isFinalSale',
        'itemId',
        'isAvailable',
        'isClearance',
        'isInStock',
        'leadTime',
        'manufacturer',
        'manufacturerPartNo',
        'personalCodes',
        'quickList',
        'sellMult',
        'seoUrl',
        'title',
        'unspsc',
        'url',
        'mainUpc',
      ],
      facetFilters: this.itemIdFacetFilters(cartData.cartItems),
      facets: [],
      hitsPerPage: cartData.cartItems.length,
      page: 0,
    };

    try {
      // Fetch product results and pricing data concurrently using forkJoin.
      // forkJoin waits for all observables to complete and then returns the combined results.
      const results = await forkJoin([
        from(this.search.getProductResults('', requestOptions)),
        this.pricing.getPricing(cartData.cartItems),
      ]).toPromise();

      // If no results are returned, log an error and exit the function.
      if (!results) {
        console.error('No results returned from forkJoin');
        return;
      }

      // Destructure the results into product results and updated products.
      const [productResults, updatedProducts] = results;
      // Typecast productResults to the expected ProductSearchResults type.
      const resultsTyped = productResults as ProductSearchResults;

      // Create a map of hits (product details) from Algolia for quick lookup.
      const hitsMap = new Map<string, ProductResult>(resultsTyped.hits.map((hit) => [hit.itemId.toUpperCase(), hit]));

      // Arrays to store invalid and updated cart items.
      const invalidCartItems: CartLine[] = [];
      const updatedCartItems: CartLine[] = [];

      // Iterate over cart items to update them with additional data or identify invalid items.
      cartData.cartItems.forEach((item) => {
        // Find the corresponding hit and updated product details.
        const hit = hitsMap.get(item.itemId.toUpperCase());
        const updatedProduct = updatedProducts.find((p: ProductResult) => p.itemId === item.itemId);

        // If both hit and updated product details are found, enhance the cart item.
        if (hit && updatedProduct) {
          updatedCartItems.push({
            ...hit,
            ...item,
            price: item.price,
            qty: item.qty,
            id: item.id,
            uom: item.uom,
            sellMult: updatedProduct.sellMult,
            cart: cartData.cartId,
          });
        } else if (this.allowNonStock || item.isNonStock) {
          // If the item is allowed to be non-stock or is already marked as non-stock, retain it.
          updatedCartItems.push(item);
        } else if (checkInvalidItems) {
          // If the item is invalid and checking for invalid items is enabled, add it to the invalid items list.
          invalidCartItems.push(item);
        }
      });

      // Remove invalid cart items if any are found and validation is enabled.
      if (invalidCartItems.length && checkInvalidItems) {
        await this.removeItems(invalidCartItems);
      }

      // Update the cart items with the enhanced and validated items.
      cartData.cartItems = updatedCartItems;
      // If using the store, update the subtotal and dispatch the updated cart to the store.
      if (this.useStore) {
        this.setSubTotal(cartData);
        this.store.dispatch(setCart({ cart: cartData }));
      }
    } catch (err) {
      // Log any errors encountered during the enhancement process and notify the user.
      console.error('Error updating prices:', err);
      this.toast.showError('Error fetching pricing');
    }
  }

  private cartIri(): string | null {
    return this.activeCart?.cartId ? `/api/carts/${this.activeCart.cartId}` : null;
  }

  calculateSubTotal(checkInvalidItems: boolean = false, newCart?: Cart) {
    const cart = newCart || this.activeCart;
    if (!cart) {
      return;
    }
    this.setSubTotal(cart);
    // check for guest user
    if (!this.user) {
      this.enhanceItems(cart, checkInvalidItems);
    }
    this.updateActiveCart(cart);
  }

  setSubTotal(cart: Cart) {
    let subTotal = 0;
    cart.cartItems.forEach((product: CartLine) => {
      if (!product.qty) {
        product.qty = 1;
      }
      if (!product.price) {
        product.price = 0;
      }
      subTotal += product.qty * product.price;
    });
    cart.subTotal = subTotal;
  }

  private itemIdFacetFilters(items: CartLine[]): [any] {
    // formats facet filters for Algolia search by itemId list
    const itemIds = items.map((x: any) => x.itemId);
    const uniqueItems = itemIds.length ? itemIds.filter((x, y, z) => z.indexOf(x) == y) : [];
    return [uniqueItems.map((item: any) => `itemId:${item}`)];
  }

  private get defaultCart(): Cart {
    // 'empty' cart object
    return {
      allowSms: false,
      approvalCartId: undefined,
      approvers: [],
      billingAddress: undefined,
      billingAddressId: null,
      billingMethod: '',
      cartId: '',
      cartItems: [],
      cartName: undefined,
      cartType: 'O',
      customerPoNumber: '',
      warehouseNumber: '',
      fulfillmentDate: undefined,
      goodsServiceTax: 0,
      id: undefined,
      instructions: '',
      maximumAmountByMonth: undefined,
      maximumAmountByOrder: undefined,
      mobileNumber: '',
      orderNotes: '',
      selectedWarehouse: '',
      shippingAddress: undefined,
      shippingAddressId: null,
      shippingMethod: '',
      shipWhenComplete: false,
      shoppingContextId: 0,
      totalSpecialCharges: 0,
      subTotal: 0,
      totalOrderValue: 0,
      userId: 0,
    } as Cart;
  }

  sxeSubmitOrder(monerisTicket?: string): Observable<any> {
    // Submit order to SXe submitSFOrder / webTransactionType = 'LSF' (requires cartId)
    let postData = {
      cartId: this.activeCart.cartId,
      isSubmitToSxe: true,
      monerisTicket,
    };
    return this.executeSxeCall(API_URL.OrdersSubmit, postData);
  }

  sxeGetPricing(loadingOpts: string[]): Observable<any> {
    // Fetch pricing data from SXe using submitSFOrder / webTransactionType = 'TSF'
    this.loading.setLoadingPrices(loadingOpts);

    let apiUrl = '';
    let postData = {};

    if (this.activeCart.cartId) {
      // authenticated user / database cart
      apiUrl = API_URL.OrdersSubmit;
      postData = {
        cartId: this.activeCart.cartId,
        isSubmitToSxe: false,
      };
    } else {
      // non-authenticated user / temp cart
      apiUrl = API_URL.OrdersGuestPricing;

      const items = this.activeCart.cartItems.map((item: CartLine) => {
        return {
          itemId: item.itemId,
          title: item.title,
          qty: item.qty,
          uom: item.uom,
        };
      });
      postData = { items };
    }
    return this.executeSxeCall(apiUrl, postData);
  }

  private executeSxeCall(apiUrl: string, postData: objStringKey): Observable<any> {
    return new Observable((observer) => {
      this.api.post(apiUrl, postData).subscribe(
        (data: any) => {
          this.loading.setLoadingPrices();
          this.applyPricingUpdates(data).subscribe(
            () => {
              if (this.useStore) {
                this.store.dispatch(setCart({ cart: this.activeCart }));
              }
              observer.next(data);
              observer.complete();
            },
            (error) => {
              console.error(error);
              observer.error(error);
            }
          );

          observer.next(data);
          observer.complete();
        },
        (error) => {
          this.toast.showError(error);
          observer.error(error);
        }
      );
    });
  }

  private applyPricingUpdates(data: any): Observable<void> {
    return new Observable((observer) => {
      const observables: Observable<any>[] = [];
      this.activeCart.subTotal = data.results.totalLineAmount;
      this.activeCart.totalSpecialCharges = data.results.totalSpecialCharges ?? 0;
      this.activeCart.goodsServiceTax = data.results.salesTaxAmount;
      this.activeCart.totalOrderValue = data.results.totalOrderValue;

      this.activeCart.cartItems.forEach((cartItem: any) => {
        const lineItem = data.results.lineItems.find(
          (item: any) => item.productNumber.toUpperCase() === cartItem.itemId.toUpperCase()
        );

        if (lineItem && (lineItem.sellPrice !== cartItem.price || lineItem.quantityOrdered !== cartItem.qty)) {
          cartItem.price = lineItem.sellPrice;
          cartItem.quantityOrdered = lineItem.qty;
          if (this.activeCart.cartId && cartItem.id) {
            observables.push(
              this.api.patch(`${API_URL.CartItems}/${cartItem.id}`, JSON.stringify({ price: lineItem.sellPrice }))
            );
          }
        } else if (!lineItem && !cartItem.isNonStock) {
          // Remove cart line if it is not within the orderSubmit return. Do not want to display data that hasn't been properly calculated.
          this.activeCart.cartItems = this.activeCart.cartItems.filter((item: any) => item.id !== cartItem.id);
          if (this.activeCart.cartId) {
            observables.push(this.api.delete(`${API_URL.CartItems}/${cartItem.id}`));
          }
        }
      });
      this.updateActiveCart(this.activeCart);

      if (observables.length > 0) {
        forkJoin(observables).subscribe(
          () => {
            observer.next();
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  public checkoutPermissions() {
    if (this.user?.userTypeId === 'POU') {
      return {
        cart: 'showCheckout',
        checkout: [],
      };
    }

    let checkout: CheckoutFlow[] = [];
    let cart = '';
    let userPermissions = (this.shoppingContext?.permissions as unknown as string[]) || [];
    let customerCompany = this.shoppingContext?.customerCompanyNumber;
    userPermissions = userPermissions.filter((x: any) => {
      return Object.values(Permissions).includes(x.toUpperCase());
    });

    // Add APPROVAL_WORKFLOW to user permissions if AW assigned to customer
    if (customerCompany?.approvalWorkflow) {
      // If (user is an approver OR user can place orders) AND (credit card OR purchase order enabled)
      const canOrder1 = userPermissions.filter((x: any) => [Permissions.APO, Permissions.AO].includes(x)).length;
      const canOrder2 = userPermissions.filter((x: any) => [Permissions.CC, Permissions.PO].includes(x)).length;
      if (!canOrder1 || !canOrder2) {
        userPermissions.push(Permissions.AW);
      }
    }

    // returns then name of the matched permission array.
    cart = this.findPermissionsMatch(userPermissions);

    let hasPO = false;
    if (userPermissions.includes(Permissions.PO)) {
      hasPO = true;
      // PO is always the default.
      checkout.push({ type: 'PO', label: 'Bill to my account', value: 1 });
    } else if (userPermissions.includes(Permissions.CC)) {
      // PO is always the default.
      checkout.push({ type: 'CC', label: 'Pay by credit card', value: hasPO ? 0 : 1 });
    }

    // resets billing method if one has been already saved in the database.
    if (checkout.length > 1 && this.activeCart?.billingMethod) {
      checkout = checkout.map((x: any) => ({
        ...x,
        value: x.type === this.activeCart.billingMethod ? 1 : 0,
      }));
    }
    // Perhaps flow should be moved
    if (userPermissions.includes(Permissions.AW) && customerCompany?.approvalWorkflow) {
      cart = 'showSendForApproval';
      checkout = [{ type: 'AO', label: 'Approve Order', value: hasPO ? 0 : 1 }];
    }

    return <any>{ checkout: checkout, cart: cart };
  }

  private findPermissionsMatch(userPermissions: string[]) {
    const filteredUserPerms = userPermissions.filter((userPerm) => checkoutPermissions.includes(userPerm));

    const arrays = { showCheckout, showCheckoutWithRestrictions, showRestrictionsMsg, showSendForApproval };
    for (const [name, array] of Object.entries(arrays)) {
      const found = array.some((permissions) => filteredUserPerms.every((p: any) => permissions.includes(p)));
      if (found) {
        return name;
      }
    }
    return '';
  }

  public setSelectedWarehouse(cart: Cart | null | undefined, pickupWarehouses: string[], deliveryWarehouses: string[]) {
    // Reset selectedWarehouse if
    // A) It is not already set
    // B) It is set, but the value doesn't exist within the current warehouse list
    if (!cart) {
      return;
    }

    if (!cart.shippingMethod || !cart.shippingMethod?.length) {
      cart.selectedWarehouse = deliveryWarehouses[0];
    } else if (
      !cart.selectedWarehouse ||
      (cart.shippingMethod === 'delivery' && !deliveryWarehouses.includes(cart.selectedWarehouse)) ||
      (cart.shippingMethod !== 'delivery' && !pickupWarehouses.includes(cart.selectedWarehouse))
    ) {
      cart.selectedWarehouse = cart.shippingMethod === 'delivery' ? deliveryWarehouses[0] : pickupWarehouses[0];
    }
  }
}
