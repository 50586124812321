<!--Image-->
<div *ngIf="content.elements.image.value.length" class="img">
  <a
    *ngIf="content.elements.actionUrl?.value; else imgNoLink"
    [href]="content.elements.actionUrl.value"
    [attr.data-testid]="DATA_TEST_ID.kontent.page.imageLink + '-' + testKey"
  >
    <img [src]="content.elements.image.value[0].url" alt="" class="img-fluid"/>
  </a>
  <ng-template #imgNoLink>
    <img [src]="content.elements.image.value[0].url" alt="" class="img-fluid"/>
  </ng-template>
</div>

<!--Video-->
<div *ngIf="videoUrl" class="video" [attr.data-testid]="DATA_TEST_ID.kontent.page.iframe + '-' + testKey">
  <iframe [src]="videoUrl" allowfullscreen></iframe>
</div>

<div class="details" *ngIf="showDetails">
  <!--Title-->
  <div *ngIf="content.elements.title.value" class="title">
    {{ content.elements.title.value }}
  </div>

  <!--Subtitle  -->
  <div *ngIf="content.elements.subtitle?.value" class="sub-title">
    {{ content.elements.subtitle.value }}
  </div>

  <!--Short Description  -->
  <div
    class="description"
    *ngIf="content.elements.shortDescription.value"
    [innerHTML]="content.elements.shortDescription.value"
  ></div>

  <!--Long Description  -->
  <div
    class="l-description"
    *ngIf="content.elements.longDescription.value"
    [innerHTML]="content.elements.longDescription.value | htmlEntityDecode"
  ></div>

  <!--Button  -->
  <div *ngIf="content.elements.buttonLabel?.value" class="link">
    <a [href]="content.elements.actionUrl.value" [attr.data-testid]="DATA_TEST_ID.kontent.page.button + '-' + testKey">{{
      content.elements.buttonLabel.value
      }}</a>
  </div>
</div>
<!--      assetType-->
<!--      target-->
<!--      isImageClickable-->
