import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../user/user.model';
import { Cart } from '../../../types/cart.model';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { logout } from '../../store/authentication/auth.actions';
import { AppStateInterface } from '../../../types/app-state.interface';
import { PunchOut } from '../../../types/punchout.model';
import { UserShoppingContext } from '../customer-company/user-shopping-context.model';
import { API_URL } from '../../../shared/consts/api-urls';
import {
  AuthPunchoutResponseInterface,
  AuthResponseInterface,
} from '../../../modules/account/login/login-form/auth-response.model';
import { parseJwt } from '../../../shared/consts/parse-jwt';
import { setUser } from '../../store/user/user.actions';
import { setPunchout } from '../../store/punchout/punchout.actions';
import jwtDecode from 'jwt-decode';
import { PunchoutService } from '../punchout/punchout.service';
import { SiteCartService } from '../cart/site-cart.service';
import { LoginFormComponent } from 'src/app/modules/account/login/login-form/login-form.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { WINDOW } from 'src/app/app.module';
import {UserTypeEnum} from "../user/user-type.enum";
import {CustomerCompanyService} from "../customer-company/customer-company.service";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  loggedIn = false;
  isLoginModalOpen = false;
  private tokenRefreshTimeout?: number | NodeJS.Timeout;
  private cookieDefaults: CookieOptions = {
    path: '/',
    secure: true,
    sameSite: 'None',
  };

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private store: Store<AppStateInterface>,
    private punchout: PunchoutService,
    private siteCart: SiteCartService,
    private modalService: NgbModal,
    public cookieService: CookieService,
    private customerCompanyService: CustomerCompanyService,
    @Inject(WINDOW) private window: Window
  ) {}

  login(value: unknown) {
    const jwt = (value as AuthResponseInterface).token;
    const refresh_token = (value as AuthResponseInterface).refresh_token;
    const refresh_token_expiration = (value as AuthResponseInterface).refresh_token_expiration;
    const rememberMe = this.cookieService.get('rememberMe');
    parseJwt(jwt);
    const decodedToken: any = jwtDecode(jwt);

    let refreshOpts: CookieOptions, jwtOpts: CookieOptions;
    refreshOpts = jwtOpts = { ...this.cookieDefaults };

    if (rememberMe) {
      // If user selects 'remember me', set expiration date to continue to refresh cookies
      // If user does not select 'remember me', leave as session cookies
      refreshOpts.expires = new Date(refresh_token_expiration * 1000);
      jwtOpts.expires = new Date(decodedToken.exp * 1000);
    }

    this.cookieService.set('refresh_token', refresh_token, refreshOpts);
    this.cookieService.set('jwt', jwt, jwtOpts);
    const user = (value as AuthResponseInterface).user;
    this.store.dispatch(setUser({ user: user }));
    this.loggedIn = !!user; // should always be true
    if (rememberMe) {
      this.scheduleRefreshToken((value as AuthResponseInterface).token);
    }
  }

  setRefreshToken(value: unknown) {
    const jwt = (value as AuthResponseInterface).token;
    const refresh_token = (value as AuthResponseInterface).refresh_token;
    const refresh_token_expiration = (value as AuthResponseInterface).refresh_token_expiration;
    const decodedToken: any = jwtDecode(jwt);
    this.cookieService.set('jwt', jwt, {
      ...this.cookieDefaults,
      expires: new Date(decodedToken.exp * 1000),
    });
    this.cookieService.set('refresh_token', refresh_token, {
      ...this.cookieDefaults,
      expires: new Date(refresh_token_expiration * 1000),
    });
    this.scheduleRefreshToken(jwt); // schedule the next refresh
  }

  loginPunchout(value: any) {
    const jwt = (value as AuthResponseInterface).token;
    const refresh_token = (value as AuthResponseInterface).token;
    const refresh_token_expiration = (value as AuthResponseInterface).refresh_token_expiration;
    parseJwt(jwt);
    const decodedToken: any = jwtDecode(jwt);
    const shoppingContext = (value as AuthPunchoutResponseInterface).shoppingContextPunchoutUser;
    const user = {
      ...(value.shoppingContextPunchoutUser as AuthPunchoutResponseInterface).defaultUser,
      firstName: value.shoppingContextPunchoutUser.externalUserFirstName,
      lastName: value.shoppingContextPunchoutUser.externalUserLastName,
      email: value.shoppingContextPunchoutUser.externalUserEmail,
      userTypeId: 'POU',
      username: value.shoppingContextPunchoutUser.externalUserIdentifier,
    };
    const punchout: PunchOut = {
      id: value.shoppingContextPunchoutUser.id,
      sessionId: value.SessionID,
      returnUrl: value.ReturnURL,
    };
    this.cookieService.set('refresh_token', refresh_token, {
      ...this.cookieDefaults,
      expires: new Date(refresh_token_expiration * 1000),
    });
    this.cookieService.set('jwt', jwt, {
      ...this.cookieDefaults,
      expires: new Date(decodedToken.exp * 1000),
    });
    this.store.dispatch(setUser({ user, shoppingContext }));
    this.store.dispatch(setPunchout({ punchout }));
    this.customerCompanyService.getAndSaveCustomer(value.shoppingContextPunchoutUser.customerNumber, user).then(data => {console.log({data})});

    this.loggedIn = !!user;
    this.siteCart.initSiteCart();
    this.scheduleRefreshToken((value as AuthResponseInterface).token);
  }

  logout() {
    let returnUrl = this.punchout.returnUrl;
    this.invalidate();
    this.httpClient.post(API_URL.AuthLogOut, {});
    if (returnUrl) {
      this.window?.open(returnUrl, '_self');
    } else {
      this.siteCart.initSiteCart();
      void this.router.navigate(['/account/login']);
    }
  }

  invalidate() {
    this.loggedIn = false;
    const user: UserModel | null | undefined = null;
    const customerCompany: UserShoppingContext | null | undefined = null;
    const cart: Cart | null | undefined = null;
    const warehouse = null;
    const userListIds = null;
    this.store.dispatch(logout({ user, customerCompany, cart, warehouse, userListIds }));
    this.window?.localStorage.clear();
    // Clear token refresh
    this.clearRefreshTokenTimeout();
    this.cookieService.delete('rememberMe', '/');
    this.cookieService.delete('refresh_token', '/');
    this.cookieService.delete('jwt', '/');
  }

  isLoggedIn() {
    this.loggedIn = !!this.getStoredUser();
    return this.loggedIn;
  }

  getRole() {
    return this.getStoredUser() ? this.getStoredUser().roles : null;
  }

  getUserType() {
    return this.getStoredUser() ? this.getStoredUser().userTypeId : null;
  }

  getPermissions() {
    return this.getStoredUser() ? this.getStoredUser().currentShoppingAccount?.permissions : [];
  }

  getStoredUser() {
    const storedUser = this.window?.localStorage.getItem('user')
      ? JSON.parse(<string>this.window?.localStorage.getItem('user'))
      : null;

    // Need to set user.currentShoppingAccount for Punchout Users
    if (storedUser && storedUser.user.userTypeId === UserTypeEnum.POU && storedUser.shoppingContext) {
      storedUser.user.currentShoppingAccount = storedUser.shoppingContext;
    }
    return storedUser ? storedUser.user : null;
  }

  getToken() {
    return this.cookieService.get('jwt') || null;
  }

  checkRefreshToken(): boolean {
    const refresh_token = this.cookieService.get('refresh_token');
    const rememberMe = this.cookieService.get('rememberMe');
    return !!(refresh_token && rememberMe);
  }

  isTokenExpired(): boolean {
    const token = this.getToken();
    if (!token) {
      return true;
    }
    try {
      const decodedToken: any = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (!decodedToken.exp || currentTime > decodedToken.exp) {
        return true;
      }
    } catch (error) {
      return true;
    }
    return false;
  }

  openLoginModal() {
    if (!this.isLoginModalOpen) {
      this.isLoginModalOpen = true;
      const modalRef = this.modalService.open(LoginFormComponent);
      (modalRef.componentInstance as LoginFormComponent).isModal = true;
      (modalRef.componentInstance as LoginFormComponent).loginSuccess.subscribe(() => {
        modalRef.close();
      });

      modalRef.result.then(
        () => {
          this.isLoginModalOpen = false;
        },
        () => {
          // dismissed login modal
          this.invalidate();
          this.isLoginModalOpen = false;
          this.router.navigate(['/']);
        }
      );
    }
  }

  scheduleRefreshToken(token: string): void {
    const decodedToken: any = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    const expiresAt = decodedToken.exp || currentTime;
    const timeToRefresh = (expiresAt - currentTime - 10 * 60) * 1000; // refresh 10 minutes before expiration
    if (this.tokenRefreshTimeout) {
      // clear any existing timeout
      clearTimeout(this.tokenRefreshTimeout);
    }

    this.tokenRefreshTimeout = setTimeout(() => {
      const refresh_token = this.cookieService.get('refresh_token');
      this.refreshToken(refresh_token).subscribe((value: unknown) => {
        if (this.loggedIn) {
          this.setRefreshToken(value);
        }
      });
    }, timeToRefresh);
  }

  clearRefreshTokenTimeout(): void {
    if (this.tokenRefreshTimeout) {
      clearTimeout(this.tokenRefreshTimeout);
    }
  }

  public refreshToken(refresh_token?: string) {
    refresh_token = refresh_token || this.cookieService.get('refresh_token');
    const body = { refresh_token: refresh_token };
    return this.httpClient.post(`${API_URL.RefreshToken}`, body);
  }

  public checkToken() {
    // verify that authenticated user is still valid, invalidate as needed
    if (this.isLoggedIn() && this.isTokenExpired() && !this.checkRefreshToken()) {
      // If punchout and cookies are allowed logout
      if (this.punchout?.returnUrl && this.checkCookiesAllowed('checkTokenTest')) {
        this.logout();
      }
      // invalidate non-punchout logged in user
      else if (!this.punchout?.returnUrl) {
        this.invalidate();
      }
    }
  }

  // Checks if cookies are allowed for the site
  checkCookiesAllowed(testCookieName: string = 'testCookie'): boolean {
    // set test cookie
    this.cookieService.set(testCookieName, 'test', {
      ...this.cookieDefaults,
      expires: 1,
    });

    // Immediately try reading it and check browser
    const cookiesAllowed = this.cookieService.check(testCookieName) && navigator.cookieEnabled;

    // remove test cookie
    this.cookieService.delete(testCookieName);
    return cookiesAllowed;
  }
}
