import { createAction, props } from '@ngrx/store';
import { UserModel } from '../../services/user/user.model';
import { UserShoppingContext } from '../../services/customer-company/user-shopping-context.model';
import { ShoppingContextPunchout } from '../../../types/punchout.model';

//General action structure use for ngrx
export const setUser = createAction(
  '[Authenticated] SetUser',
  props<{
    user?: UserModel | null | undefined;
    shoppingContext?: UserShoppingContext | ShoppingContextPunchout | null;
    defaultShoppingContext?: UserShoppingContext | null;
  }>()
);
