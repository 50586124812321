import { Component, Input, OnInit } from '@angular/core';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';

@Component({
  selector: 'val-kontent-fancy-image-banner',
  templateUrl: './kontent-fancy-image-banner.component.html',
  styleUrls: ['./kontent-fancy-image-banner.component.scss'],
})
export class KontentFancyImageBannerComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() content: any;

  constructor() {
    super()
  }

  ngOnInit(): void {}
}
