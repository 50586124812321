import { Component, Input } from '@angular/core';

@Component({
  selector: 'val-kontent-banner',
  templateUrl: './kontent-banner.component.html',
})
export class KontentBannerComponent {
  @Input() content: any;

  constructor() {}
}
