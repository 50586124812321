<div *ngIf="success" class="validation-summary-success mx-auto">
  <ul>
    <li>{{ success.message }}</li>
  </ul>
</div>

<div *ngIf="error" class="validation-summary-error validation-summary-errors">
  <ul>
    <li>{{ error.message }}</li>
  </ul>
</div>

<div *ngIf="errorMessage" class="text-danger small">
  {{ errorMessage }}
</div>
