export enum UserRoleEnum {
  ROLE_USER = 'ROLE_USER', // Default user
  ROLE_REP = 'ROLE_REP', // Sales rep user
  ROLE_PUNCHOUT = 'ROLE_PUNCHOUT', // Punchout user
  ROLE_GUEST = 'ROLE_GUEST', // Guest
  ROLE_EDI_ADMIN = 'ROLE_EDI_ADMIN', // EDI Admin
  ROLE_EDI = 'ROLE_EDI', // EDI User
  ROLE_CUSTOMER_ADMIN = 'ROLE_CUSTOMER_ADMIN', // Customer Admin
  ROLE_ADMIN = 'ROLE_ADMIN', // Vallen Admin
}
