import { Component, Input, OnInit } from '@angular/core';
import { ProductResult } from '../../../../core/services/search/search.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DownloadDocumentsService } from 'src/app/core/services/download-documents/download-documents.service';

@Component({
  selector: 'val-product-attachment-modal',
  templateUrl: './product-attachment-modal.component.html',
  styleUrls: ['./product-attachment-modal.component.scss'],
})
export class ProductAttachmentModalComponent implements OnInit {
  @Input() product: ProductResult;

  constructor(public activeModal: NgbActiveModal, private downloadService: DownloadDocumentsService) {}

  ngOnInit(): void {}

  fileName(file: string) {
    const paths = file.split('/');
    return paths.pop();
  }

  async download(): Promise<void> {
    if (!this.product.attachments) return;  
    await this.downloadService.downloadAllFiles(this.product.attachments);
  }
}
