import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ProductResult } from '../../../../core/services/search/search.model';
import { LineItem } from '../../../../modules/order/order-detail.model';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { ToastService } from '../../toast/toast.service';

@Component({
  selector: 'val-product-quantity',
  templateUrl: './product-quantity.component.html',
  styleUrls: ['./product-quantity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, // Optimize performance
})
export class ProductQuantityComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() product: ProductResult;
  @Input() lineItem: LineItem;
  @Input() disabled: boolean;
  @Output() updateCartQty = new EventEmitter<ProductResult>();
  @Input() index?: number = 0;
  @Input() isLoading?: boolean;
  @Input() isQuoteDetails: boolean = false;

  private qtyChangeSubject = new Subject<ProductResult>();
  initialQuantity: number;

  constructor(private toast: ToastService) {
    super();
  }

  qtyChange() {
    if (this.isQuoteDetails && this.initialQuantity < this.product.qty) {
      this.toast.showError(`Quantity must not exceed ${this.initialQuantity}`);
      this.product.qty = this.initialQuantity;
    }
    this.qtyChangeSubject.next(this.product);
  }

  ngOnInit(): void {
    this.initialQuantity = this.product.qty;
    this.qtyChangeSubject.pipe(debounceTime(300), takeUntil(this.destroyed)).subscribe((product) => {
      this.updateCartQty.emit(product);
    });
  }

  get isProductContext(): boolean {
    return !!this.product;
  }

  get activeModel(): any {
    return this.product || this.lineItem;
  }

  get minAndStepQuantity(): number {
    return this.isProductContext ? this.product.sellMult : 1;
  }

  get uomLabel(): string {
    return this.isProductContext ? this.product.uom : this.lineItem.uom;
  }
}
