import { APP_INITIALIZER, ErrorHandler, NgModule, InjectionToken, PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './core/components/header/header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { HeaderMainComponent } from './core/components/header/header-main/header-main.component';
import { SharedModule } from './shared/shared.module';
import { BreadcrumbsComponent } from './core/components/breadcrumbs/breadcrumbs.component';
import { NavbarComponent } from './core/components/navbar/navbar.component';
import { QuickLinksComponent } from './core/components/header/quick-links/quick-links.component';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { ImportOrderComponent } from './modules/order/import-order/import-order.component';
import { AlertBannerComponent } from './core/components/header/alert-banner/alert-banner.component';
import { FooterLinksComponent } from './core/components/footer/footer-links/footer-links.component';
import { CookieConsentComponent } from './core/components/footer/cookie-consent/cookie-consent.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ProductMenuComponent } from './core/components/navbar/product-menu/product-menu.component';
import { CookieService } from 'ngx-cookie-service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DecimalPipe, TitleCasePipe, isPlatformBrowser } from '@angular/common';
import { WidgetComponent } from './modules/dashboard/widget/widget.component';
import { ProductListingPageComponent } from './modules/product-listing-page/product-listing-page.component';
import { ProductDetailPageComponent } from './modules/product-detail-page/product-detail-page.component';
import { CartComponent } from './modules/cart/cart.component';
import { SearchService } from './core/services/search/search.service';
import { CoreModule } from './core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { userReducer } from './core/store/user/user.reducer';
import { cartReducer } from './core/store/cart/cart.reducer';
import { warehouseReducer } from './core/store/warehouse/warehouse.reducer';
import { punchoutReducer } from './core/store/punchout/punchout.reducer';
import { customerReducer } from './core/store/customer/customer.reducer';
import { productListsReducer } from './core/store/product-lists/product-lists.reducer';
import { ToastComponent } from './shared/components/toast/toast.component';
import { ErrorInterceptor } from './core/interceptors/error/error.interceptor';
import { CacheInterceptor } from './core/interceptors/cache/cache.interceptor';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NoHeaderComponent } from './shared/components/no-header/no-header.component';
import { NoFooterComponent } from './shared/components/no-footer/no-footer.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgChartsModule } from 'ng2-charts';
import { UnauthorizedComponent } from './modules/unauthorized/unauthorized.component';
import { ProductCategoriesComponent } from './modules/product-categories/product-categories.component';
import { RedirectGuard } from './core/guards/redirect.guard';
import { RedirectComponent } from './modules/redirect/redirect.component';
import { DashboardModalComponent } from './modules/dashboard/dashboard-modal/dashboard-modal.component';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { UnderMaintenanceComponent } from './modules/under-maintenance/under-maintenance.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ScrollbarHelper } from '@swimlane/ngx-datatable';
import { DimensionsHelper } from '@swimlane/ngx-datatable';
import { ServerScrollBarHelper } from './shared/ssr-helpers/server-scroll-bar-helper';
import { ServerDimensionsHelper } from './shared/ssr-helpers/server-dimensions-helper';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import * as Sentry from '@sentry/angular-ivy';
export const WINDOW = new InjectionToken<Window>('Window');

export function windowFactory(platformId: any, appId: string): Window | null {
  if (isPlatformBrowser(platformId)) {
    // We are on the browser
    return window;
  } else {
    // We are on the server
    return null;
  }
}

import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NoHeaderComponent,
    NoFooterComponent,
    HeaderMainComponent,
    QuickLinksComponent,
    FooterComponent,
    BreadcrumbsComponent,
    NavbarComponent,
    PageNotFoundComponent,
    UnauthorizedComponent,
    DashboardComponent,
    DashboardModalComponent,
    ImportOrderComponent,
    AlertBannerComponent,
    FooterLinksComponent,
    CookieConsentComponent,
    ProductListingPageComponent,
    ProductDetailPageComponent,
    CartComponent,
    ProductMenuComponent,
    WidgetComponent,
    ToastComponent,
    ProductCategoriesComponent,
    RedirectComponent,
    UnderMaintenanceComponent,
  ],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule,
    CommonModule,
    CarouselModule,
    NgbTypeaheadModule,
    BrowserModule.withServerTransition({ appId: 'ng-universal-demystified' }),
    StoreModule.forRoot(
      {
        user: userReducer,
        cart: cartReducer,
        warehouse: warehouseReducer,
        punchout: punchoutReducer,
        customer: customerReducer,
        productLists: productListsReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    NgChartsModule,
    NgxCsvParserModule,
    NgxDatatableModule,
    GoogleTagManagerModule.forRoot({
      id: environment.gtmId,
    }),
  ],
  providers: [
    CookieService,
    DecimalPipe,
    SearchService,
    TitleCasePipe,
    RedirectGuard,
    { provide: WINDOW, useFactory: windowFactory, deps: [PLATFORM_ID, APP_ID] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: ScrollbarHelper,
      useClass: ServerScrollBarHelper
    },
    // {
    //   provide: DimensionsHelper,
    //   useClass: ServerDimensionsHelper
    // },
    {
      provide: REQUEST,
      useFactory: () => () => {},
      deps: [],
    },
    [ provideClientHydration() ]
  ],
  exports: [BreadcrumbsComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
