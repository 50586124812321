import { Component, Input, OnInit } from '@angular/core';
import { ErrorsModel } from '../../../core/services/form/errors.model';
import { SuccessfulResModel } from '../../../core/services/form/success.model';

@Component({
  selector: 'val-form-alerts',
  templateUrl: './form-alerts.component.html',
  styleUrls: ['./form-alerts.component.scss'],
})
export class FormAlertsComponent implements OnInit {
  @Input() errors: ErrorsModel[] | null;
  @Input() error: ErrorsModel | null;
  @Input() success: SuccessfulResModel | null;
  @Input() control: string;
  @Input() errorMsg: string;

  constructor() {}

  ngOnInit(): void {}

  get errorMessage(): String | void {
    if (this.errorMsg) {
      return this.errorMsg;
    }
    if (!this.errors) {
      return '';
    }

    if (this.errors) {
      const errors = this.errors.filter((e: ErrorsModel) => {
        return e.control === this.control;
      });
      if (errors[0]) {
        if (errors[0].message?.length && Array.isArray(errors[0].message)) {
          return errors[0].message.join(', ');
        } else {
          return <String>errors[0].message;
        }
      }
    }
    return '';
  }
}
