import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { LoadingStatus } from '../../../types/loading-status.model';

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  private loadingPriceSubject = new BehaviorSubject<LoadingStatus>(this.loadingParams([]));
  isLoading$ = new BehaviorSubject<boolean>(false);
  progressBar$ = new BehaviorSubject<boolean>(false);
  progressBarValue$ = new BehaviorSubject<number>(0);

  start() {
    this.isLoading$.next(true);
  }

  stop() {
    this.isLoading$.next(false);
    this.stopProgressBar();
  }

  getLoadingPrices(): Observable<object> {
    return this.loadingPriceSubject.asObservable();
  }

  setLoadingPrices(params?: string[]) {
    this.loadingPriceSubject.next(this.loadingParams(params));
  }

  isLoading(): boolean {
    return this.isLoading$.value;
  }

  startProgressBar(): void {
    this.progressBar$.next(true);
    this.progressBarValue$.next(1);
  }

  stopProgressBar(): void {
    this.progressBar$.next(false);
    this.progressBarValue$.next(0);
  }

  setProgressValue(pct: number): void {
    this.progressBarValue$.next(pct);
  }

  private loadingParams(params?: string[]): LoadingStatus {
    if (!params) {
      params = [];
    }
    return {
      summary: params.includes('summary'),
      price: params.includes('price'),
      total: params.includes('total'),
    };
  }
}
