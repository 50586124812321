import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'val-widget-table',
  templateUrl: './widget-table.component.html',
})
export class WidgetTableComponent implements OnInit {
  @Input() data: any;
  @Input() widgetId: string;
  @Input() itemsPerPage = 5;
  public noData: string = 'No data available';

  constructor() {}

  ngOnInit(): void {}
}
