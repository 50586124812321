import { ProductResult } from 'src/app/core/services/search/search.model';
import { PageType } from '../../consts/page-type';

export interface ImportExportAction {
  type: ActionType;
  format: ActionFormat;
  pageType?: PageType;
  listId?: number;
}

export enum ActionType {
  IMPORT = 'Import',
  EXPORT = 'Export',
}

export enum ActionFormat {
  IMPORT_QUICKLIST = 'importQuicklist',
  IMPORT_PRODUCT_LIST = 'importProductList',
  IMPORT_ORDER = 'importOrder',
  IMPORT_ORDER_PAGE = 'importOrderPage',
  EXPORT_QUICKLIST_PDF = 'exportQuicklistPDF',
  EXPORT_PRODUCT_LIST_PDF = 'exportProductListPDF',
  EXPORT_PUBLIC_LIST_PDF = 'exportPublicListPDF',
  EXPORT_SHARED_LIST_PDF = 'exportSharedListPDF',
  EXPORT_CART_PDF = 'exportCartPDF',
  EXPORT_ORDER_DETAILS_PDF = 'exportOrderDetailsPDF',
  EXPORT_ORDER_DETAILS_EXCEL = 'exportOrderDetailsExcel',
  EXPORT_QUICKLIST_EXCEL = 'exportQuicklistExcel',
  EXPORT_EXCEL = 'exportExcel',
}

export interface CardSection {
  title: string;
  subTitle1?: string;
  subTitle2?: string;
  content?: string[];
  image?: string;
  checkboxes?: Checkbox[];
  radioboxes?: Radio[];
  checked?: boolean;
  selectedValue?: string;
}

export interface Checkbox {
  label: string;
  value: string;
  checked: boolean;
}

export interface Radio {
  name: string;
  value: string;
  checked: boolean;
}

export interface SelectedCheckboxAndRadio {
  radioboxes?: Radio[];
  checkboxes?: Checkbox[];
};

export interface ImportedProduct {
  type: string;
  value: string;
  personalCode?: string;
  quantity?: number;
}

/**
 * TODO: This is purely to give an idea of the data that we will get back from the api
 *
 * We can remove or update this based on the other metadata that the api returns
 */
export interface ImportResponse {
  // The products we find matches for in Algolia
  products?: ProductResult[];
  // The products that didn't find matches. Just return the failures as they were sent.
  invalidProducts?: ImportedProduct[];
}

export type ExportMappingType = {
  [key in ActionFormat]?: () => void;
};

export type UrlKey = 'cart' | 'order' | 'quicklist' | 'product-lists';

export const productImportTypeMapping = {
  productCode: 'itemId',
  upc: 'upcCodes',
  manufacturer: 'manufacturerPartNo',
  personalCode: 'personalCode',
};
