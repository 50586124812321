import { createReHydrationReducer } from '../hydration.reducer';
import { on } from '@ngrx/store';
import * as ProductListsActions from './product-lists.actions';
import { logout } from '../authentication/auth.actions';
import { ProductList } from 'src/app/types/product-list.model';

// Define the scoped state of this reducer
export interface ProductListsState {
  activeProductList?: ProductList | null;
  sharedProductList?: ProductList | null;
  publicProductList?: ProductList | null;
}

// Set initial state values
const initialState: ProductListsState = {
  activeProductList: null,
  sharedProductList: null,
  publicProductList: null,
};

export const productListsReducer = createReHydrationReducer(
  'productLists',
  initialState,
  on(ProductListsActions.setProductLists, (state, action) => ({
    ...state,
    ...action,
  })),
  on(logout, (state) => ({
    ...state,
    productListsPunchout: null,
    productListsRepDefault: null,
  }))
);
