import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanText',
})
export class BooleanTextPipe implements PipeTransform {
  // Display boolean value as 'Yes' or 'No'
  transform(value: boolean | number | null): string {
    return value ? 'Yes' : 'No';
  }
}
