<nav class="border-bottom site-navbar">
  <div class="container p-0">
    <div class="row m-0">
      <div class="col-lg-2 p-0 navbar products-menu">
        <val-product-menu class="w-100"></val-product-menu>
      </div>
      <div class="col-lg-10 p-0 main-menu navbar navbar-expand-lg">
        <button
          class="navbar-toggler"
          type="button"
          [class.active]="isMenuOpen"
          (click)="toggleMenu()"
          aria-label="Toggle navigation"
          [attr.data-testid]="DATA_TEST_ID.navbar.toggleNavigation"
        >
          <i class="material-icons-outlined">menu</i>
        </button>
        <div class="collapse navbar-collapse w-100" [class.show]="isMenuOpen">
          <div class="phone text-center my-2 _mobile">
            <val-support-phone></val-support-phone>
          </div>
          <ul class="navbar-nav _main justify-content-lg-around w-100 pt-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-expanded="false"
                [attr.data-testid]="DATA_TEST_ID.navbar.solutions.dropdown"
                >Solutions</a
              >
              <div class="dropdown-menu">
                <ul class="navbar-nav menu-title">
                  <li *ngFor="let link of menuService.solutionLinks">
                    <label>{{ link.title }}</label>
                    <ul class="navbar-nav _sub">
                      <li *ngFor="let subLink of link.links">
                        <a
                          class="dropdown-item"
                          [routerLink]="subLink.route"
                          target="_self"
                          [attr.data-testid]="DATA_TEST_ID.navbar.solutions.link + '-' + subLink.title | idFormatter"
                          >{{ subLink.title }}</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <val-navbar-link *ngFor="let link of menuService.navbarLinks" [link]="link"></val-navbar-link>
            <li *ngFor="let link of menuService.quickLinks" class="nav-item _mobile">
              <val-nav-link [link]="link" location="nav-bar"></val-nav-link>
            </li>
            <li class="nav-item _mobile">
              <a class="nav-link" routerLink="/locations" [attr.data-testid]="DATA_TEST_ID.navbar.locations">
                <i class="material-icons-outlined md-18">place</i> Locations
              </a>
            </li>
            <li class="nav-item _mobile">
              <a class="nav-link" routerLink="/promotions">
                <i class="material-icons-outlined md-18">star_outline</i> Specials
              </a>
            </li>
            <li *ngIf="user" class="nav-item _mobile">
              <a class="nav-link" routerLink="/dashboard" [attr.data-testid]="DATA_TEST_ID.navbar.dashboard">
                <i class="material-icons-outlined md-18">monitor</i> Dashboard
              </a>
            </li>
            <val-navbar-link
              class="_mobile"
              *ngIf="menuService.accountLinks.links?.length"
              [link]="menuService.accountLinks"
            >
            </val-navbar-link>
            <div *ngIf="user" class="pt-2 _mobile d-grid">
              <a class="btn btn-primary btn-lg" (click)="logout()" [attr.data-testid]="DATA_TEST_ID.navbar.logout"
                >Logout</a
              >
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
