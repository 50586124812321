<div class="card-header">
  <p class="card-title">{{ widget.title }}</p>
  <div class="card-tools">
    <button
      class="btn p-0"
      (click)="onDelete()"
      [attr.data-testid]="DATA_TEST_ID.valWidget.close"
      *ngIf="widget.id !== WidgetId.OVERVIEW"
    >
      <span class="material-icons-outlined">close</span>
    </button>
  </div>
</div>

<div class="card-body">
  <div class="content" [ngClass]="'content-' + widget.type">
    <div *ngIf="widget.id === WidgetId.OVERVIEW" class="p-3 pt-0">
      <div class="title">
        Welcome, <span>{{ user?.firstName }} {{ user?.lastName }}</span
        >!
      </div>

      <div class="other-details">
        <p *ngFor="let data of overviewDetails">
          <span>{{ data.label }}:</span>
          {{ data.value }}
        </p>
      </div>
    </div>

    <div *ngIf="widget.id === WidgetId.ORDERS_MONTHLY" class="p-3">
      <val-bar-chart [user]="user"></val-bar-chart>
    </div>

    <div *ngIf="widget.type === 'table'">
      <val-widget-table [data]="data" [widgetId]="widget.id"></val-widget-table>
    </div>
  </div>
</div>
<div class="card-footer">
  <a *ngIf="data?.data?.length || !data" [routerLink]="widget.route">{{ widget.linkText }}</a>
</div>
