import { EventEmitter, Injectable, Output } from '@angular/core';
import { UserShoppingContext } from './user-shopping-context.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { PagedData } from '../../../types/page-data.model';
import { Page } from '../../../types/page.model';
import { ApiService } from '../api/api.service';
import { API_URL } from '../../../shared/consts/api-urls';
import { ShoppingContextPunchout } from '../../../types/punchout.model';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { AppStateInterface } from '../../../types/app-state.interface';
import { select, Store } from '@ngrx/store';
import { currentShoppingContextSelector } from '../../store/user/user.selectors';
import { ApiOptions } from '../api/api.model';
import { UserModel } from '../user/user.model';
import { CustomerService } from 'src/app/core/services/customer/customer.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { setUser } from '../../store/user/user.actions';
import { CustomerMaster } from 'src/app/types/customer-option.model';
import { setCustomer } from 'src/app/core/store/customer/customer.actions';
import { SiteCartService } from '../cart/site-cart.service';
import { Router, NavigationExtras } from '@angular/router';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class CustomerCompanyService extends BaseSubscriptionComponent {
  private _customer: UserShoppingContext;
  private _customers: UserShoppingContext[];
  private _defaultCustomer: UserShoppingContext;
  private shoppingContext$ = this.store.pipe(select(currentShoppingContextSelector));
  private shoppingContext: UserShoppingContext | null;
  private customerError = new BehaviorSubject<boolean>(false);

  searchCriteriaSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _destroy$: Subject<void> = new Subject<void>();

  public selectedCustomerAccount: BehaviorSubject<string> = new BehaviorSubject<string>('');
  apiOptions: ApiOptions;
  public accountSelectRows: UserShoppingContext[] = [];

  // Outputs for components
  @Output() rows = new EventEmitter<UserShoppingContext[] | null>();
  @Output() setPage = new EventEmitter<any>();

  constructor(
    private api: ApiService,
    private httpClient: HttpClient,
    private store: Store<AppStateInterface>,
    private customerService: CustomerService,
    private userService: UserService,
    public siteCart: SiteCartService,
    private router: Router
  ) {
    super();
    this.initApiOptions();

    this.shoppingContext$.pipe(takeUntil(this.destroyed)).subscribe((shoppingContext) => {
      this.shoppingContext = shoppingContext as UserShoppingContext;
    });

    this.store
      .pipe(select(currentShoppingContextSelector))
      .pipe(takeUntil(this.destroyed))
      .subscribe((shoppingContext) => {
        const context = shoppingContext as UserShoppingContext;
        this.setSelectedCustomerAccount(context);
      });
  }

  public get customer() {
    return this._customer;
  }

  public set customer(value: UserShoppingContext) {
    this._customer = value;
  }

  public get customers() {
    return this._customers;
  }

  public set customers(value: UserShoppingContext[]) {
    this._customers = value;
  }

  public get defaultCustomer() {
    return this._defaultCustomer;
  }

  public set defaultCustomer(value: UserShoppingContext) {
    this._defaultCustomer = value;
    sessionStorage.setItem('defaultCustomer', JSON.stringify(value));
  }

  public subscribeToTableFilter(): void {
    this.searchCriteriaSubject
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this._destroy$),
        switchMap(async (criteria) => this.getCustomers(criteria.page, criteria.size, criteria.search))
      )
      .pipe(takeUntil(this.destroyed))
      .subscribe();
  }

  public getResults(page: Page): Observable<PagedData<UserShoppingContext>> {
    return of(this.customers)
      .pipe(map((d) => this.getPagedData(page)))
      .pipe(delay(1000 * Math.random()));
  }

  private getPagedData(page: Page): PagedData<UserShoppingContext> {
    const pagedData = new PagedData<UserShoppingContext>();
    page.totalElements = this.customers.length;
    page.totalPages = page.totalElements / page.size;
    const start = page.pageNumber * page.size;
    const end = Math.min(start + page.size, page.totalElements);
    for (let i = start; i < end; i++) {
      const customer = this.customers[i];
      pagedData.data.push(customer);
    }
    pagedData.page = page;
    return pagedData;
  }

  public getCustomers(page: number, size: number, search: any): Observable<UserShoppingContext[]> {
    let params = new HttpParams().set('page', page).set('size', size);
    search ? (params = params.set('search', search)) : '';
    return this.httpClient
      .get<UserShoppingContext[]>('assets/data/mock/customers.json', {
        params,
      })
      .pipe(
        map((data: UserShoppingContext[]) => (this.customers = data)),
        // @ts-ignore
        catchError((err) => {
          console.log('error: ', err);
        })
      );
  }

  public getCustomerAddressById(id: number): Observable<any> {
    return this.api.get(`${API_URL.CustomerAddress}/${id}`, {}, false);
  }

  public setSelectedCustomerAccount(shoppingContext: UserShoppingContext | ShoppingContextPunchout) {
    // TODO - Consider replacing this with more efficient use of customer store
    let shipToDisplay = this.getSelectedCustomerAccount(shoppingContext);
    this.selectedCustomerAccount.next(shipToDisplay);
  }

  public getSelectedCustomerAccount(shoppingContext: UserShoppingContext | ShoppingContextPunchout): string {
    // TODO - Consider replacing this with more efficient use of customer store
    let parts: any = [];
    if (shoppingContext) {
      parts = [shoppingContext.customerNumber, shoppingContext.shipTo];
      parts.push(
        'shipToName' in shoppingContext ? shoppingContext.shipToName : shoppingContext.customerCompanyNumber.name
      );
    }
    const item = parts.filter((part: string) => !!part).join('-');
    return item;
  }

  public async updateRecords(
    data: {
      currentShoppingAccountId?: number;
      defaultShoppingAccountId?: number;
      shoppingContext?: UserShoppingContext;
      user?: UserModel | null | undefined;
    },
    redirect?: boolean,
    resolve?: any,
    reject?: any
  ) {
    const shoppingContext = data.shoppingContext;
    if (data.user?.userId) {
      this.userService.updateDefaultShipTo(data.user.userId, data).subscribe({
        next: (data: any) => {
          const userClone = data.user;
          const shoppingContextClone = this.shoppingContext;
          let user = data;

          /**
           * Check for error, else update the state with fresh values
           */

          if (shoppingContext) {
            if (this.customerError.getValue()) {
              // Dispatch our cloned values when an error has been encountered
              this.store.dispatch(setUser({ user: userClone!, shoppingContext: shoppingContextClone! }));
            } else {
              this.store.dispatch(setUser({ user: user!, shoppingContext: shoppingContext! }));
              if (user?.currentShoppingAccount?.customerNumber) {
                this.getAndSaveCustomer(user.currentShoppingAccount.customerNumber, user);
              }
              this.customer = shoppingContext!;
            }
          }

          this.siteCart.initSiteCart(false);

          if (redirect) {
            let redirectDestination = this.userService.hasDashboard(user) ? '/dashboard' : '/';
            if (this.customerError.getValue()) {
              let params: NavigationExtras = {
                queryParams: {
                  customerError: this.customerError.getValue(),
                  customerNumber: user?.currentShoppingAccount?.customerNumber,
                },
              };
              this.router.navigate([redirectDestination], params);
            } else {
              this.router.navigate([redirectDestination]);
            }
            // If used from provider reject and redirect / else resolve
            if (reject) {
              reject();
            }
          } else if (resolve) {
            resolve();
          }
        },
        error: (error: string) => {
          this.api.toastError(error);
          if (reject) {
            reject();
          }
        },
      });
    }
  }

  public async getAndSaveCustomer(customerNumber: string, user?: UserModel | null | undefined) {
    if (user) {
      const companyNumber = user!.companyNumber as number || environment.companyNumber;
      const customerName = user!.currentShoppingAccount?.customerCompanyNumber.name;
      const shipTo = user!.currentShoppingAccount?.shipTo;

      this.customerService.getCustomerMaster(companyNumber, customerNumber).subscribe({
        next: (data: any) => {
          const customerMaster: CustomerMaster = this.customerService.structureCustomerMasterData(data.sxeCustomer[0]);

          this.store.dispatch(
            setCustomer({
              customer: {
                customerNumber: customerNumber,
                customerName: customerName || customerMaster.name,
                shipTo: shipTo || customerMaster.shipto,
                customerMaster: customerMaster,
              },
            })
          );
        },
        error: (error: string) => {
          this.customerError.next(true);
          this.api.toastError(error);
        },
      });
    }
  }

  private initApiOptions(): void {
    this.apiOptions = {
      count: 0,
      page: 1,
      itemsPerPage: 25,
    };
  }
}
