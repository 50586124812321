import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UserModel } from '../../../services/user/user.model';
import { MenuService } from '../../../services/menu/menu.service';
import { UserService } from '../../../services/user/user.service';
import { CustomerCompanyService } from '../../../services/customer-company/customer-company.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'val-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuickLinksComponent extends BaseSubscriptionComponent {
  @Input() user: UserModel | null;
  selectedCustomer: string;

  constructor(
    public menuService: MenuService,
    public userService: UserService,
    private customerCompanyService: CustomerCompanyService
  ) {
    super();
    this.customerCompanyService.selectedCustomerAccount.pipe(takeUntil(this.destroyed)).subscribe((data) => {
      this.selectedCustomer = data;
    });
  }

  isB2B() {
    return this.userService.isB2B(this.user);
  }

  isPunchOut() {
    return this.userService.isPunchOut(this.user);
  }

  isBuyer() {
    return this.userService.isBuyer(this.user);
  }
}
