import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SearchService } from '../../core/services/search/search.service';
import { UserService } from '../../core/services/user/user.service';
import { PermissionService } from '../../core/services/permission/permission.service';
import { UserModel } from '../../core/services/user/user.model';
import { LoadingSpinnerService } from '../../shared/components/loading-spinner/loading-spinner.service';
import { CategoryPath, ProductResult } from '../../core/services/search/search.model';
import { BreadcrumbsService } from '../../core/components/breadcrumbs/breadcrumbs.service';
import { Breadcrumb } from '../../core/components/breadcrumbs/breadcrumb.model';
import { Title } from '@angular/platform-browser';
import { ToastService } from '../../shared/components/toast/toast.service';
import { PricingService } from '../../core/services/pricing/pricing.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UtilityService } from '../../core/services/utility/utility.service';
import { ProductListService } from 'src/app/core/services/product-list/product-list.service';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../types/app-state.interface';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { LoadingStatus } from '../../types/loading-status.model';
import { SiteCartService } from '../../core/services/cart/site-cart.service';
import { Inventory } from '../../types/inventory.model';
import { WarehouseNameNumber } from '../../types/warehouse.model';
import { WINDOW } from 'src/app/app.module';

@Component({
  selector: 'val-product-page',
  templateUrl: './product-detail-page.component.html',
  styleUrls: ['../../../assets/styles/shared/products.scss', './product-detail-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductDetailPageComponent extends BaseSubscriptionComponent implements OnInit {
  constructor(
    public siteCart: SiteCartService,
    public listService: ProductListService,
    private route: ActivatedRoute,
    private crumbService: BreadcrumbsService,
    private loading: LoadingSpinnerService,
    private router: Router,
    private search: SearchService,
    private titleService: Title,
    private userService: UserService,
    private toast: ToastService,
    private util: UtilityService,
    private pricing: PricingService,
    private store: Store<AppStateInterface>,
    private permissionService: PermissionService,
    @Inject(WINDOW) private window: Window
  ) {
    super();
    this.store
      .select('warehouse')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.warehouses = data?.warehouses || [];
      });
  }

  product: ProductResult | null;
  isLoading: LoadingStatus;
  user: UserModel | null;
  quickListHeaderId: number | undefined;
  canUpdateQuickList: boolean = false;
  isBuyer: boolean;
  canViewPrices: boolean;
  productImg = '';
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    navSpeed: 700,
    nav: false,
    responsive: {
      0: {
        items: 1,
        dots: true,
      },
      380: {
        items: 4,
        dots: false,
      },
    },
  };
  selectedMedia = {
    type: '',
    src: '',
  };
  videos: string[] = [];
  hasImageCarousel = false;
  inventory: Inventory[] = [];
  warehouses: WarehouseNameNumber[] = [];
  hasProductLists: boolean = false;

  ngOnInit(): void {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.user = data?.user || null;
        this.isBuyer = this.userService.isBuyer(this.user);
        this.hasProductLists = this.userService.hasProductLists(this.user);
        this.canViewPrices = this.permissionService.canViewPrices();
        this.canUpdateQuickList = this.permissionService.canUpdateQuicklist();
        this.quickListHeaderId =
          this.user?.currentShoppingAccount?.quickListHeader?.id || data?.shoppingContext?.quickListHeader?.id;
      });

    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.get('urlId')) {
        this.getProduct(<string>params.get('urlId'));
      }
    });

    this.loading.getLoadingPrices().subscribe((loading) => {
      this.isLoading = loading || this.isLoading;
    });
  }

  toggleImageModal(type?: string, src?: string) {
    this.selectedMedia = {
      type: type || '',
      src: src || '',
    };
    const el = this.window?.document.getElementById('imageModal') as HTMLElement;
    if (el !== null) {
      el.classList.contains('d-block') ? el.classList.remove('d-block') : el.classList.add('d-block');
    }
  }

  getProduct(urlId: string) {
    this.loading.start();
    this.search
      .getProduct(urlId)
      .then((res) => {
        this.product = res.hits.length ? <ProductResult>res.hits[0] : null;
        this.productImg = this.product?.image ? this.util.clone(this.product.image) : '';

        if (this.product) {
          this.hasImageCarousel = <boolean>(this.product.imageGallery && this.product.imageGallery.length > 1);

          if (this.product.productVideo) {
            this.videos.push(this.product.productVideo);
          }
          if (this.product.installationVideo) {
            this.videos.push(this.product.installationVideo);
          }
          this.getInventory();
        } else {
          this.router.navigate(['/404']);
          return;
        }

        // Get pricing
        this.pricing.getPricing([this.product]).subscribe({
          next: (updatedProducts) => {
            this.product = updatedProducts[0];
          },
          error: (err) => {
            console.error('Error updating prices:', err);
            this.toast.showError('Error fetching pricing');
          },
        });

        // set breadcrumbs
        const crumbs: Breadcrumb[] = [];
        let crumbUrl = '/products';
        this.product!.categoryPaths![0].forEach((path: CategoryPath) => {
          crumbUrl += `/${path.slug}`;
          crumbs.push(new Breadcrumb(path.name, crumbUrl));
        });
        this.crumbService.setBreadcrumbs(crumbs);

        // set title
        this.titleService.setTitle(this.product.title);

        // TODO - Set canonical using seoUrl
      })
      .catch(() => {
        if (!this.product) {
          this.toast.show(`Error retrieving product`, {
            classname: 'alert-danger',
          });
        }
      })
      .finally(() => {
        this.loading.stop();
      });
  }

  addToCart() {
    if (this.product) {
      this.siteCart.addItem(this.product);
      this.product.qty = this.product.sellMult;
    }
  }

  private getInventory(): void {
    if (!this.userService.isBuyer(this.user)) {
      return;
    }
    // get unique warehouses
    const merged = [].concat(
      this.user?.currentShoppingAccount?.deliveryWarehouses
        ? (this.user.currentShoppingAccount.deliveryWarehouses as never[])
        : [],
      this.user?.currentShoppingAccount?.warehouses ? (this.user.currentShoppingAccount.warehouses as never[]) : []
    );
    const allWarehouses = merged.filter((item, index) => merged.indexOf(item) === index);

    // Get warehouse inventory
    if (allWarehouses.length) {
      this.inventory = []; // without this, the inventory section 'stacks up' if viewing item after item
      this.pricing.getWarehouseAvailability(this.product!, allWarehouses, this.inventory);
    }
  }

  qtyError(product: ProductResult) {
    const err = product.qty % product.sellMult !== 0 && !this.isLoading.price && product.price;
    if (err) {
      product.isSelected = false;
    }
    return err;
  }

  public hasPersonalCode(product: ProductResult): boolean {
    return this.quickListHeaderId && product.personalCodes && product.personalCodes[this.quickListHeaderId]
  }
}
