import { Component, Input, OnInit, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CounterService } from 'src/app/core/services/counter/counter.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { WINDOW } from 'src/app/app.module';

@Component({
  selector: 'val-kontent-generic-page-base-content',
  templateUrl: './kontent-generic-page-base-content.component.html',
  styleUrls: ['./kontent-generic-page-base-content.component.scss'],
})
export class KontentGenericPageBaseContentComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() content: any;
  @Input() testKey: number;
  videoUrl: SafeResourceUrl;
  showDetails = false;
  currentComponentCount: number;

  constructor(
    private sanitizer: DomSanitizer,
    private counterService: CounterService,
    @Inject(WINDOW) private window: Window
  ) {
    super();
    this.currentComponentCount = this.testKey;
  }

  ngOnInit(): void {
    if (this.content.elements.youtubeVideoId.value) {
      const unsafeUrl =
        `https://www.youtube.com/embed/${this.content.elements.youtubeVideoId.value}?rel=0&showinfo=0&enablejsapi=1&origin=` +
        this.window?.location.host;
      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
    }

    this.formatDetails();
  }

  private formatDetails(): void {
    const detailFields = ['subtitle', 'shortDescription', 'longDescription', 'buttonLabel'];
    let detailValues = 0;
    detailFields.forEach((field) => {
      let val = this.content.elements[field]?.value || '';
      if (val) {
        this.content.elements[field].value = this.clearEmpty(val);
        if (this.content.elements[field].value) {
          detailValues++;
        }
      }
    });
    this.showDetails = detailValues > 0;
  }

  private clearEmpty(content: string): string {
    const emptyPatterns = ['<p><br></p>', '<p></p>'];
    return emptyPatterns.includes(content.trim()) ? '' : content;
  }
}
