<div class="branded-footer">
  <div class="container">
    <hr />
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="logo text-center text-lg-start mb-2 mb-lg-0">
          <a routerLink="/" [attr.data-testid]="DATA_TEST_ID.noFooter.logoHomeRedirect">
            <img src="/assets/img/vallen-en-55.png" style="max-width: 100px" />
          </a>
        </div>
      </div>
      <div class="col-12 col-lg-6 text-center">
        <ul class="list-inline float-lg-end small font-secondary">
          <li class="list-inline-item">
            <a routerLink="/terms" [attr.data-testid]="DATA_TEST_ID.noFooter.termsAndConditions"
              >Terms and Conditions</a
            >
          </li>
          <li class="list-inline-item px-3">Vallen Canada © {{ currentYear }}</li>
          <li class="list-inline-item">All Rights Reserved.</li>
        </ul>
      </div>
    </div>
  </div>
</div>
