<div class="modal-header">
  <h4 class="modal-title" [ngClass]="titleClass">{{ title }}</h4>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss('Cancelled')"
    data-bs-dismiss="modal"
    aria-label="Close"
  ></button>
</div>
<div class="modal-body" [ngClass]="bodyClass">
  <div [innerHTML]="body"></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" [ngClass]="confirmClass" (click)="activeModal.close(true)">
    {{ confirmText }}
  </button>

  <button
    type="button"
    class="btn btn-secondary"
    [ngClass]="rejectClass"
    (click)="activeModal.close(false)"
    data-bs-dismiss="modal"
  >
    {{ rejectText }}
  </button>
</div>
