export enum Permissions {
  CA = 'CASH_ACCOUNT',
  AW = 'APPROVAL_WORKFLOW',
  AO = 'ALLOW_ORDER',
  PO = 'ALLOW_PURCHASE_ORDER',
  CC = 'ALLOW_CREDIT_CARD',
  APO = 'APPROVE_ORDER',
  CVAA = 'CAN_VIEW_ALL_ACCOUNTS',
  CVAST = 'CAN_VIEW_ALL_SHIP_TO',
  IEO = 'IS_ELEKNET_ONLY',
  CVP = 'CAN_VIEW_PRICE',
  ICO = 'IS_CONTRACT_ONLY',
  ECE = 'ENABLE_CUSTOMER_EQUOTE',
  EIE = 'ENABLE_ISR_EQUOTE',
  CUQL = 'CAN_UPDATE_QUICK_LIST',
  CVI = 'CAN_VIEW_INVOICE',
}

export const checkoutPermissions: string[] = [
  Permissions.CA,
  Permissions.AW,
  Permissions.AO,
  Permissions.PO,
  Permissions.CC,
  Permissions.APO,
];

export const showCheckout = [
  [Permissions.CA, Permissions.AO],
  [Permissions.AO, Permissions.CC],
  [Permissions.AO, Permissions.PO],
  [Permissions.CA, Permissions.AW, Permissions.AO],
  [Permissions.CA, Permissions.AO, Permissions.PO],
  [Permissions.CA, Permissions.AW, Permissions.APO],
  [Permissions.CA, Permissions.AO, Permissions.CC],
  [Permissions.CA, Permissions.AO, Permissions.APO],
  [Permissions.AW, Permissions.AO, Permissions.PO],
  [Permissions.AW, Permissions.AO, Permissions.CC],
  [Permissions.AW, Permissions.PO, Permissions.APO],
  [Permissions.AW, Permissions.CC, Permissions.APO],
  [Permissions.AO, Permissions.PO, Permissions.CC],
  [Permissions.AO, Permissions.PO, Permissions.APO],
  [Permissions.AO, Permissions.CC, Permissions.APO],
  [Permissions.CA, Permissions.AO, Permissions.PO, Permissions.CC],
  [Permissions.CA, Permissions.AO, Permissions.PO, Permissions.APO],
  [Permissions.CA, Permissions.AO, Permissions.CC, Permissions.APO],
  [Permissions.AW, Permissions.AO, Permissions.PO, Permissions.CC],
  [Permissions.AW, Permissions.AO, Permissions.PO, Permissions.APO],
  [Permissions.AW, Permissions.AO, Permissions.CC, Permissions.APO],
  [Permissions.AW, Permissions.PO, Permissions.CC, Permissions.APO],
  [Permissions.AO, Permissions.PO, Permissions.CC, Permissions.APO],
  [Permissions.CA, Permissions.AW, Permissions.AO, Permissions.PO],
  [Permissions.CA, Permissions.AW, Permissions.AO, Permissions.CC],
  [Permissions.CA, Permissions.AW, Permissions.AO, Permissions.APO],
  [Permissions.CA, Permissions.AW, Permissions.PO, Permissions.APO],
  [Permissions.CA, Permissions.AW, Permissions.CC, Permissions.APO],
  [Permissions.CA, Permissions.AW, Permissions.AO, Permissions.CC, Permissions.APO],
  [Permissions.CA, Permissions.AW, Permissions.PO, Permissions.CC, Permissions.APO],
  [Permissions.CA, Permissions.AO, Permissions.PO, Permissions.CC, Permissions.APO],
  [Permissions.AW, Permissions.AO, Permissions.PO, Permissions.CC, Permissions.APO],
  [Permissions.CA, Permissions.AW, Permissions.AO, Permissions.PO, Permissions.CC],
  [Permissions.CA, Permissions.AW, Permissions.AO, Permissions.PO, Permissions.APO],
  [Permissions.CA, Permissions.AW, Permissions.AO, Permissions.PO, Permissions.CC, Permissions.APO],
];

export const showCheckoutWithRestrictions = [
  [Permissions.AW, Permissions.APO],
  [Permissions.AW, Permissions.AO],
  [Permissions.AW, Permissions.AO, Permissions.APO],
];

export const showRestrictionsMsg = [
  [],
  [Permissions.PO],
  [Permissions.AO],
  [Permissions.CC],
  [Permissions.APO],
  [Permissions.CA, Permissions.PO],
  [Permissions.CA, Permissions.CC],
  [Permissions.CA, Permissions.APO],
  [Permissions.AO, Permissions.APO],
  [Permissions.PO, Permissions.CC],
  [Permissions.PO, Permissions.APO],
  [Permissions.CC, Permissions.APO],
  [Permissions.PO, Permissions.CC, Permissions.APO],
  [Permissions.CA, Permissions.PO, Permissions.CC],
  [Permissions.CA, Permissions.PO, Permissions.APO],
  [Permissions.CA, Permissions.CC, Permissions.APO],
  [Permissions.CA, Permissions.PO, Permissions.CC, Permissions.APO],
];

export const showSendForApproval = [
  [Permissions.AW],
  [Permissions.AW, Permissions.PO],
  [Permissions.CA, Permissions.AW],
  [Permissions.AW, Permissions.PO, Permissions.CC],
  [Permissions.CA, Permissions.AW, Permissions.CC],
  [Permissions.CA, Permissions.AW, Permissions.PO],
  [Permissions.CA, Permissions.AW, Permissions.PO, Permissions.CC],
];
