import { createAction, props } from '@ngrx/store';
import { UserModel } from '../../services/user/user.model';
import { Cart } from '../../../types/cart.model';
import { UserShoppingContext } from '../../services/customer-company/user-shopping-context.model';

export const logout = createAction(
  '[My Account] Logout',
  props<{
    user: UserModel | null | undefined;
    customerCompany: UserShoppingContext | null | undefined;
    cart: Cart | null | undefined;
    warehouse: string | null | undefined;
    userListIds: number[] | null | undefined;
  }>()
);
