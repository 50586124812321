import { Component, OnInit } from '@angular/core';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { KontentService } from '../../kontent.service';
import { Router } from '@angular/router';
import { LoadingSpinnerService } from '../../../../../shared/components/loading-spinner/loading-spinner.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '../../../../custom-validators/custom-validator';
import { ApiService } from 'src/app/core/services/api/api.service';
import { API_URL } from 'src/app/shared/consts/api-urls';
import { FormService } from '../../../../services/form/form.service';
import { ToastService } from '../../../../../shared/components/toast/toast.service';
import { DATA_TEST_ID } from 'src/app/shared/consts/data-test-ids';

@Component({
  selector: 'val-kontent-connect-with-us',
  templateUrl: './kontent-connect-with-us.component.html',
  styleUrls: ['./kontent-connect-with-us.component.scss'],
})
export class KontentConnectWithUsComponent implements OnInit {
  bannerContent: IContentItem;
  isSmall: boolean = false;
  DATA_TEST_ID = DATA_TEST_ID;

  constructor(
    private api: ApiService,
    public kontent: KontentService,
    private router: Router,
    private loadingService: LoadingSpinnerService,
    private formService: FormService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.contactForm.reset();
    this.getData();
  }

  public contactForm = new FormGroup({
    fullName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    companyName: new FormControl('', [Validators.maxLength(100)]),
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]),
    receiveCommunications: new FormControl(false, [Validators.required]),
    phoneNumber: new FormControl(null, [
      CustomValidator.patternValidator(this.formService.phoneRegEx, {
        isPhone: true,
      }),
    ]),
    recaptcha: new FormControl(null, [Validators.required]),
  });

  getData() {
    this.loadingService.start();
    this.kontent
      .getGenericPage('connect-with-us')
      .then((data) => {
        this.bannerContent = data.data.linkedItems['connect_with_us_banner'];
      })
      .catch((err) => {
        console.log('Error: ', err);
        this.router.navigate(['/404']);
      })
      .finally(() => {
        this.loadingService.stop();
      });
  }

  onSubmit() {
    this.contactForm.markAllAsTouched();
    if (this.contactForm.valid) {
      this.loadingService.start();
      this.api.post(`${API_URL.ConnectWithUs}`, this.contactForm.value).subscribe({
        next: (res) => {
          res && this.toast.showSuccess('Form sent successfully');
          this.contactForm.reset();
          this.loadingService.stop();
        },
        error: (error) => {
          this.toast.showError(error);
          this.loadingService.stop();
        },
      });
    } else {
      this.toast.showError('Make sure all required fields have been entered.');
    }
  }
}
