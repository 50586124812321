import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from './modal.model';

@Component({
  selector: 'val-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit {
  @Input() options: ModalOptions;

  title: string;
  titleClass: string;
  body: string | any;
  bodyClass: string;
  confirmText: string;
  confirmClass: string;
  rejectText: string;
  rejectClass: string;

  constructor(public activeModal: NgbActiveModal) {}

  // Promise.resolve(true) = User clicked the confirm button
  // Promise.resolve(false) = User clicked reject button
  // Promise.reject(<any>) = User cancelled the modal (clicked 'X' or clicked outside of modal)

  ngOnInit(): void {
    this.title = this.options?.title || '';
    this.titleClass = this.options?.titleClass || '';
    this.body = this.options?.body || '';
    this.bodyClass = this.options?.bodyClass || '';
    this.confirmText = this.options?.confirmText || 'OK';
    this.confirmClass = this.options?.confirmClass || '';
    this.rejectText = this.options?.rejectText || 'Cancel';
    this.rejectClass = this.options?.rejectClass || '';
  }
}
