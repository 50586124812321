import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'htmlEntityDecode',
})
export class HtmlEntityDecodePipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  transform(value: string): any {
    const newValue = value.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

    return this.sanitized.bypassSecurityTrustHtml(newValue);
  }
}
