export const environment = {
  production: true,
  apiRoot: 'https://prod-api.vallen.ca/',
  kontent: {
    deliveryProjectId: 'a0cee480-0e67-00bf-bb65-9e2756bad280',
    apiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjUxY2RiYzU2Y2U5MzQxOWQ5MmFkYzNiZTc1NmJlOGM2IiwNCiAgImlhdCI6ICIxNjkzNDI3NjgxIiwNCiAgImV4cCI6ICIxODUxMjgwMzgwIiwNCiAgInZlciI6ICIyLjAuMCIsDQogICJzY29wZV9pZCI6ICIxNjI5OTgyZjBlYmU0N2JlYjViNzE1ODg0NjU0ZjNkNyIsDQogICJwcm9qZWN0X2NvbnRhaW5lcl9pZCI6ICI1MWU4ZDU4YjczNzkwMDhiYjNiMjgwOTc5YjUxNmM3YSIsDQogICJhdWQiOiAiZGVsaXZlci5rb250ZW50LmFpIg0KfQ.kX87uNTqvUURN0WisYTTwSaVc_lnMRKWKIPTvFAhqXM',
  },
  algolia: {
    appId: 'LCZ09P4P1R',
    apiKey: 'a43f44a2001c896ebfc29a245e4902f6',
    site: 'ca',
    apiToken: 'pUtYVOARq6CPK5C4GQd2',
  },
  moneris: {
    environment: 'prod', // or 'qa'
  },
  companyNumber: 55,
  customerNumber: 202254,
  warehouse: 'FC',
  googleMapsApi: 'AIzaSyCxeRaHvdBZeGYKkVhf3kwy56hgvXRvwIA',
  recaptchaSiteKey: '6LdHQ7kmAAAAAOBhboHwSITDw7BUuWToGzTxeOSt',
  underMaintenance: false,
  gtmId: 'GT-PJ4N56L',
  localSSR: false,
  site: 'CA',
};
