<li *ngIf="!link.links?.length; else hasDropdown" class="nav-item">
  <a class="nav-link" [routerLink]="link.route" [attr.data-testid]="DATA_TEST_ID.navbar.link + '-' + link.title">{{
    link.title
  }}</a>
</li>

<ng-template #hasDropdown>
  <li class="nav-item dropdown">
    <a
      class="nav-link dropdown-toggle"
      data-bs-toggle="dropdown"
      href="#"
      role="button"
      aria-expanded="false"
      [attr.data-testid]="DATA_TEST_ID.navbar.dropDown + '-' + link.title | idFormatter"
    >
      <i *ngIf="link.icon" class="material-icons-outlined md-18">{{ link.icon }}</i>
      {{ link.title }}
    </a>
    <div class="dropdown-menu">
      <ul class="navbar-nav _sub">
        <li class="link" *ngFor="let subLink of link.links; let index = index">
          <a
            class="dropdown-item nav-link dropdown-toggle"
            [attr.data-bs-toggle]="subLink.type && isSmallScreen() && 'dropdown'"
            (click)="subLink.route ? goToRoute(subLink.route) : listService.showList($event, link, subLink)"
            [attr.data-testid]="DATA_TEST_ID.navbar.subLink + '-' + subLink.title | idFormatter"
          >
            {{ subLink.title }}
            <i class="material-icons-outlined list-icon md-18" *ngIf="!subLink.route">chevron_right</i>
          </a>

          <!-- Product List Navbar Component -->
          <val-navbar-product-lists
            *ngIf="subLink.type"
            class="lists dropright"
            [ngClass]="{ 'dropdown-menu': isSmallScreen() }"
            [user]="user"
            [link]="subLink"
          ></val-navbar-product-lists>
        </li>
      </ul>
    </div>
  </li>
</ng-template>
