import { Component, OnInit } from '@angular/core';
import { PageTitle } from '../../shared/components/page-title/page-title.model';
import { Router } from '@angular/router';

@Component({
  selector: 'val-unauthorized',
  template: ` <div class="container">
    <val-page-title [pageTitle]="pageTitle"></val-page-title>
    <div class="alert alert-danger m-5 text-center" [innerHTML]="message"></div>
  </div>`,
})
export class UnauthorizedComponent implements OnInit {
  public pageTitle = new PageTitle('Access Denied', 'lock', '');
  public message = `Your current account settings do not allow access to this page.<br />
      If you feel that you should have access to this page, please contact support.`;

  constructor(private router: Router) {
    // You may override the default message by passing in your own message from the sending page
    // example: this.router.navigate(['unauthorized'], { state: { msg: 'YOur message here' }});
    const state = this.router.getCurrentNavigation()?.extras?.state;
    if (state && state['msg']) {
      this.message = state['msg'];
    }
  }

  ngOnInit(): void {}
}
