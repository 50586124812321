import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private authService: AuthenticationService, private router: Router, private modalService: NgbModal) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isTokenExpired() && !this.authService.checkRefreshToken()) {
      void this.authService.openLoginModal();
      return false;
    }

    return this.checkUserLogin(next);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(next, state);
  }

  checkUserLogin(route: ActivatedRouteSnapshot): boolean {
    // To prevent access based on role or userType,
    // specify the appropriate role (string) or userType (array) in the routing module
    if (this.authService.isLoggedIn()) {
      const userRole = this.authService.getRole();
      const userType = this.authService.getUserType();
      if (
        (route.data['role'] && userRole.indexOf(route.data['role']) === -1) ||
        (route.data['userType'] && route.data['userType'].indexOf(userType) === -1)
      ) {
        void this.router.navigate(['/unauthorized']);
        return false;
      }
      return true;
    }

    void this.authService.openLoginModal();
    return false;
  }
}
