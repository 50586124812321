import { Component, OnInit } from '@angular/core';
import { PageTitle } from '../../shared/components/page-title/page-title.model';

@Component({
  selector: 'val-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  pageTitle = new PageTitle('Page does not exist or some other error occurred.', '', '');

  constructor() {}

  ngOnInit(): void {}
}
