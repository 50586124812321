import { Injectable } from '@angular/core';
import { camelCasePropertyNameResolver, DeliveryClient, IContentItem, IDeliveryClient } from '@kontent-ai/delivery-sdk';
import { environment } from '../../../../environments/environment';
import { AngularHttpService } from '../../services/angular-http/angular-http.service';
import { Meta, Title } from '@angular/platform-browser';
import { BreadcrumbsService } from '../breadcrumbs/breadcrumbs.service';
import { Breadcrumb } from '../breadcrumbs/breadcrumb.model';
import { ArticleItem } from './models/content-types/article_item';
import { GenericPage } from './models/content-types/generic_page';
import { ArticleCategoryItem } from './models/content-types/article_category_item';
import { ArticleListingPage } from './models/content-types/article_listing_page';
import { SupplierListingPage } from './models/content-types/supplier_listing_page';
import { BranchLocation } from './models/content-types/branch_location';
import { ContactUsPage } from './models/content-types/contact_us_page';
import { BusinessHours } from './models/content-types/business_hours';
import { MegaMenuContent } from './models/content-types/product_menu_marketing';
import { AccountDashboardB2BLandingPage } from './models/content-types/dashboard_b2b';
import { NavigationMenu } from './models/content-types/navigation_menu';
import { NavigationLinkItem } from './models/content-types/navigation_link_item';
import { NavLink } from '../../../shared/components/nav-link/nav-link.model';
import { NavbarLink } from '../navbar/navbar-link/navbar-link.model';
import { GlobalFooter } from './models/content-types/global_footer';
import { UtilityService } from '../../services/utility/utility.service';

@Injectable({
  providedIn: 'root',
})
export class KontentService {
  public deliveryClient: IDeliveryClient;
  private sectionClass: any = {
    banner: 'wide-banner fluid',
    fancy_image_banner: 'cubes-banner',
    formstack_form: 'form',
    left_image_fancy_component: 'txt-media fancy right',
    right_image_fancy_component: 'txt-media fancy left',
    left_image_overlapping_text_component: 'txt-media fancy olap right',
    right_image_overlapping_text_component: 'txt-media fancy olap left',
    left_image_list_component: 'txt-media list right',
    right_image_list_component: 'txt-media list left',
    n3_box_logos: 'box-3 logos',
    n4_box_logos: 'box-4 logos',
    n1_column: 'box-1',
    n2_columns: 'box-2',
    n3_columns: 'box-3',
    n4_columns: 'box-4',
    shared_asset_carousel: 'product-slider',
    two_columns_tiny_banner: 'box-2 tiny-banner',
  };
  public articleCategories: any[] = [];
  public categoryObject: any = {};
  public articleListingBanner: any;
  public dashboardBanner: any;

  constructor(
    angularHttpService: AngularHttpService,
    private breadcrumbService: BreadcrumbsService,
    private titleService: Title,
    private metaService: Meta,
    private utilityService: UtilityService
  ) {
    this.deliveryClient = new DeliveryClient({
      projectId: environment.kontent.deliveryProjectId,
      httpService: angularHttpService,
      defaultQueryConfig: {
        useSecuredMode: true,
      },
      propertyNameResolver: camelCasePropertyNameResolver,
      linkedItemsReferenceHandler: 'ignore',
      secureApiKey: environment.kontent.apiKey,
    });
  }

  public getSuppliers(codeName: string) {
    return this.deliveryClient.item<SupplierListingPage>(codeName).toPromise();
  }

  public getBusinessHours(codeName: string) {
    return this.deliveryClient.item<BusinessHours>(codeName).toPromise();
  }

  public getContactUs(codeName: string) {
    return this.deliveryClient.item<ContactUsPage>(codeName).toPromise();
  }

  public getBranch(slug: string) {
    return this.deliveryClient.items<BranchLocation>().equalsFilter('elements.action_url', slug).toPromise();
  }

  public getGenericPage(slug: string) {
    return this.deliveryClient.items<GenericPage>().equalsFilter('elements.slug_url', slug).toPromise();
  }

  public getLocationsPage() {
    return this.deliveryClient.items<BranchLocation>().equalsFilter('system.type', 'branch_location').toPromise();
  }

  public getArticle(slug: string) {
    return this.deliveryClient.items<ArticleItem>().equalsFilter('elements.url_slug', slug).toPromise();
  }

  public async getGlobalFooter() {
    return await this.deliveryClient
      .items<GlobalFooter>()
      .equalsFilter('system.type', 'global_footer')
      .toPromise()
      .then((res: any) => {
        this.sanitizeNonNavItemData(res);
        return res;
      })
      .then((footerObject: any) => {
        const footerNavLinkItems = footerObject.data.linkedItems.footer_navigation.elements.navigationLinkItems.value;
        return this.deliveryClient
          .items<NavigationMenu>()
          .inFilter('system.codeName', footerNavLinkItems)
          .toPromise()
          .then((footerNavLinks: any) => {
            footerObject.data.items[0].elements.footerNavigationMenu.linkedItems = this.handleSubMenuLinks(
              footerNavLinkItems,
              footerNavLinks
            );

            return footerObject.data.items[0];
          });
      });
  }

  /**
   * This method calls the Kontent API navigation menu with a filter on solutions so we can pull these dynamically.
   *
   * @returns an array of nav menu items with links
   */
  public getSolutionsMenu() {
    return this.deliveryClient
      .itemsFeed<NavigationMenu>()
      .equalsFilter('system.codeName', 'solutions')
      .toPromise()
      .then((res) => {
        // Return an array of strings to be used in our second call below.
        // @ts-ignore
        return res.data.items[0].elements.navigationLinkItems.value;
      })
      .then((subMenus) => {
        return this.deliveryClient
          .items<NavigationLinkItem>()
          .inFilter('system.codeName', subMenus)
          .toPromise()
          .then((subMenuLinks) => {
            // store links in object so we can reference them later and ensure they are displayed in the correct order
            let linkObj: any = {};
            Object.values(subMenuLinks.data.linkedItems).forEach((item: any) => {
              // TODO - May want to come back at some point and adjust links to check for/handle external URLs, but as of now all URLs are internal
              linkObj[item.system.codename] = {
                title: item.elements.title.value,
                route: item.elements.actionUrl.value,
              };
            });

            // generate solutionsMenu, looping through subMenus to make sure we display in correct order
            const solutionsMenu: NavbarLink[] = [];
            subMenus.forEach((codeName: string) => {
              const menu: NavbarLink = subMenuLinks.data.items
                .filter((x: NavigationLinkItem) => x.system.codename === codeName)
                .map((item: any) => {
                  const links: NavLink[] = [];
                  // populate links array in correct order
                  item.elements.navigationLinkItems.value.forEach((linkItem: any) => {
                    if (linkObj[linkItem]) {
                      links.push(linkObj[linkItem]);
                    }
                  });
                  return { title: item.elements.title.value.trim(), links };
                })[0];
              solutionsMenu.push(menu);
            });
            return solutionsMenu;
          });
      })
      .catch(() => {
        console.error('Error fetching SOLUTIONS menu');
        return [];
      });
  }

  public getArticleList(categoryId?: string) {
    let apiRequest = this.deliveryClient
      .itemsFeed<ArticleItem>()
      .equalsFilter('system.type', 'article_item')
      .orderByDescending('elements.date');
    if (categoryId) {
      apiRequest = apiRequest.containsFilter('elements.category', [categoryId]);
    }
    return apiRequest.toPromise();
  }

  public getArticleCategories() {
    // cached categories
    if (this.articleCategories.length) {
      return Promise.resolve(this.articleCategories);
    }

    return this.deliveryClient
      .items<ArticleCategoryItem>()
      .equalsFilter('system.type', 'article_category_item')
      .orderByDescending('system.last_modified')
      .toPromise()
      .then((res) => {
        this.articleCategories = res.data.items.map((i) => {
          return {
            id: i.system.codename,
            label: i.elements.label.value,
            urlSlug: i.elements.urlSlug.value,
          };
        });
        this.articleCategories.forEach((c: any) => {
          this.categoryObject[c.id] = { label: c.label, urlSlug: c.urlSlug };
        });
        return this.articleCategories;
      });
  }

  public getArticleListingBanner() {
    // cached banner
    if (this.articleListingBanner) {
      return Promise.resolve(this.articleListingBanner);
    }

    return this.deliveryClient
      .itemsFeed<ArticleListingPage>()
      .equalsFilter('system.type', 'article_listing_page')
      .toPromise()
      .then((res) => {
        return this.deliveryClient.item(res.data.items[0].elements.pageBanner.value[0]).toPromise();
      })
      .then((res) => {
        this.articleListingBanner = res.data.item.elements;
        return this.articleListingBanner;
      });
  }

  public getArticleCategory(codeName: string) {
    return this.deliveryClient.item<ArticleCategoryItem>(codeName).toPromise();
  }

  public getDashboardBanner() {
    // cached banner
    if (this.dashboardBanner) {
      return Promise.resolve(this.dashboardBanner);
    }

    return this.deliveryClient
      .itemsFeed<AccountDashboardB2BLandingPage>()
      .equalsFilter('system.type', 'dashboard_b2b')
      .toPromise()
      .then((res) => {
        return this.deliveryClient.item(res.data.items[0].elements.leaderboardBanner.value[0]).toPromise();
      })
      .then((res) => {
        this.dashboardBanner = res.data;
        return this.dashboardBanner;
      });
  }

  public getPageTitle(article: any): string {
    let title = '';
    if (article?.elements) {
      title = article.elements.metadataMetaTitle.value.length
        ? article.elements.metadataMetaTitle.value
        : article.elements.pageName
        ? article.elements.pageName.value
        : article.elements.title
        ? article.elements.title.value
        : '';
    }
    return title ? title : 'Vallen';
  }

  public setMeta(article: any, crumbs: boolean): void {
    if (article?.elements) {
      this.titleService.setTitle(this.getPageTitle(article));
      this.metaService.addTag({
        name: 'description',
        content: article.elements.metadataMetaDescription.value,
      });
      if (article.elements.pageName && article.elements.pageName.value && crumbs) {
        this.breadcrumbService.setBreadcrumbs([new Breadcrumb(article.elements.pageName.value, '')]);
      }
    }
  }

  public setArticleListingMeta(article: any, crumbs: boolean): void {
    this.metaService.addTag({
      name: 'description',
    });
    if (article.elements.pageName && article.elements.pageName.value && crumbs) {
      this.breadcrumbService.setBreadcrumbs([new Breadcrumb(article.elements.pageName.value, '')]);
    }
  }

  public async formatData(data: any): Promise<any[]> {
    const containers: string[] = data?.elements?.containers?.value || [];
    let formatted: any;

    if (containers.length) {
      await this.deliveryClient
        .items()
        .inFilter('system.codename', containers)
        .toPromise()
        .then((res) => {
          containers.forEach((codeName, toIndex) => {
            const fromIndex = res.data.items.findIndex((item) => item.system.codename === codeName);
            const element = res.data.items.splice(fromIndex, 1)[0];
            res.data.items.splice(toIndex, 0, element);
            formatted = res.data;
          });
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
    return formatted;
  }

  public getSectionStyles(content: any): any {
    if (!content || !content.items) {
      return {};
    }
    let styleObj: any = {};
    content.items.forEach((section: IContentItem) => {
      styleObj[section.system.id] = this.sectionStyles(section);
    });

    return styleObj;
  }

  private sectionStyles(section: IContentItem): string {
    // loop through data elements to extract class names
    let classes: string[] = [];
    if (this.sectionClass[section.system.type]) {
      classes.push(this.sectionClass[section.system.type]);
    }

    ['displayStyle', 'contentStyle', 'textOverImage', 'fullScreenDisplay', 'theme'].forEach((k) => {
      if (section.elements[k] && section.elements[k].value.length) {
        classes.push(
          section.elements[k].value
            .map((s: any) => {
              return this.sectionClass[s.codename] ? this.sectionClass[s.codename] : s.codename;
            })
            .join(' ')
        );
      }
    });
    const isFluid = classes.filter((c) => {
      return c.includes('fluid');
    });
    classes.push(section.system.type.includes('banner') || isFluid.length ? 'container-fluid' : 'container');

    return classes.join(' ').replace('brand_color', 'brand');
  }

  public getProductMenuAds() {
    let apiRequest = this.deliveryClient
      .itemsFeed<MegaMenuContent>()
      .equalsFilter('system.type', 'product_menu_marketing');
    return apiRequest.toPromise();
  }
  private handleSubMenuLinks(values: string[], res: any): NavbarLink[] {
    // Store links in object so we can reference them later and ensure they are displayed in the correct order
    let linkObj: any = {};
    Object.values(res.data.linkedItems).forEach((item: any) => {
      linkObj[item.system.codename] = {
        title: item.elements.title.value,
        route: !this.utilityService.isValidUrl(item.elements.actionUrl.value) ? item.elements.actionUrl.value : null,
        href: this.utilityService.isValidUrl(item.elements.actionUrl.value) ? item.elements.actionUrl.value : null,
      };
    });

    // Generate subMenu, looping through values array to make sure we display in the correct order
    const subMenu: NavbarLink[] = [];
    values.forEach((codename: string) => {
      const menu: NavbarLink = res.data.items
        .filter((x: NavigationLinkItem) => x.system.codename === codename)
        .map((item: any) => {
          const links: NavLink[] = [];
          // Populate links array in correct order
          item.elements.navigationLinkItems.value.forEach((linkItem: any) => {
            if (linkObj[linkItem]) {
              links.push(linkObj[linkItem]);
            }
          });
          return { title: item.elements.title.value.trim(), links };
        })[0];
      subMenu.push(menu);
    });
    return subMenu;
  }

  /**
   * This is a method to do some data manipulation to the kontent cms responses before they
   * reach the template code.
   *
   * @param response
   * @returns elementsObject
   */
  private sanitizeNonNavItemData(response: any) {
    if (!response.data.items[0]) {
      return;
    }
    /**
     * Create two arrays based on the elements object, one for the keys and the other the values
     */
    const elementKeys: any[] = Object.keys(response.data.items[0].elements);
    let elementValues: any[] = Object.values(response.data.items[0].elements);
    const linkedItems: any = response.data.linkedItems;

    response.data.items.forEach((responseItem: any) => {
      elementValues = Object.values(responseItem.elements);

      elementValues.forEach((element: any) => {
        // Do an array check on the element.value because not everything is returned as an array from the kontent cms
        if (Array.isArray(element.value)) {
          // Iterate over the element values
          element.value.forEach((item: any) => {
            /**
             * This check will grab the linkedItems specific to the item we are currently iterating through
             *
             * This is keying off the element.type being more specific than 'modular_content'
             */
            if (linkedItems[item]) {
              // Check for type business_hours as it's data structure is different to that of navLink_items
              this.pushToLinkedItems(element, linkedItems[item]);
            }
          });
        }
      });
    });

    /**
     * Now that we have an array of sanitized objects, we need to convert it back into an object so our components
     * can handle them with ease.
     */
    const elementsObject: Record<string, any> = {};
    elementKeys.forEach((key, index) => {
      elementsObject[key] = elementValues[index];
    });

    return elementsObject;
  }

  private pushToLinkedItems(element: any, linkedItem: any) {
    if (linkedItem.system.type === 'shared_text_item') {
      element.linkedItems.push({
        label: linkedItem.elements.label.value,
        link: linkedItem.elements.value.value,
      });
    } else if (linkedItem.system.type === 'branch_location') {
      element.linkedItems.push({
        type: linkedItem.system.type,
        actionUrl: linkedItem.elements.actionUrl.value,
        address: linkedItem.elements.address.value,
        city: linkedItem.elements.city.value,
        faxNumber: linkedItem.elements.faxNumber.value,
        name: linkedItem.elements.name.value,
        phoneNumber: linkedItem.elements.phoneNumber.value,
        postalCode: linkedItem.elements.postalCode.value,
        province: linkedItem.elements.province.value,
      });
    } else if (linkedItem.system.type === 'shared_asset') {
      element.linkedItems.push({
        type: linkedItem.system.type,
        label: linkedItem.elements.title?.value !== '' ? linkedItem.elements.title?.value : linkedItem.system.name,
        link: linkedItem.elements.actionUrl?.value,
        target: linkedItem.elements.target?.value[0]?.codename,
        image: linkedItem.elements.image?.value[0],
      });
    }
  }
}
